import {StyledSearchBar} from './styles';

type SearchBarProps = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: any) => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({value, onChange, onKeyDown}) => {
  return (
    <StyledSearchBar>
      <label htmlFor='inputSearch'>
        <i className='pi pi-search'></i>
      </label>
      <input type='search' id='inputSearch' placeholder='Search' value={value} onChange={onChange} onKeyDown={onKeyDown} />
    </StyledSearchBar>
  );
};
export default SearchBar;
