import {deleteClientNote, patchClientNote, postClientNote} from 'api/chatowl.api';
import {InfoNotes} from 'components/clients/note/info-notes';
import {useAppDispatch} from 'hooks';
import React, {useEffect, useState} from 'react';
import {hideLoading, showLoading, sendMessage} from 'store/action-creators';
import {signDate} from 'utils/helpers';
import {InputText} from 'primereact/inputtext';
import {
  PField,
  PropertyColumn,
  PTitle,
  PValue,
  PValueCaption,
  Section,
  SectionAction,
  SectionTitle,
  SectionTitleAction,
  SeparatorLine,
  Tag,
} from '../styles';
import {addTagToClient, removeTagFromClient} from 'api/chatowl.api';
import moment from 'moment';

type ClientInfoProps = {client: ClientDto};

export const ClientInfo: React.FC<ClientInfoProps> = ({client}) => {
  const [newNote, setNewNote] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteDto[]>([]);
  const [newTag, setNewTag] = useState<boolean>(false);
  const [newTagText, setNewTagText] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<TagDTO>();

  const dispatch = useAppDispatch();

  const saveNote = async (note: string) => {
    const noteRequest: CreateSimpleNoteRequest = {note, noteType: 'general'};
    dispatch(showLoading());
    const response = (await postClientNote(noteRequest, client.username)) as any;
    const newNote = response.data.data as NoteDto;
    notes.push(newNote);
    dispatch(hideLoading());
    setNewNote(false);
  };

  const editNote = async (noteId: number, note: string) => {
    const noteRequest: UpdateNoteRequest = {note};
    dispatch(showLoading());
    const response = (await patchClientNote(noteRequest, noteId, client.username)) as any;
    const editedNote = response.data.data as NoteDto;
    notes.map((n) => {
      if (n.id === editedNote.id) n.note = editedNote.note;
      return n;
    });
    dispatch(hideLoading());
  };

  const deleteNote = async (noteId: number) => {
    if (newNote) setNewNote(false);
    else {
      dispatch(showLoading());
      await deleteClientNote(noteId, client.username);
      const index = notes.findIndex((n) => n?.id === noteId);
      delete notes[index];
      dispatch(hideLoading());
    }
  };

  const addTag = async () => {
    if (!newTagText) {
      setNewTag(false);
      return;
    }
    if (client.tags.some((t: TagDTO) => t.tag === newTagText)) {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Tag already exists in client!'}));
      return;
    }
    const tagRequest: TagRequest = {
      tag: newTagText,
    };
    dispatch(showLoading());
    const response = (await addTagToClient(tagRequest, client.username)) as any;
    const newTag = response.data.data as TagDTO;
    client.tags.push(newTag);
    dispatch(hideLoading());
    dispatch(
      sendMessage({
        severity: 'success',
        summary: `Tag ${newTagText} added successfully`,
      })
    );
    setNewTag(false);
    setNewTagText('');
  };

  const removeTag = async (tagId: number) => {
    dispatch(showLoading());
    await removeTagFromClient(tagId, client.username);
    const index = client.tags.findIndex((t) => t?.id === tagId);
    delete client.tags[index];
    dispatch(hideLoading());
    setSelectedTag(undefined);
  };

  const handleKeysInAddTag = async (e: any) => {
    if (e.code === 'Enter') await addTag();
    if (e.code === 'Escape') {
      setNewTag(false);
      setNewTagText('');
    }
  };

  useEffect(() => {
    setNotes(client.notes);
  }, [client]);
  return (
    <>
      <Section>
        <PropertyColumn>
          <PField>
            <PTitle>Location</PTitle>
            <PValue>{client.location}</PValue>
          </PField>
          <PField>
            <PTitle>Last seen</PTitle>
            <PValue title={moment(client.lastSeen).calendar()}>
              {client.lastSeen ? moment(client.lastSeen).fromNow() : null}
            </PValue>
          </PField>
          <PField>
            <PTitle>Activity</PTitle>
            <PValue>{client.activity}</PValue>
          </PField>
        </PropertyColumn>
        <PropertyColumn>
          <PField>
            <PTitle>Push Notifications</PTitle>
            <PValue>{client.pushNotification}</PValue>
          </PField>
          <PField>
            <PTitle>Signed up</PTitle>
            <PValue title={moment(client.signedUp).calendar()}>{moment(client.signedUp).fromNow()}</PValue>
          </PField>
        </PropertyColumn>
      </Section>
      <SeparatorLine />
      <SectionTitle>Assessment Results</SectionTitle>
      <Section>
        <PropertyColumn>
          <PField>
            <PTitle>Time investent</PTitle>
            <PValue>Quite a bit</PValue>
          </PField>
        </PropertyColumn>
        <PropertyColumn>
          <PField>
            <PTitle>Anxiety and depresion test</PTitle>
            <PValue>{client.testsResults}</PValue>
          </PField>
        </PropertyColumn>
      </Section>
      <SeparatorLine />
      <SectionTitle>Therapy Plan</SectionTitle>
      <Section>
        <PropertyColumn>
          <PField>
            <PTitle>Current therapy plan</PTitle>
            <PValue>{client.clientProgram?.name}</PValue>
            <PValueCaption>
              {client.clientProgram?.startDate ? (
                <div>
                  <span>Since </span>
                  <span title={moment(client.clientProgram?.startDate).calendar()}>{`${signDate(
                    client.clientProgram?.startDate
                  )}`}</span>
                </div>
              ) : null}
            </PValueCaption>
          </PField>
        </PropertyColumn>
        <PropertyColumn>
          <PField>
            <PTitle>Position in plan</PTitle>
            <PValue>{client.clientProgram?.programPosition}</PValue>
          </PField>
        </PropertyColumn>
      </Section>
      <SeparatorLine />
      {/*
        *
        *
        * * Not implemented yet
        * 
        * 
      <SectionTitle>Subscription</SectionTitle>
      <Section>
        <PropertyColumn>
          <PField>
            <PTitle>Subription status</PTitle>
            <PValue>{client.subscription.status}</PValue>
            <PValue>{client.subscription.status}</PValue>
            <PValueCaption>
              {client.subscription.status} {client.subscription.subscriptionType} on {client.subscription.updateDate}
            </PValueCaption>
          </PField>
        </PropertyColumn>
        <PropertyColumn>
          <PField>
            <PTitle>Total revenue</PTitle>
            <PValue>{client.subscription.totalRevenue}</PValue>
          </PField>
        </PropertyColumn>
      </Section> */}
      <SeparatorLine />
      <SectionTitleAction>
        <SectionTitle>Tags</SectionTitle>
        <SectionAction
          onClick={() => {
            if (selectedTag) removeTag(selectedTag.id);
          }}
          hidden={!selectedTag}
          title='Remove selected tag from client'
        >
          <i className='pi pi-trash' style={{color: '#EB5757', fontSize: '1.6rem'}}></i>
        </SectionAction>
      </SectionTitleAction>
      <Section>
        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}}>
          {client.tags.map((t: TagDTO) => (
            <Tag
              color={selectedTag?.id === t.id ? '#C4C4C4' : '#F0F0F0'}
              onClick={() => (selectedTag?.id === t.id ? setSelectedTag(undefined) : setSelectedTag(t))}
              size='medium'
            >
              {t.tag}
            </Tag>
          ))}
          <Tag
            color='#F6F6F6'
            style={{border: '3px solid #2C98F0', color: '#2C98F0', backgroundColor: 'white', cursor: 'pointer'}}
            onClick={() => {
              setNewTag(true);
            }}
            size='medium'
          >
            + Add Tag
          </Tag>
        </div>
      </Section>
      <Section>
        <div style={{display: 'flex', marginBottom: '15px', width: '100%'}}>
          {newTag && (
            <>
              <InputText
                autoFocus
                placeholder='Enter new tag...'
                onChange={(e: any) => setNewTagText(e.target.value)}
                onBlur={addTag}
                onKeyDown={handleKeysInAddTag}
                style={{width: '100%'}}
              ></InputText>
            </>
          )}
        </div>
      </Section>
      <SeparatorLine />
      <SectionTitleAction>
        <SectionTitle>Notes</SectionTitle>
        <SectionAction
          onClick={() => {
            setNewNote(true);
          }}
        >
          New note
        </SectionAction>
      </SectionTitleAction>
      <Section>
        <InfoNotes
          saveNote={saveNote}
          editNote={editNote}
          deleteNote={deleteNote}
          newNote={newNote}
          notes={client.notes}
        ></InfoNotes>
      </Section>
    </>
  );
};
