import {getProgramDetails, patchTherapyProgram, updateVideo} from 'api/chatowl.api';
import {FormElement, StyledFormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {
  CancelButton,
  Form,
  PageBody,
  PageContainer as GlobalPageContaner,
  PageFooter,
  PageHeader,
  PageTitle,
  SaveButton,
} from 'components/page';
import {DefaultProgramAssignment} from 'components/therapy-programs/default-program-assignment';
import {useAppDispatch} from 'hooks';
import {SelectButton} from 'primereact/selectbutton';
import {Toast} from 'primereact/toast';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import styled from 'styled-components';
import {isAnyStringEmpty} from 'utils/helpers';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;
interface RouteParam {
  id: string;
}

function validateSave(name: string, tagline: string, intro: string, description: string, mediaId: number | undefined): boolean {
  return !!name && mediaId !== 0 && !!tagline && !!intro && !!description;
}

export const EditProgram: React.FC<{title?: string}> = ({title}) => {
  const [programId, setProgramId] = useState<number>();
  const [programName, setProgramName] = useState('');
  const [programTagLine, setProgramTagLine] = useState('');
  const [programIntro, setProgramIntro] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [isCurrentDefaultProgram, setIsCurrentDefaultProgram] = useState(false); // state of the default program setting after edition
  const [willBeDefaultProgram, setWillBeDefaultProgram] = useState(false); // state of the default program setting while editing
  const [savingProgram, setSavingProgram] = useState(false);
  const [status, setStatus] = useState<ProgramStatus>();
  const [programImageUrl, setProgramImageUrl] = useState('');
  const [initialStatus, setInitialStatus] = useState('');
  const [newCrops, setNewCrops] = useState<Crop[]>([]);
  const [programType, setProgramType] = useState<ProgramVersion>();

  const toast: React.MutableRefObject<any> = useRef(null);
  const [mediaId, setMediaId] = useState<number>();
  const [media, setMedia] = useState<MediaDto>();

  const history = useHistory();

  const params = useParams<RouteParam>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchProgramDetail = async () => {
      try {
        dispatch(showLoading());
        const response = (await getProgramDetails(+params.id)) as any;
        const therapyProgram = response.data.data as ProgramDto;
        setProgramId(+therapyProgram.id);
        setProgramName(therapyProgram.name);
        setProgramDescription(therapyProgram.description);
        setIsCurrentDefaultProgram(therapyProgram.isDefault);
        setWillBeDefaultProgram(therapyProgram.isDefault);
        setStatus(therapyProgram.status);
        setInitialStatus(therapyProgram.status);
        setMedia(therapyProgram.media);
        setMediaId(therapyProgram.mediaId);
        setProgramTagLine(therapyProgram.tagline);
        setProgramIntro(therapyProgram.intro);
        setProgramType(therapyProgram.version);
      } catch (error) {
        throw error; // TODO
      } finally {
        dispatch(hideLoading());
      }
    };
    fetchProgramDetail();
  }, []);

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onProgramNameChange = (programName: string) => {
    setProgramName(programName);
  };

  const onProgramTagLineChange = (programTagLine: string) => {
    setProgramTagLine(programTagLine);
  };

  const onProgramDescriptionChange = (programDescription: string) => {
    setProgramDescription(programDescription);
  };

  const onProgramIntroChange = (programIntro: string) => {
    setProgramIntro(programIntro);
  };

  const onCancelProgram = () => {
    setProgramName('');
    setProgramDescription('');
    setIsCurrentDefaultProgram(false);
    setSavingProgram(false);
    history.push(`/therapy-programs/${programId}`);
  };

  const onSaveProgram = async () => {
    if (
      validateSave(programName, programTagLine, programIntro, programDescription, mediaId) &&
      !isAnyStringEmpty([programName, programTagLine, programIntro, programDescription])
    ) {
      dispatch(showLoading());
      let errorEditingMedia = false;
      setSavingProgram(true);
      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
        const media: UpdateMediaVideoRequest = {
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
          crops: newCrops.map((crop) => ({type: crop.type, data: crop.data})),
        };
        const mediaVideo = (await updateVideo(mediaId!, media)) as any;
        if (!(mediaVideo.response.status >= 200 && mediaVideo.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          setSavingProgram(false);
          errorEditingMedia = true;
        }
      }
      if (!errorEditingMedia) {
        try {
          const editedProgram: UpdateProgramRequest = {
            name: programName,
            description: programDescription,
            language: 'en-US',
            status: status,
            isDefault: willBeDefaultProgram,
            mediaId: mediaId!,
            tagline: programTagLine,
            intro: programIntro,
          };
          const therapyProgram = (await patchTherapyProgram(programId!, editedProgram)) as any;
          if (therapyProgram.response.status >= 200 && therapyProgram.response.status < 300) {
            dispatch(
              sendMessage({
                severity: 'success',
                summary: 'Program successfully updated',
                detail: therapyProgram.data.data.name,
              })
            );
            history.push(`/therapy-programs/${programId}`);
          } else {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
            setSavingProgram(false);
            dispatch(hideLoading());
          }
        } catch (error) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          console.error(error);
        } finally {
          setSavingProgram(false);
          dispatch(hideLoading());
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Fields can not be empty.'}));
      dispatch(hideLoading());
    }
  };

  const selectButtonValues1 = [
    {name: 'Draft', value: 'draft', disabled: initialStatus === 'published'},
    {name: 'Published', value: 'published'},
    {name: 'Archived', value: 'archived'},
  ];

  const onChangeStatus = (e: any) => {
    if (!!e.value) setStatus(e.value);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Edit Therapy Program</PageTitle>
      </PageHeader>
      <PageBody>
        <Form>
          <div style={{marginBottom: '30px', width: '100%'}}>
            <SelectButton
              style={{width: 'fit-content'}}
              value={status}
              onChange={onChangeStatus}
              options={selectButtonValues1}
              optionLabel='name'
              tooltip={initialStatus === 'published' ? 'This program is already published and cannot be draft.' : undefined}
              tooltipOptions={{position: 'left'}}
            />
          </div>
          <FormElement
            type='input'
            label='Name'
            value={programName}
            placeholder='Therapy program name'
            onChange={onProgramNameChange}
            // helper={`${programType ? capitalize(programType) : 'Full'} version`}
          />
          <FormElement
            type='input'
            label='Tagline'
            value={programTagLine}
            placeholder='Therapy program Tagline'
            onChange={onProgramTagLineChange}
          />

          <FormElement
            type='text-area'
            label='Intro'
            value={programIntro}
            placeholder='Therapy program intro'
            onChange={onProgramIntroChange}
          />
          <FormElement
            type='text-area'
            label='Description'
            value={programDescription}
            placeholder='Therapy program description'
            onChange={onProgramDescriptionChange}
          />
          <DefaultProgramAssignment
            isCurrentDefaultProgram={isCurrentDefaultProgram}
            willBeDefaultProgram={willBeDefaultProgram}
            setWillBeDefaultProgram={setWillBeDefaultProgram}
          />
        </Form>
        <StyledFormElement>
          <MediaChooser
            onChooseMedia={setMediaId}
            media={media}
            mediaType={'video'}
            imagesTypes={['full_width_regular', 'full_width_tall']}
            addCrop={addCrop}
            actualName={programName}
          />
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onCancelProgram}>Cancel</CancelButton>
        <SaveButton onClick={onSaveProgram} disabled={savingProgram} disable={savingProgram}>
          Save
        </SaveButton>
      </PageFooter>
      <Toast ref={toast} />
    </PageContainer>
  );
};
