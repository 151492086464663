import {CategoriesUl} from 'components/info/categories-ul';
import {useHistory} from 'react-router-dom';
import {
  DetailButtonLink as IDButtonLink,
  DetailField as IDField,
  DetailFieldImage as IDFieldImage,
  DetailFieldLabel as IDFieldLabel,
  DetailFieldValue as IDFieldValue,
  DetailHeader as IDHeader,
  DetailID as IDID,
  DetailTitle as IDTitle,
  Label,
} from '../../../components/tools/main-page-components';

type ImageDetailsProps = {
  image: ImageDto | null;
};

export type DropdownType = {
  label: string;
  value: string | number;
};

export const ImageDetails: React.FC<ImageDetailsProps> = ({image}) => {
  const history = useHistory();
  return (
    <div>
      <div>
        <IDHeader>
          <div style={{display: 'flex'}}>
            <IDTitle>Image Info</IDTitle>
            {image?.isFree && <Label color={'#20C5A0'}>Free</Label>}
          </div>
          <div>
            <IDButtonLink onClick={() => history.push(`/tools/images/edit/${image?.id}`)}>
              {/* <i className='pi pi-external-link' style={{marginRight: '10px'}}></i> */}
              Edit
            </IDButtonLink>
          </div>
        </IDHeader>
        <IDID>{image?.id}</IDID>
      </div>
      <div>
        <IDField>
          <IDFieldLabel>Category</IDFieldLabel>
          <IDFieldValue>
            <CategoriesUl categories={image?.toolCategories}></CategoriesUl>
          </IDFieldValue>
        </IDField>
        <IDField>
          <IDFieldLabel>Title</IDFieldLabel>
          <IDFieldValue>{image?.title}</IDFieldValue>
        </IDField>
        <IDField>
          <IDFieldLabel>Description</IDFieldLabel>
          <IDFieldValue>{image?.description}</IDFieldValue>
        </IDField>
        {image?.promotedTool && (
          <IDField>
            <IDFieldLabel>Promoted {image?.promotedTool?.toolType}</IDFieldLabel>
            <IDFieldValue>{image?.promotedTool?.title}</IDFieldValue>
          </IDField>
        )}
        <IDField>
          <IDFieldLabel>Image</IDFieldLabel>
          <IDFieldImage src={image?.media?.crops.find((crop) => crop.type === 'full_width_tall')?.url} alt={image?.title} />
          {/*VER COMO SE LLAMA EL ATRIBUTO EN EL QUE VIENE LA IMAGE Y CAMBIARLO POR image.title*/}

          <div style={{display: 'flex', flexDirection: 'column'}}>
            {image?.media?.crops.find((crop) => crop.type === 'squared') && (
              <img
                style={{maxWidth: '40%', marginTop: '20px'}}
                src={image?.media?.crops.find((crop) => crop.type === 'squared')?.url}
                alt='squared crop'
              ></img>
            )}

            {image?.media?.crops.find((crop) => crop.type === 'full_width_regular') && (
              <img
                style={{width: '90%', marginTop: '20px'}}
                src={image?.media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                alt='full_width_regular crop'
              ></img>
            )}
            {image?.media?.crops.find((crop) => crop.type === 'full_screen_landscape') && (
              <img
                style={{maxWidth: '100%', marginTop: '20px'}}
                src={image?.media?.crops.find((crop) => crop.type === 'full_screen_landscape')?.url}
                alt='full_screen_landscape crop'
              ></img>
            )}

            {image?.media?.crops.find((crop) => crop.type === 'full_screen_portrait') && (
              <img
                style={{maxWidth: '50%', marginTop: '20px', marginBottom: '10px'}}
                src={image?.media?.crops.find((crop) => crop.type === 'full_screen_portrait')?.url}
                alt='full_screen_portrait crop'
              ></img>
            )}
            {image?.media?.crops.find((crop) => crop.type === 'full_width_tall') && (
              <img
                style={{maxWidth: '60%', marginTop: '20px', marginBottom: '10px'}}
                src={image?.media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                alt='full_width_tall crop'
              ></img>
            )}
          </div>
        </IDField>
      </div>
    </div>
  );
};
export default ImageDetails;
