import styled from 'styled-components';
import {PageHeader as PageHeaderGlobal} from '../styles';

export const Container = styled.div`
  width: 100%;
  padding: 0px 0px 0px 3%;
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const PageHeader = styled(PageHeaderGlobal)`
  border-bottom: solid 1px #b4c1c1;
  margin-bottom: 0px;
  padding-bottom: 42px;
`;

export const LeftContainer = styled.div`
  display: flex;
  width: 60%;
  border-right: 1px solid #b4c1c1;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3% 0 3% 0;
`;

export const RightContainer = styled.div`
  padding: 2% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  border: solid 1px #b4c1c1;
  border-radius: 3px;
  height: 100%;
  margin: 6% 0;
  padding: 6%;
  flex-direction: column;
`;

export const ClientName = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 3%;
`;

export const ClientInfo = styled.div`
  font-size: 20px;
  margin: 3% 0;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
`;

export const BoxMessageContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(180, 193, 193, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  color: #495057;
  margin-top: 18px;
  height: fit-content;
`;
