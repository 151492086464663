import styled from 'styled-components';

export const StyleAddVideoFilesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

export const StyleAddVideoFiles = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 250px;
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;

  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
