import React, {useState} from 'react';
import {NoteBody, Note, Notes, Icon, NewNote, NoteFooter, RedButtonNote, DefaultButtonNote, InputNote} from './styles';

type InfoNotesProps = {
  notes: NoteDto[];
  newNote: boolean;
  saveNote: (valueNote: string) => void;
  editNote: (noteId: number, note: string) => void;
  deleteNote: (noteId: number) => void;
};

export const InfoNotes: React.FC<InfoNotesProps> = ({notes, newNote, saveNote, editNote, deleteNote}) => {
  const [valueNote, setValueNote] = useState('');
  const [editing, setEditing] = useState<number | undefined>(undefined);
  const [editValue, setEditValue] = useState('');

  const onDeleteNote = () => {
    deleteNote(editing!);
    setEditing(undefined);
  };

  const onEditNote = () => {
    editNote(editing!, editValue);
    setEditing(undefined);
  };

  const onSaveNote = () => {
    saveNote(valueNote);
    setValueNote('');
  };

  const onEditing = (note: NoteDto) => {
    setEditing(note.id);
    setEditValue(note.note);
  };

  return (
    <Notes>
      {newNote && (
        <>
          <NewNote>
            <InputNote onChange={(e) => setValueNote(e.target.value)} value={valueNote} placeholder='Type something...' />
          </NewNote>
          <NoteFooter>
            <RedButtonNote onClick={onDeleteNote}>Delete note</RedButtonNote>
            <DefaultButtonNote onClick={onSaveNote}>Save note</DefaultButtonNote>
          </NoteFooter>
        </>
      )}
      {notes.map((n) => (
        <>
          {editing === n.id ? (
            <>
              <NewNote>
                <InputNote onChange={(e) => setEditValue(e.target.value)} value={editValue} placeholder='Type something...' />
              </NewNote>
              <NoteFooter>
                <RedButtonNote onClick={onDeleteNote}>Delete note</RedButtonNote>
                <DefaultButtonNote onClick={onEditNote}>Save note</DefaultButtonNote>
              </NoteFooter>
            </>
          ) : (
            <Note onClick={() => onEditing(n)}>
              <NoteBody>{n.note}</NoteBody>
              <Icon>
                <i className='pi pi-chevron-right'></i>
              </Icon>
            </Note>
          )}
        </>
      ))}
    </Notes>
  );
};
