import styled from 'styled-components';
export {
  CardContent,
  CardImage,
  CardThumbnail,
  CardsWrapper,
  CardTitle,
  ImageTag,
  MediaType,
  FilterWrapper,
  ToggleContainer,
  PDButtonLink,
  PDTitle,
  PDID,
  PDHeader,
  PDField,
  PDFieldValue,
  Label,
  PDFieldLabel,
};

type CardContentProp = {
  isSelected: boolean;
};
const CardContent = styled.div`
  width: 430px;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 0px;
  border-color: ${(props: CardContentProp) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
`;

const CardImage = styled.div`
  display: flex;
  width: 93%;
  height: 78%;
  border-radius: 8px;
  background-color: #d3d3d3;
  position: relative;
  justify-content: center;
`;

const CardTitle = styled.div`
  width: 100%;
  margin-left: 10%;
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
`;

const ImageTag = styled.div`
  background-color: black;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  display: flex;
`;

const MediaType = styled.div`
  background-color: rgba(41, 59, 85, 0.5);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1px;
  margin-top: 20px;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 10px;
`;

const CardThumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`;

const PDHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PDTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 8px;
`;

const PDButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

const PDID = styled.div`
  color: #6c757d;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  margin-bottom: 24px;

  :before {
    content: 'ID: ';
  }
`;

const PDField = styled.div`
  margin-bottom: 32px;
`;

const PDFieldLabel = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 8px;
  user-select: none;
`;

const PDFieldValue = styled.div`
  color: #010101;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
`;

const Label = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 12px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  min-width: 66px;
`;

export const MediasNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;
  :after {
    content: 'Media Bank is empty';
  }
`;
