import {Interaction} from 'components/message';
import {Icon} from 'components/message-row/styles';
import {PageTitle, PDButtonBack} from 'components/page';
import SearchBar from 'components/search-bar';
import {ScrollPanel} from 'primereact/scrollpanel';
import {SelectButton} from 'primereact/selectbutton';
import React, {useState, useEffect} from 'react';
import {PageContainer} from '../styles';
import {
  PageHeader,
  Container,
  LeftContainer,
  RightContainer,
  SearchContainer,
  Content,
  ClientName,
  ClientInfo,
  BoxMessageContainer,
} from './styles';
import {ClientChat} from '../../clients/details/client-chat';
import {getClientDetails, getInteraction, getClientInteractions} from 'api/chatowl.api';
import {useParams, useHistory} from 'react-router-dom';
import {signDate} from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';

type Option = {
  name: string;
  value: number;
};

const selectOptions: Option[] = [
  {name: 'Information', value: 0},
  {name: 'Chat', value: 1},
];

interface RouteParam {
  interactionId: string;
}

type AnswerMessageProps = {title?: string};

export const AnswerMessage: React.FC<AnswerMessageProps> = ({title}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState(0);
  const [client, setClient] = useState<ClientDto>();
  const [interaction, setInteraction] = useState<InteractionDTO>();
  const [interactions, setInteractions] = useState<InteractionDTO[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const params = useParams<RouteParam>();
  const history = useHistory();

  const [currentSelectedInteractionId, setCurrentSelectedInteractionId] = useState('');

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const int = params.interactionId;
    const fetchInteraction = async () => {
      try {
        const response = (await getInteraction(int)) as any;
        const resInteraction = response.data.data as InteractionDTO;
        setInteraction(resInteraction);
        setClient(resInteraction.client);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchInteraction();
  }, []);

  useEffect(() => {
    const fetchClientHistory = async () => {
      try {
        const resInteractions = (await getClientInteractions(interaction!.client!.username)) as any;
        const resInts = resInteractions.data.data as Array<InteractionDTO>;
        setInteractions(resInts);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    if (interaction) {
      fetchClientHistory();
      setCurrentSelectedInteractionId(interaction.id);
    }
  }, [interaction]);

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const interactionSkeleton = [1, 2, 3, 4, 5, 6];

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Answer Message</PageTitle>
        <PDButtonBack onClick={() => history.push('/messaging')}>
          <i className='pi pi-angle-left' style={{fontSize: '1.5rem'}}></i>
          Back to list
        </PDButtonBack>
      </PageHeader>
      <Container>
        <LeftContainer>
          <SearchContainer>
            <SearchBar onChange={onChangeSearchValue} value={searchValue} />
          </SearchContainer>
          <ScrollPanel style={{width: '100%', height: '79vh', paddingRight: '6%'}}>
            {isLoading ? (
              interactionSkeleton.map((i) => (
                <BoxMessageContainer style={{height: '30%'}}>
                  <Skeleton style={{height: '100%', width: '100%'}} />
                </BoxMessageContainer>
              ))
            ) : interactions?.length ? (
              interactions
                .filter(
                  (i) =>
                    (i.clientQuestion[0].type === 'text' &&
                      i.clientQuestion[0].text.toLowerCase().includes(searchValue.toLowerCase())) ||
                    i.counselorAnswer?.text.toLowerCase().includes(searchValue.toLowerCase()) ||
                    i.clientQuestion[0].type === 'image' ||
                    i.clientQuestion[0].type === 'audio' ||
                    i.clientQuestion[0].type === 'video'
                )
                .map((i) => (
                  <Interaction
                    interaction={i}
                    searchValue={searchValue}
                    setCurrentSelectedInteractionId={setCurrentSelectedInteractionId}
                    selectedInteractionId={currentSelectedInteractionId}
                  />
                ))
            ) : (
              <h5 style={{textAlign: 'center', margin: '5% 5% 5% 5%'}}>No messages found...</h5>
            )}
          </ScrollPanel>
        </LeftContainer>
        <RightContainer>
          <SelectButton
            value={selectValue}
            options={selectOptions}
            onChange={(e) => setSelectValue(e.value)}
            optionLabel='name'
          />
          <Content>
            {selectValue === 0 && client ? (
              <>
                <ClientName>{client.alias}</ClientName>
                {client.clientProgram && (
                  <ClientInfo>
                    <Icon width='20' src='/assets/layout/images/icons/paper-icon.svg' />
                    {client.clientProgram.name}
                  </ClientInfo>
                )}
                <ClientInfo>
                  <Icon width='20' src='/assets/layout/images/icons/calendar-clock-icon.svg' />
                  Using app{' '}
                  {signDate(client.signedUp.toString()) === 'Today' || signDate(client.signedUp.toString()) === 'Yesterday'
                    ? 'since ' + signDate(client.signedUp.toString())
                    : 'for ' + signDate(client.signedUp.toString())!.slice(0, -3)}
                </ClientInfo>
                <ClientInfo>
                  <Icon width='20' src='/assets/layout/images/icons/card-icon.svg' />
                  Subscribed
                </ClientInfo>
              </>
            ) : (
              <>{client && <ClientChat client={client} withNotes={false} />}</>
            )}
          </Content>
        </RightContainer>
      </Container>
    </PageContainer>
  );
};
