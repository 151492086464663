export function repeatValue<T>(v: T, times: number) {
  const elems = [] as T[];
  for (let i = 0; i < times; i++) {
    elems.push(v);
  }
  return elems;
}

export function repeatText(char: string, times: number) {
  return repeatValue(char, times).join('');
}
