import styled from 'styled-components';

const [categoryIcon, categoryIcon2x, categoryIcon3x] = [
  require('./images/category-card-icon.png').default,
  require('./images/category-card-icon@2x.png').default,
  require('./images/category-card-icon@3x.png').default,
];
const [normalIcon, normalIcon2x, normalIcon3x] = [
  require('./images/normal-card-icon.png').default,
  require('./images/normal-card-icon@2x.png').default,
  require('./images/normal-card-icon@3x.png').default,
];

type GeneralCardContainerType = {};

export const SubCategoryIconChild = styled.div`
  background: url('${categoryIcon}');
  background: image-set(url('${categoryIcon}') 1x, url('${categoryIcon2x}') 2x, url('${categoryIcon3x}') 3x);
  flex: 0 0 25px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const NormalIconCategoryChild = styled.div`
  background: url('${normalIcon}');
  background: image-set(url('${normalIcon}') 1x, url('${normalIcon2x}') 2x, url('${normalIcon3x}') 3x);
  flex: 0 0 25px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const GeneralCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-color: ${(props: GeneralCardContainerType) => 'transparent'};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 8px;

  min-width: 0;
  padding: 6px;
  transition: 0.1s;
  user-select: none;

  flex: 1;
  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const GeneralCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
`;

export const GeneralCardHeader = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
  flex-direction: column;
  margin-left: 9px;
`;

export const GeneralCardType = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #6c757d;
`;
export const GeneralTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #010101;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Arial;
`;

export const GeneralDescription = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  height: 21px;
  margin: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const GeneralCategory = styled.div`
  font-weight: bold;
  margin-left: 8px;
  min-height: 23px;
  max-height: 23px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type GeneralImageProps = {
  isSidebarOpen: boolean;
};

export const GeneralCategoryAuthor = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const GeneralImage = styled.img`
  align-self: center;
  border-radius: 4px;
  width: 40px;
  width: 40px;
  object-fit: cover;
  margin-left: 30px;
`;
