import {useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  GeneralCardContainer,
  GeneralCardHeader,
  GeneralCardInfo,
  GeneralCategory,
  GeneralCategoryAuthor,
  GeneralDescription,
  GeneralImage,
  GeneralTitle,
} from './styles';

interface GeneralCardProps {
  selectedToolID: number | undefined; //selected Tool ID, state variable in the component.
  onClick: (toolID?: number) => void; //If already selected unselect, else, select.
  tool?: AnyToolDto;
  loading?: boolean;
}

export const GeneralCard: React.FC<GeneralCardProps> = ({
  selectedToolID, //selected Tool ID, state variable in the component.
  onClick, //If already selected unselect, else, select.
  tool,
  loading,
}) => {
  // var { id: toolID, title: toolTitle, description: toolDescription, toolCategory, media, program } = tool;
  const isSelected = selectedToolID === tool?.id;
  const generalImageUrl =
    tool?.media?.crops.find((c) => {
      if (isSelected) {
        return c.type === 'squared';
      } else {
        return c.type === 'full_width_regular';
      }
    })?.url || tool?.media?.crops.find((c) => c.type === 'full_width_regular')?.url;

  useEffect(() => {
    if (isSelected) {
      const element = document.getElementById(`${tool?.id}_tool`);
      element?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [isSelected]);

  const toolCategories = (
    <div style={{display: 'flex'}}>
      {tool?.toolCategories?.map((category) => (
        <GeneralCategory title={category?.name}>{category?.name}</GeneralCategory>
      ))}
    </div>
  );

  return (
    <GeneralCardContainer
      key={tool?.id}
      onClick={() => onClick(tool?.id)}
      isSelected={isSelected}
      placeholder={'Top Level Container'}
      id={tool?.id + '_tool'}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <GeneralCardInfo>
            <GeneralCardHeader>
              <GeneralTitle title={tool?.title}>{tool?.title ? tool.title : tool?.quoteContent}</GeneralTitle>
            </GeneralCardHeader>
            <GeneralDescription title={tool?.description}>{tool?.description}</GeneralDescription>
            {tool?.toolType === 'quote' && tool?.quoteAuthor ? (
              <GeneralCategoryAuthor>
                <>
                  {toolCategories}

                  <GeneralCategory title={tool.quoteAuthor}>{tool.quoteAuthor}</GeneralCategory>
                </>
              </GeneralCategoryAuthor>
            ) : (
              <>{toolCategories}</>
            )}
          </GeneralCardInfo>
          <>{generalImageUrl && <GeneralImage isSidebarOpen={!!selectedToolID} src={generalImageUrl} alt={tool?.title} />}</>
        </>
      )}
    </GeneralCardContainer>
  );
};
export default GeneralCard;
