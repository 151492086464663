import {flatMapCategories} from 'api/category.functions';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {Skeleton} from 'primereact/skeleton';
import * as React from 'react';
import {repeatText} from 'utils/repeat.util';
import {CategoryMultiSelectItem, CategoryMultiSelectRemoveButton} from './style';
const CHILD_CHAR = '\u2514';
const NBSP_CHAR = '\u00A0\u00A0';
const MAX_CHIP_CHARS = 15;

const CategorySelector: React.FC<{
  categories?: CategoryToolTreeNode[];
  selected: number[];
  onChange: (e: number[]) => unknown;
  loading: boolean;
  error?: boolean;
  singleSelection?: boolean;
  optional?: boolean;
}> = ({categories, onChange, selected, loading, error, singleSelection, optional}) => {
  const optionsData = React.useMemo(
    () =>
      flatMapCategories(categories, (node, level, parent) => ({
        label: `${repeatText(NBSP_CHAR, level!)}${parent ? CHILD_CHAR : ''}${node.name}`,
        value: node.id,
        name: node.name,
        trimmedName: node.name.length > MAX_CHIP_CHARS ? `${node.name.substring(0, MAX_CHIP_CHARS)}...` : node.name,
      })),
    [categories]
  );

  // if is optionalSelection, add an option at the beggining of the list
  // with value -1 and label, name and trimmedName "No selection"
  const options = React.useMemo(() => {
    if (optional) {
      const noSelection = {
        label: 'No selection',
        value: -1,
        name: 'No selection',
        trimmedName: 'No selection',
      };
      return [noSelection, ...optionsData];
    }
    return optionsData;
  }, [optional, optionsData]);

  if (loading) {
    return <Skeleton height='2.5rem' />;
  }

  if (singleSelection) {
    return (
      <Dropdown
        value={selected?.[0]}
        options={options}
        onChange={(e) => {
          if (typeof e.value === 'number') {
            onChange([e.value]);
          } else if (Array.isArray(e.value) || e.value === null) {
            onChange(e.value);
          } else {
            onChange([]);
          }
        }}
        placeholder='No selection'
        optionValue='value'
        optionLabel='label'
        disabled={error}
      />
    );
  }
  return (
    <MultiSelect
      value={selected}
      options={options}
      onChange={(e) => {
        if (typeof e.value === 'number') {
          onChange([e.value]);
        } else if (Array.isArray(e.value)) {
          onChange(e.value);
        } else {
          onChange([]);
        }
      }}
      placeholder='Select Category'
      display='chip'
      optionValue='value'
      optionLabel='label'
      selectedItemsLabel='name'
      disabled={error}
      selectedItemTemplate={(optValue) => {
        if (typeof optValue === 'number') {
          const option = optionsData.find((o) => o.value === optValue);
          return (
            <CategoryMultiSelectItem>
              {option?.trimmedName}
              <CategoryMultiSelectRemoveButton onClick={() => onChange(selected.filter((v) => v !== optValue))}>
                x
              </CategoryMultiSelectRemoveButton>
            </CategoryMultiSelectItem>
          );
        }
      }}
    ></MultiSelect>
  );
};

export default CategorySelector;
