import {getCategory} from 'api/chatowl.api';
import {CursorLoading} from 'components/page';
import {ProgressSpinner} from 'primereact/progressspinner';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import {
  DetailButtonLink as EDButtonLink,
  DetailField as EDField,
  DetailFieldImage as EDFieldImage,
  DetailFieldLabel as EDFieldLabel,
  DetailFieldRow as EDFieldRow,
  DetailFieldValue as EDFieldValue,
  DetailHeader as EDHeader,
  DetailID as EDID,
  DetailTitle as EDTitle,
} from '../../../components/tools/main-page-components';

type CategoryDetailsProps = {
  category: CategoryToolTreeNode | null;
};

export const mediaTypesDropdown: DropdownType[] = [
  {label: 'Audio File', value: 'audio'},
  {label: 'Video File', value: 'video'},
];

export const repeatLimitDropdown: DropdownType[] = [
  {label: 'Can be repeated at any time', value: 0},
  {label: 'After 6 hours', value: 21600},
  {label: 'After 24 hours', value: 86400},
  {label: 'After 2 days', value: 172800},
];

export const resumeLimitDropdown: DropdownType[] = [
  {label: 'No resume limit', value: 0},
  {label: '10 minutes', value: 600},
  {label: '30 minutes', value: 1800},
  {label: '1 hour', value: 3600},
  {label: '2 hours', value: 7200},
  {label: '3 hours', value: 10800},
  {label: '4 hours', value: 14400},
  {label: '6 hours', value: 21600},
  {label: '18 hours', value: 64800},
  {label: '1 day', value: 86400},
  {label: '2 days', value: 162800},
];

export const InstructionsLabelValue: DropdownType[] = [
  {label: 'Dim the lights', value: 'dim_lights'},
  {label: 'Headphones', value: 'headphones'},
  {label: 'Make yourself comfortable', value: 'comfortable'},
];

export const translateSecondsRepeat = (seconds: number | undefined): string => {
  const repeatLimitTime: DropdownType | undefined = repeatLimitDropdown.find((time) => time.value === seconds);
  return repeatLimitTime ? repeatLimitTime.label : seconds!.toString();
};

export const translateResumeLimit = (resume: string) => resume || 'No resume limit';
export const translateRepeatLimit = (repeat: string) => repeat || 'Can be repeated at any time';

const formatBoolean = (b: boolean) => (b ? 'True' : 'False');
export const CategoryDetails: React.FC<CategoryDetailsProps> = ({category: propCategory}) => {
  const history = useHistory();
  let instructionsString: string | undefined = '';
  const categoryId = propCategory?.id;
  const {data: categoryInServer, isLoading: loadingFromServer} = useQuery(['categories', categoryId], {
    queryFn: () => getCategory(categoryId!),
    enabled: !!categoryId,
  });
  const category = {...propCategory, ...categoryInServer} as DetailedCategoryToolNode;

  const fullWidthRegularImageUrl = category?.imageMedia?.crops.find((c) => c.type === 'full_width_regular')?.url || '';

  // const fullWidrhTallImageUrl = category?.media?.crops.find((c) => c.type === 'full_width_tall')?.url || '';
  const videoLandscapeUrl = category?.videoMedia?.url || '';
  const videoPortraitUrl = category?.videoMedia?.portraitVideoUrl || '';

  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  return (
    <CursorLoading style={{height: '1000px'}} disable={loadingVideo || loadingImage || loadingFromServer}>
      <div>
        <EDHeader>
          <div style={{display: 'flex'}}>
            <EDTitle>Category Info</EDTitle>
          </div>
          <div>
            <EDButtonLink onClick={() => history.push(`/tools/categories/edit/${category?.id}`)}>Edit</EDButtonLink>
          </div>
        </EDHeader>
        <EDID>{category?.id}</EDID>
      </div>
      <div>
        <EDField>
          <EDFieldLabel>Name</EDFieldLabel>
          <EDFieldValue>{category?.name}</EDFieldValue>
        </EDField>
        <EDFieldRow>
          <EDField>
            <EDFieldLabel>Show as card</EDFieldLabel>
            <EDFieldValue>{formatBoolean(category?.hideItems)}</EDFieldValue>
          </EDField>
          <EDField>
            <EDFieldLabel>Sort children</EDFieldLabel>
            <EDFieldValue>{formatBoolean(category?.orderItems)}</EDFieldValue>
          </EDField>
        </EDFieldRow>
        <EDField>
          <EDFieldLabel>Description</EDFieldLabel>
          <EDFieldValue>{category?.description}</EDFieldValue>
        </EDField>
        {fullWidthRegularImageUrl && (
          <EDField>
            <EDFieldLabel>Image</EDFieldLabel>
            {loadingImage && (
              <EDFieldValue>
                <ProgressSpinner style={{width: '45px', height: '45px'}} />
              </EDFieldValue>
            )}
            <EDFieldImage
              onLoadStart={() => setLoadingImage(true)}
              onLoad={() => setLoadingImage(false)}
              onError={() => setLoadingImage(false)}
              src={fullWidthRegularImageUrl}
              alt='Full Width Regular Crop'
              title='Full Width Regular Crop'
            />
          </EDField>
        )}
        {category?.videoMedia && (
          <EDField>
            <EDFieldLabel>Video</EDFieldLabel>
            {loadingVideo && (
              <EDFieldValue>
                <ProgressSpinner style={{width: '45px', height: '45px'}} />
              </EDFieldValue>
            )}
            <video
              width='300'
              controls
              key={videoLandscapeUrl}
              onLoadStart={() => setLoadingVideo(true)}
              onLoadedData={() => setLoadingVideo(false)}
              poster={category?.videoMedia?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
            >
              <source src={videoLandscapeUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </EDField>
        )}
      </div>
    </CursorLoading>
  );
};
export default CategoryDetails;
