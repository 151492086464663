import {addImageTool, updateImageTool, getCategoryTree, getPromoteTypesTools, updateImage} from 'api/chatowl.api';
import {FormElement, StyledFormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {
  ArchiveButton,
  CancelButton,
  CursorLoading,
  Form,
  PageBody,
  PageContainer as GlobalPageContaner,
  PageFooter,
  PageHeader,
  PageTitle,
  SaveButton,
} from 'components/page';
import SpinnerLoading from 'components/spinner-loading';
import CategorySelector from 'components/tools/category-selector';
import {Dropdown} from 'primereact/dropdown';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {shallowEqual} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {selectImage, sendMessage, sendUndoToast, unselectImage} from 'store/action-creators';
import styled from 'styled-components';
import {isAnyStringEmpty} from 'utils/helpers';
import {listImages} from '../../../api/chatowl.api';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {useToolToPromote} from '../hooks/useToolToPromote';
const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const imagesTypes: CropType[] = ['full_width_regular', 'full_screen_portrait', 'full_width_tall']; //En diseño aparecen 3 en un caso, pero 2 arriba a la derecha

type MediaDetailsFormType = 'add' | 'edit';

export const AddImage: React.FC<{title?: string}> = ({title}) => {
  const params = useParams<{formType: MediaDetailsFormType; imageId: string}>();
  const formType = params.formType;
  const imageId = +params.imageId || null;
  let selectedImage: ImageDto | null = useAppSelector((state: RootState) => state.tools.images.selectedImage, shallowEqual);
  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const [imageDescription, setImageDescription] = useState<string>('');
  const [imageCategories, setImageCategories] = useState<number[]>([]);
  const [userClickedArchive, setUserClickedArchive] = useState<boolean>(false);

  const [editTitle, setEditTitle] = useState('');
  const [images, setImages] = useState<ImageDto[]>([]);
  const [imagesLoading, setImagesLoading] = useState(false);

  const [mediaId, setMediaId] = useState<number | undefined>(0);
  const [newCrops, setNewCrops] = useState<Crop[]>([]);

  const [saving, setSaving] = useState(false);
  const history = useHistory();

  const {
    typesToolsOptions,
    toolTypeToPromote,
    setToolTypeToPromote,
    toolsOptions,
    toolIdToPromote,
    setToolIdToPromote,
  } = useToolToPromote();

  const editing: boolean = formType === 'edit' && !!selectedImage;

  const dispatch = useAppDispatch();

  const validateSave = (description: string, mediaId: number | undefined): boolean =>
    !(toolTypeToPromote && !toolIdToPromote) && !!description && mediaId !== 0;

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    if (selectedImage && formType === 'edit') {
      setImageDescription(selectedImage.description);
      setImageCategories(selectedImage.toolCategories?.map((v) => v.id) ?? []);
      setMediaId(selectedImage.mediaId);
      setEditTitle(selectedImage.title);
    }
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = (await listImages({withDeleted: false, sortBy: 'id'})) as any;
        const imagesResponse = response.data ? response.data.data : [];
        setImages(imagesResponse);
        const imageSelected = imagesResponse.find((image: any) => image.id === imageId) as ImageDto;
        if (imageSelected) dispatch(selectImage(imageSelected));
        setImageDescription(imageSelected.description);
        setImageCategories(imageSelected.toolCategories?.map((v) => v.id) ?? []);
        setMediaId(imageSelected.mediaId);
        setToolTypeToPromote(imageSelected.promotedTool?.toolType);
        setToolIdToPromote(imageSelected.promotedTool?.id);
      } catch (error) {
        throw error; // TODO
      } finally {
        setImagesLoading(false);
      }
    };
    if (imageId) {
      setImagesLoading(true);
      fetchImages();
    }
  }, []);

  const onChangeToolTypeToPromote = (e: any) => {
    setToolIdToPromote(undefined);
    if (e.value === 'none') {
      setToolTypeToPromote(undefined);
    } else {
      setToolTypeToPromote(e.value);
    }
  };

  const onCancelImage = () => {
    history.push('/tools/images');
  };

  const onArchiveImage = async () => {
    if (!userClickedArchive) setUserClickedArchive(true);
    else {
      setSaving(true);
      const editedImage: UpdateImageToolRequest = {
        isArchived: !selectedImage?.isArchived,
      };
      try {
        let updatedExercise = (await updateImageTool(editedImage, selectedImage?.id!)) as any;
        if (updatedExercise.response.status >= 200 && updatedExercise.response.status < 300) {
          updatedExercise = updatedExercise.data.data;
          dispatch(unselectImage());
          !selectedImage?.isArchived && dispatch(sendUndoToast({idTool: selectedImage?.id!, toolType: 'image'}));
          history.push('/tools/images');
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          setSaving(false);
        }
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onSaveImage = async () => {
    if (validateSave(imageDescription, mediaId) && !isAnyStringEmpty([imageDescription, editTitle])) {
      setSaving(true);
      let errorEditingMedia = false;
      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = newCrops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = newCrops!.find((crop) => crop.type === 'full_screen_portrait');
        const squared = newCrops!.find((crop) => crop.type === 'squared');
        const crops: UpdateMediaImageRequest = {
          fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
          fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
          squared: squared ? squared.blob : undefined,
        };
        const mediaImage = (await updateImage(mediaId!, crops)) as any;
        if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          setSaving(false);
          errorEditingMedia = true;
        }
      }
      if (!errorEditingMedia) {
        const newImage: CreateImageToolRequest = {
          description: imageDescription,
          categories: imageCategories,
          mediaId: mediaId!,
          startToolId: toolIdToPromote ?? null,
          title: editTitle,
        };
        if (!editing) {
          try {
            const createdImage = (await addImageTool(newImage)) as any;
            if (createdImage.response.status >= 200 && createdImage.response.status < 300) {
              dispatch(selectImage(createdImage.data.data));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Image created successfully',
                  detail: imageDescription,
                })
              );
              history.push('/tools/images');
            } else {
              if (imageDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Image description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        } else {
          //Editing
          try {
            const createdImage = selectedImage?.id && ((await updateImageTool(newImage, selectedImage?.id)) as any);
            if (createdImage.response.status >= 200 && createdImage.response.status < 300) {
              dispatch(selectImage(createdImage.data.data));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Image updated successfully',
                  detail: imageDescription,
                })
              );
              history.push('/tools/images');
            } else {
              if (imageDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Image description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        }
      }
    } else {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Please fill all the fields'}));
    }
  };

  return (
    <CursorLoading disable={saving}>
      {imagesLoading ? (
        <SpinnerLoading show />
      ) : (
        <PageContainer>
          <PageHeader>{editing ? <PageTitle>Edit Image</PageTitle> : <PageTitle>Add Image</PageTitle>}</PageHeader>
          <PageBody>
            <Form>
              <StyledFormElement>
                <label htmlFor={`input Category`}>{'Category'}</label>
                <CategorySelector
                  loading={loadingCategories}
                  selected={imageCategories}
                  categories={categoryTree}
                  onChange={setImageCategories}
                  error={!!errorLoadingCategories}
                />
              </StyledFormElement>
              <FormElement type='text' label='Title' value={editTitle} placeholder='Title' onChange={setEditTitle} />

              <FormElement
                type='text-area'
                label='Description'
                value={imageDescription}
                placeholder='Description'
                onChange={(inputValue) => setImageDescription(inputValue)}
              />

              <StyledFormElement>
                <label htmlFor='toolTypeToPromote'>{'Promote'}</label>
                <Dropdown
                  id='toolTypeToPromote'
                  value={toolTypeToPromote}
                  options={typesToolsOptions}
                  onChange={onChangeToolTypeToPromote}
                  placeholder='Select tool type to promote'
                  optionLabel='name'
                />
                {/* promoteLabels cargado dinamicamente */}
              </StyledFormElement>
              {toolTypeToPromote && (
                <StyledFormElement>
                  <label htmlFor='toolIdToPromote'>{`Select which ${toolTypeToPromote} to promote`}</label>
                  <Dropdown
                    id='toolIdToPromote'
                    value={toolIdToPromote}
                    options={toolsOptions}
                    onChange={(e) => setToolIdToPromote(e.value)}
                    placeholder={`Select ${toolTypeToPromote} to promote`}
                    optionLabel='name'
                  />
                  {/* promoteLabels cargado dinamicamente */}
                </StyledFormElement>
              )}
            </Form>
            <StyledFormElement style={{width: '100%'}}>
              {!editing ? (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                />
              ) : (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  media={selectedImage!.media}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                />
              )}
            </StyledFormElement>
          </PageBody>
          <PageFooter>
            {editing &&
              (!selectedImage!.isArchived ? (
                <ArchiveButton onClick={onArchiveImage} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Archive ?' : 'Archive'}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={onArchiveImage} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Unarchive ?' : 'Unarchive'}
                </ArchiveButton>
              ))}

            <CancelButton onClick={onCancelImage}>
              <Link to='/tools/images/'>Cancel</Link>
            </CancelButton>
            <SaveButton disabled={saving} onClick={onSaveImage} disable={saving}>
              {saving ? 'Saving...' : 'Save'}
            </SaveButton>
          </PageFooter>
        </PageContainer>
      )}
    </CursorLoading>
  );
};
