import {CancelButton, PageFooter, SaveButton, ArchiveButton as DeleteButton, ButtonLink} from 'components/page';
import {FormElement, StyledFormElement} from 'components/form-element';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {createAudio, updateAudio, deleteMedia} from 'api/chatowl.api';
import {ImageCropperManager} from '../../../../components/crop-manager';
import {AddInput, FormContainer, InputButton, InputComplete, PageBody, Tag} from '../../styles';
import {StyleAddAudioFiles, Header} from './styles';
import AudioUploader from 'components/media/audio-uploader';
import AudioPlayer from 'components/media/audio-player';
import getBlobDuration from 'get-blob-duration';
import {useAppSelector, useAppDispatch} from '../../../../hooks';
import {hideLoading, sendMessage, showLoading, unselectMedia} from '../../../../store/action-creators';
import {RootState} from '../../../../index';
import {shallowEqual} from 'react-redux';
import {Button} from 'primereact/button';
import imageCompression from 'browser-image-compression';
import {createFile, isAnyStringEmpty} from 'utils/helpers';

function validateSave(
  name: string,
  audioBlob: Blob | undefined,
  audioUrl: string | undefined,
  crops: number,
  imagesCrops: number
): boolean {
  return !!name && (!!audioBlob || !!audioUrl) && (!!crops || !!imagesCrops);
}

export const AudioFormContainer: React.FC<{
  formType: 'add' | 'edit';
  mediaName?: string;
  onSuccess: (mediaId: number, mediaName: string) => void;
  onError: () => void;
  name: string;
  setName: (name: string) => void;
  tags: {
    list: string[] | undefined;
    push: (newTag: string) => void;
    drop: (deleted: string) => void;
    initTag: (tagList: string[]) => void;
  };
  originalAudio: Blob | undefined;
  setOriginalAudio: (audio: Blob | undefined) => void;
  originalAudioURL: string | undefined;
  setOriginalAudioURL: (audioURL: string | undefined) => void;
  originalImage: Blob | undefined;
  setOriginalImage: (image: Blob | undefined) => void;
  crops: Crop[];
  setCrops: (Crops: Crop[]) => void;
  imagesCrop: CropDto[];
  setImagesCrop: (iCrop: CropDto[]) => void;
}> = ({
  formType,
  mediaName: nameFromMediaChooser,
  onSuccess,
  onError,
  name,
  setName,
  tags,
  originalAudio,
  setOriginalAudio,
  originalAudioURL,
  setOriginalAudioURL,
  originalImage,
  setOriginalImage,
  crops,
  setCrops,
  imagesCrop,
  setImagesCrop,
}) => {
  const history = useHistory();
  const [currentTag, setCurrentTag] = useState<Tag>();
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);
  const dispatch = useAppDispatch();
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});

  useEffect(() => {
    if (formType === 'edit' && selectedMedia) {
      const fetchAudioBlob = async () => {
        if (selectedMedia) {
          const audioBlob = await fetch(selectedMedia?.url).then((r) => r.blob());
          setOriginalAudio(audioBlob);
        }
      };
      setName(selectedMedia.name);
      tags.initTag(selectedMedia.tags!);
      setOriginalAudioURL(selectedMedia.url);
      setImagesCrop(selectedMedia.crops);
      fetchAudioBlob();
      selectedMedia.settings && setSettings(JSON.parse(selectedMedia.settings));
    } else {
      nameFromMediaChooser && setName(nameFromMediaChooser);
    }
  }, [selectedMedia]);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
  };

  const clearForm = () => {
    setName('');
    tags.initTag([]);
    setCurrentTag(undefined);
    setOriginalAudio(undefined);
    setOriginalAudioURL(undefined);
    setOriginalImage(undefined);
    setCrops([]);
    setImagesCrop([]);
    setUserClickedDelete(false);
    dispatch(unselectMedia());
  };

  const sendAudio = async () => {
    if (validateSave(name, originalAudio, originalAudioURL, crops.length, imagesCrop.length) && !isAnyStringEmpty([name])) {
      dispatch(showLoading());

      let duration = originalAudio ? await getBlobDuration(originalAudio) : undefined;

      if (!duration) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        dispatch(hideLoading());
        return;
      } else {
        duration = Math.trunc(duration);
      }

      let thumbnail = null;

      if (originalImage) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 570,
        };
        let file: any = originalImage;
        thumbnail = file && (await imageCompression(file, options));
        thumbnail = file && createFile(thumbnail, 'thumbnail.jpg');
      }

      if (formType === 'add') {
        const newAudio: CreateMediaAudioRequest = {
          name,
          tags: tags.list,
          originalAudio: originalAudio!,
          fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
          originalImage: originalImage!,
          duration,
          thumbnail,
        };

        try {
          const mediaAudio = (await createAudio(newAudio)) as any;
          if (mediaAudio.response.status >= 200 && mediaAudio.response.status < 300) {
            onSuccess(mediaAudio.data.data.id, name);
            clearForm();
          } else {
            onError();
          }
        } catch (e) {
          onError();
        } finally {
          dispatch(hideLoading());
        }
      }

      if (formType === 'edit') {
        const fullWidthTall = crops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = crops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = crops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = crops!.find((crop) => crop.type === 'full_screen_portrait');
        let newAudio: UpdateMediaAudioRequest;
        if (crops.length) {
          newAudio = {
            name,
            tags: tags.list,
            crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
            originalAudio: originalAudio,
            originalImage: originalImage!,
            fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
            duration,
            thumbnail,
          };
        } else {
          newAudio = {
            name,
            tags: tags.list,
            crops: [],
            originalAudio: originalAudio,
            originalImage: originalImage!,
            duration,
          };
        }
        try {
          const mediaAudio = (await updateAudio(selectedMedia!.id, newAudio)) as any;
          if (mediaAudio.response.status >= 200 && mediaAudio.response.status < 300) {
            onSuccess(mediaAudio.data.data.id, name);
          } else {
            onError();
          }
        } catch (e) {
          onError();
          dispatch(hideLoading());
        } finally {
          dispatch(hideLoading());
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name, Audio and Image can not be empty.'}));
    }
  };

  const onCancelAudioDetails = () => {
    dispatch(unselectMedia());
    history.push('/media-bank');
  };

  const onDeleteAudio = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      try {
        dispatch(showLoading());
        const res: any = await deleteMedia(selectedMedia!.id);
        dispatch(unselectMedia());
        res.response.status === 400
          ? res.data.error.includes('MEDIA_IS_CURRENTLY_IN_USE')
            ? dispatch(
                sendMessage({
                  severity: 'error',
                  summary: 'ERROR',
                  detail: 'Audio can not be deleted because it is currently in use',
                })
              )
            : dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}))
          : dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Audio successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handleOnClickNewAudio = () => {
    setOriginalAudio(undefined);
    setOriginalAudioURL('');
  };

  return (
    <>
      <PageBody>
        <FormContainer>
          <FormElement type='input' label='Name' value={name ? name : ''} placeholder='Media name' onChange={setName} />
          <StyledFormElement>
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value.toLowerCase())}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ',') {
                    e.preventDefault();
                    currentTag && tags.push(currentTag);
                  }
                }}
              />
              <AddInput onClick={() => currentTag && tags.push(currentTag)}>Add</AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag id={`${tag}_audio`} key={`${tag}_audio`} color={'#008C8C'} onClick={() => tags.drop(tag)}>
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </FormContainer>

        <StyleAddAudioFiles>
          <StyledFormElement>
            <Header>
              <label>Audio</label>
              <ButtonLink onClick={handleOnClickNewAudio}>Upload New Audio</ButtonLink>
            </Header>
            {!originalAudio ? (
              <AudioUploader
                onUploadAudio={(audio: File) => {
                  setOriginalAudio(audio);
                  setOriginalAudioURL(URL.createObjectURL(audio));
                }}
              >
                <p style={{color: 'red'}}>*Required</p>
                <p>Upload an audio file (.mp3)</p>
                <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload File </Button>
              </AudioUploader>
            ) : (
              originalAudioURL && <AudioPlayer audioSrc={originalAudioURL} />
            )}
          </StyledFormElement>
          <StyledFormElement>
            {formType === 'edit' ? (
              <ImageCropperManager
                mediaType='audio'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                imagesCrop={imagesCrop}
                image={selectedMedia?.originalImageUrl || selectedMedia?.thumbnailUrl}
                imageForSettings={selectedMedia?.originalImageUrl || selectedMedia?.thumbnailUrl}
                settings={settings}
                setSettings={setSettings}
              />
            ) : (
              <ImageCropperManager
                settings={settings}
                setSettings={setSettings}
                mediaType='audio'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                originalImage={originalImage}
              />
            )}
          </StyledFormElement>
        </StyleAddAudioFiles>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteAudio} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelAudioDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={sendAudio}>Save</SaveButton>
      </PageFooter>
    </>
  );
};
