import React, {useEffect, useRef, useState} from 'react';
import {StyledFormElement} from 'components/form-element';
import {PageHeader, PageTitle} from 'components/page';
import {SelectButton as MediaTypeSelector} from 'primereact/selectbutton';
import {ImageFormContainer} from '../containers/image';
import {VideoFormContainer} from '../containers/video';
import {AudioFormContainer} from '../containers/audio';
import {capitalize} from 'utils/helpers';
import {useAppDispatch} from 'hooks';
import {ADD_MEDIA_FLOW_STATUS} from 'utils/constants';
import {sendMessage} from 'store/action-creators';
import {PageContainer} from '../styles';
import {useHistory} from 'react-router-dom';
import {useTags} from 'hooks';
const queryString = require('query-string');

export interface MediaTypesComboState {
  name: string;
  value: MediaType;
  disabled?: boolean;
}
export const AddMedia: React.FC<{title?: string}> = ({title}) => {
  const [name, setName] = useState('');
  const tags = useTags();
  const [originalAudio, setOriginalAudio] = useState<Blob | undefined>();
  const [originalAudioURL, setOriginalAudioURL] = useState<string | undefined>('');
  const [originalImageImage, setOriginalImageImage] = useState<Blob | undefined>();
  const [originalImageAudio, setOriginalImageAudio] = useState<Blob | undefined>();
  const [originalImageVideo, setOriginalImageVideo] = useState<Blob | undefined>();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [imagesCrop, setImagesCrop] = useState<CropDto[]>([]);
  const [originalVideo, setOriginalVideo] = useState<Blob | undefined>();
  const [originalVideoURL, setOriginalVideoURL] = useState<string | undefined>('');
  const [portraitVideo, setPortraitVideo] = useState<Blob | undefined>();
  const [portraitVideoURL, setPortraitVideoURL] = useState<string | undefined>('');
  const [originalImageURL, setOriginalImageURL] = useState<string | undefined>();

  const [mediaType, setMediaType] = useState<MediaType>(defaultMediaType);
  const [mediaName, setMediaName] = useState('');
  const [mediaTypeComboOptions, setMediaTypeOptions] = useState<MediaTypesComboState[]>(defaultMediaTypeComboState);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryOptions = queryString.parse(window.location.search);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  // on mount:
  useEffect(() => {
    if (queryOptions.source === 'media-chooser') {
      // try to load form automatically, using name or media type possible provided

      const name = localStorage.getItem('mediaName');
      if (name) {
        setMediaName(name);
        localStorage.setItem('mediaName', '');
      }

      try {
        var mediaType = (localStorage.getItem('mediaType') || defaultMediaType) as MediaType;
        setMediaType(mediaType);
      } catch (error) {
        setMediaType(defaultMediaType);
      }
    }
    if (queryOptions['lock-others-media-types']) {
      const mediaTypes: MediaTypesComboState[] = mediaTypeComboOptions.map((option) => {
        if (option.value !== mediaType) {
          return {...option, disabled: true};
        } else {
          return option;
        }
      });
      setMediaTypeOptions(mediaTypes);
    }
  }, []);

  const onSucess = (mediaId: number, mediaName: string) => {
    dispatch(
      sendMessage({
        severity: 'success',
        summary: `${capitalize(mediaType)} created successfully`,
        detail: mediaName,
      })
    );

    if (queryOptions.source === 'media-chooser') {
      localStorage.setItem('mediaId', mediaId.toString());
      localStorage.setItem('mediaName', mediaName.toString());
      localStorage.setItem(ADD_MEDIA_FLOW_STATUS, 'success');
    }

    if (queryOptions['keep-adding']) {
      setMediaName('');
      document.getElementById('add-media-title')?.scrollIntoView({behavior: 'smooth'});
    } else {
      history.push('/media-bank');
    }
  };

  const onError = () => {
    dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));

    if (queryOptions.source === 'media-chooser') {
      localStorage.setItem('mediaId', '');
      localStorage.setItem('mediaName', '');
      localStorage.setItem(ADD_MEDIA_FLOW_STATUS, 'error');
    }
  };

  const handleSetMediaType = (e: {value: MediaType}): void => {
    e.value && setMediaType(e.value);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle id='add-media-title'>Add Media</PageTitle>
      </PageHeader>
      <StyledFormElement>
        <MediaTypeSelector value={mediaType} onChange={handleSetMediaType} options={mediaTypeComboOptions} optionLabel='name' />
      </StyledFormElement>
      {mediaType === 'video' && (
        <VideoFormContainer
          formType='add'
          mediaName={mediaName}
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalImage={originalImageVideo}
          setOriginalImage={setOriginalImageVideo}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
          originalVideo={originalVideo}
          setOriginalVideo={setOriginalVideo}
          originalVideoURL={originalVideoURL}
          setOriginalVideoURL={setOriginalVideoURL}
          portraitVideo={portraitVideo}
          setPortraitVideo={setPortraitVideo}
          portraitVideoURL={portraitVideoURL}
          setPortraitVideoURL={setPortraitVideoURL}
        />
      )}
      {mediaType === 'audio' && (
        <AudioFormContainer
          formType='add'
          mediaName={mediaName}
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalAudioURL={originalAudioURL}
          setOriginalAudioURL={setOriginalAudioURL}
          originalAudio={originalAudio}
          setOriginalAudio={setOriginalAudio}
          originalImage={originalImageAudio}
          setOriginalImage={setOriginalImageAudio}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
        />
      )}
      {mediaType === 'image' && (
        <ImageFormContainer
          formType='add'
          mediaName={mediaName}
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalImage={originalImageImage}
          setOriginalImage={setOriginalImageImage}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
          originalImageURL={originalImageURL}
          setOriginalImageURL={setOriginalImageURL}
        />
      )}
    </PageContainer>
  );
};

const defaultMediaType: MediaType = 'video';

const defaultMediaTypeComboState: MediaTypesComboState[] = [
  {name: 'Audio', value: 'audio', disabled: undefined},
  {name: 'Video', value: 'video', disabled: undefined},
  {name: 'Image', value: 'image', disabled: undefined},
];
