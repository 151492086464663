import React from 'react';
import {Link} from 'react-router-dom';
import AppSubmenu from './app.submenu';

type MenuModeType = 'static' | 'slim' | 'overlay';

type AppMenuProps = {
  onMenuClick: () => void;
  model: any[];
  menuMode: MenuModeType;
  active: boolean;
  mobileMenuActive: boolean;
  onMenuItemClick: (event: any) => void;
  onRootMenuItemClick: () => void;
};

const AppMenu: React.FC<AppMenuProps> = (props) => {
  return (
    <div className='layout-sidebar' onClick={props.onMenuClick}>
      <Link to='/' className='logo'>
        <img id='app-logo' className='logo-image' src='/assets/layout/images/logo-white.svg' alt='chatowl logo' />
        <span className='app-name'>ChatOwl</span>
      </Link>

      <div className='layout-menu-container'>
        <AppSubmenu
          items={props.model}
          menuMode={props.menuMode}
          parentMenuItemActive
          menuActive={props.active}
          mobileMenuActive={props.mobileMenuActive}
          root
          onMenuItemClick={props.onMenuItemClick}
          onRootMenuItemClick={props.onRootMenuItemClick}
        />
      </div>
    </div>
  );
};

export default AppMenu;
