import {Button} from 'components/page';
import styled from 'styled-components';

export const AddButton = styled(Button)`
  background: white;
  border: 1px dashed #2c98f0;
  color: #2c98f0;
  margin: 6px 0px 40px 0px;
  font-size: 24px;
  padding: 30px;
  margin-top: 60px;
  border-radius: 10px;
`;

export const DetailContainer = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const Body = styled.div`
  margin-top: 30px;
  height: 80vh;
`;

export const DeleteButton = styled.span`
  color: #eb5757;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
`;

export const ActionButtons = styled.div`
  bottom: 0px;
  display: flex;
  justify-content: center;
`;

export const modalStyle = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
    zIndex: 1,
  },
};

export const DeleteMediaModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

export const DeleteMediaModalCancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
`;

export const DeleteMediaModalYesButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
`;

export const ModalContainer = styled.div`
  margin: 10px 40px 10px 40px;
  h5 {
    text-align: center;
  }
`;
