import styled from 'styled-components';

export const PageHeader = styled.div`
  align-items: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  padding-top: 32px;
  flex-direction: column;
  width: 100%;
`;

export const Tag = styled.div`
  position: absolute;
  left: -20px;
  top: -5px;
  padding: 4px;
  background-color: #6ebbf7;
  color: white;
  border-radius: 8px;
  font-weight: 900;
`;

export const PageId = styled.div`
  display: flex;
  font-style: normal;
  font-size: 14px;
  margin: 2px;
  align-items: center;

  color: rgb(108, 117, 125);
`;

export const Label = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 12px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  min-width: 66px;
`;

export const PageDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageDescriptionText = styled.p`
  display: flex;
  font-style: normal;
  font-size: 16px;
  width: 90%;
  margin-top: 16px;
  margin-right: 16px;

  color: rgb(33, 50, 76);
`;

export const PageImg = styled.img`
  display: flex;
  height: 180px;
  margin-top: -10px;
  border-radius: 4px;
`;

export const ListControls = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSearchBar = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  color: #495057;
  cursor: text;
  display: inline-flex;
  flex: 1;
  min-width: 50px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  :hover:not(:focus-within) {
    background-color: #fafafa;
  }

  i {
    cursor: text;
    display: flex;
    margin: 0 8px 0 20px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  :focus-within {
    border: 1px solid #293b55;
  }
`;

export const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
`;

export const SidebarBorder = styled.div`
  bottom: 0px;
  position: relative;
  top: 0px;
  transform: translateZ(0px);
  width: 24px;
`;

export const SidebarMargin = styled.div`
  right: -4px;
  position: relative;
  width: 24px;
  height: 100%;
`;

export const SidebarLine = styled.div`
  background: rgba(0, 0, 0, 0)
    linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%) repeat
    scroll 0% 0%;
  bottom: 0px;
  right: -3px;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: right, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
`;

export const SidebarButton = styled.button`
  background: white none repeat scroll 0px center;
  border: 0px none;
  border-radius: 50%;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);
  cursor: ${(props: SidebarProps) => props.isActive && 'pointer'};
  height: 24px;
  opacity: ${(props: SidebarProps) => (props.isActive ? '1' : '0')};
  outline: currentcolor none 0px;
  padding: 0px;
  position: absolute;
  top: 32px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(50%);
  width: 24px;

  :hover {
    background: #293b55 none repeat scroll 0px center;
    color: white;
  }
`;

export const SidebarButtonSpan = styled.span`
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
  height: 45px;
`;

export const PDHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

export const PDTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 28px;
  font-weight: 800;
  line-height: 29px;
`;

export const PDButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

export const PDField = styled.div`
  margin-bottom: 32px;
`;

export const PDFieldLabel = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
  user-select: none;
`;

export const PDFieldValue = styled.div`
  color: #010101;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-size: 16px;
`;

export const PDFieldValueContainer = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
  align-items: center;
`;

export const PDFieldImage = styled.img`
  width: 400px;
`;

export const WeekSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

type SidebarProps = {
  isActive?: boolean;
};

export const SidebarContent = styled.div`
  bottom: 0px;
  top: 0px;
  right: 0px;
  max-width: 500px;
  min-width: 75px;
  width: ${(props: SidebarProps) => (props.isActive ? '500px' : '75px')};
  padding: 32px 28px 32px 40px;
  position: relative;
  transition: left 0.3s ease-in-out;
`;

type StyledFilterProps = {
  isSelected?: boolean;
};

export const StyledFilter = styled.div`
  align-items: center;
  background: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : 'white')};
  border-color: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : '#ced4da')};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: ${(props: StyledFilterProps) => props.isSelected && '0px 2px 8px rgba(0, 0, 0, 0.1)'};
  color: ${(props: StyledFilterProps) => (props.isSelected ? 'white' : '#293b55')};
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: 0.1s;
  margin: 1px 4px;
  justify-content: center;
  padding: 4px 24px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.2s;
  user-select: none;
  width: 100px;

  :not(:hover) {
    box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.01);
  }

  :hover {
    background: ${(props: StyledFilterProps) => !props.isSelected && '#fafafa'};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
  }
`;

export const SidebarSVG = styled.svg`
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  vertical-align: bottom;
`;

export const ActivityList = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

export const ActivitiesNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;
  font-size: 28px;
  flex-direction: column;
`;

type ActivityCardContainerType = {
  isSelected: boolean;
};

export const ActivityCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-color: ${(props: ActivityCardContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-width: 0;
  padding: 16px;
  transition: 0.1s;
  user-select: none;
  width: 100%;
  position: relative;

  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const ActivityCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
  justify-content: space-between;
`;

export const FullActivityCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const ActivityCardHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const ActivityTitle = styled.span`
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  margin-left: 8px;
  margin-right: 16px;
  min-height: 28px;
`;

export const ActivityDescription = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  word-break: break-all;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ActivityImage = styled.img`
  align-self: center;
  border-radius: 4px;
  height: 88px;
  min-width: ${(props: ActivityImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  max-width: ${(props: ActivityImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  object-fit: cover;
`;

type ActivityImageProps = {
  isSidebarOpen: boolean;
};

export const DayActivityContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 80px;
`;

export const MomentDay = styled.div`
  font-size: 16px;
  color: #777c7c;
  font-weight: 500;
  margin-top: 24px;
`;

export const NotificationOptions = styled.div`
  margin-top: 25px;
`;

export const MenuContainer = styled.div`
  .delete-program .p-menuitem-link .p-menuitem-text {
    color: #a91b0d;
  }
  .delete-program .p-menuitem-link .p-menuitem-icon {
    color: #a91b0d;
  }
`;
