import React, {useState} from 'react';
import {ChatNote} from 'components/clients/note/chat-note';
import moment from 'moment';
import {Notes} from 'components/clients/note/styles';
import {
  NewNoteChat,
  ReceiveMessage,
  ReceiveMessageBox,
  ReceiveMessageDate,
  SendMessage,
  SendMessageBox,
  SendMessageDate,
} from 'pages/clients/styles';
import {useAppDispatch} from 'hooks';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import {deleteClientNote, patchClientNote, postClientNote} from 'api/chatowl.api';

type ChatBubbleProps = {
  bubble: ChatBubbleDto;
  index: number;
  showingChats: ChatBubbleDto[];
  username: string;
  withNotes: boolean;
};

export const ChatBubble: React.FC<ChatBubbleProps> = ({bubble, index, showingChats, username, withNotes}) => {
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteDto[]>(bubble.notes);

  const dispatch = useAppDispatch();

  const dateTime = moment(bubble.timestamp).format('MM/DD/YYYY hh:mm:ss a');
  const time = moment(bubble.timestamp).format('hh:mm a');

  const createNote = async (note: string) => {
    const noteRequest: CreateChatNoteRequest = {note, noteType: 'chat', relatedMessageId: bubble.id};
    dispatch(showLoading());
    const newNote = ((await postClientNote(noteRequest, username)) as any).data.data as NoteDto;
    setNotes((notes) => [...(notes || []), newNote]);
    dispatch(hideLoading());
    dispatch(sendMessage({severity: 'success', summary: 'Note created', life: 3000}));
    setIsAddingNote(false);
  };

  const updateNote = async (noteId: number, note: string) => {
    const noteRequest: UpdateNoteRequest = {note};
    dispatch(showLoading());
    const editedNote = ((await patchClientNote(noteRequest, noteId, username)) as any).data.data as NoteDto;
    setNotes(notes.map((note) => (note.id === noteId ? editedNote : note)));
    dispatch(sendMessage({severity: 'success', summary: 'Note updated', life: 3000}));
    dispatch(hideLoading());
  };

  const deleteNote = async (noteId: number) => {
    dispatch(showLoading());
    await deleteClientNote(noteId, username);
    setNotes(notes.filter((note) => note.id !== noteId));
    dispatch(sendMessage({severity: 'success', summary: 'Note deleted', life: 3000}));
    dispatch(hideLoading());
  };

  return (
    <>
      {index > 0 &&
        moment(showingChats[index].timestamp).format('MM/DD/YYYY') !==
          moment(showingChats[index - 1].timestamp).format('MM/DD/YYYY') && (
          <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            {moment(showingChats[index].timestamp).format('MM/DD/YYYY')}
          </div>
        )}
      {bubble.sender === 'user' ? (
        <SendMessageBox
          onMouseEnter={() => {
            if (withNotes) setIsHovered(true);
          }}
          onMouseLeave={() => {
            if (withNotes) setIsHovered(false);
          }}
          style={{backgroundColor: isHovered ? '#f2f2f2' : 'white'}}
        >
          {isHovered && withNotes && (
            <NewNoteChat title='Add a note to this message' onClick={() => setIsAddingNote(true)}>
              <NewNoteIcon />
            </NewNoteChat>
          )}
          <SendMessage>{bubble.text}</SendMessage>
          <SendMessageDate title={dateTime}>{time}</SendMessageDate>
          {withNotes && (
            <Notes>
              {notes?.map((note: NoteDto) => (
                <ChatNote key={note.id} updateNote={updateNote} deleteNote={deleteNote} note={note} />
              ))}
              {isAddingNote && <ChatNote isAdding createNote={createNote} discardNote={() => setIsAddingNote(false)} />}
            </Notes>
          )}
        </SendMessageBox>
      ) : (
        <ReceiveMessageBox>
          <ReceiveMessage>{bubble.text}</ReceiveMessage>
          <ReceiveMessageDate title={dateTime}>{time}</ReceiveMessageDate>
        </ReceiveMessageBox>
      )}
    </>
  );
};

const NewNoteIcon = () => {
  return (
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d)'>
        <circle cx='17' cy='15' r='15' fill='white' />
      </g>
      <path
        d='M13.125 8C12.2962 8 11.5013 8.32924 10.9153 8.91529C10.3292 9.50134 10 10.2962 10 11.125V19.875C10 20.7038 10.3292 21.4987 10.9153 22.0847C11.5013 22.6708 12.2962 23 13.125 23H17.715C18.5434 22.9998 19.3379 22.6707 19.9237 22.085L24.085 17.9237C24.6707 17.3379 24.9998 16.5434 25 15.715V11.125C25 10.2962 24.6708 9.50134 24.0847 8.91529C23.4987 8.32924 22.7038 8 21.875 8H13.125ZM11.25 11.125C11.25 10.6277 11.4475 10.1508 11.7992 9.79917C12.1508 9.44754 12.6277 9.25 13.125 9.25H21.875C22.3723 9.25 22.8492 9.44754 23.2008 9.79917C23.5525 10.1508 23.75 10.6277 23.75 11.125V15.5H20.625C19.7962 15.5 19.0013 15.8292 18.4153 16.4153C17.8292 17.0013 17.5 17.7962 17.5 18.625V21.75H13.125C12.6277 21.75 12.1508 21.5525 11.7992 21.2008C11.4475 20.8492 11.25 20.3723 11.25 19.875V11.125ZM18.75 21.4375V18.625C18.75 18.1277 18.9475 17.6508 19.2992 17.2992C19.6508 16.9475 20.1277 16.75 20.625 16.75H23.4375C23.3687 16.8538 23.29 16.95 23.2 17.04L19.04 21.2013C18.9513 21.29 18.8538 21.3688 18.75 21.4388V21.4375Z'
        fill='#02ACEC'
      />
      <defs>
        <filter id='filter0_d' x='0' y='0' width='34' height='34' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
