export const priorityValues = [
  {name: 'Low', value: 1},
  {name: 'Medium', value: 2},
  {name: 'High', value: 3},
];

export const repeatValues = [
  {name: 'Every day', value: 1},
  {name: 'Every 2 days', value: 2},
  {name: 'Every 3 days', value: 3},
  {name: 'Every 4 days', value: 4},
  {name: 'Every 1 week', value: 7},
  {name: 'Every 2 weeks', value: 14},
  {name: 'Every 3 weeks', value: 21},
  {name: 'Every 4 weeks', value: 28},
];
