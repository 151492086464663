import Skeleton from 'react-loading-skeleton';
import {
  GeneralCardContainer,
  GeneralCardHeader,
  GeneralCardInfo,
  GeneralCardType,
  GeneralDescription,
  GeneralImage,
  GeneralTitle,
  NormalIconCategoryChild,
  SubCategoryIconChild,
} from './styles';

interface GeneralCardProps {
  item: CategoryToolTreeNode | ImageDto | ExerciseDto | QuoteDto;
  loading?: boolean;
  type: 'category' | 'image' | 'exercise' | 'quote';
}

export const CategoryEditCard: React.FC<GeneralCardProps> = ({item, loading, type}) => {
  // var { id: toolID, title: toolTitle, description: toolDescription, toolCategory, media, program } = tool;
  //const isSelected = selectedId === category?.id;

  let generalImageUrl = null;
  let title = '';
  switch (type) {
    case 'category':
      const cat = item as CategoryToolTreeNode;
      generalImageUrl = cat.imageUrl;
      title = cat.name;
      break;
    case 'exercise':
      let exercise = item as ExerciseDto;
      generalImageUrl = exercise.media?.thumbnailUrl;
      title = exercise.title;
      break;
    case 'image':
      let image = item as ImageDto;
      generalImageUrl = image.media?.thumbnailUrl;
      title = image.title;
      break;
    case 'quote':
      const quote = item as QuoteDto;
      generalImageUrl = quote.media?.thumbnailUrl;
      title = quote.title;
      break;
    default:
      break;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <GeneralCardContainer key={item.id} onClick={() => {}} placeholder={'Top Level Container'} id={item.id + '_item'}>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {type === 'category' ? <SubCategoryIconChild /> : <NormalIconCategoryChild />}
            <GeneralCardInfo>
              <GeneralCardHeader>
                <GeneralCardType>{`${type[0].toUpperCase()}${type.substring(1)}`}</GeneralCardType>
                <GeneralTitle title={title}>{title}</GeneralTitle>
              </GeneralCardHeader>
              <GeneralDescription title={item.description}>{item.description}</GeneralDescription>
            </GeneralCardInfo>
            <>{generalImageUrl && <GeneralImage src={generalImageUrl} alt={title} />}</>
          </>
        )}
      </GeneralCardContainer>
    </div>
  );
};
export default CategoryEditCard;
