import {Button} from 'components/page';
import styled from 'styled-components';

export const SliderHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SliderTitleImage = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SliderTitle = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 20px;
`;

export const SliderAlias = styled.div`
  font-weight: 800px;
  font-size: 34px;
`;

export const SliderLocation = styled.div`
  color: #6c757d;
`;

export const DetailContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  margin-right: 10px;
`;

export const PropertyColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PField = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

export const PTitle = styled.div`
  color: grey;
  font-weight: 600;
`;

export const PValue = styled.div`
  margin-top: 10px;
  font-size: 18px;
`;

export const PValueCaption = styled.div`
  color: #b4c1c1;
  width: 80%;
  margin-top: 10px;
`;

export const SeparatorLine = styled.div`
  align-self: center;
  width: 100%;
  background-color: #b4c1c1;
  height: 1px;
  opacity: 0.8;
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SectionTitleAction = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const SectionAction = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline black;
  cursor: pointer;
`;

export const ActionDate = styled.div`
  color: #6c757d;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 30%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Action = styled.div`
  width: 60%;
`;

export const PropName = styled.div`
  color: #6c757d;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const BoxChat = styled.div`
  border: 1px solid rgba(180, 193, 193, 0.5);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const SendMessage = styled.div`
  margin: 5px;
  align-self: flex-end;
  background-color: #02acec;
  width: fit-content;
  padding: 10px;
  border-radius: 20px 20px 0px 20px;
  color: white;
  font-size: 16px;
`;

export const ReceiveMessage = styled.div`
  align-self: flex-start;
  background-color: #12e7b7;
  color: #010101;
  margin: 5px;
  width: fit-content;
  padding: 10px;
  border-radius: 0px 20px 20px 20px;
  font-size: 16px;
`;

export const SendMessageDate = styled.div`
  cursor: default;
  align-self: flex-end;
  width: fit-content;
  font-size: 12px;
  text-align: right;
  position: relative;
  margin-bottom: 15px;
  padding-right: 5px;
`;

export const ReceiveMessageDate = styled.div`
  cursor: default;
  align-self: flex-start;
  width: fit-content;
  font-size: 12px;
  text-align: left;
  position: relative;
`;

export const ReceiveMessageBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px;
  flex-direction: column;
`;

export const SendMessageBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px;
  flex-direction: column;
  position: relative;
`;

export const ChartLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ChartContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  color: #9ba2aa;
  text-decoration: underline;
  cursor: pointer;
`;

export const modalStyle = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

export const RedirectModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

export const RedirectModalSelectCancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
  margin: 6px;
`;

export const RedirectModalSelectYesButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
  margin: 6px;
`;

export const FilterContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

export const Tag = styled.span<{size?: string}>`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 16px;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: ${(props) => (props.size === 'medium' ? '32px' : '24px')};
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: ${(props) => (props.size === 'medium' ? '6px 16px' : '4px 12px')};
  top: 0px;
  user-select: none;
  min-width: 66px;
`;

export const NewNoteChat = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const BoxMessageContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(180, 193, 193, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  color: #495057;
  margin-top: 18px;
  height: fit-content;
`;
