import React from 'react';
import GridLoader from 'react-spinners/GridLoader';
import {Backdrop, override, SpinnerContainer} from './styles';

type SpinnerLoadingProps = {
  show: boolean;
};

const SpinnerLoading: React.FC<SpinnerLoadingProps> = ({show}) => {
  return (
    <SpinnerContainer show={show}>
      <Backdrop />
      <GridLoader size={50} color={'#12E7B7'} css={override} loading={true} />
    </SpinnerContainer>
  );
};

export default SpinnerLoading;
