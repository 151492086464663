import styled from 'styled-components';

type CardContentProp = {
  isSelected: boolean;
};

export const CardThumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`;

export const CardContent = styled.div`
  width: 430px;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  right: 0px;
  border-color: ${(props: CardContentProp) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  padding-top: 10px;
`;

export const CardImage = styled.div`
  display: flex;
  width: 93%;
  height: 78%;
  border-radius: 8px;
  background-color: #d3d3d3;
  position: relative;
  justify-content: center;
`;

export const CardTitle = styled.div`
  margin-left: 4%;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  align-self: start;
`;

export const ImageTag = styled.div`
  background-color: black;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  display: flex;
`;

export const MediaType = styled.div`
  background-color: rgba(41, 59, 85, 0.5);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  align-self: center;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
`;
