import styled from 'styled-components';

export const StyledSearchBar = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  color: #495057;
  cursor: text;
  display: inline-flex;
  flex: 1;
  margin-left: 20px;
  min-width: 50px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  :hover:not(:focus-within) {
    background-color: #fafafa;
  }

  i {
    cursor: text;
    display: flex;
    margin: 0 8px 0 20px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  :focus-within {
    border: 1px solid #293b55;
  }
`;
