import {CategoriesUl} from 'components/info/categories-ul';
import {useHistory} from 'react-router-dom';
import {
  DetailButtonLink as QDButtonLink,
  DetailField as QDField,
  DetailFieldImage as QDFieldImage,
  DetailFieldLabel as QDFieldLabel,
  DetailFieldRow as QDFieldRow,
  DetailFieldValue as QDFieldValue,
  DetailHeader as QDHeader,
  DetailID as QDID,
  DetailTitle as QDTitle,
  Label,
} from '../../../components/tools/main-page-components';

type QuoteDetailsProps = {
  quote: QuoteDto | null;
};

export type DropdownType = {
  label: string;
  value: string | number;
};

export const QuoteDetails: React.FC<QuoteDetailsProps> = ({quote}) => {
  const history = useHistory();

  return (
    <div>
      <div>
        <QDHeader>
          <div style={{display: 'flex'}}>
            <QDTitle>Quote Info</QDTitle>
            {quote?.isFree && <Label color={'#20C5A0'}>Free</Label>}
          </div>
          <div>
            <QDButtonLink onClick={() => history.push(`/tools/quotes/edit/${quote?.id}`)}>Edit</QDButtonLink>
          </div>
        </QDHeader>
        <QDID>{quote?.id}</QDID>
      </div>
      <div>
        <QDField>
          <QDFieldLabel>Quote</QDFieldLabel>
          <QDFieldValue>{quote?.quoteContent}</QDFieldValue>
        </QDField>
        <QDFieldRow>
          <QDField>
            <QDFieldLabel>Author</QDFieldLabel>
            <QDFieldValue>{quote?.quoteAuthor}</QDFieldValue>
          </QDField>
          <QDField>
            <QDFieldLabel>Category</QDFieldLabel>
            <QDFieldValue>
              <CategoriesUl categories={quote?.toolCategories}></CategoriesUl>
            </QDFieldValue>
          </QDField>
        </QDFieldRow>
        {quote?.promotedTool && (
          <QDField>
            <QDFieldLabel>Promoted {quote?.promotedTool?.toolType}</QDFieldLabel>
            <QDFieldValue>{quote?.promotedTool?.title}</QDFieldValue>
          </QDField>
        )}
        <QDField>
          <QDFieldLabel>Image</QDFieldLabel>
          <QDFieldImage src={quote?.media?.crops.find((crop) => crop.type === 'full_width_regular')?.url} alt={quote?.title} />

          <div style={{display: 'flex', flexDirection: 'column'}}>
            {quote?.media?.crops.find((crop) => crop.type === 'squared') && (
              <img
                style={{maxWidth: '40%', marginTop: '20px'}}
                src={quote?.media?.crops.find((crop) => crop.type === 'squared')?.url}
                alt={quote?.media?.name}
              ></img>
            )}

            {quote?.media?.crops.find((crop) => crop.type === 'full_width_regular') && (
              <img
                style={{width: '90%', marginTop: '20px'}}
                src={quote?.media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                alt={quote?.media?.name}
              ></img>
            )}
            {quote?.media?.crops.find((crop) => crop.type === 'full_screen_landscape') && (
              <img
                style={{maxWidth: '100%', marginTop: '20px'}}
                src={quote?.media?.crops.find((crop) => crop.type === 'full_screen_landscape')?.url}
                alt={quote?.media?.name}
              ></img>
            )}

            {quote?.media?.crops.find((crop) => crop.type === 'full_screen_portrait') && (
              <img
                style={{maxWidth: '50%', marginTop: '20px', marginBottom: '10px'}}
                src={quote?.media?.crops.find((crop) => crop.type === 'full_screen_portrait')?.url}
                alt={quote?.media?.name}
              ></img>
            )}
            {quote?.media?.crops.find((crop) => crop.type === 'full_width_tall') && (
              <img
                style={{maxWidth: '60%', marginTop: '20px', marginBottom: '10px'}}
                src={quote?.media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                alt={quote?.media?.name}
                // onLoad={handleLoaded}
              ></img>
            )}
          </div>
        </QDField>
      </div>
    </div>
  );
};
export default QuoteDetails;
