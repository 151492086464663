import React from 'react';
import styled from 'styled-components';

type FormElementProps = {
  type: string;
  label: string;
  placeholder?: string;
  value: string | number | undefined;
  onChange: (value: string) => void;
  helper?: string;
};

type StyledFormElementProps = {
  isVisible?: boolean;
  withHelpText?: boolean;
};

export const StyledFormElement = styled.div`
  display: ${(props: StyledFormElementProps) => (props.isVisible !== false ? 'flex' : 'none')};
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
  animation: 0.5s fadeIn;
  animation-fill-mode: initial;
  visibility: ${(props: StyledFormElementProps) => (props.isVisible !== false ? 'visible' : 'hidden')};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  label {
    color: #010101;
    cursor: ${(props: StyledFormElementProps) => (props.withHelpText ? 'help' : 'auto')};
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
    user-select: none;
  }

  input,
  textarea {
    background: #ffffff;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 3px;

    color: #495057;
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    padding: 8px 16px;
    transition: 0.1s;

    :hover:not(:focus-within) {
      background-color: #fafafa;
      z-index: 2;
    }

    :focus {
      outline: none;
    }

    :focus-within {
      border: 1px solid #293b55;
    }
  }

  textarea {
    resize: vertical;
    height: 200px;
  }
`;

export const FormElement: React.FC<FormElementProps> = ({type, label, placeholder, value, onChange, helper}) => {
  return (
    <StyledFormElement>
      <label htmlFor={`input${label}`}>{label}</label>
      {type === 'text-area' ? (
        <textarea id={`input${label}`} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
      ) : (
        <>
          <input
            id={`input${label}`}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
          />
          <span>{helper}</span>
        </>
      )}
    </StyledFormElement>
  );
};
