import React, {useEffect, useState} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  Form,
  PageFooter,
  CancelButton,
  SaveButton,
  HelpText,
} from 'components/page';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {StyledFormElement} from 'components/form-element';

import {useAppDispatch} from 'hooks';
import {sendMessage} from 'store/action-creators';

import {getSettings, updateSettings, updateToolMedias} from 'api/chatowl.api'; //updateSettings,
import MediaChooser from 'components/media-chooser';
import {StyleAddAudioFiles as StyledFormElementAux} from 'pages/media-bank/containers/audio/styles';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

export const Settings: React.FC<{title?: string}> = ({title}) => {
  const [settingValues, setSettingValues] = useState<AppSettingDto[]>([]); //This state variable is the mapping between setting_keys and values selected.
  const [dropdownLabels, setDropdownLabels] = useState<AppSettingLabelsDto>(); //Contains all dropdowns info.

  const [optionsTrialDuration, setOptionsTrialDuration] = useState<Label[]>([]);
  const [optionsDefaultSession, setOptionsDefaultSession] = useState<Label[]>([]);
  const [optionsAssessmentSession, setOptionsAssessmentSession] = useState<Label[]>([]);
  const [optionsDefaultGuidedJournal, setOptionsDefaultGuidedJournal] = useState<Label[]>([]);

  const [trialDuration, setDefaultTrialDuration] = useState(0);
  const [defaultSessionId, setDefaultSessionId] = useState(0);
  const [assessmentSessionId, setAssessmentSessionId] = useState(0);
  const [guidedJournalId, setDefaultGuidedJournalId] = useState(0);
  const [journalId, setDefaultJournalId] = useState(0);
  const [messagingId, setDefaultMessagingId] = useState(0);
  const [moodMeterId, setDefaultMoodMeterId] = useState(0);
  const [oneMinuteBreathingId, setDefaultOneMinuteBreathingId] = useState(0);

  const [guidedJournalMediaId, setGuidedJournalMediaId] = useState({mediaId: 0, imageChanged: false});
  const [journalMediaId, setJournalMediaId] = useState({
    mediaId: 0,
    imageChanged: false,
  });
  const [messagingMediaId, setMessagingMediaId] = useState({
    mediaId: 0,
    imageChanged: false,
  });
  const [moodMeterMediaId, setMoodMeterMediaId] = useState({
    mediaId: 0,
    imageChanged: false,
  });
  const [oneMinuteBreathingMediaId, setOneMinuteBreathingMediaId] = useState({
    mediaId: 0,
    imageChanged: false,
  });

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = (await getSettings()) as any;
        const settings: AppSettingsDto = response.data && response.data.data;
        setSettingValues(settings.values);

        const optionsTrialDuration = settings.options.default_trial_duration;
        setOptionsTrialDuration(optionsTrialDuration);
        const defaultTrialDuration = settings.values.find((setting) => setting.key === 'default_trial_duration')?.value;
        defaultTrialDuration && setDefaultTrialDuration(+defaultTrialDuration);

        const optionsDefaultSession = settings.options.default_session_id;
        setOptionsDefaultSession(optionsDefaultSession);
        const defaultSessionId = settings.values.find((setting) => setting.key === 'default_session_id')?.value;
        defaultSessionId && setDefaultSessionId(+defaultSessionId);

        const optionsAssessmentSession = settings.options.assessment_session_id;
        setOptionsAssessmentSession(optionsAssessmentSession);
        const assessmentSessionId = settings.values.find((setting) => setting.key === 'assessment_session_id')?.value;
        assessmentSessionId && setAssessmentSessionId(+assessmentSessionId);

        const optionsDefaultGuidedJournal = settings.options.default_guided_journal_id;
        setOptionsDefaultGuidedJournal(optionsDefaultGuidedJournal);
        const defaultGuidedJournalId = settings.values.find((setting) => setting.key === 'default_guided_journal_id')?.value;
        defaultGuidedJournalId && setDefaultGuidedJournalId(+defaultGuidedJournalId);

        const defaultJournalId = settings.values.find((setting) => setting.key === 'default_journal_id')?.value;
        defaultJournalId && setDefaultJournalId(+defaultJournalId);

        const defaultMessagingId = settings.values.find((setting) => setting.key === 'default_messaging_id')?.value;
        defaultMessagingId && setDefaultMessagingId(+defaultMessagingId);

        const defaultMoodMeterId = settings.values.find((setting) => setting.key === 'default_mood_meter_id')?.value;
        defaultMoodMeterId && setDefaultMoodMeterId(+defaultMoodMeterId);

        const defaultOneMinuteBreathingId = settings.values.find((setting) => setting.key === 'default_one_minute_breathing_id')
          ?.value;
        defaultOneMinuteBreathingId && setDefaultOneMinuteBreathingId(+defaultOneMinuteBreathingId);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMediasToUpdate = (): Array<{id: number; mediaId: number}> =>
    [
      {id: guidedJournalId, mediaId: guidedJournalMediaId},
      {id: journalId, mediaId: journalMediaId},
      {id: messagingId, mediaId: messagingMediaId},
      {id: moodMeterId, mediaId: moodMeterMediaId},
      {id: oneMinuteBreathingId, mediaId: oneMinuteBreathingMediaId},
    ]
      .filter((update) => update.mediaId.imageChanged)
      .map((update) => ({id: update.id, mediaId: update.mediaId.mediaId}));

  const getSettingValues = (): Array<AppSetting> => [
    {key: 'default_trial_duration', value: trialDuration.toString()},
    {key: 'default_session_id', value: defaultSessionId.toString()},
    {key: 'assessment_session_id', value: assessmentSessionId.toString()},
    {key: 'default_guided_journal_id', value: guidedJournalId.toString()},
  ];

  const onClickSave = async () => {
    try {
      const settings: AppSettingRequest = {data: getSettingValues()};
      const updateToolMediaRequest: UpdateToolMediaRequest = {data: getMediasToUpdate()};
      const response = (await updateSettings(settings)) as any;
      if (response.response.status >= 200 && response.response.status < 300) {
        const response = (await updateToolMedias(updateToolMediaRequest)) as any;
        if (response.response.status >= 200 && response.response.status < 300) {
          dispatch(
            sendMessage({
              severity: 'success',
              summary: 'SUCCESS',
              detail: 'Settings updated successfully.',
            })
          );
          document.getElementById('settingsTitle')?.scrollIntoView({behavior: 'smooth', block: 'center'});
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong updating images'}));
        }
      } else {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong updating settings'}));
      }
    } catch (error) {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      console.error(error);
    }
  };

  const onClickCancel = () => {
    history.push('/');
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle id='settingsTitle'>Settings</PageTitle>
      </PageHeader>
      <PageBody>
        <Form>
          <StyledFormElement>
            <label htmlFor={`Trial duration in days`}>{'Trial duration in days'}</label>
            <Dropdown
              value={trialDuration.toString()}
              options={optionsTrialDuration}
              onChange={(e) => setDefaultTrialDuration(e.value)}
              placeholder='Select default trial duration'
              optionLabel='name'
            />
            <HelpText>
              After the end of the trial period the client has to purchase a subscription in order to continue with their therapy.
            </HelpText>
          </StyledFormElement>
          <StyledFormElement>
            <label title='Default Session' htmlFor={`input Default Session`}>
              {'Default Session'}
            </label>
            <Dropdown
              value={defaultSessionId}
              options={optionsDefaultSession}
              onChange={(e) => setDefaultSessionId(e.value)}
              placeholder='Select Default Session'
              optionLabel='name'
            />
            <HelpText>
              The default session is started whenever the client navigates to the chat screen and is not currently in a session.
            </HelpText>
          </StyledFormElement>
          <StyledFormElement>
            <label title='Assessment Session' htmlFor={`input Assessment Session`}>
              {'Assessment Session'}
            </label>
            <Dropdown
              value={assessmentSessionId}
              options={optionsAssessmentSession}
              onChange={(e) => setAssessmentSessionId(e.value)}
              placeholder='Select Assessment Session'
              optionLabel='name'
            />
            <HelpText>
              The assessment is the session which let's the client select which therapy plan they want to follow.
            </HelpText>
          </StyledFormElement>
          {/* <StyledFormElement>
            <label htmlFor={`input Guided Journal Session`}>{'Guided Journal Session'}</label>
            <Dropdown
              value={guidedJournalId}
              options={optionsDefaultGuidedJournal}
              onChange={(e) => setDefaultGuidedJournalId(e.value)}
              placeholder='Select Guided Journal Session'
              optionLabel='name'
            />
            <HelpText>The guided journal is an exercise that starts this session in the chat view.</HelpText>
          </StyledFormElement> */}
        </Form>
        {/* <div style={{display: 'flex', flexDirection: 'column'}}> */}
        <StyledFormElement>
          <label
            title='Select a default image for the following tools'
            htmlFor={`input Select a default image for the following tools`}
            style={{marginBottom: '25px'}}
          >
            {'Select a default image for the following tools'}
          </label>
          <StyledFormElementAux>
            <MediaChooser
              onChooseMedia={(mediaId: number) => setGuidedJournalMediaId({mediaId, imageChanged: true})}
              media={settingValues.find((value) => value.key === 'default_guided_journal_id')?.media}
              mediaType={'image'}
              imagesTypes={['full_width_regular']}
              smallSize={true}
              label={'Guided Journal'}
            />
          </StyledFormElementAux>
          <StyledFormElementAux>
            <MediaChooser
              onChooseMedia={(mediaId: number) => setJournalMediaId({mediaId, imageChanged: true})}
              media={settingValues.find((setting) => setting.key === 'default_journal_id')?.media}
              mediaType={'image'}
              imagesTypes={['full_width_regular']}
              smallSize={true}
              label={'Journal'}
            />
          </StyledFormElementAux>
          <StyledFormElementAux>
            <MediaChooser
              onChooseMedia={(mediaId: number) => setMessagingMediaId({mediaId, imageChanged: true})}
              media={settingValues.find((setting) => setting.key === 'default_messaging_id')?.media}
              mediaType={'image'}
              imagesTypes={['full_width_regular']}
              smallSize={true}
              label={'Messaging'}
            />
          </StyledFormElementAux>
          <StyledFormElementAux>
            <MediaChooser
              onChooseMedia={(mediaId: number) => setMoodMeterMediaId({mediaId, imageChanged: true})}
              media={settingValues.find((setting) => setting.key === 'default_mood_meter_id')?.media}
              mediaType={'image'}
              imagesTypes={['full_width_regular']}
              smallSize={true}
              label={'Mood Meter'}
            />
          </StyledFormElementAux>
          <StyledFormElementAux>
            <MediaChooser
              onChooseMedia={(mediaId: number) => setOneMinuteBreathingMediaId({mediaId, imageChanged: true})}
              media={settingValues.find((setting) => setting.key === 'default_one_minute_breathing_id')?.media}
              mediaType={'image'}
              imagesTypes={['full_width_regular']}
              smallSize={true}
              label={'One minute breathing'}
            />
          </StyledFormElementAux>
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onClickCancel}>Cancel</CancelButton>
        <SaveButton onClick={onClickSave}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
