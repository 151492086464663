import React, {useContext, useEffect} from 'react';
import {Redirect, Route, Switch, useLocation, withRouter} from 'react-router-dom';
import App from './app';
import {Login} from '../../pages/login';
import {Error} from '../../pages/error';
import {NotFound} from '../../pages/not-found';
import {Access} from '../../pages/access';
import {AuthContext} from 'contexts/auth.context';
import SpinnerLoading from 'components/spinner-loading';
import {useAppSelector} from 'hooks';

const AppWrapper: React.FC = () => {
  let location = useLocation();
  const {isAuthenticated} = useContext(AuthContext);

  const loading = useAppSelector<LoadingState>((x) => x.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <SpinnerLoading show={loading.show} />
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/error' component={Error} />
        <Route path='/notfound' component={NotFound} />
        <Route path='/access' component={Access} />
        {isAuthenticated && <Route component={App} />}
        <Redirect to='/login' />
      </Switch>
    </>
  );
};

export default withRouter(AppWrapper);
