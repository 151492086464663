import React, {FC, useEffect, useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from './page';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {isCanvasData} from 'utils/helpers';

type ImageCropperProps = {
  imageSrc: string;
  isOpen: boolean;
  onCancelCrop: any;
  onSaveCrop: (image: Blob, cropper?: any) => void;
  aspectRatio?: number;
  minHeight?: number;
  minWidth?: number;
  cropDataSelected?: CanvasData;
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
`;

const SaveButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
`;

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 255,
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

export const ImageCropper: FC<ImageCropperProps> = ({
  imageSrc,
  isOpen,
  onCancelCrop,
  onSaveCrop,
  aspectRatio,
  minWidth,
  cropDataSelected,
}) => {
  const [cropper, setCropper] = useState<any>();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      try {
        if (aspectRatio === 1 / 1)
          cropper
            .getCroppedCanvas({width: 600, height: 600})
            .toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()), 'image/jpeg');
        if (aspectRatio === 25 / 8)
          cropper
            .getCroppedCanvas({width: 400, height: 128})
            .toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()), 'image/jpeg');
        if (aspectRatio === 16 / 9) {
          const height = 2500 * (9 / 16);
          cropper
            .getCroppedCanvas({width: 2500, height})
            .toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()), 'image/jpeg');
        }
        if (aspectRatio === 9 / 16) {
          const height = 1000 * (16 / 9);
          cropper
            .getCroppedCanvas({width: 1000, height})
            .toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()), 'image/jpeg');
        }
        if (aspectRatio === 12 / 7)
          cropper
            .getCroppedCanvas({width: 900, height: 525})
            .toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()), 'image/jpeg');
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (typeof cropper !== 'undefined' && aspectRatio) {
      setTimeout(() => {
        const image = cropper.getImageData();
        const canvas = cropper.getCanvasData();
        let minW = minWidth ? minWidth : 0;

        if (minW > image.naturalWidth) {
          minW = image.naturalWidth;
        }

        const width = (canvas.width * minW) / image.naturalWidth;
        const height = width * Math.pow(aspectRatio, -1);

        if (cropper.cropBoxData?.minWidth !== undefined && cropper.cropBoxData?.minHeight !== undefined) {
          cropper.setCropBoxData({
            minWidth: width,
            minHeight: height,
          });
          if (cropDataSelected && isCanvasData(cropDataSelected)) {
            const cropBoxData = {
              left: (cropDataSelected.left * canvas.width) / image.naturalWidth,
              top: (cropDataSelected.top * canvas.height) / image.naturalHeight,
              width: (cropDataSelected.width * canvas.width) / image.naturalWidth,
              height: (cropDataSelected.height * canvas.height) / image.naturalHeight,
            };
            cropper.setCropBoxData(cropBoxData);
          }
        }
      }, 30);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancelCrop}
      contentLabel='Edit Crop'
      style={style}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <h5 style={{textAlign: 'center'}}>Edit Crop</h5>
      <Cropper
        crop={cropper}
        style={{width: '100%'}}
        initialAspectRatio={1}
        preview='.img-preview'
        src={imageSrc}
        viewMode={1}
        guides={true}
        background={false}
        responsive={true}
        autoCropArea={1}
        aspectRatio={aspectRatio ? aspectRatio : undefined}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <ButtonsContainer>
        <CancelButton onClick={onCancelCrop}>Cancel</CancelButton>
        <SaveButton onClick={getCropData}>Save</SaveButton>
      </ButtonsContainer>
    </Modal>
  );
};
