import {RedirectErrorBoundary} from 'components/error-boundary/error.boundary';
import React, {useEffect, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {Link} from 'react-router-dom';
import {filterExercisesBySearchValue, filterExercisesByTags} from 'store/action-creators';
import styled from 'styled-components';
import {listExercises} from '../../../api/chatowl.api';
import GeneralSidebar from '../../../components/general-sidebar';
import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import SearchBar from '../../../components/search-bar';
import GeneralFilters from '../../../components/tools/filters';
import ExerciseCard from '../../../components/tools/general-card-list';
import {
  AddButton as AddExerciseButton,
  ListControls,
  ListGeneral as ExerciseList,
  ListWrapper as ExerciseListWrapper,
} from '../../../components/tools/main-page-components';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {selectExercise, unselectExercise} from '../../../store/action-creators';
import ExerciseDetails from './exercise-detail';

const ExerciseNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No Exercises';
  }
`;

export const Exercises: React.FC<{title?: string}> = ({title}) => {
  const [exercises, setExercises] = useState<ExerciseDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const selectedExercise: ExerciseDto | null = useAppSelector(
    (state: RootState) => state.tools.exercises.selectedExercise,
    shallowEqual
  );

  const exerciseTags: ToolTag[] = ['Active', 'Archived'];
  const selectedTags: ToolTag[] = useAppSelector((state: RootState) => state.tools.exercises.filters.tags, shallowEqual);
  const searchValue: string = useAppSelector((state: RootState) => state.tools.exercises.filters.search, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const response = (await listExercises({withDeleted: false, sortBy: 'id'})) as any;
        const exercisesResponse = response.data.data;
        setExercises(exercisesResponse);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchExercises();
  }, []);

  useEffect(() => {
    if (
      (selectedExercise?.isArchived && !selectedTags.includes('Archived')) ||
      (!selectedExercise?.isArchived && !selectedTags.includes('Active'))
    ) {
      dispatch(unselectExercise());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const filters: string[] = ['Active', 'Archived'];

  const onExerciseClick = (toolID?: number): void => {
    const exerciseSelected = exercises.find((exercise) => exercise.id === toolID);
    if (exerciseSelected)
      toolID !== selectedExercise?.id ? dispatch(selectExercise(exerciseSelected)) : dispatch(unselectExercise());
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(filterExercisesBySearchValue(e.target.value));
  };

  const onClick = (): void => {
    dispatch(unselectExercise());
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as ToolTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(filterExercisesByTags(newTags));
  };

  const filterByStatus = (exercise: ExerciseDto) =>
    !selectedTags.length ||
    (selectedTags.includes('Active') && !exercise.isArchived) ||
    (selectedTags.includes('Archived') && exercise.isArchived);

  const filterBySearchValue = (exercise: ExerciseDto) =>
    !searchValue.toLowerCase() ||
    exercise.id.toString().includes(searchValue) ||
    exercise.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    exercise.description?.toLowerCase().includes(searchValue.toLowerCase());

  const exercisesFiltered = (exercises && exercises.filter(filterByStatus).filter(filterBySearchValue)) || [];

  const skeleton = [1, 2, 3, 4, 5, 6];

  return (
    <PageContainer>
      <RedirectErrorBoundary>
        <ExerciseListWrapper>
          <PageHeader>
            <PageTitle>Exercises</PageTitle>
            <AddExerciseButton>
              <Link to='/tools/exercises/add'>+ Add Exercise</Link>
            </AddExerciseButton>
          </PageHeader>
          <ListControls>
            <GeneralFilters tags={exerciseTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter}></GeneralFilters>
            <SearchBar value={searchValue} onChange={onSearchValueChange} />
          </ListControls>
          <ExerciseList>
            {isLoading ? (
              skeleton.map((v) => <ExerciseCard key={v} selectedToolID={0} onClick={() => {}} />)
            ) : exercisesFiltered.length ? (
              exercisesFiltered.map((exercise) => (
                <ExerciseCard key={exercise.id} selectedToolID={selectedExercise?.id} tool={exercise} onClick={onExerciseClick} />
              ))
            ) : (
              <ExerciseNotFound />
            )}
          </ExerciseList>
        </ExerciseListWrapper>
        <GeneralSidebar isActive={!!selectedExercise} onClick={onClick}>
          <ExerciseDetails exercise={selectedExercise} />
        </GeneralSidebar>
      </RedirectErrorBoundary>
    </PageContainer>
  );
};
