import React from 'react';
import {Filters} from '../main-page-components';
import {StyledFilter} from './styles';

interface GeneralFiltersProps {
  tags: string[]; //An array string representing the filters you want to display. Example: const filters: string[] = ['Active', 'Archived'];

  selectedTags: string[]; //State variable that represents the filters selected in page.
  onClickToggle: (filter: string) => void; //Turn on or turn off filter. Using state variable's "selectedFilters" setter.
}

export const GeneralFilters: React.FC<GeneralFiltersProps> = ({tags: filters, selectedTags: selectedFilters, onClickToggle}) => {
  const Filter = (filter: string) => {
    return (
      <StyledFilter key={filter} onClick={() => onClickToggle(filter)} isSelected={selectedFilters.some((f) => f === filter)}>
        {filter}
      </StyledFilter>
    );
  };

  return <Filters>{filters.map(Filter)}</Filters>;
};
export default GeneralFilters;

/* 

  */
