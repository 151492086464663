import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding-left: 32px;
  padding-right: 32px;
`;

export const PageListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 20px;
  overflow: auto;
`;

export const PageHeader = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  padding-top: 32px;
  width: 100%;
`;

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  margin: 2px;
  display: flex;
  justify-content: space-between;

  color: rgb(33, 50, 76);
`;

export const Button = styled.span`
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  height: 33px;
  justify-content: center;
  text-align: center;
  transition: 0.1s;
  min-width: 136px;
  :hover {
    opacity: 90%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    :focus,
    :hover,
    :visited,
    :link,
    :active {
      text-decoration: none;
    }
  }
`;

export const ButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

type SaveButtonProps = {
  disable?: boolean;
};

/*export const SaveButton = styled(Button)`
  background: #2c98f0;
  border: 1px solid #2c98f0;
  color: white;
  margin: 6px;
  disabled: ${(props: SaveButtonProps) => (props.disabled ? 'true' : 'false')};
`;
*/

export const SaveButton = styled.button`
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  height: 33px;
  justify-content: center;
  text-align: center;
  transition: 0.1s;
  min-width: 136px;
  background: #2c98f0;
  border: 1px solid #2c98f0;
  color: white;
  margin: 6px;
  ${(props: SaveButtonProps) => props.disable && 'cursor: not-allowed'};
`;
//${(props: SaveButtonProps) => props.disable && 'pointer-events: none'};
/*   :hover {
    ${(props: SaveButtonProps) => props.disable && 'cursor: wait'};
  }
   */

export const CancelButton = styled(Button)`
  background: white;
  border: 1px solid #2c98f0;
  color: #2c98f0;
  margin: 6px;
`;

export const DeleteButton = styled(Button)`
  background: white;
  border: 1px solid #eb5757;
  color: #eb5757;
  margin: 6px;
`;

type ArchiveButtonProps = {
  userClicked?: boolean;
};

export const ArchiveButton = styled(CancelButton)`
  color: ${(props: ArchiveButtonProps) => (props.userClicked ? 'white' : '#eb5757')};
  border: 1px solid #eb5757;
  background: ${(props: ArchiveButtonProps) => (props.userClicked ? '#eb5757' : 'white')};
`;

export const PageBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 80px;
  padding-bottom: 150px;
`;

export const PageFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  position: fixed;
  bottom: 0px;
  left: 224px; /* same as sidebar width */
  background-color: white;
  width: 100%;
  z-index: 3;
  box-shadow: 15px 4px 12px -3px #aaa;
`;

export const AddButton = styled(Button)`
  background: #2c98f0;
  color: white;
  text-decoration: none;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    :focus,
    :hover,
    :visited,
    :link,
    :active {
      color: white;
      text-decoration: none;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
  min-width: 500px;
  max-width: 500px;
  width: 100%;
`;

export const DefaultProgramButtonContainer = styled.div`
  margin-bottom: 38px;
`;

type StyledDefaultProgramButtonProps = {
  isDefaultProgram?: boolean;
};

export const DefaultProgramButton = styled(Button)`
  background: transparent;
  color: ${(props: StyledDefaultProgramButtonProps) => (props.isDefaultProgram ? '#EB5757' : '#2c98f0')};
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  justify-content: flex-start;
  width: max-content;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

export const HelpText = styled.span`
  color: #777c7c;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 12px;
`;

export const Label = styled.label`
  color: #010101;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  margin-bottom: 8px;
`;

export const ActivityListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: auto;
`;

type CursorLoadingProps = {
  disable?: boolean;
};

export const CursorLoading = styled.div`
  cursor: ${(props: CursorLoadingProps) => (props.disable ? 'progress' : 'default')};
`;

export const PDButtonBack = styled.div`
  color: #2c98f0;
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  align-items: center;
  display: flex;
  user-select: none;
  padding: 10px;
`;

export const ControlWrapperRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
