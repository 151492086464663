export const capitalize = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const getCanvasBlob = async (canvas: any): Promise<Blob> => {
  return new Promise((resolve) =>
    canvas.toBlob((blob: Blob) => {
      resolve(createFile(blob, 'blob.jpg'));
    }, 'image/jpeg')
  );
};

export const createFile = (blob: Blob, fileName: string, type: string = 'image/jpeg') =>
  new File([blob], fileName, {type, lastModified: new Date().getTime()});

export const momentToNumber = (moment: ActivityMoment): number => {
  switch (moment) {
    case 'morning':
      return 0;
    case 'afternoon':
      return 1;
    case 'evening':
      return 2;
    case 'night':
      return 3;
    default:
      return 4;
  }
};

export const secondsToLabel = (seconds: number | undefined): string => {
  if (!seconds) return '';
  if (seconds >= 0 && seconds < 60) return `${seconds} second${seconds === 1 ? '' : 's'}`;
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) return `${minutes} minute${minutes === 1 ? '' : 's'}`;
  const hours = Math.round(minutes / 60);
  if (hours < 24) return `${hours} hour${hours === 1 ? '' : 's'}`;
  const days = Math.round(hours / 24);
  if (days < 7) return `${days} day${days === 1 ? '' : 's'}`;
  const weeks = Math.round(days / 7);
  if (weeks < 4) return `${weeks} week${weeks === 1 ? '' : 's'}`;
  const months = Math.round(days / 30);
  if (months < 12) return `${months} month${months === 1 ? '' : 's'}`;
  const years = Math.round(months / 12);
  return `${years} year${years === 1 ? '' : 's'}`;
};

export const isCanvasData = (data: CanvasData | undefined): boolean =>
  data?.height !== undefined && data.width !== undefined && data?.top !== undefined && data?.left !== undefined;

export const isAnyStringEmpty = (texts: string[]): boolean => {
  let valid: boolean = false;
  texts.forEach((t) => {
    if (t.match(/^ *$/) !== null) valid = true;
  });
  return valid;
};

export const signDate = (date: string | Date): string | undefined => {
  let message;
  let dateLastSeen = new Date(date);
  let diff = Math.floor(new Date().getTime() - dateLastSeen.getTime());
  let day = 1000 * 60 * 60 * 24;
  let days = Math.floor(diff / day);
  let months = Math.floor(days / 31);
  let years = Math.floor(months / 12);
  if (days === 0) message = 'Today';
  if (days === 1) message = 'Yesterday';
  if (days > 1) message = days + ' days ago';
  if (months > 0)
    if (months === 1) message = months + ' month ago';
    else message = months + ' months ago';
  if (years > 0)
    if (years === 1) message = years + ' year ago';
    else message = years + ' years ago';

  return message;
};

export const isValidValue = (propertyType: string, value: any): boolean => {
  try {
    switch (propertyType) {
      case 'string[]':
        return checkArrayStringValue(value as (string | null)[]);
      case 'boolean[]':
        return checkArrayBooleanValue(value as (boolean | null)[]);
      case 'number[]':
        return checkArrayNumberValue(value as (number | null)[]);
      case 'string':
        return checkSimpleStringValue(value?.toString() || null);
      case 'boolean':
        return checkSimpleBooleanValue(value as boolean | null);
      case 'number':
        return checkSimpleNumberValue(Number(value) as number | null);
      default:
        console.log('Invalid Prop Type', propertyType, value);
    }
  } catch (err) {
    console.log('Invalid Prop Type', propertyType, value);
  }
  return false;
};
const checkSimpleStringValue = (value: string | null): boolean => {
  return typeof value === 'string' || value == null; //sparse values in arrays are valid
};
const checkSimpleBooleanValue = (value: boolean | null): boolean => {
  return typeof value === 'boolean' || value == null; //sparse values in arrays are valid
};
const checkSimpleNumberValue = (value: number | null): boolean => {
  return (typeof value === 'number' || value == null) && !isNaN(value as number); //sparse values in arrays are valid
};
const checkArrayStringValue = (value: (string | null)[]): boolean => {
  const result = value.every(function (item: string | null) {
    return typeof item === 'string' || item == null; //sparse arrays are valid
  });
  return result;
};
const checkArrayBooleanValue = (value: (boolean | null)[]): boolean => {
  const result = value.every(function (item: boolean | null) {
    return typeof item === 'boolean' || item == null; //sparse arrays are valid
  });
  return result;
};
const checkArrayNumberValue = (value: (number | null)[]): boolean => {
  const result = value.every(function (item: number | null) {
    return typeof item === 'number' || item == null; //sparse arrays are valid
  });
  return result;
};

export const cast = (value: any, type: string) => {
  if (type === 'string') return value;
  if (type === 'number' || type === 'boolean' || type === 'number[]' || type === 'boolean[]') return JSON.parse(value);
  if (type === 'string[]') return value.substring(1, value.length - 1).split(',');
};

/** Cut the text and adds ellipsis (three dots) after the text to place it in fixed-width places
 * @example cutText('hello world', 10) => 'hello...'
 * */
export const cutText = (text: string, width: number): string => {
  if (!text) return '';
  if (text.length <= width) return text;
  return text.substring(0, width - 3) + '...';
};
