import React from 'react';
import {StyledFilter} from './styles';

type FilterToggleProps = {
  filter: string;
  selectedFilter: string[];
  setSelectedFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

const FilterToggle: React.FC<FilterToggleProps> = ({filter, selectedFilter, setSelectedFilter}) => {
  const toggleFilters = () => {
    selectedFilter.some((f) => f === filter)
      ? setSelectedFilter(selectedFilter.filter((f) => f !== filter)) // turn off
      : setSelectedFilter([...selectedFilter, filter]); // turn on
  };
  return (
    <StyledFilter key={filter} onClick={toggleFilters} isSelected={selectedFilter.some((f) => f === filter)}>
      {filter}
    </StyledFilter>
  );
};

export default FilterToggle;
