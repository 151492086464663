import {assignInteractionToCounselor} from 'api/chatowl.api';
import moment from 'moment';
import React, {useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import {
  Icon,
  IconText,
  Message,
  MessageClient,
  MessageContainer,
  MessageDataContainer,
  MessageIndicator,
  MessageInfo,
  MessageText,
  Tag,
} from './styles';

type InteractionRowProps = {
  interaction?: InteractionDTO;
  isLast?: boolean;
  searchValue?: string;
};

export const MessageRow: React.FC<InteractionRowProps> = ({interaction, isLast, searchValue}) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  const getColorTag = (state: string) => {
    switch (state) {
      case 'overdue':
        return '#EB5757';
      case 'unassigned':
        return '#D563FF';
      case 'assigned_to_me':
        return '#20C5A0';
      default:
        return 'transparent';
    }
  };

  const getReg = () => {
    let expre = '(' + searchValue + ')';
    let re = new RegExp(expre, 'i');
    return re;
  };

  const getColorIndicator = (state: string) => {
    switch (state) {
      case 'overdue':
        return '#EB5757';
      case 'unassigned':
        return '#A031F8';
      case 'assigned_to_me':
        return '#20C5A0';
      default:
        return 'transparent';
    }
  };

  const formatTagMessage = (tag: string): string => {
    switch (tag) {
      case 'overdue':
        return 'Overdue';
      case 'unassigned':
        return 'Unassigned';
      case 'assigned_to_me':
        return 'Assigned to me';
      default:
        return '';
    }
  };

  const handleRowClick = async (intId: string) => {
    if (interaction?.assignedCounselorEmail === undefined) await assignInteractionToCounselor(intId);
    history.push(`/messaging/${intId}`);
  };

  const questionDate = interaction?.clientQuestion[0] && interaction.clientQuestion[0].date;

  let assignedTo = '',
    assignedToLabel = '';
  if (interaction?.assignedCounselorEmail === '') {
    assignedTo = assignedToLabel = 'Assigned';
  } else if (!interaction?.assignedCounselorEmail) {
    assignedTo = assignedToLabel = 'Unassigned';
  } else {
    assignedTo = interaction.assignedCounselorEmail;
    assignedToLabel = `Assigned to ${assignedTo}`;
  }

  return (
    <>
      {' '}
      {interaction && isLast !== undefined ? (
        <MessageContainer
          isLast={isLast}
          onMouseEnter={(e) => setHover(true)}
          onMouseLeave={(e) => setHover(false)}
          onClick={async () => await handleRowClick(interaction.id)}
        >
          <Message>
            <MessageIndicator hover={hover} color={getColorIndicator(interaction.tags[0])} />
            <MessageDataContainer>
              <MessageInfo>
                <Tag color={getColorTag(interaction.tags[0])}>{formatTagMessage(interaction.tags[0])}</Tag>
                <IconText>
                  <Icon width='20' src='/assets/layout/images/icons/clock-icon.svg' />
                  <span title={`Asked on ${moment(questionDate).format('YYYY/MM/DD h:mm:ss a')}`}>
                    {moment(questionDate).calendar()}
                  </span>
                </IconText>
                <IconText style={{overflow: 'hidden'}}>
                  <Icon width='20' src='/assets/layout/images/icons/profile-icon.svg' />
                  <span title={assignedToLabel}>{assignedTo || 'Unassigned'}</span>
                </IconText>
              </MessageInfo>
              <MessageText title={interaction.clientQuestion[0].text}>
                {interaction.clientQuestion[0] && (
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {interaction.clientQuestion[0].type === 'text' &&
                      reactStringReplace(interaction.clientQuestion[0].text, getReg(), (match, i) => (
                        <span key={i} style={{backgroundColor: '#f9fc3d', fontWeight: 'bold'}}>
                          {match}
                        </span>
                      ))}
                    {interaction.clientQuestion[0].type === 'image' && (
                      <>
                        <i className='pi pi-image' style={{fontSize: '1.5rem'}}></i> Photo
                      </>
                    )}
                    {interaction.clientQuestion[0].type === 'audio' && (
                      <>
                        <i className='pi pi-volume-up' style={{fontSize: '1.5rem'}}></i> Audio
                      </>
                    )}
                    {interaction.clientQuestion[0].type === 'video' && (
                      <>
                        <i className='pi pi-video' style={{fontSize: '1.5rem'}}></i> Video
                      </>
                    )}
                  </div>
                )}
              </MessageText>
              <MessageClient>
                <IconText>
                  <Icon width='20' src='/assets/layout/images/icons/paper-icon.svg' />
                  {interaction.clientPlan}
                </IconText>
                <IconText>
                  <Icon width='20' src='/assets/layout/images/icons/calendar-clock-icon.svg' />
                  Using App since {interaction.clientCreatedAt.slice(0, 10).replace(/-/g, '/')}
                </IconText>
                <IconText>
                  <Icon width='20' src='/assets/layout/images/icons/card-icon.svg' />
                  {interaction.clientSubscription}
                </IconText>
              </MessageClient>
            </MessageDataContainer>
          </Message>
        </MessageContainer>
      ) : (
        <MessageContainer isLast={false}>
          {' '}
          <Skeleton />
        </MessageContainer>
      )}{' '}
    </>
  );
};
