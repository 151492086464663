import {editExercise, updateImageTool, updateQuote, editSession} from 'api/chatowl.api';
import classNames from 'classnames';
import {Signout} from 'components/signout';
import {AuthContext} from 'contexts/auth.context';
import {BotChat} from 'pages/bot-chat';
import {Clients} from 'pages/clients';
import {AddMedia} from 'pages/media-bank/add-media';
import {EditMedia} from 'pages/media-bank/edit-media';
import {MediaBank} from 'pages/media-bank/media-list';
import {AnswerMessage} from 'pages/messaging/answer-message';
import {Messaging} from 'pages/messaging/index';
import {Settings} from 'pages/settings';
import {AddProgram} from 'pages/therapy-programs/add-program';
import {EditProgram} from 'pages/therapy-programs/edit-program';
import {ManageWeeks} from 'pages/therapy-programs/manage-weeks';
import {AddCategory} from 'pages/tools/categories/add-categories';
import {ToolCategories} from 'pages/tools/categories/categories';
import {SortToolCategories} from 'pages/tools/categories/sort-categories';
import {AddExercise} from 'pages/tools/exercises/add-edit-exercise';
import {Exercises} from 'pages/tools/exercises/exercises';
import {AddImage} from 'pages/tools/images/add-image';
import {Images} from 'pages/tools/images/images';
import {AddQuote} from 'pages/tools/quotes/add-quote';
import {Quotes} from 'pages/tools/quotes/quotes';
import {AddSession} from 'pages/tools/sessions/add-edit-session';
import {Sessions} from 'pages/tools/sessions/sessions';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import {Toast} from 'primereact/toast';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {clearFilters} from 'store/action-creators';
import {capitalize} from 'utils/helpers';
import {UserRole} from 'utils/user.roles';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {RootState} from '../../index';
import {AddActivity} from '../../pages/therapy-programs/activities/add-activity';
import {EditActivity} from '../../pages/therapy-programs/activities/edit-activity';
import {TherapyProgramDetail} from '../../pages/therapy-programs/therapy-program-detail';
import {TherapyPrograms} from '../../pages/therapy-programs/therapy-programs';
import AppMenu from './app.menu';
import './app.scss';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const toast: React.MutableRefObject<any> = useRef(null);
  const toastUndo: React.MutableRefObject<any> = useRef(null);

  const toastMessage: ToastMessage | null = useAppSelector((state: RootState) => state.toast.toastInfo, shallowEqual);
  const toggleToastMessage: boolean = useAppSelector((state: RootState) => state.toast.toggleToast, shallowEqual);

  const toastUndoArchive: ToastToolInfo | null = useAppSelector((state: RootState) => state.undoToast.toastInfo, shallowEqual);
  const toggleToastUndo: boolean = useAppSelector((state: RootState) => state.undoToast.toggleToast, shallowEqual);

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    toastMessage &&
      toast.current.show({
        life: toastMessage.life || 7000,
        severity: toastMessage.severity,
        summary: toastMessage.summary,
        detail: toastMessage.detail,
      });
  }, [toastMessage, toggleToastMessage]); //toggleToast alllows me send two equal Messages.

  useEffect(() => {
    toastUndoArchive &&
      toastUndo.current.show({
        life: 7000,
        severity: 'warn',
        summary: `${capitalize(toastUndoArchive.toolType)} archived`,
        detail: 'Click here to undo action',
      });
  }, [toastUndoArchive, toggleToastUndo]); //toggleToast alllows me send two equal Messages.

  const handleOnClick = async () => {
    toastUndo.current.clear();
    if (toastUndoArchive?.toolType === 'session') {
      const editedSession: UpdateSessionRequest = {
        isArchived: false,
      };
      let updatedSession = (await editSession(toastUndoArchive.idTool, editedSession)) as any;
      if (updatedSession.response.status >= 200 && updatedSession.response.status < 300) {
        toast.current.show({
          severity: 'success',
          summary: 'Archive undone',
          detail: 'Session has been unarchived',
        });
      }
    }
    if (toastUndoArchive?.toolType === 'exercise') {
      const editedExercise: UpdateExerciseRequest = {
        isArchived: false,
      };
      let updatedExercise = (await editExercise(toastUndoArchive.idTool, editedExercise)) as any;
      if (updatedExercise.response.status >= 200 && updatedExercise.response.status < 300) {
        toast.current.show({
          severity: 'success',
          summary: 'Archive undone',
          detail: 'Exercise has been unarchived',
        });
      }
    }
    if (toastUndoArchive?.toolType === 'quote') {
      const editedQuote: UpdateQuoteRequest = {
        isArchived: false,
      };
      let updatedQuote = (await updateQuote(editedQuote, toastUndoArchive.idTool)) as any;
      if (updatedQuote.response.status >= 200 && updatedQuote.response.status < 300) {
        toast.current.show({
          severity: 'success',
          summary: 'Archive undone',
          detail: 'Quote has been unarchived',
        });
      }
    }
    if (toastUndoArchive?.toolType === 'image') {
      const editedImage: UpdateImageToolRequest = {
        isArchived: false,
      };
      let updatedImage = (await updateImageTool(editedImage, toastUndoArchive.idTool)) as any;
      if (updatedImage.response.status >= 200 && updatedImage.response.status < 300) {
        toast.current.show({
          severity: 'success',
          summary: 'Archive undone',
          detail: 'Image has been unarchived',
        });
      }
    }
    //Add other tools
    history.go(0); //When routing is fixed, it should reload page and remain in tool.
  };

  let menuClick = false;
  const {role: userRoles} = useContext(AuthContext);

  const menu = [
    {
      label: '',
      icon: 'pi pi-fw pi-home',
      items: [
        {
          label: 'Therapy Programs',
          icon: 'pi pi-fw pi-list',
          to: '/therapy-programs/',
          roles: [UserRole.Staff],
          command: () => dispatch(clearFilters()),
        },
        {
          label: 'Tools',
          icon: 'pi pi-fw pi-th-large',
          items: [
            {
              label: 'Sessions',
              to: '/tools/sessions',
              roles: [UserRole.Staff],
              command: () => dispatch(clearFilters({exclude: 'sessions'})),
            },
            {
              label: 'Exercises',
              to: '/tools/exercises',
              roles: [UserRole.Staff],
              command: () => dispatch(clearFilters({exclude: 'exercises'})),
            },
            {
              label: 'Quotes',
              to: '/tools/quotes',
              roles: [UserRole.Staff],
              command: () => dispatch(clearFilters({exclude: 'quotes'})),
            },
            {
              label: 'Images',
              to: '/tools/images',
              roles: [UserRole.Staff],
              command: () => dispatch(clearFilters({exclude: 'images'})),
            },
            {
              label: 'Categories',
              to: '/tools/categories',
              roles: [UserRole.Staff],
              command: () => dispatch(clearFilters({exclude: 'categories'})),
            },
            // {label: 'Categories'},
          ],
          roles: [UserRole.Staff],
        },
        {
          label: 'Messaging',
          icon: 'pi pi-fw pi-inbox',
          to: '/messaging/',
          roles: [UserRole.Staff, UserRole.Therapist],
          command: () => dispatch(clearFilters()),
        },
        // {label: 'Bot Chat', icon: 'pi pi-fw pi-comment', to: '/bot-chat/'},
        {
          label: 'Media Bank',
          icon: 'pi pi-fw pi-image',
          to: '/media-bank/',
          roles: [UserRole.Staff],
          command: () => dispatch(clearFilters({exclude: 'media-bank'})),
        },
        {
          label: 'Clients',
          icon: 'pi pi-fw pi-users',
          to: '/clients/',
          roles: [UserRole.Staff],
          command: () => dispatch(clearFilters()),
        },
        {
          label: 'Settings',
          icon: 'pi pi-fw pi-cog',
          to: '/settings/',
          roles: [UserRole.Staff],
          command: () => dispatch(clearFilters()),
        },
      ],
    },
    {separator: true},
    {
      label: 'Administrator User',
      icon: 'pi pi-fw pi-user',
      items: [{label: 'Sign Out', icon: 'pi pi-fw pi-sign-out', to: '/signout/'}],
    },
  ];

  const routers = [
    {
      path: '/therapy-programs',
      exact: true,
      component: TherapyPrograms,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
      title: 'Therapy Programs',
      roles: [UserRole.Staff],
    },
    {
      path: '/therapy-programs/:id/manage-weeks',
      exact: true,
      component: ManageWeeks,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
      title: 'Manage Weeks',
      roles: [UserRole.Staff],
    },
    {
      path: '/therapy-programs/add',
      component: AddProgram,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
      title: 'Add Program',
      roles: [UserRole.Staff],
    },
    {
      path: '/therapy-programs/edit/:id',
      component: EditProgram,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
      title: 'Edit Program',
      roles: [UserRole.Staff],
    },

    {
      path: '/messaging/:interactionId',
      component: AnswerMessage,
      meta: {breadcrumb: [{parent: 'Messaging', label: 'Answer Message'}]},
      title: 'Answer Message',
      roles: [UserRole.Staff, UserRole.Therapist],
    },

    {
      path: '/messaging',
      component: Messaging,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Messaging'}]},
      title: 'Messaging',
      roles: [UserRole.Staff, UserRole.Therapist],
    },

    {
      path: '/bot-chat',
      component: BotChat,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Bot Chat'}]},
      title: 'Bot Chat',
      roles: [UserRole.Staff, UserRole.Therapist],
    },
    {
      path: '/media-bank',
      exact: true,
      component: MediaBank,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Media Bank'}]},
      title: 'Media Bank',
      roles: [UserRole.Staff],
    },
    {
      path: '/media-bank/medias/add/',
      exact: true,
      component: AddMedia,
      meta: {breadcrumb: [{parent: 'Media Bank', label: 'Media Bank'}]},
      title: 'Add Media',
      roles: [UserRole.Staff],
    },
    {
      path: '/media-bank/medias/:mediaId/edit/',
      exact: true,
      component: EditMedia,
      meta: {breadcrumb: [{parent: 'Media Bank', label: 'Media Bank'}]},
      title: 'Edit Media',
      roles: [UserRole.Staff],
    },

    {
      path: '/clients',
      component: Clients,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Clients'}]},
      title: 'Clients',
      roles: [UserRole.Staff],
    },
    {
      path: '/settings',
      component: Settings,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Settings'}]},
      title: 'Settings',
      roles: [UserRole.Staff],
    },
    {path: '/signout', component: Signout, meta: {breadcrumb: [{parent: 'Menu', label: 'Sign Out'}]}, title: 'Sign Out'},
    {
      path: '/therapy-programs/:progId/activities/add',
      component: AddActivity,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
      title: 'Add Activity',
      roles: [UserRole.Staff],
    },
    {
      path: '/therapy-programs/:progId/activities/edit/:id',
      component: EditActivity,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
      title: 'Edit Activity',
      roles: [UserRole.Staff],
    },
    {
      path: '/therapy-programs/:id',
      component: TherapyProgramDetail,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
      title: 'Therapy Program Detail',
      roles: [UserRole.Staff],
    },

    {
      path: '/tools/exercises',
      component: Exercises,
      exact: true,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Exercises'}]},
      title: 'Exercises',
      roles: [UserRole.Staff],
    },

    {
      path: '/tools/exercises/:formType/:exerciseId?',
      component: AddExercise,
      exact: true,
      meta: {breadcrumb: [{parent: 'Exercises', label: 'Exercises'}]},
      title: 'Exercise',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/categories',
      component: ToolCategories,
      exact: true,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Categories'}]},
      title: 'Categories',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/categories/sort',
      component: SortToolCategories,
      exact: true,
      meta: {breadcrumb: [{parent: 'Categories', label: 'Categories'}]},
      title: 'Categories',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/categories/:formType/:categoryId?',
      component: AddCategory,
      exact: true,
      meta: {breadcrumb: [{parent: 'Categories', label: 'Categories'}]},
      title: 'Category',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/sessions',
      component: Sessions,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Sessions'}]},
      exact: true,
      title: 'Sessions',
      roles: [UserRole.Staff],
    },

    {
      path: '/tools/sessions/:formType/:sessionId?',
      component: AddSession,
      exact: true,
      meta: {breadcrumb: [{parent: 'Sessions', label: 'Sessions'}]},
      title: 'Session',
      roles: [UserRole.Staff],
    },

    {
      path: '/tools/quotes',
      component: Quotes,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Quotes'}]},
      exact: true,
      title: 'Quotes',
      roles: [UserRole.Staff],
    },

    {
      path: '/tools/quotes/:formType/:quoteId?',
      component: AddQuote,
      meta: {breadcrumb: [{parent: 'Quotes', label: 'Quotes'}]},
      exact: true,
      title: 'Quote',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/images',
      component: Images,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Images'}]},
      exact: true,
      title: 'Images',
      roles: [UserRole.Staff],
    },
    {
      path: '/tools/images/:formType/:imageId?',
      component: AddImage,
      meta: {breadcrumb: [{parent: 'Images', label: 'Images'}]},
      exact: true,
      title: 'Image',
      roles: [UserRole.Staff],
    },
  ];

  const onDocumentClick = () => {
    if (!menuClick) {
      unblockBodyScroll();
    }
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-static': true,
      'p-ripple-disabled': true,
    },
    'layout-sidebar-darkgray'
  );

  return (
    <div className={containerClassName} data-theme='light' onClick={onDocumentClick}>
      <Toast ref={toast}></Toast>
      <Toast ref={toastUndo} onClick={handleOnClick}></Toast>
      <div className='layout-content-wrapper'>
        <div>
          <Switch>
            <Route exact path='/'>
              {userRoles.some((role) => role === UserRole.Staff) ? (
                <Redirect to='/therapy-programs' />
              ) : userRoles.some((role) => role === UserRole.Therapist) ? (
                <Redirect to='/messaging' />
              ) : (
                "You don't have permissions"
              )}
            </Route>

            {routers.map((router, index) => {
              return (
                <Route
                  key={`router${index}`}
                  path={router.path}
                  exact={!!router.exact}
                  render={(props) =>
                    !router.roles || router.roles.some((r) => userRoles.some((r2) => r2 === r)) ? (
                      <router.component {...props} title={router.title ? router.title : ''} />
                    ) : (
                      <Redirect to='/' />
                    )
                  }
                />
              );
            })}

            <Route>
              <Redirect to='/notfound' />
            </Route>
          </Switch>
        </div>
      </div>

      <AppMenu
        model={menu}
        menuMode='static'
        active={menuActive}
        mobileMenuActive={false}
        onMenuClick={onMenuClick}
        onMenuItemClick={() => {}}
        onRootMenuItemClick={onRootMenuItemClick}
      ></AppMenu>
      <div className='layout-mask modal-in'></div>
    </div>
  );
};

export default App;
