import {flatMapCategories} from 'api/category.functions';
import {getCategoryTree} from 'api/chatowl.api';
import {CardContent, CardImage, CardTitle, MediaType} from 'components/media-card/style';
import {CardThumbnail} from 'components/media-chooser/styles';
import CategoryCard from 'components/tools/category-card';
import {useAppDispatch, useAppSelector} from 'hooks';
import {RootState} from 'index';
import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {shallowEqual} from 'react-redux';
import {Link} from 'react-router-dom';
import {filterCategoriesBySearchValue, filterCategoriesByTags} from 'store/action-creators';
import {sendMessage} from 'store/action-creators';
import styled from 'styled-components';
import GeneralSidebar from '../../../components/general-sidebar';
import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import SearchBar from '../../../components/search-bar';
import GeneralFilters from '../../../components/tools/filters';
import {AddButton as AddCategoryButton, ListControls} from '../../../components/tools/main-page-components';
import CategoryDetails from './category-detail';
//import ImageDetails from './image-detail';
const EmptyCard = () => {
  return (
    <div className={'p-col-fixed'} style={{visibility: 'hidden'}}>
      <CardContent className='box p-shadow-6' onClick={() => {}} isSelected={false}>
        <CardImage>
          <CardThumbnail src='' />
          <MediaType></MediaType>
        </CardImage>
        <CardTitle>Hola</CardTitle>
      </CardContent>
    </div>
  );
};

const ImageNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;
  :after {
    content: 'No images';
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1px;
`;

export const ToolCategories: React.FC<{title?: string}> = ({title}) => {
  const dispatch = useAppDispatch();

  const categoryTags: ToolTag[] = ['Active', 'Archived'];
  const selectedTags: ToolTag[] = useAppSelector((state: RootState) => state.tools.categories.filters.tags, shallowEqual);
  const searchValue: string = useAppSelector((state: RootState) => state.tools.categories.filters.search, shallowEqual);

  const {
    data: categoryTreeRaw,
    isLoading: loadingCategories,
    error: errorLoadingCategories,
    refetch: reloadCategories,
  } = useQuery('category/api', () => getCategoryTree(), {
    onSuccess: () => {},
    onError: (error: Error) =>
      dispatch(
        sendMessage({
          severity: 'error',
          summary: 'There was an error while loading the categories',
          detail: error?.message || '',
        })
      ),
  });

  /** Category Tree Raw without archived nodes (if filter 'Archived' is not applied) */
  const categoryTree: CategoryToolTreeNode[] = useMemo(() => {
    const removeArchived = (categoryTree: CategoryToolTreeNode[]): CategoryToolTreeNode[] => {
      const newTree = categoryTree.map((c: CategoryToolTreeNode) => {
        const category = {...c};
        if (category.isArchived) {
          return null;
        }
        if (category.subcategories) {
          category.subcategories = removeArchived(category.subcategories);
        }
        return category;
      });
      return newTree.filter((v) => v !== null) as CategoryToolTreeNode[];
    };
    if (!categoryTreeRaw) {
      return [];
    }
    if (!selectedTags.includes('Archived') && selectedTags.includes('Active')) {
      return removeArchived(categoryTreeRaw);
    }
    return categoryTreeRaw;
  }, [categoryTreeRaw, selectedTags]);

  const [selectedCategory, setSelectedCategory] = useState(null as CategoryToolTreeNode | null);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    if (
      (selectedCategory?.isArchived && !selectedTags.includes('Archived')) ||
      (!selectedCategory?.isArchived && !selectedTags.includes('Active'))
    ) {
      setSelectedCategory(null);
    }
  }, [selectedTags]);

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(filterCategoriesBySearchValue(e.target.value));
  };

  const onClick = (): void => {
    setSelectedCategory(null);
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as ToolTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(filterCategoriesByTags(newTags));
  };

  const [filterFn, treeView] = React.useMemo(() => {
    let treeView = selectedTags?.indexOf('Archived') < 0;
    const filters: ((c: CategoryToolTreeNode) => boolean)[] = [
      (v) =>
        !selectedTags.length ||
        selectedTags.some((filterName) => {
          if (filterName === 'Active') return !v.isArchived;
          else if (filterName === 'Archived') return v.isArchived;
          else {
            return true;
          }
        }),
    ];

    const lowerCaseSearchValue = searchValue.toLowerCase().trim();
    if (lowerCaseSearchValue) {
      filters.push((v) =>
        [v.description, v.id.toString(), v.name].some((text) => text?.toLowerCase().indexOf(lowerCaseSearchValue) >= 0)
      );

      treeView = false;
    }
    return [(v: CategoryToolTreeNode) => filters.every((fn) => fn(v)), treeView];
  }, [selectedTags?.length, searchValue]);

  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader>
          <PageTitle>Categories</PageTitle>
          <AddCategoryButton>
            <Link to='/tools/categories/add'>+ Add category</Link>
          </AddCategoryButton>
        </PageHeader>
        <ListControls>
          <GeneralFilters tags={categoryTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter}></GeneralFilters>
          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
            <AddCategoryButton>
              <Link to='/tools/categories/sort'>Sort top categories</Link>
            </AddCategoryButton>
            <SearchBar value={searchValue} onChange={onSearchValueChange} />
          </div>
        </ListControls>

        {flatMapCategories(
          categoryTree,
          (node, level, parent, distanceToParent) => (
            <CategoryCard
              category={node}
              onClick={() => setSelectedCategory(node)}
              loading={loadingCategories}
              level={treeView ? level : 0}
              distanceToParent={distanceToParent}
            />
          ),
          filterFn
        )}
      </div>

      <GeneralSidebar isActive={!!selectedCategory} onClick={onClick}>
        <CategoryDetails category={selectedCategory} />
      </GeneralSidebar>
    </PageContainer>
  );
};
