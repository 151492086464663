/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import {createCategory, getCategory, getCategoryTree, updateCategory, updateImage, updateVideo} from 'api/chatowl.api';
import {FormElement, StyledFormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {
  ArchiveButton,
  CancelButton,
  CursorLoading,
  Form,
  Label,
  PageBody,
  PageContainer as GlobalPageContaner,
  PageFooter,
  PageHeader,
  PageTitle,
  SaveButton,
} from 'components/page';
import SpinnerLoading from 'components/spinner-loading';
import CategoryEditCard from 'components/tools/category-edit-child-card';
import CategorySelector from 'components/tools/category-selector';
import {useAppDispatch} from 'hooks';
import {StyleAddAudioFiles as StyledFormElementAux} from 'pages/media-bank/containers/audio/styles';
import {InputSwitch} from 'primereact/inputswitch';
import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Controller, useForm} from 'react-hook-form';
import {useMutation, useQuery} from 'react-query';
import {Link, useHistory, useParams} from 'react-router-dom';
import {sendMessage} from 'store/action-creators';
import styled from 'styled-components';
import * as yup from 'yup';
const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const CheckboxCaption = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777c7c;
  flex-basis: 100%;
  margin-top: 7.5px;
`;

type CheckboxEvent = {
  originalEvent: SyntheticEvent<Element, Event>;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

function validateSave(name: string, description: string, mediaId: number | undefined, categories: number[]): boolean {
  return !!name && !!description && mediaId !== 0 && !!categories?.length;
}

type MediaDetailsFormType = 'add' | 'edit';
const CategoryFormValidator = yup.object({
  name: yup.string().required(),
  description: yup.string().optional().max(2000),
  hideItems: yup.boolean().required(),
  orderItems: yup.boolean().required(),
  parentCategoryId: yup.number(),
  videoId: yup.number(),
  imageId: yup.number(),
});
type CategoryForm = yup.InferType<typeof CategoryFormValidator>;
export const AddCategory: React.FC<{title?: string}> = ({title}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const params = useParams<{formType: MediaDetailsFormType; categoryId: string}>();
  const formType = params.formType;
  const categoryId = +params.categoryId || null;
  const [imgSettings, setImgSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});
  const [videoSettings, setVideoSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});
  const [newCropsImg, setNewCropsImg] = useState<Crop[]>([]);
  const [newCropsVideo, setNewCropsVideo] = useState<Crop[]>([]);

  const addCropVideo = (newCrop: Crop) => {
    const addedCrops = newCropsVideo.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCropsVideo.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCropsVideo, newCrop]; // else push crop
    setNewCropsVideo(addedCrops);
  };
  const addCropImg = (newCrop: Crop) => {
    const addedCrops = newCropsImg.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCropsImg.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCropsImg, newCrop]; // else push crop
    setNewCropsImg(addedCrops);
  };

  const {data: categoryInServer, isLoading: loading, refetch: reloadCategoryInServer} = useQuery(['categories', categoryId], {
    queryFn: () => getCategory(categoryId!),
    enabled: !!categoryId,
  });

  const {mutateAsync: saveNewCategoryApi, isLoading: savingNewCategory} = useMutation((req: CreateCategoryRequest) =>
    createCategory(req)
  );
  const {mutateAsync: updateCategoryApi, isLoading: updatingNewCategory} = useMutation((req: UpdateCategoryRequest) =>
    updateCategory(categoryId!, req)
  );
  const {mutateAsync: archiveCategoryApi, isLoading: archiving} = useMutation((isArchived: boolean) =>
    updateCategory(categoryId!, {isArchived})
  );
  const {mutateAsync: updateVideoCrops, isLoading: updatingVideoCrop} = useMutation((r: [number, UpdateMediaVideoRequest]) =>
    updateVideo(r[0], r[1])
  );
  const {mutateAsync: updateImgCrops, isLoading: updatingImgCrop} = useMutation((r: [number, UpdateMediaImageRequest]) =>
    updateImage(r[0], r[1])
  );
  const saving = savingNewCategory || updatingNewCategory || updatingVideoCrop || updatingImgCrop || archiving;

  const {handleSubmit, control, setValue, watch} = useForm<CategoryForm>({
    resolver: yupResolver(CategoryFormValidator),
    defaultValues: {
      name: '',
      description: '',
      hideItems: false,
      orderItems: false,
    },
  });
  const showSort = watch('orderItems');
  const actualName = watch('name');

  useEffect(() => {
    if (categoryInServer) {
      setValue('name', categoryInServer.name);
      setValue('description', categoryInServer.description);
      if (categoryInServer.parentCategoryId) {
        setValue('parentCategoryId', categoryInServer.parentCategoryId);
      }
      setValue('orderItems', categoryInServer.orderItems);
      setValue('hideItems', categoryInServer.hideItems);
      setValue('imageId', categoryInServer.imageMedia?.id);
      setValue('videoId', categoryInServer.videoMedia?.id);
      //set;
    }
  }, [categoryInServer]);
  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);
  const [customOrder, setCustomOrder] = useState(null as null | number[]);
  const sortedChildren = useMemo(() => {
    const merged = [
      ...(categoryInServer?.exercises?.map((v) => ({...v, type: 'exercise'})) ?? []),
      ...(categoryInServer?.subcategories?.map((v) => ({...v, type: 'category'})) ?? []),
    ];
    if (customOrder) {
      merged.sort((a, b) => customOrder.indexOf(a.id) - customOrder.indexOf(b.id));
    } else {
      merged.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
    }

    return merged;
  }, [categoryInServer, customOrder]);

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const startIndex: number = result.source.index;
    const endIndex: number = result.destination.index;
    setCustomOrder((oldOrderState) => {
      const oldOrder = oldOrderState ?? sortedChildren.map((v) => v.id);
      let newOrder = null;
      if (oldOrder) {
        newOrder = [];

        let diff = 0;
        for (let i = 0; i < oldOrder.length; i++) {
          if (i + diff === startIndex) {
            diff++;
          }
          if (i === endIndex) {
            diff--;
            newOrder[i] = oldOrder[startIndex];
          } else {
            newOrder[i] = oldOrder[i + diff];
          }
        }
      }
      return newOrder;
    });
  };
  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const editing: boolean = formType === 'edit' && !!categoryInServer;

  // do not include category itself in the list of candidates for parent category
  // i.e: remove recursively from categoryTree any category:CategoryToolTreeNode or category.subcategory:CategoryToolTreeNode with the same id of categoryInServer.id
  const removeCategoryFromTree = (categoryTree: CategoryToolTreeNode[]): CategoryToolTreeNode[] => {
    const newTree = categoryTree.map((category: CategoryToolTreeNode) => {
      if (category.id === categoryInServer?.id) {
        return null;
      }
      if (category.subcategories) {
        category.subcategories = removeCategoryFromTree(category.subcategories);
      }
      return category;
    });
    return newTree.filter((v) => v !== null) as CategoryToolTreeNode[];
  };

  const categoryTreeWithoutSelf = useMemo(() => {
    if (categoryTree) {
      return removeCategoryFromTree(categoryTree);
    }
    return [];
  }, [categoryTree]);

  const onCancel = () => {
    history.push('/tools/categories');
  };

  const toastError = (msg: string) => {
    dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: msg}));
  };
  const toastSuccess = (msg: string, summary: string) => {
    dispatch(sendMessage({severity: 'success', summary, detail: msg}));
  };
  const toastWarning = (msg: string, summary: string = 'WARNING') => {
    dispatch(sendMessage({severity: 'warn', summary, detail: msg}));
  };
  const redirectCategoriesHome = () => history.push('/tools/categories');

  const formToCreateRequest: (f: CategoryForm) => CreateCategoryRequest = (formState) => ({
    ...formState,
  });
  const formToUpdateRequest: (f: CategoryForm) => UpdateCategoryRequest = (formState) => ({
    ...formState,
    parentCategoryId: formState.parentCategoryId === -1 ? null : formState.parentCategoryId,
    itemsOrder: showSort
      ? sortedChildren.map((v) => ('name' in v ? {id: v.id, type: 'subcategory'} : {id: v.id, type: 'exercise'}))
      : undefined,
  });
  const [userClickedArchive, setUserClickedArchive] = useState<boolean>(false);

  const onArchive = async () => {
    if (!userClickedArchive) setUserClickedArchive(true);
    else {
      try {
        await archiveCategoryApi(!categoryInServer?.isArchived);
        setUserClickedArchive(false);
        reloadCategoryInServer();
      } catch (ex) {
        toastError('Something went wrong');
        setUserClickedArchive(false);
      }
    }
  };

  const onSave = handleSubmit(
    async (formData) => {
      try {
        if (newCropsVideo.length > 0 && formData.videoId) {
          const fullWidthTall = newCropsVideo!.find((crop) => crop.type === 'full_width_tall');
          const fullWidthRegular = newCropsVideo!.find((crop) => crop.type === 'full_width_regular');
          const crops: UpdateMediaVideoRequest = {
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            crops: newCropsVideo.map((crop) => ({type: crop.type, data: crop.data})),
            settings: videoSettings,
          };
          await updateVideoCrops([formData.videoId!, crops]);
        }
      } catch (error) {
        toastError('Something went wrong while editing Media.');
        throw error;
      }
      try {
        if (newCropsImg.length > 0 && formData.imageId) {
          const fullWidthTall = newCropsImg!.find((crop) => crop.type === 'full_width_tall');
          const fullWidthRegular = newCropsImg!.find((crop) => crop.type === 'full_width_regular');
          const fullScreenLandscape = newCropsImg!.find((crop) => crop.type === 'full_screen_landscape');
          const fullScreenPortrait = newCropsImg!.find((crop) => crop.type === 'full_screen_portrait');
          const squared = newCropsImg!.find((crop) => crop.type === 'squared');

          const crops: UpdateMediaImageRequest = {
            fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
            squared: squared ? squared.blob : undefined,
            settings: imgSettings,
          };
          await updateImgCrops([formData.imageId, crops]);
        }
      } catch (error) {
        toastError('Something went wrong while editing Media.');
        throw error;
      }
      try {
        if (editing) {
          const updateRequest = formToUpdateRequest(formData);

          const updatedCategory = await updateCategoryApi(updateRequest);
          toastSuccess(updatedCategory.name, 'Category updated successfully');
        } else {
          const createRequest = formToCreateRequest(formData);
          const createdCategory = await saveNewCategoryApi(createRequest);
          toastSuccess(createdCategory.name, 'Category created successfully');
        }
        redirectCategoriesHome();
      } catch (error) {
        toastError('Something went wrong');
      }
    },
    (errors) => {
      const message = Object.entries(errors || {})
        .map(
          ([fieldName, description]) =>
            `${fieldName[0].toUpperCase()}${fieldName.substring(1)} error: ${description?.message || 'No error message'}`
        )
        .join('\n');
      toastWarning(message);
    }
  );

  return (
    <CursorLoading disable={saving}>
      {loading ? (
        <SpinnerLoading show />
      ) : (
        <PageContainer>
          <PageHeader>{editing ? <PageTitle>Edit Category</PageTitle> : <PageTitle>Add Category</PageTitle>}</PageHeader>
          <PageBody>
            <Form>
              <Controller
                name='name'
                control={control}
                render={({field}) => <FormElement type='input' label='Name' placeholder='Category name' {...field} />}
              />

              <Controller
                name='description'
                control={control}
                render={({field}) => (
                  <FormElement type='text-area' label='Description' placeholder='Category description' {...field} />
                )}
              />
              <Controller
                name='parentCategoryId'
                control={control}
                render={({field}) => (
                  <StyledFormElement>
                    <Label>Parent Category</Label>
                    <CategorySelector
                      singleSelection
                      optional
                      categories={categoryTreeWithoutSelf}
                      selected={field.value ? [field.value] : []}
                      loading={loadingCategories}
                      onChange={(e) => {
                        field.onChange(e?.[0]);
                      }}
                    />
                  </StyledFormElement>
                )}
              />
              <Controller
                name='hideItems'
                control={control}
                render={({field}) => (
                  <StyledFormElement style={{flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <Label>Show as card</Label>
                    <InputSwitch {...field} checked={field.value} />
                    <CheckboxCaption>
                      This will hide the horizontal list of child cards and display the category as a card instead.
                    </CheckboxCaption>
                  </StyledFormElement>
                )}
              />

              {formType === 'edit' && (
                <>
                  <Controller
                    name='orderItems'
                    control={control}
                    render={({field}) => (
                      <StyledFormElement style={{flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        <Label>Sort Children</Label>
                        <InputSwitch {...field} checked={field.value} />
                        <CheckboxCaption>Enable custom sorting</CheckboxCaption>
                      </StyledFormElement>
                    )}
                  />

                  {showSort && (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} style={{width: '85%'}}>
                            {sortedChildren
                              .filter((c) => !c.isArchived)
                              .map((node, index) => (
                                <Draggable isDragDisabled={false} key={node.id} draggableId={`${node.id}`} index={index}>
                                  {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <CategoryEditCard
                                        item={node}
                                        type={node.type as 'image' | 'exercise' | 'quote' | 'category'}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </>
              )}
            </Form>
            <StyledFormElementAux>
              <MediaChooser
                onChooseMedia={(id) => setValue('imageId', id)}
                media={categoryInServer?.imageMedia}
                mediaType={'image'}
                addCrop={addCropImg}
                actualName={actualName}
                key={'Image'}
                changeSettings={setImgSettings}
              />

              <MediaChooser
                onChooseMedia={(id) => setValue('videoId', id)}
                media={categoryInServer?.videoMedia}
                mediaType={'video'}
                addCrop={addCropVideo}
                actualName={actualName}
                key={'Video'}
                changeSettings={setVideoSettings}
              />
            </StyledFormElementAux>
          </PageBody>
          <PageFooter>
            {editing &&
              (!categoryInServer!.isArchived ? (
                <ArchiveButton onClick={onArchive} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Archive ?' : 'Archive'}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={onArchive} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Unarchive ?' : 'Unarchive'}
                </ArchiveButton>
              ))}

            <CancelButton onClick={redirectCategoriesHome}>
              <Link to='/tools/categories/'>Cancel</Link>
            </CancelButton>
            <SaveButton disabled={saving} onClick={onSave} disable={saving}>
              {saving ? 'Saving...' : 'Save'}
            </SaveButton>
          </PageFooter>
        </PageContainer>
      )}
    </CursorLoading>
  );
};
