import styled from 'styled-components';

type GeneralCardContainerType = {
  isSelected: boolean;
};

export const GeneralCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-color: ${(props: GeneralCardContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
  margin-top: 8px;
  margin-bottom: 8px;
  max-height: 120px;
  min-width: 0;
  padding: 16px;
  transition: 0.1s;
  user-select: none;

  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const GeneralCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  width: 100%;
`;

export const GeneralCardHeader = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
`;

export const GeneralTitle = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  margin-left: 8px;
  margin-right: 16px;
  max-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const GeneralDescription = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  height: 21px;
  margin: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const GeneralCategory = styled.span`
  font-weight: bold;
  margin: 0px 8px;
  min-height: 23px;
  max-height: 23px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type GeneralImageProps = {
  isSidebarOpen: boolean;
};

export const GeneralCategoryAuthor = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const GeneralImage = styled.img`
  align-self: center;
  border-radius: 4px;
  height: 88px;
  min-width: ${(props: GeneralImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  max-width: ${(props: GeneralImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  object-fit: cover;
  margin-left: 30px;
`;
