import React from 'react';
import styled from 'styled-components';

export const CategoriesUl: React.FC<{categories?: CategoryDto[]}> = ({categories}) => {
  if (!categories) {
    return null;
  }
  return (
    <StyledUl>
      {categories.map((c) => (
        <li>{c.name}</li>
      ))}
    </StyledUl>
  );
};

const StyledUl = styled.ul`
  padding-left: inherit;
  margin: 8px auto;
  line-height: 1.3em;
  list-style: none;
`;
