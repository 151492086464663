import {useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import {GeneralCardContainer, GeneralCardHeader, GeneralCardInfo, GeneralDescription, GeneralImage, GeneralTitle} from './styles';

interface GeneralCardProps {
  onClick: (categoryId?: number) => void; //If already selected unselect, else, select.
  category: CategoryToolTreeNode;
  selectedId?: number;
  loading?: boolean;
  level: number;
  distanceToParent: number;
  justTitle?: boolean;
}

export const CategoryCard: React.FC<GeneralCardProps> = ({
  onClick, //If already selected unselect, else, select.
  category,
  loading,
  selectedId,
  level,
  distanceToParent,
  justTitle,
}) => {
  // var { id: toolID, title: toolTitle, description: toolDescription, toolCategory, media, program } = tool;
  const isSelected = selectedId === category?.id;
  const generalImageUrl = category.imageUrl;

  useEffect(() => {
    if (isSelected) {
      const element = document.getElementById(`${category?.id}_category`);
      element?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [isSelected]);

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {!!level && (
        <div style={{alignSelf: 'stretch'}}>
          <div
            style={{
              height: `calc(${(distanceToParent - 1) * 100 + 50}% + 8px)`,
              position: 'relative',
              transform: `translateY(-8px) translateY(-${136 * (distanceToParent - 1)}px)`,
              flex: `0 0  25px`,
              width: 25,
              border: '1px solid black',
              marginLeft: 25 * level + Math.max(0, level - 1) * 25,
              borderTop: 0,
              borderRight: 0,
            }}
            data-distance-to-parent={distanceToParent}
          ></div>
        </div>
      )}
      <GeneralCardContainer
        key={category?.id}
        onClick={() => onClick(category?.id)}
        isSelected={isSelected}
        placeholder={'Top Level Container'}
        id={category?.id + '_category'}
        level={level}
        justTitle={justTitle}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <GeneralCardInfo justTitle={justTitle}>
              <GeneralCardHeader>
                <GeneralTitle title={category?.name}>{category?.name}</GeneralTitle>
              </GeneralCardHeader>
              {!justTitle && <GeneralDescription title={category?.description}>{category?.description}</GeneralDescription>}
            </GeneralCardInfo>
            <>
              {generalImageUrl && !justTitle && (
                <GeneralImage isSidebarOpen={!!selectedId} src={generalImageUrl} alt={category?.name} />
              )}
            </>
          </>
        )}
      </GeneralCardContainer>
    </div>
  );
};
export default CategoryCard;
