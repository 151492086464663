import React, {ChangeEvent, createRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ImageCropper} from 'components/image-cropper';
import {Button} from 'primereact/button';
import {createFile, getCanvasBlob, isCanvasData} from 'utils/helpers';
import {ButtonLink} from '../../components/page';
import {MediaSettings} from 'components/media-settings';
import {useAppDispatch} from 'hooks';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import {PDFieldLabel} from '../../pages/media-bank/media-list/styles';

type SelectImageBoxProps = {
  isVisible: boolean;
  image: boolean;
};

const SelectImageBox = styled.div`
  align-items: center;
  border: 2px dashed #bcc1c7;
  box-sizing: border-box;
  border-radius: 8px;
  display: ${(props: SelectImageBoxProps) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: ${(props: SelectImageBoxProps) => (props.image ? '60vh' : '30vh')};

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: #777c7c;
  }

  :hover:not(:focus-within) {
    background-color: #fafafa;
    cursor: pointer;
    transition: 0.1s;
  }
`;

const CropContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Crop = styled.img`
  margin: 2%;
  top: 0px;
`;

const getAspectRatioCropParams = (originalWidth: number, originalHeight: number, transformedRatio: number, fill = false) => {
  let sx = 0;
  let sy = 0;
  let sWidth = originalWidth;
  let sHeight = originalHeight;
  let dx = 0;
  let dy = 0;
  let dWidth = originalWidth;
  let dHeight = originalHeight;
  let canvasWidth = originalWidth;
  let canvasHeight = originalHeight;

  const originalRatio = originalWidth / originalHeight;

  if (originalRatio > transformedRatio) {
    if (fill) {
      canvasHeight = originalWidth / transformedRatio;
      dy = Math.abs(canvasHeight - originalHeight) / 2;
    } else {
      sWidth = originalWidth * transformedRatio;
      canvasWidth = sWidth;
      dWidth = sWidth;
      sx = Math.abs(originalWidth - sWidth) / 2;
    }
  }

  if (originalRatio < transformedRatio) {
    if (fill) {
      canvasWidth = originalHeight * transformedRatio;
      dx = Math.abs(canvasWidth - originalWidth) / 2;
    } else {
      sHeight = originalWidth / transformedRatio;
      canvasHeight = sHeight;
      dHeight = sHeight;
      sy = Math.abs(originalHeight - sHeight) / 2;
    }
  }

  return {
    sx,
    sy,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight,
    canvasWidth,
    canvasHeight,
  };
};

type ImageCropperManagerProps = {
  mediaType: MediaType;
  setOriginalImage?: (image: Blob) => void;
  addCrop?: (crop: Crop) => void;
  imagesCrop?: CropDto[];
  image?: string;
  imageForSettings?: string;
  onUpdateImage?: () => void;
  imagesTypes?: CropType[];
  weeks?: boolean;
  label?: string;
  settings: ImageSettings | undefined;
  setSettings: (settings: ImageSettings) => void;
  originalImage?: Blob;
};

export const ImageCropperManager: React.FC<ImageCropperManagerProps> = ({
  weeks,
  mediaType,
  setOriginalImage,
  addCrop,
  imagesCrop,
  image,
  imageForSettings,
  children,
  onUpdateImage,
  imagesTypes,
  label,
  settings,
  setSettings,
  originalImage,
}) => {
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
  const fileSelectorRef = createRef<HTMLInputElement>();

  const [squaredImg, setSquaredImg] = useState('');
  const [fullWidthImg, setFullWidthImg] = useState('');
  const [landScapeImg, setLandScapeImg] = useState('');
  const [portraitImg, setPortraitImg] = useState('');
  const [tallImg, setTallImg] = useState('');

  const [squaredBlob, setSquaredBlob] = useState<Blob>();
  const [fullWidthBlob, setFullWidthBlob] = useState<Blob>();
  const [landScapeBlob, setLandScapeBlob] = useState<Blob>();
  const [portraitBlob, setPortraitBlob] = useState<Blob>();
  const [tallBlob, setTallBlob] = useState<Blob>();

  const [squaredCrop, setSquaredCrop] = useState<CanvasData>();
  const [fullWidthCrop, setFullWidthCrop] = useState<CanvasData>();
  const [landScapeCrop, setLandScapeCrop] = useState<CanvasData>();
  const [portraitCrop, setPortraitCrop] = useState<CanvasData>();
  const [tallCrop, setTallCrop] = useState<CanvasData>();

  const [editingCropType, setEditingCropType] = useState<CropType>();
  const [aspectRatioSelected, setAspectRatioSelected] = useState<number>();
  const [minHeigthSelected, setMinHeigthSelected] = useState<number>();
  const [minWidthSelected, setMinWidthSelected] = useState<number>();
  const [cropDataSelected, setCropDataSelected] = useState<CanvasData>();

  const [originalImageSettings, setOriginalImageSettings] = useState('');
  const [originalImageSettingsBlob, setOriginalImageSettingsBlob] = useState<any | undefined>();

  const [isLoading, setIsLoading] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (originalImage) {
      const blob = originalImage;
      const image = URL.createObjectURL(blob);
      let img = new Image();
      img.src = image;

      img.onload = async function () {
        setOriginalImage && setOriginalImage(blob);
        setOriginalImageUrl(image);
        setOriginalImageSettings(image);
        setOriginalImageSettingsBlob(blob);
        createCanvas(image);
      };
    }
  }, []);

  useEffect(() => {
    const fetchBlobImage = async () => {
      if (image) {
        const imageBlob = await fetch(image).then((r) => r.blob());
        setOriginalImageUrl(URL.createObjectURL(imageBlob));
      }
      if (imageForSettings) {
        const settingBlob = await fetch(imageForSettings).then((r) => r.blob());
        setOriginalImageSettings(URL.createObjectURL(settingBlob));
        setOriginalImageSettingsBlob(settingBlob);
      }
    };

    if (imagesCrop && image) {
      fetchBlobImage();

      imageForSettings && setOriginalImageSettings(imageForSettings);
      setOriginalImageUrl(image);
      if (mediaType !== 'video' && mediaType !== 'audio') {
        const squared = imagesCrop.find((x) => x.type === 'squared')?.url;
        const squaredCrop = imagesCrop.find((x) => x.type === 'squared')?.data;
        setSquaredImg(squared ? squared : '');
        setSquaredCrop(squaredCrop);
      }

      const fullWidth = imagesCrop.find((x) => x.type === 'full_width_regular')?.url;
      const fullWidthCrop = imagesCrop.find((x) => x.type === 'full_width_regular')?.data;
      setFullWidthImg(fullWidth ? fullWidth : '');
      setFullWidthCrop(fullWidthCrop);

      if (mediaType !== 'video') {
        const landScape = imagesCrop.find((x) => x.type === 'full_screen_landscape')?.url;
        const landScapeCrop = imagesCrop.find((x) => x.type === 'full_screen_landscape')?.data;
        setLandScapeImg(landScape ? landScape : '');
        setLandScapeCrop(landScapeCrop);
      }

      if (mediaType !== 'video') {
        const portrait = imagesCrop.find((x) => x.type === 'full_screen_portrait')?.url;
        const portraitCrop = imagesCrop.find((x) => x.type === 'full_screen_portrait')?.data;
        setPortraitImg(portrait ? portrait : '');
        setPortraitCrop(portraitCrop);
      }
      const tall = imagesCrop.find((x) => x.type === 'full_width_tall')?.url;
      const tallCrop = imagesCrop.find((x) => x.type === 'full_width_tall')?.data;
      setTallImg(tall ? tall : '');
      setTallCrop(tallCrop);
    }
  }, [imagesCrop, image]);

  useEffect(() => {
    squaredBlob && addCrop && addCrop({type: 'squared', blob: squaredBlob, data: squaredCrop});
  }, [squaredBlob, squaredCrop]);

  useEffect(() => {
    fullWidthBlob && addCrop && addCrop({type: 'full_width_regular', blob: fullWidthBlob, data: fullWidthCrop});
  }, [fullWidthBlob, fullWidthCrop]);

  useEffect(() => {
    landScapeBlob && addCrop && addCrop({type: 'full_screen_landscape', blob: landScapeBlob, data: landScapeCrop});
  }, [landScapeBlob, landScapeCrop]);

  useEffect(() => {
    portraitBlob && addCrop && addCrop({type: 'full_screen_portrait', blob: portraitBlob, data: portraitCrop});
  }, [portraitBlob, portraitCrop]);

  useEffect(() => {
    tallBlob && addCrop && addCrop({type: 'full_width_tall', blob: tallBlob, data: tallCrop});
  }, [tallBlob, tallCrop]);

  const onChooseImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const blob = event.target.files[0];
      const image = URL.createObjectURL(blob);
      let img = new Image();
      img.src = image;

      img.onload = async function () {
        if (img.width >= 2500 && img.height >= 2500) {
          setOriginalImage && setOriginalImage(blob);
          setOriginalImageUrl(image);
          setOriginalImageSettings(image);
          setOriginalImageSettingsBlob(blob);
          createCanvas(image);
        } else {
          dispatch(
            sendMessage({severity: 'warn', summary: 'WARNING', detail: 'The image has to have at least a size of 2500x2500'})
          );
        }
      };
    }
    if (fileSelectorRef?.current?.value) fileSelectorRef.current.value = '';
  };

  const createCanvas = (image: string) => {
    createSquared(image);
    createFullWidth(image);
    createLandscape(image);
    createPortrait(image);
    createTall(image);
  };

  const createSquared = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('squared');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async function () {
      const size = Math.min(img.width, img.height);

      const max = Math.max(img.width, img.height);

      const since = (max - size) / 2;

      if (squaredCrop && isCanvasData(squaredCrop)) {
        ctx.drawImage(img, squaredCrop.left, squaredCrop.top, squaredCrop.width, squaredCrop.height, 0, 0, 600, 600);
      } else {
        if (max === img.width) {
          const canvasData: CanvasData = {
            height: size,
            width: size,
            top: 0,
            left: since,
          };
          ctx.drawImage(img, since, 0, size, size, 0, 0, 600, 600);
          setSquaredCrop(canvasData);
        } else {
          const canvasData: CanvasData = {
            height: size,
            width: size,
            top: since,
            left: 0,
          };
          ctx.drawImage(img, 0, since, size, size, 0, 0, 600, 600);
          setSquaredCrop(canvasData);
        }
      }

      setSquaredImg(canvas?.toDataURL('image/jpeg'));

      if (mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setSquaredBlob(blob);
      }
    };
    img.src = image;
  };

  const createFullWidth = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('fullWidth');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async function () {
      // 25 : 8 proportion
      const size = img.width * (8 / 25);
      const since = (img.height - size) / 2;

      if (fullWidthCrop && isCanvasData(fullWidthCrop))
        ctx.drawImage(img, fullWidthCrop.left, fullWidthCrop.top, fullWidthCrop.width, fullWidthCrop.height, 0, 0, 400, 128);
      else {
        const canvasData: CanvasData = {
          height: size,
          width: img.width,
          top: since,
          left: 0,
        };
        ctx.drawImage(img, 0, since, img.width, size, 0, 0, 400, 128);
        setFullWidthCrop(canvasData);
      }

      setFullWidthImg(canvas?.toDataURL('image/jpeg'));
      const blob = await getCanvasBlob(canvas);
      setFullWidthBlob(blob);
    };
    img.src = image;
  };

  const createLandscape = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('landScape');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async () => {
      // min: 2500 x proportion

      const widthSize = 2500;
      const heightSize = widthSize * (9 / 16);

      const height = img.width * (9 / 16);

      canvas.width = widthSize;
      canvas.height = heightSize;

      const since = (img.height - height) / 2;

      if (landScapeCrop && isCanvasData(landScapeCrop))
        ctx.drawImage(
          img,
          landScapeCrop.left,
          landScapeCrop.top,
          landScapeCrop.width,
          landScapeCrop.height,
          0,
          0,
          widthSize,
          heightSize
        );
      else {
        const canvasData: CanvasData = {
          height: height,
          width: img.width,
          top: since,
          left: 0,
        };
        ctx.drawImage(img, 0, since, img.width, height, 0, 0, widthSize, heightSize);
        setLandScapeCrop(canvasData);
      }

      setLandScapeImg(canvas?.toDataURL('image/jpeg'));

      if (mediaType === 'audio' || mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setLandScapeBlob(blob);
      }
    };
    img.src = image;
  };

  const createPortrait = (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('portrait');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async () => {
      // min: 1000 x proportion

      const widthSize = 1000;
      const heightSize = widthSize * (16 / 9);

      const width = img.height * (9 / 16);

      canvas.width = widthSize;
      canvas.height = heightSize;

      const since = (img.width - width) / 2;

      if (portraitCrop && isCanvasData(portraitCrop))
        ctx.drawImage(
          img,
          portraitCrop.left,
          portraitCrop.top,
          portraitCrop.width,
          portraitCrop.height,
          0,
          0,
          widthSize,
          heightSize
        );
      else {
        const canvasData: CanvasData = {
          height: img.height,
          width: width,
          top: 0,
          left: since,
        };
        ctx.drawImage(img, since, 0, width, img.height, 0, 0, widthSize, heightSize);
        setPortraitCrop(canvasData);
      }

      setPortraitImg(canvas?.toDataURL('image/jpeg'));

      if (mediaType === 'audio' || mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setPortraitBlob(blob);
      }
    };
    img.src = image;
  };

  const createTall = (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('tall');
    var ctx = canvas && canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    img.onload = async () => {
      // 12 : 7 proportion

      // let size = img.width * (7 / 12);

      if (tallCrop && isCanvasData(tallCrop))
        ctx.drawImage(img, tallCrop.left, tallCrop.top, tallCrop.width, tallCrop.height, 0, 0, 900, 525);
      else {
        let height = img.width * (7 / 12);
        let width = img.width;
        let since = (img.height - height) / 2;

        if (height >= img.height) {
          height = img.height;
          width = img.height * (12 / 7);
          since = (img.width - width) / 2;

          var canvasData = {
            height: height,
            width: width,
            left: since,
            top: 0,
          };

          ctx.drawImage(img, since, 0, width, height, 0, 0, 900, 525);
        } else {
          var canvasData = {
            height: height,
            width: width,
            top: since,
            left: 0,
          };

          ctx.drawImage(img, 0, since, width, height, 0, 0, 900, 525);
        }
        setTallCrop(canvasData);
      }

      setTallImg(canvas?.toDataURL('image/jpeg'));
      const blob = await getCanvasBlob(canvas);
      setTallBlob(blob);
    };
    img.src = image;
  };

  const onCancelCrop = () => {
    setIsImageCropperOpen(false);
    setEditingCropType(undefined);
  };

  const onSaveCrop = (blob?: Blob, cropper?: any) => {
    if (blob) {
      const file = createFile(blob, 'crop.jpg');
      const imageSrcCropped = URL.createObjectURL(file);

      const canvasData: CanvasData = {
        height: cropper.height,
        width: cropper.width,
        top: cropper.y,
        left: cropper.x,
      };

      switch (editingCropType) {
        case 'full_screen_landscape':
          setLandScapeCrop(canvasData);
          setLandScapeImg(imageSrcCropped);
          setLandScapeBlob(file);
          break;
        case 'full_screen_portrait':
          setPortraitCrop(canvasData);
          setPortraitImg(imageSrcCropped);
          setPortraitBlob(file);
          break;
        case 'full_width_regular':
          setFullWidthCrop(canvasData);
          setFullWidthImg(imageSrcCropped);
          setFullWidthBlob(file);
          break;
        case 'full_width_tall':
          setTallCrop(canvasData);
          setTallImg(imageSrcCropped);
          setTallBlob(file);
          break;
        case 'squared':
          setSquaredCrop(canvasData);
          setSquaredImg(imageSrcCropped);
          setSquaredBlob(file);
          break;
        default:
          break;
      }
    }
    setEditingCropType(undefined);
    setIsImageCropperOpen(false);
  };

  const openFileSelector = () => {
    if (fileSelectorRef && fileSelectorRef.current) {
      fileSelectorRef.current.click();
    }
  };

  const onClickImage = (cropType: CropType) => {
    setEditingCropType(cropType);

    if (cropType === 'squared') {
      setAspectRatioSelected(1 / 1);
      setMinWidthSelected(600);
      setCropDataSelected(squaredCrop);
    } else if (cropType === 'full_width_regular') {
      setAspectRatioSelected(25 / 8);
      setMinWidthSelected(400);
      setCropDataSelected(fullWidthCrop);
    } else if (cropType === 'full_screen_landscape') {
      setAspectRatioSelected(16 / 9);
      setMinWidthSelected(2500);
      setCropDataSelected(landScapeCrop);
    } else if (cropType === 'full_screen_portrait') {
      setMinWidthSelected(1000);
      setAspectRatioSelected(9 / 16);
      setCropDataSelected(portraitCrop);
    } else if (cropType === 'full_width_tall') {
      setAspectRatioSelected(12 / 7);
      setMinWidthSelected(900);
      setCropDataSelected(tallCrop);
    }
    setIsImageCropperOpen(true);
  };

  const onSaveSettings = (brightnessValue: number, contrastValue: number, urlImage: string) => {
    setSettings({brightness: brightnessValue, contrast: contrastValue});
    setOriginalImageUrl(urlImage);
    createCanvas(urlImage);
  };

  useEffect(() => {
    if (weeks && isLoading > 0) dispatch(hideLoading());
    if (mediaType === 'audio' && isLoading > 3) dispatch(hideLoading());
    if (mediaType === 'image' && isLoading > 4) dispatch(hideLoading());
    if (mediaType === 'video' && isLoading > 1) dispatch(hideLoading());
    if (imagesTypes && imagesTypes.length <= isLoading) dispatch(hideLoading());
  }, [isLoading]);

  useEffect(() => {
    if (imagesCrop) dispatch(showLoading());
  }, []);

  return (
    <>
      {originalImageUrl === '' ? (
        <>
          {children ? (
            children
          ) : (
            <SelectImage
              mediaType={mediaType}
              openFileSelector={openFileSelector}
              fileSelectorRef={fileSelectorRef}
              onChooseImage={onChooseImage}
            />
          )}
        </>
      ) : (
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {label ? (
              <label style={{fontSize: '24px', fontWeight: 'bold', color: '#6C757D'}}>{label}</label>
            ) : (
              <label style={{fontSize: '24px'}}>{onUpdateImage ? 'Image' : 'Images formats'}</label>
            )}
            <MediaSettings
              mediaBlob={originalImageSettingsBlob}
              mediaUrl={originalImageSettings}
              onSaveSettings={onSaveSettings}
              settings={settings}
            />
            <ButtonLink onClick={onUpdateImage ? onUpdateImage : openFileSelector}>
              {onUpdateImage ? 'Change Media' : 'Upload New Image'}
            </ButtonLink>
          </div>
          <CropContainer>
            {weeks ? (
              <Crop onLoad={() => setIsLoading(isLoading + 1)} src={squaredImg} width='600' />
            ) : (
              <>
                {(!imagesTypes || imagesTypes.includes('full_width_tall')) && (
                  <div style={{display: 'grid'}}>
                    <PDFieldLabel>Full width tall</PDFieldLabel>
                    <Crop
                      onLoad={() => setIsLoading(isLoading + 1)}
                      src={tallImg}
                      width='406vh'
                      onClick={() => onClickImage('full_width_tall')}
                    />
                  </div>
                )}
                {(!imagesTypes || imagesTypes.includes('full_width_regular')) && (
                  <div style={{display: 'grid'}}>
                    <PDFieldLabel>Full width regular</PDFieldLabel>
                    <Crop
                      onLoad={() => setIsLoading(isLoading + 1)}
                      src={fullWidthImg}
                      height='130vh'
                      onClick={() => onClickImage('full_width_regular')}
                      alt={'Alt'}
                    />
                  </div>
                )}
                {(!imagesTypes || imagesTypes.includes('squared')) && mediaType !== 'video' && mediaType !== 'audio' && (
                  <div style={{display: 'grid'}}>
                    <PDFieldLabel>Squared</PDFieldLabel>
                    <Crop
                      onLoad={() => setIsLoading(isLoading + 1)}
                      src={squaredImg}
                      height='130vh'
                      onClick={() => onClickImage('squared')}
                    />
                  </div>
                )}
                {(!imagesTypes || imagesTypes.includes('full_screen_portrait')) && mediaType !== 'video' && (
                  <div style={{display: 'grid'}}>
                    <PDFieldLabel>Full Screen Portrait</PDFieldLabel>
                    <Crop
                      onLoad={() => setIsLoading(isLoading + 1)}
                      src={portraitImg}
                      height='406vh'
                      onClick={() => onClickImage('full_screen_portrait')}
                    />
                  </div>
                )}
                {(!imagesTypes || imagesTypes.includes('full_screen_landscape')) && mediaType !== 'video' && (
                  <div style={{display: 'grid'}}>
                    <PDFieldLabel>Full Screen Landscape</PDFieldLabel>
                    <Crop
                      onLoad={() => setIsLoading(isLoading + 1)}
                      src={landScapeImg}
                      width='406vh'
                      onClick={() => onClickImage('full_screen_landscape')}
                    />
                  </div>
                )}
              </>
            )}
          </CropContainer>
        </>
      )}
      <canvas id='squared' width='600px' height='600px' style={{display: 'none'}}></canvas>
      <canvas id='fullWidth' width='400px' height='128px' style={{display: 'none'}}></canvas>
      <canvas id='landScape' width='854px' height='480px' style={{display: 'none'}}></canvas>
      <canvas id='portrait' width='480px' height='854px' style={{display: 'none'}}></canvas>
      <canvas id='tall' width='900px' height='525px' style={{display: 'none'}}></canvas>
      <input ref={fileSelectorRef} type='file' accept='.jpg' onChange={onChooseImage} style={{visibility: 'hidden'}} />

      <ImageCropper
        imageSrc={originalImageUrl}
        isOpen={isImageCropperOpen}
        onCancelCrop={onCancelCrop}
        onSaveCrop={onSaveCrop}
        aspectRatio={aspectRatioSelected}
        minHeight={minHeigthSelected}
        minWidth={minWidthSelected}
        cropDataSelected={cropDataSelected}
      />
    </>
  );
};

type SelectImageProps = {
  mediaType: MediaType;
  openFileSelector: () => void;
  onChooseImage: (event: ChangeEvent<HTMLInputElement>) => void;
  fileSelectorRef: React.RefObject<HTMLInputElement>;
};

const SelectImage: React.FC<SelectImageProps> = ({mediaType, openFileSelector, onChooseImage, fileSelectorRef}) => {
  return (
    <>
      <label>Image</label>
      <SelectImageBox image={mediaType === 'image'} onClick={openFileSelector} isVisible={true}>
        <p style={{color: 'red'}}>*Required</p>
        <p>Select an image</p>
        <Button className='p-button-outlined p-mr-2 p-mb-2'> Select an image </Button>
        <input ref={fileSelectorRef} accept='.jpg' type='file' onChange={onChooseImage} style={{visibility: 'hidden'}} />
      </SelectImageBox>
    </>
  );
};
