import React from 'react';

type VideoPlayerProps = {
  videoSrc: string;
  poster?: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({videoSrc, poster}) => {
  return (
    <video width='390' controls poster={poster}>
      <source src={videoSrc} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
