import {updateImage} from 'api/chatowl.api';
import {FormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {CancelButton, SaveButton} from 'components/page';
import {useAppDispatch} from 'hooks';
import {DetailContainer} from 'pages/clients/styles';
import React, {useEffect, useState} from 'react';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import {isAnyStringEmpty} from 'utils/helpers';
import DeleteWeekModal from './delete-week-modal';
import {ActionButtons, Body, DeleteButton, Header} from './styles';

type WeekDetailProps = {
  onCancel: () => void;
  week: Week;
  isEditing: boolean;
  onAddEdit: (week: Week) => void;
  onDelete: () => void;
};

const imagesTypes: CropType[] = ['full_width_regular'];

const WeekDetail: React.FC<WeekDetailProps> = ({onCancel, week, isEditing, onAddEdit, onDelete}) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [mediaId, setMediaId] = useState<number>();
  const [media, setMedia] = useState<MediaDto>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newCrops, setNewCrops] = useState<Crop[]>([]);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEditing) {
      setName(week.name);
      setDescription(week.description);
      setMediaId(week.media?.id);
      setMedia(week.media);
      setSettings(week.media?.settings);
    } else {
      setName('');
      setDescription('');
      setMediaId(0);
      setMedia(undefined);
    }
  }, [week]);

  const onSave = async () => {
    dispatch(showLoading());
    if (isAnyStringEmpty([name, description]) || !mediaId)
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'All fields can not be empty'}));
    else {
      const newWeek = {
        id: week.id,
        name,
        description,
        mediaId,
        position: week.position,
      };

      let errorEditingMedia = false;

      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = newCrops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = newCrops!.find((crop) => crop.type === 'full_screen_portrait');
        const squared = newCrops!.find((crop) => crop.type === 'squared');
        const crops: UpdateMediaImageRequest = {
          fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
          fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
          squared: squared ? squared.blob : undefined,
          settings,
        };
        const mediaImage = (await updateImage(mediaId!, crops)) as any;
        if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          errorEditingMedia = true;
        }
      }

      if (!errorEditingMedia) onAddEdit(newWeek);
    }
    dispatch(hideLoading());
  };

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  return (
    <DetailContainer>
      <Header>
        <h1>{isEditing ? 'Edit week' : 'New week'}</h1>
        {isEditing && <DeleteButton onClick={() => setModalOpen(true)}>Delete week</DeleteButton>}
      </Header>
      <Body>
        <FormElement type='input' label='Name' value={name} onChange={(n) => setName(n)} />
        <FormElement type='text-area' label='Description' value={description} onChange={(d) => setDescription(d)} />
        <div style={{height: '410px', display: 'flex'}}>
          <MediaChooser
            key={'Image'}
            onChooseMedia={setMediaId}
            imagesTypes={imagesTypes}
            mediaType={'image'}
            addCrop={addCrop}
            changeSettings={setSettings}
            actualName={name}
          />
        </div>
        <ActionButtons>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <SaveButton
            disable={name === '' && description === '' && mediaId === 0}
            disabled={name === '' && description === '' && mediaId === 0}
            onClick={onSave}
          >
            Save
          </SaveButton>
        </ActionButtons>
      </Body>
      <DeleteWeekModal isDeleteModalOpen={modalOpen} toggleDeletePrompt={() => setModalOpen(false)} onDeleteWeek={onDelete} />
    </DetailContainer>
  );
};

export default WeekDetail;
