import styled from 'styled-components';
import {Button} from 'components/page';

export const BoxMessage = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  color: #495057;
  height: fit-content;
`;

type BoxMessageContainerType = {
  isSelected: boolean;
};

export const BoxMessageContainer = styled.div`
  background: #ffffff;
  border: ${(props: BoxMessageContainerType) => props.isSelected && '4px solid rgba(44, 152, 240, 0.5)'};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 8px;
  color: #495057;
  margin-top: 18px;
  margin-left: 5px;
  height: fit-content;
  transition: 0.1s;
`;

export const BoxTitle = styled.div`
  padding: 1% 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoxSend = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);
  background-color: rgba(33, 150, 243, 0.1);
  font-size: 20px;
  padding: 3%;
`;

export const BoxReceive = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);
  font-size: 20px;
  padding: 3%;
`;

export const BoxNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);
  font-size: 16px;
`;

export const NewNote = styled.div`
  text-decoration: underline black;
  cursor: pointer;
`;

export const Icon = styled.i`
  align-self: center;
  margin-right: 5px;
`;

export const TextareaContainer = styled.div`
  width: 100%;
  textarea {
    resize: vertical !important;
  }
`;

export const AnswerButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
  margin: 6px;
  display: inline-flex;
  padding: 0 15px;
  width: fit-content;
`;

export const InteractionInfoAnsweredAt = styled.div`
  width: 30%;
  text-align: left;
`;

export const InteractionInfoRepliedAt = styled.div`
  width: 30%;
  text-align: right;
`;

export const InteractionInfoAssignedTo = styled.div`
  flex-grow: 1;
  text-align: center;
`;

export const BoxAssignedTo = styled.div`
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
