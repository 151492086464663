import React, {useState, useEffect} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  Form,
  PageFooter,
  CancelButton,
  SaveButton,
  DeleteButton,
  Label,
} from 'components/page';
import styled from 'styled-components';
import {deleteActivity, getActivityTypesTools, patchActivityProgram} from 'api/chatowl.api';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {StyledFormElement} from 'components/form-element';
import {InputSwitch} from 'primereact/inputswitch';
import {repeatValues, priorityValues} from '../../../utils/values';
import {useAppSelector, useAppDispatch} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';
import {hideLoading, sendMessage, showLoading, unselectActivity} from 'store/action-creators';
import {NotificationOptions} from '../styles';
import {ActivityRepeatInterval} from 'components/therapy-programs/default-program-assignment/activities/forms';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const SwitchContainer = styled(StyledFormElement)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25%;
`;

interface RouteParam {
  id: string;
  progId: string;
}

export const EditActivity: React.FC<{title?: string}> = ({title}) => {
  const [typeLabelValue, setTypeLabelValue] = useState('add-exercise');
  const [toolComboVisible, setToolComboVisible] = useState(true);
  const [tool, setTool] = useState<number | undefined>(1);
  const [priority, setPriority] = useState<number | undefined>(1);
  const [locked, setLocked] = useState<boolean | undefined>(true);
  const [notifications, setNotifications] = useState<boolean | undefined>(true);
  const [emails, setEmails] = useState<boolean | undefined>(true);
  const [repeat, setRepeat] = useState<number>(0);
  const [mandatory, setMandatory] = useState<boolean | undefined>(false);
  const [toolValues, setToolValues] = useState<ComboOptionDto[]>([]);
  const [typeValues, setTypeValues] = useState<ComboOptionDto[]>([]);

  const params = useParams<RouteParam>();

  const dispatch = useAppDispatch();

  const selectedActivity: ActivityDto | null = useAppSelector(
    (state: RootState) => state.activities.selectedActivity,
    shallowEqual
  );

  const history = useHistory();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchActivityTypesTools = async () => {
      dispatch(showLoading());
      try {
        const response = (await getActivityTypesTools()) as any;
        const types: ComboOptionDto[] = response.data.data;

        setTypeValues(types);

        types.map((x) =>
          x.labels?.map((y) => {
            if (y.value === selectedActivity?.activityTool?.id) {
              setToolValues(x.labels!);
              setTool(selectedActivity?.activityTool?.id);
            }
          })
        );
        dispatch(hideLoading());
      } catch (error) {
        dispatch(hideLoading());
        throw error; // TODO
      }
    };

    fetchActivityTypesTools();

    setTypeLabelValue(selectedActivity?.typeLabel.value);
    setTool(selectedActivity?.activityTool?.id);
    setPriority(selectedActivity?.priority ? selectedActivity?.priority : 1);
    setLocked(selectedActivity?.isLocked);
    setNotifications(selectedActivity?.sendsPushNotification);
    setEmails(selectedActivity?.sendsEmail);
    setRepeat(selectedActivity?.repeatInterval || 0);
    setMandatory(selectedActivity?.isMandatory);
  }, [selectedActivity]);

  useEffect(() => {
    const showToolDropDown = typeLabelValue !== 'due-mood-meter' && typeLabelValue !== 'due-journal';
    setToolComboVisible(showToolDropDown);
  }, [typeLabelValue]);

  const onCancelActivity = () => {
    dispatch(unselectActivity());
    history.push(`/therapy-programs/${params.progId}`);
  };

  const onDeleteActivity = async () => {
    if (params.progId && selectedActivity?.id) {
      dispatch(showLoading());
      const response = (await deleteActivity(Number.parseInt(params.progId), selectedActivity?.id)) as any;
      if (response.response.status >= 200 && response.response.status < 300) {
        dispatch(unselectActivity());
        dispatch(
          sendMessage({
            severity: 'success',
            summary: 'Activity deleted successfully',
            detail: selectedActivity?.title,
          })
        );
        dispatch(hideLoading());
        history.push(`/therapy-programs/${params.progId}`);
      } else {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        dispatch(hideLoading());
      }
    }
  };

  const onSaveActivity = async () => {
    dispatch(showLoading());

    const type = typeLabelValue?.split('-')[0] as ActivityType;

    const activity: UpdateActivityReqest = {
      toolId: tool,
      type: type,
      isLocked: locked,
      isMandatory: mandatory,
      repeatInterval: repeat,
      priority,
      sendsEmail: emails,
      sendsPushNotification: notifications,
      timeDue: {
        day: selectedActivity?.timeDue.day,
      },
    };

    const programId = Number.parseInt(params.progId);

    if (!programId) throw Error();

    const activityId = selectedActivity?.id;

    if (!activityId) throw Error();

    await patchActivityProgram(programId, activityId, activity);

    dispatch(unselectActivity());
    dispatch(hideLoading());
    history.goBack();
  };

  const onChangeType = (e: any) => {
    setTypeLabelValue(e.value);
    const type = typeValues.find((x) => x.value === e.value);
    if (!!type && type.labels) {
      setToolValues(type.labels);
      setTool(parseInt(type.labels[0].value.toString()));
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Edit Activity</PageTitle>
      </PageHeader>
      <PageBody>
        <Form>
          <StyledFormElement>
            <Label>Type</Label>
            <Dropdown value={typeLabelValue} onChange={onChangeType} options={typeValues} optionLabel='name' />
          </StyledFormElement>
          {typeLabelValue?.includes('add') && (
            <>
              <SwitchContainer>
                <Label>Locked</Label>
                <InputSwitch checked={locked} onChange={(e) => setLocked(e.value)} />
              </SwitchContainer>
            </>
          )}
          {toolComboVisible && (
            <StyledFormElement>
              <Label>Tool</Label>
              <Dropdown value={tool} onChange={(e) => setTool(e.value)} options={toolValues} optionLabel='name' />
            </StyledFormElement>
          )}
          <StyledFormElement>
            <Label>Priority</Label>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorityValues} optionLabel='name' />
          </StyledFormElement>
          {typeLabelValue?.includes('due') && (
            <>
              <ActivityRepeatInterval repeatInterval={repeat} setRepeatInterval={setRepeat} />
              <SwitchContainer>
                <Label>Mandatory</Label>
                <InputSwitch checked={mandatory} onChange={(e) => setMandatory(e.value)} />
              </SwitchContainer>
            </>
          )}
          <NotificationOptions>
            <SwitchContainer>
              <Label>Sends push notifications</Label>
              <InputSwitch checked={notifications} onChange={(e) => setNotifications(e.value)} />
            </SwitchContainer>
            <SwitchContainer>
              <Label>Sends emails</Label>
              <InputSwitch checked={emails} onChange={(e) => setEmails(e.value)} />
            </SwitchContainer>
          </NotificationOptions>
        </Form>
        <StyledFormElement></StyledFormElement>
      </PageBody>
      <PageFooter>
        <DeleteButton onClick={onDeleteActivity}>Delete</DeleteButton>
        <CancelButton onClick={onCancelActivity}>Cancel</CancelButton>
        <SaveButton onClick={onSaveActivity}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
