import React, {useEffect, useState} from 'react';
import {PageHeader, PageTitle} from 'components/page';
import {ImageFormContainer} from './../containers/image';
import {VideoFormContainer} from './../containers/video';
import {AudioFormContainer} from './../containers/audio';
import {useHistory, useParams} from 'react-router-dom';
import {capitalize} from 'utils/helpers';
import {useAppDispatch} from 'hooks';
import {hideLoading, selectMedia, sendMessage, showLoading} from 'store/action-creators';
import {PageContainer} from '../styles';
import {getMedia} from 'api/chatowl.api';
import {useTags} from 'hooks';
const queryString = require('query-string');

export const EditMedia: React.FC<{title?: string}> = ({title}) => {
  const [name, setName] = useState('');
  const tags = useTags();
  const [originalAudio, setOriginalAudio] = useState<Blob | undefined>();
  const [originalAudioURL, setOriginalAudioURL] = useState<string | undefined>('');
  const [originalImage, setOriginalImage] = useState<Blob | undefined>();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [imagesCrop, setImagesCrop] = useState<CropDto[]>([]);
  const [originalVideo, setOriginalVideo] = useState<Blob | undefined>();
  const [originalVideoURL, setOriginalVideoURL] = useState<string | undefined>('');
  const [portraitVideo, setPortraitVideo] = useState<Blob | undefined>();
  const [portraitVideoURL, setPortraitVideoURL] = useState<string | undefined>('');
  const [originalImageURL, setOriginalImageURL] = useState<string | undefined>();

  const params = useParams<{mediaId?: string}>();
  const mediaId = +params.mediaId!;

  const [mediaType, setMediaType] = useState<MediaType | 'media'>('media');

  const history = useHistory();
  const dispatch = useAppDispatch();
  const queryOptions = queryString.parse(window.location.search);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        dispatch(showLoading());
        const response = (await getMedia(mediaId)) as any;
        const media = response.data.data as MediaDto;
        setMediaType(media.type);
        dispatch(selectMedia(media));
        dispatch(hideLoading());
      } catch (error) {
        dispatch(hideLoading());
        console.error(error);
        history.push('/error');
      }
    };
    fetchMedia();
  }, []);

  const onSucess = (mediaId: number, mediaName: string) => {
    // easter egg to try edit the next media available.
    if (queryOptions['next']) {
      const nextId = mediaId + 1;

      dispatch(
        sendMessage({
          severity: 'success',
          summary: `${capitalize(mediaType)} updated successfully.`,
          detail: 'Redirecting to edit next media...',
        })
      );

      setTimeout(() => {
        history.push(`/media-bank/medias/${nextId}/edit?next=true`);
        history.go(0);
      }, 3000);
    } else {
      dispatch(
        sendMessage({
          severity: 'success',
          summary: `${capitalize(mediaType)} updated successfully`,
          detail: mediaName,
        })
      );

      history.push('/media-bank');
    }
  };

  const onError = () => dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Edit Media</PageTitle>
      </PageHeader>
      {mediaType === 'video' && (
        <VideoFormContainer
          formType='edit'
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalImage={originalImage}
          setOriginalImage={setOriginalImage}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
          originalVideo={originalVideo}
          setOriginalVideo={setOriginalVideo}
          originalVideoURL={originalVideoURL}
          setOriginalVideoURL={setOriginalVideoURL}
          portraitVideo={portraitVideo}
          setPortraitVideo={setPortraitVideo}
          portraitVideoURL={portraitVideoURL}
          setPortraitVideoURL={setPortraitVideoURL}
        />
      )}
      {mediaType === 'audio' && (
        <AudioFormContainer
          formType='edit'
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalAudioURL={originalAudioURL}
          setOriginalAudioURL={setOriginalAudioURL}
          originalAudio={originalAudio}
          setOriginalAudio={setOriginalAudio}
          originalImage={originalImage}
          setOriginalImage={setOriginalImage}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
        />
      )}
      {mediaType === 'image' && (
        <ImageFormContainer
          formType='edit'
          onSuccess={onSucess}
          onError={onError}
          name={name}
          setName={setName}
          tags={tags}
          originalImage={originalImage}
          setOriginalImage={setOriginalImage}
          crops={crops}
          setCrops={setCrops}
          imagesCrop={imagesCrop}
          setImagesCrop={setImagesCrop}
          originalImageURL={originalImageURL}
          setOriginalImageURL={setOriginalImageURL}
        />
      )}
    </PageContainer>
  );
};
