import {Button} from 'components/page';
import styled from 'styled-components';
import {PageContainer as GlobalPageContaner, PageBody as PageBodyGlobal} from 'components/page';

//Here are the common styles for the 3 types of media Details

export const InputButton = styled.div`
  width: 100%;
  position: relative;
`;

export const InputComplete = styled.input`
  margin: 0px;
  padding: 0px;
  width: 100%;
  outline: none;
  height: 38px;
  border-radius: 5px;
`;

export const AddInput = styled.div`
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 25px;
  z-index: 2;
  border: none;
  top: 25%;
  height: 30px;
  cursor: pointer;
  color: '#12C98F0';
  transform: translateX(2px);
`;

export const Tag = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 12px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  min-width: 66px;
`;

export const modalStyle = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

export const RedirectModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

export const RedirectModalSelectCancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
`;

export const RedirectModalSelectYesButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
`;

export const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

export const PageBody = styled(PageBodyGlobal)`
  margin-right: 0px;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 2%;
  min-width: 500px;
  max-width: 500px;
`;
