import styled from 'styled-components';

type MessageContainerProps = {
  isLast: boolean;
};

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  min-height: 116px;
  border-top: 1px solid #b4c1c1;
  ${(props: MessageContainerProps) => props.isLast && 'border-bottom: 1px solid #b4c1c1'};
  font-size: 20px;
  cursor: pointer;
`;

type MessageIndicatorProps = {
  color: string;
  hover: boolean;
};

export const MessageIndicator = styled.div`
  background-color: ${(props: MessageIndicatorProps) => props.color};
  ${(props: MessageIndicatorProps) => (props.hover ? 'opacity: 0.4' : 'opacity: 0.2')};
  min-width: 1.5%;
  width: 1.5%;
`;

export const Message = styled.div`
  display: flex;
  width: 100%;
`;

export const MessageDataContainer = styled.div`
  display: flex;
  overflow: hidden;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 100%;
  width: 100%;
`;

export const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 2%;
  width: 20%;
`;

export const MessageText = styled.div`
  min-width: 33%;
  max-width: 33%;
  width: 33%;
  display: flex;
  justify-content: space-between;
  padding-left: 6%;
  white-space: nowrap;
  text-overflow: ellipsis;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 33%;
  }
`;

export const MessageClient = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 6%;
  width: 33%;
`;

type TagProps = {
  color: string;
};

export const Tag = styled.div`
  font-size: 11px;
  /* height: 18px; */
  width: fit-content;
  padding: 2px 8px;
  font-weight: bold;
  color: white;
  border-radius: 6px;
  background-color: ${(props: TagProps) => props.color};
`;

export const IconText = styled.div`
  display: flex;
  font-size: 15px;
`;

export const Icon = styled.img`
  margin-right: 3%;
`;
