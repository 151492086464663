import {MessageRow} from 'components/message-row';
import {PageTitle} from 'components/page';
import SearchBar from 'components/search-bar';
import React, {useState, useEffect} from 'react';
import {PageContainer, FilterOption, PageHeader, SearchFilterContainer, MessageContainer} from './styles';
import {getUnrespondedMessages} from 'api/chatowl.api';
import {selectMessagingTagsFilter, unselectMessagingTagsFilter, sendMessage} from 'store/action-creators';
import {useAppDispatch, useAppSelector} from 'hooks';
import Skeleton from 'react-loading-skeleton';

type Filter = {
  name: string;
  color: string;
};

const filters: Filter[] = [
  {name: 'Overdue', color: '#EB5757'},
  {name: 'Unassigned', color: '#D563FF'},
  {name: 'Assigned to me', color: '#20C5A0'},
];

type MessagingProps = {title?: string};

export const Messaging: React.FC<MessagingProps> = ({title}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValues, setFilterValues] = useState<Filter[]>([]);
  const [filtersObj, setFiltersObj] = useState<UnrespondedMessagesFiltersType>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unrespondedMessages, setUnrespondedMessages] = useState<InteractionDTO[]>();

  const selectedTags: string[] = useAppSelector((state: any) => state.messagingFilters.selectedTags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchUnrespondedMessages = async () => {
      try {
        let activeTags: any = {};
        activeTags.tags = selectedTags;
        const response = (await getUnrespondedMessages({...activeTags})) as any;
        const res = response.data.data as [];
        setUnrespondedMessages(res);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchUnrespondedMessages();
    const fetching = setInterval(() => fetchUnrespondedMessages(), 60000);
    return () => clearInterval(fetching);
  }, []);

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchMessaging = async () => {
    let ft: any = {};
    ft.search = searchValue;

    if (selectedTags) ft.tags = selectedTags;

    const response = (await getUnrespondedMessages({...ft})) as any;
    if (response.data.error) {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: response.data.error.details[0].message}));
      return;
    }
    setFiltersObj(ft);
    const int = response.data.data as InteractionDTO[];
    setUnrespondedMessages(int);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchMessaging();
    }, 400);

    return () => clearTimeout(delay);
  }, [searchValue]);

  const onChangeTag = async (val: string) => {
    let ts = selectedTags;
    selectedTags.includes(val) ? dispatch(unselectMessagingTagsFilter(val)) : dispatch(selectMessagingTagsFilter(val));
    ts.includes(val) ? (ts = ts.filter((status) => status !== val)) : (ts = [...ts, val]);
    let ft: any = {};
    ft.tags = ts;

    if (searchValue) ft.search = searchValue;

    setIsLoading(true);
    const response = (await getUnrespondedMessages({...ft})) as any;
    if (response.data.error) {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: response.data.error.details[0].message}));
      return;
    }
    setFiltersObj(ft);
    const int = response.data.data as InteractionDTO[];
    setUnrespondedMessages(int);
    setIsLoading(false);
  };

  const interactionSkeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Messaging</PageTitle>
        <SearchFilterContainer>
          {filters.map((f) => (
            <FilterOption
              onClick={async () => await onChangeTag(f.name)}
              color={f.color}
              selected={selectedTags.some((fv) => fv == f.name)}
            >
              {f.name}
            </FilterOption>
          ))}
          <SearchBar onChange={onChangeSearchValue} value={searchValue} />
        </SearchFilterContainer>
      </PageHeader>
      <div>
        {isLoading ? (
          interactionSkeleton.map((i) => <Skeleton style={{width: '100%', height: '10vh'}} />)
        ) : unrespondedMessages?.length ? (
          unrespondedMessages.map((m, i) => (
            <MessageRow key={m.id} interaction={m} isLast={i === unrespondedMessages.length - 1} searchValue={searchValue} />
          ))
        ) : (
          <h3 style={{margin: '5% 5% 5% 5%'}}>No unreplied interactions found</h3>
        )}
      </div>
    </PageContainer>
  );
};
