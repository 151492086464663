import {CategoriesUl} from 'components/info/categories-ul';
import {CursorLoading} from 'components/page';
import {ProgressSpinner} from 'primereact/progressspinner';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {capitalize, secondsToLabel} from 'utils/helpers';
import {
  DetailButtonLink as EDButtonLink,
  DetailField as EDField,
  DetailFieldImage as EDFieldImage,
  DetailFieldLabel as EDFieldLabel,
  DetailFieldRow as EDFieldRow,
  DetailFieldValue as EDFieldValue,
  DetailHeader as EDHeader,
  DetailID as EDID,
  DetailTitle as EDTitle,
  Label,
} from '../../../components/tools/main-page-components';

type ExerciseDetailsProps = {
  exercise: ExerciseDto | null;
};

export const mediaTypesDropdown: DropdownType[] = [
  {label: 'Audio File', value: 'audio'},
  {label: 'Video File', value: 'video'},
];

export const repeatLimitDropdown: DropdownType[] = [
  {label: 'Can be repeated at any time', value: 0},
  {label: 'After 6 hours', value: 21600},
  {label: 'After 24 hours', value: 86400},
  {label: 'After 2 days', value: 172800},
];

export const resumeLimitDropdown: DropdownType[] = [
  {label: 'No resume limit', value: 0},
  {label: '10 minutes', value: 600},
  {label: '30 minutes', value: 1800},
  {label: '1 hour', value: 3600},
  {label: '2 hours', value: 7200},
  {label: '3 hours', value: 10800},
  {label: '4 hours', value: 14400},
  {label: '6 hours', value: 21600},
  {label: '18 hours', value: 64800},
  {label: '1 day', value: 86400},
  {label: '2 days', value: 162800},
];

export const InstructionsLabelValue: DropdownType[] = [
  {label: 'Dim the lights', value: 'dim_lights'},
  {label: 'Headphones', value: 'headphones'},
  {label: 'Make yourself comfortable', value: 'comfortable'},
];

export const translateSecondsRepeat = (seconds: number | undefined): string => {
  const repeatLimitTime: DropdownType | undefined = repeatLimitDropdown.find((time) => time.value === seconds);
  return repeatLimitTime ? repeatLimitTime.label : seconds!.toString();
};

export const translateResumeLimit = (resume: string) => resume || 'No resume limit';
export const translateRepeatLimit = (repeat: string) => repeat || 'Can be repeated at any time';

export const ExerciseDetails: React.FC<ExerciseDetailsProps> = ({exercise}) => {
  const history = useHistory();
  let instructionsString: string | undefined = '';
  if (exercise?.instructions) {
    const instructionsLength: number = exercise?.instructions.length;
    exercise?.instructions.map((instructionValue, iterator) => {
      const LabelValueAux = InstructionsLabelValue.find((labelValue) => instructionValue === labelValue.value);
      instructionsString += LabelValueAux ? LabelValueAux.label : 'None';
      instructionsString += instructionsLength > 1 && iterator + 1 < instructionsLength ? ', ' : ''; //When there's more than 1 instruction
      return instructionsString;
    });
  }
  const fullWidthRegularImageUrl = exercise?.media?.crops.find((c) => c.type === 'full_width_regular')?.url || '';
  const fullWidrhTallImageUrl = exercise?.media?.crops.find((c) => c.type === 'full_width_tall')?.url || '';
  const audioUrl = exercise?.media?.url || '';
  const videoLandscapeUrl = exercise?.media?.url || '';
  const videoPortraitUrl = exercise?.media?.portraitVideoUrl || '';
  const mediaType: MediaType | '' = exercise?.media?.type || '';

  const [loading, setLoading] = useState(true);

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <CursorLoading style={{height: '1000px'}} disable={loading}>
      <div>
        <EDHeader>
          <div style={{display: 'flex'}}>
            <EDTitle>Exercise Info</EDTitle>
            {exercise?.isFree && <Label color={'#20C5A0'}>Free</Label>}
          </div>
          <div>
            <EDButtonLink onClick={() => history.push(`/tools/exercises/edit/${exercise?.id}`)}>Edit</EDButtonLink>
          </div>
        </EDHeader>
        <EDID>{exercise?.id}</EDID>
      </div>
      <div>
        <EDField>
          <EDFieldLabel>Name</EDFieldLabel>
          <EDFieldValue>{exercise?.title}</EDFieldValue>
        </EDField>
        <EDFieldRow>
          <EDField>
            <EDFieldLabel>Category</EDFieldLabel>
            <EDFieldValue>
              <CategoriesUl categories={exercise?.toolCategories}></CategoriesUl>
            </EDFieldValue>
          </EDField>
          <EDField>
            <EDFieldLabel>Type</EDFieldLabel>
            <EDFieldValue>{capitalize(mediaType)}</EDFieldValue>
          </EDField>
        </EDFieldRow>
        <EDField>
          <EDFieldLabel>Description</EDFieldLabel>
          <EDFieldValue>{exercise?.description}</EDFieldValue>
        </EDField>
        <EDFieldRow>
          <EDField>
            <EDFieldLabel>Repeat Limit</EDFieldLabel>
            <EDFieldValue>
              {exercise?.isRepeatable ? translateRepeatLimit(secondsToLabel(exercise?.repeatLimit)) : 'No Repeatable'}
            </EDFieldValue>
          </EDField>
          <EDField>
            <EDFieldLabel>Resume Limit</EDFieldLabel>
            <EDFieldValue>{translateResumeLimit(secondsToLabel(exercise?.resumeLimit))}</EDFieldValue>
          </EDField>
        </EDFieldRow>
        <EDField>
          <EDFieldLabel>Instructions</EDFieldLabel>

          <EDFieldValue>{instructionsString ? instructionsString : 'None'}</EDFieldValue>
        </EDField>

        <EDField>
          <EDFieldLabel>Image</EDFieldLabel>
          {loading && (
            <EDFieldValue>
              <ProgressSpinner style={{width: '45px', height: '45px'}} />
            </EDFieldValue>
          )}
          <EDFieldImage src={fullWidthRegularImageUrl} alt='Full Width Regular Crop' title='Full Width Regular Crop' />
          <EDFieldImage src={fullWidrhTallImageUrl} alt='Full Width Tall Crop' title='Full Width Tall Crop' />
        </EDField>
        {mediaType === 'audio' && (
          <EDField>
            <EDFieldLabel>Audio File</EDFieldLabel>
            {loading && (
              <EDFieldValue>
                <ProgressSpinner style={{width: '45px', height: '45px'}} />
              </EDFieldValue>
            )}
            <audio controls key={audioUrl} onLoadedData={handleLoaded}>
              <source src={audioUrl} type='audio/mpeg' />
              Your browser does not support the audio tag.
            </audio>
          </EDField>
        )}

        {mediaType === 'video' && (
          <>
            <EDField>
              <EDFieldLabel>Video formats</EDFieldLabel>
              {loading && (
                <EDFieldValue>
                  <ProgressSpinner style={{width: '45px', height: '45px'}} />
                </EDFieldValue>
              )}
              <video
                width='300'
                controls
                key={videoLandscapeUrl}
                onLoadedData={handleLoaded}
                poster={exercise?.media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
              >
                <source src={videoLandscapeUrl} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </EDField>
            <EDField>
              <video
                width='300'
                controls
                key={videoPortraitUrl}
                onLoadedData={handleLoaded}
                poster={exercise?.media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
              >
                <source src={videoPortraitUrl} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </EDField>
          </>
        )}
      </div>
    </CursorLoading>
  );
};
export default ExerciseDetails;
