import React from 'react';

type AudioPlayerProps = {
  audioSrc: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({audioSrc}) => {
  return (
    <audio controls>
      <source src={audioSrc} type='audio/mpeg' />
      Your browser does not support the audio tag.
    </audio>
  );
};
//see if 'audio/mpeg' works. Else try audio/mp3
export default AudioPlayer;
