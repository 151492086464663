import React, {useEffect, useState} from 'react';
import {ControlWrapperRow, PageBody, PageContainer, PageHeader, PageTitle, PDButtonBack} from 'components/page';
import GeneralSidebar from 'components/general-sidebar';
import SearchBar from 'components/search-bar';
import {useHistory, useParams} from 'react-router-dom';
import {Timeline} from 'primereact/timeline';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {
  GeneralCardContainer,
  GeneralCardInfo,
  GeneralCardHeader,
  GeneralTitle,
  GeneralDescription,
  GeneralCategory,
  GeneralImage,
} from 'components/tools/general-card-list/styles';
import {AddButton} from './styles';
import WeekDetail from './week-detail';
import {
  deleteProgramWeek,
  editProgramWeek,
  getProgramDetails,
  getProgramWeeks,
  patchProgramWeek,
  postProgramWeek,
} from 'api/chatowl.api';
import {useAppDispatch, useAppSelector} from 'hooks';
import {shallowEqual} from 'react-redux';
import {RootState} from 'index';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import Skeleton from 'react-loading-skeleton';

interface RouteParam {
  id: string;
}

const weeksSkeleton = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];

export const ManageWeeks: React.FC<{title?: string}> = ({title}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [weeks, setWeeks] = useState<Array<Week>>([]);
  const [timeLine, setTimeLine] = useState<Array<string>>(weeksSkeleton);
  const [selectedWeek, setSelectedWeek] = useState<any>();
  const [newWeek, setNewWeek] = useState<any>(' ');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedProgram, setSelectedProgram] = useState<ProgramDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<RouteParam>();

  const fetchWeeks = async () => {
    try {
      const programResponse = (await getProgramDetails(+params.id)) as any;
      setSelectedProgram(programResponse.data.data);
      const response = (await getProgramWeeks(params.id)) as any;
      const weeksResponse = response.data.data ? response.data.data : [];
      weeksResponse.map((x: Week) => (x.weekId = x.id));
      setWeeks(weeksResponse);
      const time: Array<string> = [];
      weeksResponse.map((x: Week, i: number) => time.push(`Week ${x.position}`));
      setTimeLine(time);
    } catch (error) {
      throw error; // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWeeks();
  }, []);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  const reorder = (list: Array<Week>, startIndex: number, endIndex: number): Array<any> => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.map((x: Week, i: number) => (x.position = i + 1));

    return result;
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(weeks, result.source.index, result.destination.index);
    setWeeks(items);
    if (selectedProgram?.id) {
      const response = (await patchProgramWeek(items, selectedProgram.id)) as any;
      if (response.response.status <= 200 && response.response.status > 300) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        fetchWeeks();
      }
    }
  };

  const addWeek = () => {
    setSelectedWeek(newWeek);
    setIsEditing(false);
  };

  const clickWeek = (item: any) => {
    selectedWeek?.id === item.id ? setSelectedWeek(null) : setSelectedWeek(item);
    setIsEditing(true);
  };

  const onDelete = async () => {
    if (selectedWeek?.id && selectedProgram?.id) {
      dispatch(showLoading());
      const response = (await deleteProgramWeek(selectedWeek.id, selectedProgram.id)) as any;
      if (response.response.status >= 200 && response.response.status < 300) {
        dispatch(
          sendMessage({
            severity: 'success',
            summary: 'Program week deleted successfully',
            detail: selectedWeek.name,
          })
        );
        setSelectedWeek(null);
        fetchWeeks();
      } else {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
      dispatch(hideLoading());
    }
  };

  const onAddEdit = async (week: Week) => {
    if (selectedProgram?.id) {
      dispatch(showLoading());
      if (!isEditing) {
        const newWeek = {
          id: week.id,
          name: week.name,
          description: week.description,
          mediaId: week.mediaId,
          position: weeks.length + 1,
        };
        const response = (await postProgramWeek(newWeek, selectedProgram.id)) as any;
        if (response.response.status >= 200 && response.response.status < 300) {
          dispatch(
            sendMessage({
              severity: 'success',
              summary: 'Program week created successfully',
              detail: week.name,
            })
          );
          setSelectedWeek(null);
          fetchWeeks();
          dispatch(hideLoading());
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          dispatch(hideLoading());
        }
      } else {
        const newWeek = {
          id: week.id,
          name: week.name,
          description: week.description,
          mediaId: week.mediaId,
          position: week.position,
        };
        const response = (await editProgramWeek(newWeek, selectedProgram.id)) as any;
        if (response.response.status >= 200 && response.response.status < 300) {
          dispatch(
            sendMessage({
              severity: 'success',
              summary: 'Program week updated successfully',
              detail: week.name,
            })
          );
          setSelectedWeek(null);
          fetchWeeks();
          dispatch(hideLoading());
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          dispatch(hideLoading());
        }
      }
    }
  };

  const filterBySearchValue = (week: Week) =>
    !searchValue.toLowerCase() ||
    week?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
    week?.description?.toString()?.toLowerCase().includes(searchValue.toLowerCase());

  const weeksFiltered = weeks.filter(filterBySearchValue);

  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader>
          <PageTitle>{selectedProgram?.name}</PageTitle>
          <PDButtonBack onClick={() => history.goBack()}> {'< Back to plan'}</PDButtonBack>
        </PageHeader>
        <ControlWrapperRow>
          <h2>Manage weeks</h2>
          <SearchBar onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
        </ControlWrapperRow>
        <PageBody style={{marginTop: '40px', marginRight: 0}}>
          <div>
            <Timeline
              style={{marginTop: '60px', fontSize: '16px'}}
              marker={marker}
              value={searchValue.length > 0 ? [] : timeLine}
              content={content}
              opposite={opposite}
              layout='vertical'
              align='right'
            />
          </div>
          {isLoading ? (
            <div style={{width: '85%'}}>
              {weeksSkeleton.map((w, i) => (
                <GeneralCardContainer key={i} isSelected={false}>
                  <Skeleton />
                  <GeneralCardInfo>
                    <Skeleton />
                    <GeneralCardHeader>
                      <GeneralTitle>
                        <Skeleton />
                      </GeneralTitle>
                    </GeneralCardHeader>
                    <GeneralDescription>
                      <Skeleton count={3} />
                    </GeneralDescription>
                  </GeneralCardInfo>
                </GeneralCardContainer>
              ))}
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={{width: '85%'}}>
                    {weeksFiltered.map((item, index) => (
                      <Draggable isDragDisabled={searchValue.length > 0} key={item.id} draggableId={`${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <GeneralCardContainer
                              key={1}
                              onClick={() => clickWeek(item)}
                              isSelected={item.id === selectedWeek?.id}
                              placeholder={'Top Level Container'}
                            >
                              <GeneralCardInfo>
                                <GeneralCardHeader>
                                  <GeneralTitle>{item.name}</GeneralTitle>
                                </GeneralCardHeader>
                                <GeneralDescription>{item.description}</GeneralDescription>
                              </GeneralCardInfo>
                              <GeneralImage
                                isSidebarOpen={true}
                                src={item.media?.crops.find((x) => x.type === 'squared')?.url}
                                alt={item.name}
                              />
                            </GeneralCardContainer>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <AddButton onClick={addWeek}> + Add Week </AddButton>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </PageBody>
      </div>
      <GeneralSidebar isActive={!!selectedWeek} onClick={() => setSelectedWeek(null)}>
        <WeekDetail
          onDelete={onDelete}
          isEditing={isEditing}
          onCancel={() => setSelectedWeek(null)}
          week={selectedWeek}
          onAddEdit={onAddEdit}
        />
      </GeneralSidebar>
    </PageContainer>
  );
};

const marker = (item: any) => {
  return <div style={{border: '1px solid #2C98F0', borderRadius: '50%', width: '24px', height: '24px'}}></div>;
};

const content = (item: any) => {
  return <div style={{height: '128px', width: '60px'}}>{item}</div>;
};

const opposite = (item: any) => {
  return <div style={{height: '0px', width: '0px', padding: '0px'}}></div>;
};
