import styled from 'styled-components';

export const Note = styled.div`
  display: flex;
  border-radius: 8px;
  border-style: solid;
  border-color: #b4c1c1;
  border-width: 1px;
  box-sizing: border-box;
  width: 98%;
  padding: 10px 15px;
  margin: 5px 1%;
  justify-content: space-between;
  cursor: pointer;
`;

export const NewNote = styled(Note)`
  background-color: #fffce6;
  margin-bottom: 0px;
  min-height: 30px;
  border-radius: 10px 10px 0px 0px;
  cursor: auto;

  & ::placeholder {
    font-style: italic;
  }
`;

export const InputNote = styled.textarea`
  border: none;
  overflow: auto;
  outline: none;
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #fffce6;
  resize: none;
`;

export const RedButtonNote = styled.div`
  align-self: 'flex-end';
  font-size: 13px;
  color: red;
  text-decoration: underline red;
  cursor: pointer;
`;

export const DefaultButtonNote = styled.div`
  align-self: 'flex-end';
  font-size: 13px;
  text-decoration: underline black;
  cursor: pointer;
  margin-right: 10px;
`;

export const NoteFooter = styled(Note)`
  border-radius: 0px 0px 10px 10px;
  background-color: #fffce6;
  margin-top: 0px;
  border-width: 0px 1px 1px 1px;
  cursor: auto;
`;

export const NoteBody = styled.div`
  display: block;
  display: -webkit-box;
  max-width: 90%;
  height: 43px;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Notes = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-self: center;
`;

export const MessagesNotes = styled.div`
  font-size: 16px;
  background-color: #fffce6;
  border-radius: 0px 0px 8px 8px;
`;

export const MessageNote = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);
`;

export const MessageInputNote = styled.textarea`
  font-size: 16px;
  border: none;
  overflow: auto;
  outline: none;
  height: 300px;
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #fffce6;
  resize: none;
  padding: 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);

  & ::placeholder {
    font-size: 16px;
    font-style: italic;
  }
`;

export const MessageNoteFooter = styled.div`
  padding: 0px 10px;
  border-top: 1px solid rgba(180, 193, 193, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const BubbleNote = styled.div`
  margin: 5px;
  background-color: #fffce6;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: #b4c1c1;
  border-width: 1px;
  box-sizing: border-box;
  overflow-wrap: break-word;
`;

export const BubbleInputNote = styled.textarea`
  margin: 5px;
  border: none;
  overflow: auto;
  outline: none;
  height: 100px;
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #fffce6;
  resize: none;
  padding: 15px;
  border-radius: 20px;
  border-style: solid;
  border-color: #b4c1c1;
  border-width: 1px;
  box-sizing: border-box;

  & ::placeholder {
    font-size: 14px;
    font-style: italic;
  }
`;

export const ChatNoteFooter = styled(MessageNoteFooter)`
  border-top-width: 0px;
  margin-bottom: 12px;
`;
