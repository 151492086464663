import React, {useState, useEffect} from 'react';

import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import styled from 'styled-components';
import GeneralSidebar from '../../../components/general-sidebar';
import {
  ListWrapper as ImageListWrapper,
  AddButton as AddImageButton,
  ListControls,
  ListGeneral as ImageList,
} from '../../../components/tools/main-page-components';
import GeneralFilters from '../../../components/tools/filters';
import ImageCard from '../../../components/tools/general-card-list';

import SearchBar from '../../../components/search-bar';
import {Link} from 'react-router-dom';
import {listImages} from '../../../api/chatowl.api';

import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {shallowEqual} from 'react-redux';
import {selectImage, unselectImage} from '../../../store/action-creators';

import ImageDetails from './image-detail';
import MediaCard from 'components/media-card';
import {CardContent, CardImage, MediaType, CardTitle} from 'components/media-card/style';
import {CardThumbnail} from 'components/media-chooser/styles';
import {cutText} from 'utils/helpers';
import {filterImagesBySearchValue, filterImagesByTags} from 'store/action-creators';

const ImageNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;
  :after {
    content: 'No images';
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1px;
`;

export const Images: React.FC<{title?: string}> = ({title}) => {
  const [images, setImages] = useState<ImageDto[]>([]);
  const selectedImage: ImageDto | null = useAppSelector((state: RootState) => state.tools.images.selectedImage, shallowEqual);

  const imageTags: ToolTag[] = ['Active', 'Archived'];
  const selectedTags: ToolTag[] = useAppSelector((state: RootState) => state.tools.images.filters.tags, shallowEqual);
  const searchValue: string = useAppSelector((state: RootState) => state.tools.images.filters.search, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = (await listImages({withDeleted: false, sortBy: 'id'})) as any;
        const imagesResponse = response.data ? response.data.data : [];
        setImages(imagesResponse);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    if (
      (selectedImage?.isArchived && !selectedTags.includes('Archived')) ||
      (!selectedImage?.isArchived && !selectedTags.includes('Active'))
    ) {
      dispatch(unselectImage());
    }
  }, [selectedTags]);

  const onImageClick = (toolID: number): void => {
    const imageSelected = images.find((image) => image.id === toolID);
    if (imageSelected) toolID !== selectedImage?.id ? dispatch(selectImage(imageSelected)) : dispatch(unselectImage());
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(filterImagesBySearchValue(e.target.value));
  };

  const onClick = (): void => {
    dispatch(unselectImage());
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as ToolTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(filterImagesByTags(newTags));
  };

  const onClickCard = (imageID: number) => {
    const imageSelected = images.find((image) => image.id === imageID);
    if (imageSelected) imageID !== selectedImage?.id ? dispatch(selectImage(imageSelected)) : dispatch(unselectImage());
  };

  const filterByStatus = (image: ImageDto) =>
    !selectedTags.length ||
    (selectedTags.includes('Active') && !image.isArchived) ||
    (selectedTags.includes('Archived') && image.isArchived);

  const filterBySearchValue = (image: ImageDto) =>
    !searchValue.toLowerCase() ||
    image.id.toString().includes(searchValue) ||
    image.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
    image.description?.toLowerCase().includes(searchValue.toLowerCase());

  const imagesFiltered = images.filter(filterByStatus).filter(filterBySearchValue);

  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader>
          <PageTitle>Images</PageTitle>
          <AddImageButton>
            <Link to='/tools/images/add'>+ Add Image</Link>
          </AddImageButton>
        </PageHeader>
        <ListControls>
          <GeneralFilters tags={imageTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter}></GeneralFilters>
          <SearchBar value={searchValue} onChange={onSearchValueChange} />
        </ListControls>

        {imagesFiltered.length ? (
          <CardsWrapper className='p-grid'>
            {imagesFiltered.map((image) => (
              <MediaCard
                selectedMediaID={selectedImage?.id}
                media={{
                  thumbnailUrl: image.media?.thumbnailUrl,
                  id: image.id!,
                  isInUse: image.media?.isInUse,
                  type: 'image',
                  name: cutText(image.title ?? image.description, 40),
                  crops: image.media?.crops!,
                  url: image.media?.url!,
                  settings: image.media?.settings!,
                }}
                onClick={onClickCard}
              ></MediaCard>
            ))}
            <EmptyCard></EmptyCard>
          </CardsWrapper>
        ) : (
          <ImageNotFound />
        )}
      </div>

      <GeneralSidebar isActive={!!selectedImage} onClick={onClick}>
        <ImageDetails image={selectedImage} />
      </GeneralSidebar>
    </PageContainer>
  );
};

const EmptyCard = () => {
  return (
    <div className={'p-col-fixed'} style={{visibility: 'hidden'}}>
      <CardContent className='box p-shadow-6' onClick={() => {}} isSelected={false}>
        <CardImage>
          <CardThumbnail src='' />
          <MediaType></MediaType>
        </CardImage>
        <CardTitle>Hola</CardTitle>
      </CardContent>
    </div>
  );
};
