import React, {useEffect, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {Link} from 'react-router-dom';
import {filterQuotesBySearchValue, filterQuotesByTags} from 'store/action-creators';
import styled from 'styled-components';
import {listQuotes} from '../../../api/chatowl.api';
import GeneralSidebar from '../../../components/general-sidebar';
import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import SearchBar from '../../../components/search-bar';
import GeneralFilters from '../../../components/tools/filters';
import QuoteCard from '../../../components/tools/general-card-list';
import {
  AddButton as AddQuoteButton,
  ListControls,
  ListGeneral as QuoteList,
  ListWrapper as QuoteListWrapper,
} from '../../../components/tools/main-page-components';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {selectQuote, unselectQuote} from '../../../store/action-creators';
import QuoteDetails from './quote-detail';

const QuoteNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No Quotes';
  }
`;

export const Quotes: React.FC<{title?: string}> = ({title}) => {
  const [quotes, setQuotes] = useState<QuoteDto[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const selectedQuote: QuoteDto | null = useAppSelector((state: RootState) => state.tools.quotes.selectedQuote, shallowEqual);

  const quoteTags: ToolTag[] = ['Active', 'Archived'];
  const selectedTags: ToolTag[] = useAppSelector((state: RootState) => state.tools.quotes.filters.tags, shallowEqual);
  const searchValue: string = useAppSelector((state: RootState) => state.tools.quotes.filters.search, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = (await listQuotes({withDeleted: false, sortBy: 'id'})) as any;
        const quotesResponse = response.data ? response.data.data : [];
        setQuotes(quotesResponse);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchQuotes();
  }, []);

  useEffect(() => {
    if (
      (selectedQuote?.isArchived && !selectedTags.includes('Archived')) ||
      (!selectedQuote?.isArchived && !selectedTags.includes('Active'))
    ) {
      //dispatch(unselectQuote()); So it remains selected when user Add/Edits a Quote
    }
  }, [selectedTags]);

  const onQuoteClick = (toolID?: number): void => {
    const quoteSelected = quotes.find((quote) => quote.id === toolID);
    if (quoteSelected) toolID !== selectedQuote?.id ? dispatch(selectQuote(quoteSelected)) : dispatch(unselectQuote());
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(filterQuotesBySearchValue(e.target.value));
  };

  const onClick = (): void => {
    dispatch(unselectQuote());
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as ToolTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(filterQuotesByTags(newTags));
  };

  const filterByStatus = (quote: QuoteDto) =>
    !selectedTags.length ||
    (selectedTags.includes('Active') && !quote.isArchived) ||
    (selectedTags.includes('Archived') && quote.isArchived);

  const filterBySearchValue = (quote: QuoteDto) =>
    !searchValue.toLowerCase() ||
    quote.id.toString().includes(searchValue) ||
    quote.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
    quote.description?.toLowerCase().includes(searchValue?.toLowerCase()) ||
    quote.quoteAuthor?.toLowerCase().includes(searchValue?.toLowerCase()) ||
    quote.quoteContent?.toLowerCase().includes(searchValue?.toLowerCase()) ||
    quote.toolCategories?.some((v) => v.name.toLowerCase().includes(searchValue?.toLowerCase()));

  const quotesFiltered = quotes.filter(filterByStatus).filter(filterBySearchValue);

  const skeleton = [1, 2, 3, 4, 5, 6];

  return (
    <PageContainer>
      <QuoteListWrapper>
        <PageHeader>
          <PageTitle>Quotes</PageTitle>
          <AddQuoteButton>
            <Link to='/tools/quotes/add'>+ Add Quote</Link>
          </AddQuoteButton>
        </PageHeader>
        <ListControls>
          <GeneralFilters tags={quoteTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter}></GeneralFilters>
          <SearchBar value={searchValue} onChange={onSearchValueChange} />
        </ListControls>
        <QuoteList>
          {isLoading ? (
            skeleton.map((v) => <QuoteCard key={v} selectedToolID={0} onClick={() => {}} />)
          ) : quotesFiltered.length ? (
            quotesFiltered.map((quote) => (
              <QuoteCard key={quote.id} selectedToolID={selectedQuote?.id} onClick={onQuoteClick} tool={quote} />
            ))
          ) : (
            <QuoteNotFound />
          )}
        </QuoteList>
      </QuoteListWrapper>

      <GeneralSidebar isActive={!!selectedQuote} onClick={onClick}>
        <QuoteDetails quote={selectedQuote} />
      </GeneralSidebar>
    </PageContainer>
  );
};
