import React, {useState, useEffect} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  Form,
  PageFooter,
  CancelButton,
  SaveButton,
  CursorLoading,
  HelpText,
} from 'components/page';
import styled from 'styled-components';
import {addTherapyProgram, updateVideo} from 'api/chatowl.api';
import {Link, useHistory} from 'react-router-dom';
import {FormElement, StyledFormElement} from 'components/form-element';
import {DefaultProgramAssignment} from 'components/therapy-programs/default-program-assignment';
import MediaChooser from 'components/media-chooser';
import {useAppDispatch} from 'hooks';
import {hideLoading, sendMessage, showLoading} from 'store/action-creators';
import {Checkbox} from 'primereact/checkbox';
import {isAnyStringEmpty} from 'utils/helpers';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

function validateSave(name: string, tagline: string, intro: string, description: string, mediaId: number | undefined): boolean {
  return !!name && !!mediaId && !!tagline && !!intro && !!description;
}

export const AddProgram: React.FC<{title?: string}> = ({title}) => {
  const [programName, setProgramName] = useState('');
  const [programTagLine, setProgramTagLine] = useState('');
  const [programIntro, setProgramIntro] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [willBeDefaultProgram, setWillBeDefaultProgram] = useState(false);
  const [programImageBlob, setProgramImageBlob] = useState<Blob>(new Blob());
  const [savingProgram, setSavingProgram] = useState(false);
  const [weeksValue, setweeksValue] = useState(1);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [mediaId, setMediaId] = useState<number>();
  const [newCrops, setNewCrops] = useState<Crop[]>([]);
  const [alsoCreateSlim, setAlsoCreateSlim] = useState<boolean>(true);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  const onProgramNameChange = (programName: string) => {
    setProgramName(programName);
  };

  const onProgramTagLineChange = (programTagLine: string) => {
    setProgramTagLine(programTagLine);
  };

  const onProgramDescriptionChange = (programDescription: string) => {
    setProgramDescription(programDescription);
  };

  const onProgramIntroChange = (programIntro: string) => {
    setProgramIntro(programIntro);
  };

  const onCancelProgram = () => {
    setProgramName('');
    setProgramDescription('');
    setWillBeDefaultProgram(false);
    setSavingProgram(false);
  };

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onSaveProgram = async () => {
    if (
      validateSave(programName, programTagLine, programIntro, programDescription, mediaId) &&
      !isAnyStringEmpty([programName, programTagLine, programIntro, programDescription])
    ) {
      dispatch(showLoading());
      let errorEditingMedia = false;
      setSavingProgram(true);
      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');

        const media: UpdateMediaVideoRequest = {
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
        };
        const mediaImage = (await updateVideo(mediaId!, media)) as any;
        if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          setSavingProgram(false);
          errorEditingMedia = true;
        }
      }
      if (!errorEditingMedia) {
        try {
          const newProgram: CreateProgramRequest = {
            name: programName,
            description: programDescription,
            language: 'en-US',
            status: 'draft',
            isDefault: willBeDefaultProgram,
            weeks: weeksValue,
            mediaId: mediaId!,
            tagline: programTagLine,
            intro: programIntro,
            alsoCreateSlim: alsoCreateSlim, //TODO: full-slim
          };
          const therapyProgram = (await addTherapyProgram(newProgram)) as any;
          if (therapyProgram.response.status >= 200 && therapyProgram.response.status < 300) {
            dispatch(
              sendMessage({
                severity: 'success',
                summary: 'Therapy Program created successfully',
                detail: programName,
              })
            );
            history.push('/therapy-programs');
          } else {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
            setSavingProgram(false);
          }
        } catch (error) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          console.error(error);
        } finally {
          setSavingProgram(false);
          dispatch(hideLoading());
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Fields can not be empty.'}));
      dispatch(hideLoading());
    }
  };

  return (
    <CursorLoading disable={savingProgram}>
      <PageContainer>
        <PageHeader>
          <PageTitle>Add Therapy Program</PageTitle>
        </PageHeader>
        <PageBody>
          <Form>
            <FormElement
              type='input'
              label='Name'
              value={programName}
              placeholder='Therapy program name'
              onChange={onProgramNameChange}
            />
            <FormElement
              type='input'
              label='Tagline'
              value={programTagLine}
              placeholder='Therapy program Tagline'
              onChange={onProgramTagLineChange}
            />

            <FormElement
              type='text-area'
              label='Intro'
              value={programIntro}
              placeholder='Therapy program intro'
              onChange={onProgramIntroChange}
            />
            <FormElement
              type='text-area'
              label='Description'
              value={programDescription}
              placeholder='Therapy program description'
              onChange={onProgramDescriptionChange}
            />
            <DefaultProgramAssignment
              isCurrentDefaultProgram={false}
              willBeDefaultProgram={willBeDefaultProgram}
              setWillBeDefaultProgram={setWillBeDefaultProgram}
            />
            <StyledFormElement>
              <div style={{flexDirection: 'row', marginBottom: '10px'}}>
                <Checkbox inputId='cb1' onChange={(e) => setAlsoCreateSlim(e.checked)} checked={alsoCreateSlim}></Checkbox>
                <label style={{marginLeft: '1em'}} htmlFor='cb1' className='p-checkbox-label'>
                  Also create a slim version
                </label>
              </div>
              <HelpText>Check this box to create another version of this program in slim format.</HelpText>
            </StyledFormElement>
          </Form>
          <StyledFormElement>
            <MediaChooser
              onChooseMedia={setMediaId}
              mediaType={'video'}
              imagesTypes={['full_width_regular', 'full_width_tall']}
              addCrop={addCrop}
              actualName={programName}
            />
          </StyledFormElement>
        </PageBody>
        <PageFooter>
          <CancelButton onClick={onCancelProgram}>
            <Link to='/therapy-programs/'>Cancel</Link>
          </CancelButton>
          <SaveButton onClick={onSaveProgram} disabled={savingProgram} disable={savingProgram}>
            Save
          </SaveButton>
        </PageFooter>
      </PageContainer>
    </CursorLoading>
  );
};
