import {getClientChats} from 'api/chatowl.api';
import {ChatBubble} from 'components/clients/chat-bubble';
import {Button} from 'primereact/button';
import {ScrollPanel} from 'primereact/scrollpanel';
import React, {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {BoxChat} from '../styles';

type ClientChatProps = {client: ClientDto; withNotes: boolean};

const INITIAL_CHAT_NUMBER = 10;

export const ClientChat: React.FC<ClientChatProps> = ({client, withNotes}) => {
  const [chatBubbles, setChatBubbles] = useState<ChatBubbleDto[]>([]);
  const [showingChats, setShowingChats] = useState<ChatBubbleDto[]>();
  const [showingChatsNumber, setShowingChatsNumber] = useState<number>(INITIAL_CHAT_NUMBER);
  const [moreChatsAvailable, setMoreChatsAvailable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = (await getClientChats(client.username)) as any;
        let res = response.data.data as [];
        if (res) {
          res.reverse();
          const showing = res.slice(0, INITIAL_CHAT_NUMBER);
          setShowingChats(showing);
          setChatBubbles(res);
        }
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchChats();
  }, [client]);

  const handleShowMoreChats = async () => {
    const newChatsNumber = showingChatsNumber + INITIAL_CHAT_NUMBER;
    if (newChatsNumber > chatBubbles.length) {
      const lastDate = chatBubbles[0].timestamp;
      const response = (await getClientChats(client.username, lastDate)) as any;
      let res = response.data.data as [];
      if (res) {
        res.reverse();
        const nuevaReq: ChatBubbleDto[] = res;
        const newChats = chatBubbles.concat(nuevaReq);
        setChatBubbles(newChats);
      } else {
        setMoreChatsAvailable(false);
      }
    }
    setShowingChatsNumber(newChatsNumber);
    const showing = chatBubbles.slice(0, newChatsNumber);
    setShowingChats(showing);
  };

  return (
    <>
      {!isLoading ? (
        <BoxChat>
          <ScrollPanel style={{width: '100%', height: '65vh'}}>
            {moreChatsAvailable && showingChats?.length ? (
              <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Button
                  icon='pi pi-plus'
                  iconPos='left' //review
                  className='p-button-rounded p-button-secondary'
                  title='Show more messages'
                  style={{cursor: 'pointer'}}
                  onClick={handleShowMoreChats}
                />
              </div>
            ) : (
              <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>No more available messages</div>
            )}
            {showingChats
              ?.slice(0)
              .reverse()
              .map((bubble: ChatBubbleDto, index) => {
                return (
                  <ChatBubble
                    withNotes={withNotes}
                    key={bubble.id}
                    bubble={bubble}
                    index={index}
                    showingChats={showingChats}
                    username={client.username}
                  ></ChatBubble>
                );
              })}
          </ScrollPanel>
        </BoxChat>
      ) : (
        <BoxChat>
          <ScrollPanel style={{width: '100%', height: '65vh'}}>
            <Skeleton style={{width: '100%', height: '65vh'}} />
          </ScrollPanel>
        </BoxChat>
      )}
    </>
  );
};
