/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import {signOut} from 'utils/aws-cognito.utils';
import {AuthContext} from 'contexts/auth.context';

export const Signout: React.FC<{title?: string}> = ({title}) => {
  const {setIsAuthenticated} = useContext(AuthContext);

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  // useEffect(() => {
  signOut();
  setIsAuthenticated(false);
  // }, []);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
};
