import React, {useState, useEffect} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  Form,
  PageFooter,
  CancelButton,
  SaveButton,
  Label,
} from 'components/page';
import {SelectButton} from 'primereact/selectbutton';
import styled from 'styled-components';
import {getActivityTypesTools, addActivityToProgram, getProgramDetails} from 'api/chatowl.api';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {StyledFormElement} from 'components/form-element';
import {InputSwitch} from 'primereact/inputswitch';
import {priorityValues, repeatValues} from '../../../utils/values';
import {useAppSelector, useAppDispatch} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';
import {momentToNumber} from 'utils/helpers';
import {hideLoading, sendMessage, showLoading, unselectActivity} from 'store/action-creators';
import {NotificationOptions} from '../styles';
import {ActivityRepeatInterval} from 'components/therapy-programs/default-program-assignment/activities/forms';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const SwitchContainer = styled(StyledFormElement)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25%;
`;

export const AddActivity: React.FC<{title?: string}> = ({title}) => {
  const [therapyProgram, setTherapyProgram] = useState<ProgramDto>();
  const [typeLabelValue, setTypeLabelValue] = useState('add-exercise');
  const [moment, setMoment] = useState<ActivityMoment>('morning');
  const [toolComboVisible, setToolComboVisible] = useState(true);
  const [tool, setTool] = useState(1);
  const [priority, setPriority] = useState(1);
  const [repeat, setRepeat] = useState(0);
  const [mandatory, setMandatory] = useState(false);
  const [locked, setLocked] = useState<boolean | undefined>(false);
  const [notifications, setNotifications] = useState<boolean>(true);
  const [emails, setEmails] = useState<boolean>(true);
  const [toolValues, setToolValues] = useState<ComboOptionDto[]>([]);
  const [typeValues, setTypeValues] = useState<ComboOptionDto[]>([]);

  const newActivityInfo: ActivityForm | null = useAppSelector((state: RootState) => state.activities.newActivity, shallowEqual);

  const dispatch = useAppDispatch();

  const params = useParams<{progId?: string}>();
  const programId = +params.progId!;

  const selectedProgram: ProgramDto | null = useAppSelector((state: RootState) => state.programs.selectedProgram, shallowEqual);

  const history = useHistory();

  const fetchActivityTypesTools = async () => {
    try {
      dispatch(showLoading());
      const response = (await getActivityTypesTools()) as any;
      const types = response.data.data;
      setTypeValues(types);
      setTypeLabelValue(types[0].value);
      setToolValues(types[0].labels);
      setTool(types[0].labels[0].value);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      throw error; // TODO
    }
  };

  const fetchProgram = async () => {
    dispatch(showLoading());
    try {
      const response = (await getProgramDetails(programId)) as any;
      const therapyProgram = response.data.data as ProgramDto;
      setTherapyProgram(therapyProgram);
    } catch (error) {
      console.error(error);
      dispatch(sendMessage({severity: 'error', summary: 'Something went wrong on fetching Therapy Program'}));
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    fetchProgram();
    fetchActivityTypesTools();
  }, []);

  useEffect(() => {
    const showToolDropDown = typeLabelValue !== 'due-mood-meter' && typeLabelValue !== 'due-journal';
    setToolComboVisible(showToolDropDown);
  }, [typeLabelValue]);

  const onCancelActivity = () => {
    dispatch(unselectActivity());
    history.push(`/therapy-programs/${programId}`);
  };

  const onSaveActivity = async () => {
    dispatch(showLoading());

    const type = typeLabelValue.split('-')[0] as ActivityType;
    const order =
      (therapyProgram?.programActivities[newActivityInfo!.day - 1].moments[momentToNumber(moment)].activities.length as number) +
      1;

    try {
      const activity: CreateActivityRequest = {
        toolId: tool,
        type,
        isLocked: locked,
        isMandatory: mandatory,
        repeatInterval: repeat,
        priority,
        timeDue: {
          day: newActivityInfo!.day,
          moment: moment,
          order: order || 1,
        },
        sendsEmail: emails,
        sendsPushNotification: notifications,
      };

      await addActivityToProgram(programId, activity);

      history.push(`/therapy-programs/${programId}`);
      dispatch(unselectActivity());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const selectMomentValues: {name: string; value: ActivityMoment}[] = [
    {name: 'Morning', value: 'morning'},
    {name: 'Afternoon', value: 'afternoon'},
    {name: 'Evening', value: 'evening'},
    {name: 'Night', value: 'night'},
  ];

  const onChangeType = (e: any) => {
    setTypeLabelValue(e.value);
    const type = typeValues.find((x) => x.value === e.value);
    if (!!type && type.labels) {
      setToolValues(type.labels);
      setTool(parseInt(type.labels[0].value.toString()));
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Add Activity</PageTitle>
      </PageHeader>
      <PageBody>
        <Form>
          <StyledFormElement>
            <SelectButton value={moment} onChange={(e) => setMoment(e.value)} options={selectMomentValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Type</Label>
            <Dropdown value={typeLabelValue} onChange={onChangeType} options={typeValues} optionLabel='name' />
          </StyledFormElement>
          {typeLabelValue.includes('add') && (
            <>
              <SwitchContainer>
                <Label>Locked</Label>
                <InputSwitch checked={locked} onChange={(e) => setLocked(e.value)} />
              </SwitchContainer>
            </>
          )}
          {toolComboVisible && (
            <StyledFormElement>
              <Label>Tool</Label>
              <Dropdown
                value={tool}
                onChange={(e) => setTool(e.value)}
                options={toolValues}
                optionLabel='name'
                placeholder='Select a Tool'
              />
            </StyledFormElement>
          )}
          <StyledFormElement>
            <Label>Priority</Label>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorityValues} optionLabel='name' />
          </StyledFormElement>
          {typeLabelValue.includes('due') && (
            <>
              <ActivityRepeatInterval repeatInterval={repeat} setRepeatInterval={setRepeat} />
              <SwitchContainer>
                <Label>Mandatory</Label>
                <InputSwitch checked={mandatory} onChange={(e) => setMandatory(e.value)} />
              </SwitchContainer>
            </>
          )}
          <NotificationOptions>
            <SwitchContainer>
              <Label>Sends push notifications</Label>
              <InputSwitch checked={notifications} onChange={(e) => setNotifications(e.value)} />
            </SwitchContainer>
            <SwitchContainer>
              <Label>Sends emails</Label>
              <InputSwitch checked={emails} onChange={(e) => setEmails(e.value)} />
            </SwitchContainer>
          </NotificationOptions>
        </Form>
        <StyledFormElement></StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onCancelActivity}>Cancel</CancelButton>
        <SaveButton onClick={onSaveActivity}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
