import {getCategoryTree, updateCategoriesOrder} from 'api/chatowl.api';
import CategoryCard from 'components/tools/category-card';
import {useAppDispatch} from 'hooks';
import React, {useEffect, useMemo, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useMutation, useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {sendMessage} from 'store/action-creators';
import styled from 'styled-components';
import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import {DetailButtonLink as EDButtonLink} from '../../../components/tools/main-page-components';

const SubTitle = styled.div`
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  color: #777c7c;
  margin-bottom: 38px;
`;

export const SortToolCategories: React.FC<{title?: string}> = ({title}) => {
  const dispatch = useAppDispatch();

  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories, refetch: reloadCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onSuccess: () => {
        setCategoryOrder(null);
      },
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const [categoryOrder, setCategoryOrder] = useState(null as number[] | null);
  const sortedParentCategories = useMemo(() => {
    const sorted = [...(categoryTree || [])];
    if (categoryOrder) {
      sorted.sort((a, b) => categoryOrder.indexOf(a.id) - categoryOrder.indexOf(b.id));
    } else {
      sorted.sort((a, b) => a.order - b.order);
    }
    return sorted;
  }, [categoryTree, categoryOrder]);
  const {mutateAsync: sendOrderToServer} = useMutation((order: number[]) => updateCategoriesOrder(order), {
    onSuccess: () => {
      reloadCategories();
    },
  });

  useEffect(() => {
    if (categoryOrder) {
      const updateTimeout = setTimeout(() => {
        sendOrderToServer(categoryOrder);
      }, 1000);

      return () => clearTimeout(updateTimeout);
    }
  }, [categoryOrder, sendOrderToServer]);
  const reorder = (startIndex: number, endIndex: number) => {
    setCategoryOrder((oldOrderState) => {
      let oldOrder = oldOrderState;
      if (!oldOrder) {
        const sortedTree = categoryTree || [];
        sortedTree.sort((a, b) => a.order - b.order);
        oldOrder = sortedTree.map((c) => c.id);
      }
      let newOrder = null;
      if (oldOrder) {
        newOrder = [];

        let diff = 0;
        for (let i = 0; i < oldOrder.length; i++) {
          if (i + diff === startIndex) {
            diff++;
          }
          if (i === endIndex) {
            diff--;
            newOrder[i] = oldOrder[startIndex];
          } else {
            newOrder[i] = oldOrder[i + diff];
          }
        }
      }
      return newOrder;
    });
  };

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    reorder(result.source.index, result.destination.index);
  };

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);
  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader style={{width: '85%'}}>
          <PageTitle>Top Categories</PageTitle>
          <EDButtonLink>
            <Link to='/tools/categories/'> &lt; Back to Categories</Link>
          </EDButtonLink>
        </PageHeader>
        <SubTitle>Use drag and drop to sort the top categories.</SubTitle>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{width: '85%'}}>
                {sortedParentCategories.map((node, index) => (
                  <Draggable isDragDisabled={false} key={node.id} draggableId={`${node.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <CategoryCard
                          category={node}
                          onClick={() => {}}
                          loading={loadingCategories}
                          level={0}
                          distanceToParent={0}
                          justTitle
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </PageContainer>
  );
};
