/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef, useContext} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ProgressBar} from 'primereact/progressbar';
import ReactCodeInput from 'react-verification-code-input';
import isEmail from 'validator/lib/isEmail';
import {useHistory} from 'react-router-dom';
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {createUser, getSession, initiateAuth} from 'utils/aws-cognito.utils';
import {AuthContext} from 'contexts/auth.context';

enum LoginStep {
  EMAIL_FORM,
  EMAIL_CODE_REQUEST,
  SMS_CODE_REQUEST,
}

type EmailFormProps = {
  errorMsg: JSX.Element | undefined;
  loading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  value: string;
};

const EmailForm: React.FC<EmailFormProps> = ({errorMsg, loading, onChange, onSubmit, value}) => {
  return (
    <form className='login-form p-fluid' onSubmit={onSubmit}>
      <div className='login-field'>
        <label htmlFor='inputEmail'>Input your email below to receive the access code to your account</label>
        <InputText id='inputEmail' placeholder='Email' onChange={onChange} value={value} required />
      </div>
      <div className='submit-container'>
        {loading ? (
          <ProgressBar className='progress-bar' mode='indeterminate' />
        ) : (
          <Button className='primary-button' label='Continue' type='submit'></Button>
        )}
      </div>
      <div className='text-center'>{errorMsg}</div>
    </form>
  );
};

type VerificationCodeRequestProps = {
  errorMsg: JSX.Element | undefined;
  fields: number;
  inputRef: React.RefObject<ReactCodeInput> | null;
  loading: boolean;
  onChange: (verificationCode: string) => void;
  onComplete: (verificationCode: string) => void;
  onBack: () => void;
  value: string;
};

export const VerificationCodeRequest: React.FC<VerificationCodeRequestProps> = ({
  children,
  errorMsg,
  fields,
  inputRef,
  loading,
  onChange,
  onComplete,
  onBack,
  value,
}) => {
  const values: string[] = value.split('');

  return (
    <form className='login-form p-fluid'>
      <div className='login-field'>
        {children}
        <div className='verification-inputs'>
          <ReactCodeInput
            autoFocus
            fields={fields}
            fieldHeight={50}
            onChange={onChange}
            onComplete={onComplete}
            ref={inputRef}
            type='number'
            values={values}
          />
        </div>
      </div>
      <div className='submit-container'>
        {loading ? (
          <ProgressBar className='progress-bar' mode='indeterminate' />
        ) : (
          <Button className='p-button-link' label='Back' onClick={onBack} />
        )}
      </div>
      <div className='text-center'>{errorMsg}</div>
    </form>
  );
};

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');

  const [emailCode, setEmailCode] = useState('');
  const emailCodeInput: React.RefObject<ReactCodeInput> = useRef(null);

  const [smsCode, setSmsCode] = useState('');
  const smsCodeInput: React.RefObject<ReactCodeInput> = useRef(null);

  const [step, setStep] = useState(LoginStep.EMAIL_FORM);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<JSX.Element | undefined>(undefined);

  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const {setIsAuthenticated} = useContext(AuthContext);

  const history = useHistory();

  /**
   * If user is already logged, go to home
   */
  useEffect(() => {
    getSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          loginOK();
        }
      })
      .catch((exception) => {
        console.error(exception);
      });
  }, []);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const onEmailCodeInputChange = (verificationCode: string) => {
    setEmailCode(verificationCode);
    errorMsg && setErrorMsg(undefined);
  };
  const onSmsCodeInputChange = (verificationCode: string) => {
    setSmsCode(verificationCode);
    errorMsg && setErrorMsg(undefined);
  };

  const onEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isEmail(email)) {
      setErrorMsg(<p className='error-message'>Please enter a valid email.</p>);
      return;
    }
    setLoading(true);
    try {
      const cognitoUser = createUser(email);
      setCognitoUser(cognitoUser);
      await initiateAuth(cognitoUser, email);
      goToEmailCodeRequest();
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      setErrorMsg(
        <p className='error-message'>
          We couldn't send the verification code. <br />
          Please try again in a few minutes.
        </p>
      );
    }
  };

  /***** EMAIL CODE CALLBACKS *****/

  const onEmailCodeInputBack = () => goToEmailForm();

  const onEmailCodeInputSubmit = async (verificationCode: string) => {
    try {
      if (!loading) {
        setLoading(true);
        cognitoUser?.sendCustomChallengeAnswer(verificationCode, {
          onSuccess: onEmailCodeAnswerSuccess,
          onFailure: onEmailCodeAnswerFailure,
          customChallenge: onEmailCodeAnswerCustomChallenge,
        });
      }
    } catch (ex) {
      setLoading(false);
      console.log('onEmailCodeInputSubmit error', ex);
    }
  };

  const onEmailCodeAnswerSuccess = () => {
    setLoading(false);
    loginOK();
  };

  const onEmailCodeAnswerFailure = (err: any) => {
    setLoading(false);
    console.log('emailCodeAnswerFailure', err);
    setErrorMsg(
      <p className='error-message'>
        The verification code is invalid. <br />
        Please try again in a few minutes.
      </p>
    );
    clearEmailCode();
    goToEmailForm();
  };

  const onEmailCodeAnswerCustomChallenge = (challengeParameters: any) => {
    setLoading(false);

    if (challengeParameters.CHALLENGE_TYPE === 'EMAIL_CHALLENGE') {
      setErrorMsg(
        <p className='error-message'>
          The verification code is invalid. <br />
          Please try again.
        </p>
      );
      clearEmailCode();
    } else if (challengeParameters.CHALLENGE_TYPE === 'SMS_CHALLENGE') {
      goToSmsCodeRequest();
    }
  };

  /***** SMS CODE CALLBACKS *****/

  const onSmsCodeInputBack = () => goToEmailForm();

  const onSmsCodeInputSubmit = async (verificationCode: string) => {
    try {
      if (!loading) {
        setLoading(true);
        cognitoUser?.sendCustomChallengeAnswer(verificationCode, {
          onSuccess: onSmsCodeAnswerSuccess,
          onFailure: onSmsCodeAnswerFailure,
          customChallenge: onSmsCodeAnswerCustomChallenge,
        });
      }
    } catch (ex) {
      setLoading(false);
      console.log('onSmsCodeInputSubmit error', ex);
    }
  };

  const onSmsCodeAnswerSuccess = () => {
    setLoading(false);
    loginOK();
  };

  const onSmsCodeAnswerFailure = (err: any) => {
    console.log('onSmsCodeAnswerFailure', err);
    setLoading(false);
    setErrorMsg(
      <p className='error-message'>
        The verification code is invalid. <br />
        Please try again in a few minutes.
      </p>
    );
    clearSmsCode();
    goToEmailForm();
  };

  const onSmsCodeAnswerCustomChallenge = (challengeParameters: any) => {
    setLoading(false);

    if (challengeParameters.CHALLENGE_TYPE === 'EMAIL_CHALLENGE') {
      goToEmailCodeRequest();
    } else if (challengeParameters.CHALLENGE_TYPE === 'SMS_CHALLENGE') {
      setErrorMsg(
        <p className='error-message'>
          The verification code is invalid. <br />
          Please try again.
        </p>
      );
      clearSmsCode();
    }
  };

  const loginOK = () => {
    setIsAuthenticated(true);
    history.replace('/');
  };

  /***** HELPERS *****/

  useEffect(() => {
    if (email.length) {
      setErrorMsg(undefined);
    }
  }, [email]);

  const clearEmailCode = () => {
    setEmailCode('');
    emailCodeInput?.current?.__clearvalues__();
  };

  const clearSmsCode = () => {
    setSmsCode('');
    smsCodeInput?.current?.__clearvalues__();
  };

  const goToEmailForm = () => {
    setEmail('');
    setStep(LoginStep.EMAIL_FORM);
  };

  const goToEmailCodeRequest = () => {
    clearEmailCode();
    setStep(LoginStep.EMAIL_CODE_REQUEST);
  };

  const goToSmsCodeRequest = () => {
    clearSmsCode();
    setStep(LoginStep.SMS_CODE_REQUEST);
  };

  return (
    <div className='login-body'>
      <div className='login-wrapper'>
        <div className='login-panel'>
          <img src='assets/layout/images/logo-dark.svg' className='logo' alt='chatowl-logo' />

          <h2>Log In</h2>
          {step === LoginStep.EMAIL_FORM && (
            <EmailForm errorMsg={errorMsg} loading={loading} onChange={onEmailChange} onSubmit={onEmailSubmit} value={email} />
          )}
          {step === LoginStep.EMAIL_CODE_REQUEST && (
            <VerificationCodeRequest
              errorMsg={errorMsg}
              fields={6}
              loading={loading}
              onBack={onEmailCodeInputBack}
              onChange={onEmailCodeInputChange}
              onComplete={onEmailCodeInputSubmit}
              inputRef={emailCodeInput}
              value={emailCode}
            >
              <div className='form-message'>
                <p>
                  We sent your one-time code to <strong>{email}</strong>
                </p>
                <p>Insert your 6 digit code below:</p>
              </div>
            </VerificationCodeRequest>
          )}
          {step === LoginStep.SMS_CODE_REQUEST && (
            <VerificationCodeRequest
              errorMsg={errorMsg}
              fields={4}
              loading={loading}
              onBack={onSmsCodeInputBack}
              onChange={onSmsCodeInputChange}
              onComplete={onSmsCodeInputSubmit}
              inputRef={smsCodeInput}
              value={smsCode}
            >
              <p>Now, enter the 4-digit SMS verification code that we sent to your phone</p>
            </VerificationCodeRequest>
          )}
        </div>
        <div className='login-image'>
          <div className='login-image-content'>
            <h1>Access to your</h1>
            <h1>ChatOwl Account</h1>
            <h3>
              Lorem ipsum dolor sit amet, consectetur <br />
              adipiscing elit. Donec posuere velit nec enim <br />
              sodales, nec placerat erat tincidunt.
            </h3>
          </div>
          <div className='image-footer'>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div className='icons'>
              <i className='pi pi-github'></i>
              <i className='pi pi-twitter'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
