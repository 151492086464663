import {Interaction} from 'components/message';
import React, {useState, useEffect} from 'react';
import {getClientInteractions} from 'api/chatowl.api';
import Skeleton from 'react-loading-skeleton';
import {BoxMessageContainer} from '../styles';
import {ScrollPanel} from 'primereact/scrollpanel';

type ClientMessagesProps = {client: ClientDto};

const interactionSkeleton = [1, 2, 3, 4, 5, 6];

export const ClientMessages: React.FC<ClientMessagesProps> = ({client}) => {
  const [interactions, setInteractions] = useState<InteractionDTO[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [_, setCurrentSelectedInteractionId] = useState('');

  useEffect(() => {
    const fetchClientHistory = async () => {
      try {
        const resInteractions = (await getClientInteractions('61a7eb5a-3318-11eb-adc1-0242ac120050')) as any;
        const resInts = resInteractions.data.data as Array<InteractionDTO>;
        setInteractions(resInts);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchClientHistory();
  }, []);

  return (
    <>
      <ScrollPanel style={{width: '100%', height: '79vh'}}>
        {isLoading ? (
          interactionSkeleton.map((_) => (
            <BoxMessageContainer style={{height: '30%'}}>
              <Skeleton style={{height: '100%', width: '100%'}} />
            </BoxMessageContainer>
          ))
        ) : interactions?.length ? (
          interactions
            .filter((i) => i.counselorAnswer)
            .map((i) => <Interaction interaction={i} setCurrentSelectedInteractionId={setCurrentSelectedInteractionId} />)
        ) : (
          <h5 style={{textAlign: 'center', margin: '5% 5% 5% 5%'}}>No messages found...</h5>
        )}
      </ScrollPanel>
    </>
  );
};
