import React, {useEffect, useState} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  Form,
  PageFooter,
  CancelButton,
  SaveButton,
  ArchiveButton,
  CursorLoading,
  Label,
} from 'components/page';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {FormElement, StyledFormElement} from 'components/form-element';
import {useParams} from 'react-router-dom';

import {useAppSelector, useAppDispatch} from 'hooks';
import {selectSession, sendMessage, sendUndoToast, unselectSession} from 'store/action-creators';
import {RootState} from '../../../index';
import {shallowEqual} from 'react-redux';
import SpinnerLoading from 'components/spinner-loading';
import {getCategoryTree, listSessions} from '../../../api/chatowl.api';

import {DropdownType} from './session-detail';

import {getFlowsLabels, addSession, updateImage, editSession} from 'api/chatowl.api';
import MediaChooser from 'components/media-chooser';
import {repeatLimitDropdown, resumeLimitDropdown} from '../exercises/exercise-detail';
import {InputSwitch} from 'primereact/inputswitch';
import {isAnyStringEmpty} from 'utils/helpers';
import CategorySelector from 'components/tools/category-selector';
import {useQuery} from 'react-query';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const passToDropdownType = (labels: Label[]): DropdownType[] => {
  let dropdown: DropdownType[] = [];
  labels?.map((label) => {
    const {name, value} = label;
    const newObj = {label: name, value};
    dropdown = [...dropdown, newObj];
    return label;
  });
  return dropdown;
};

const imagesTypes: CropType[] = ['full_width_regular'];
type MediaDetailsFormType = 'add' | 'edit';

export const AddSession: React.FC<{title?: string}> = ({title}) => {
  const params = useParams<{formType: MediaDetailsFormType; sessionId: string}>();
  const formType = params.formType;
  const sessionId = +params.sessionId || null;
  let selectedSession: SessionDto | null = useAppSelector(
    (state: RootState) => state.tools.sessions.selectedSession,
    shallowEqual
  );
  const [sessionName, setSessionName] = useState<string>('');
  const [sessionDescription, setSessionDescription] = useState<string>('');
  const [sessionCategories, setSessionCategories] = useState<number[]>([]);
  const [sessionFlow, setSessionFlow] = useState<number>(0);
  const [isRepeatable, setIsRepeatable] = useState(false);
  const [repeatLimit, setRepeatLimit] = useState(0);
  const [resumeLimit, setResumeLimit] = useState<number>(0);
  const [flowsLabels, setFlowsLabels] = useState<DropdownType[]>([]); // Dropdown options

  const [saving, setSaving] = useState(false);
  const [mediaId, setMediaId] = useState<number | undefined>(0);
  const [newCrops, setNewCrops] = useState<Crop[]>([]);

  const [userClickedArchive, setUserClickedArchive] = useState<boolean>(false);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});
  const [sessions, setSessions] = useState<SessionDto[]>([]);
  const [sessionsLoading, setSessionsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const validateSave = (): boolean => !!sessionDescription && !!sessionName && mediaId !== 0 && sessionFlow !== 0;

  const editing: boolean = formType === 'edit' && !!selectedSession;

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = (await getFlowsLabels()) as any;
        const sessionsResponse: Label[] = response.data ? response.data.data : [];
        const DropdownType = passToDropdownType(sessionsResponse);
        setFlowsLabels(DropdownType);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFlows();
  }, []);

  useEffect(() => {
    if (selectedSession && formType === 'edit') {
      setSessionName(selectedSession.title);
      setSessionDescription(selectedSession.description);
      setSessionCategories(selectedSession.toolCategories?.map((category) => category.id));
      setSessionFlow(selectedSession.flowId);
      setIsRepeatable(selectedSession.isRepeatable);
      setRepeatLimit(selectedSession.repeatLimit || 0);
      setResumeLimit(selectedSession.resumeLimit);
      setMediaId(selectedSession.mediaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = (await listSessions({withDeleted: false, sortBy: 'id'})) as any;
        const sessionsResponse: SessionDto[] = response.data ? response.data.data : [];
        setSessions(sessionsResponse);
        const sessionSelected = sessionsResponse.find((session: any) => session.id === sessionId);
        if (sessionSelected) {
          dispatch(selectSession(sessionSelected));
          setSessionName(sessionSelected.title);
          setSessionDescription(sessionSelected.description);
          setSessionCategories(sessionSelected.toolCategories?.map((category) => category.id));
          setSessionFlow(sessionSelected.flowId);
          setIsRepeatable(sessionSelected.isRepeatable);
          setRepeatLimit(sessionSelected.repeatLimit || 0);
          setResumeLimit(sessionSelected.resumeLimit);
          setMediaId(sessionSelected.mediaId);
        }
      } catch (error) {
        throw error; // TODO
      } finally {
        setSessionsLoading(false);
      }
    };
    if (sessionId) {
      setSessionsLoading(true);
      fetchSessions();
    }
  }, []);

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onCancelSession = () => {
    history.push('/tools/sessions');
  };

  const onArchiveSession = async () => {
    if (!userClickedArchive) setUserClickedArchive(true);
    else {
      setSaving(true);
      const editedSession: UpdateSessionRequest = {
        isArchived: !selectedSession?.isArchived,
      };
      try {
        let updatedSession = (await editSession(selectedSession?.id!, editedSession)) as any;
        if (updatedSession.response.status >= 200 && updatedSession.response.status < 300) {
          updatedSession = updatedSession.data.data;
          dispatch(unselectSession());
          !selectedSession?.isArchived && dispatch(sendUndoToast({idTool: selectedSession?.id!, toolType: 'session'}));
          history.push('/tools/sessions');
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          setSaving(false);
        }
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const onSaveSession = async () => {
    if (validateSave() && !isAnyStringEmpty([sessionName, sessionDescription])) {
      let errorEditingMedia = false;
      setSaving(true);
      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = newCrops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = newCrops!.find((crop) => crop.type === 'full_screen_portrait');
        const squared = newCrops!.find((crop) => crop.type === 'squared');
        const crops: UpdateMediaImageRequest = {
          fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
          fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
          squared: squared ? squared.blob : undefined,
          crops: newCrops.map((crop) => ({type: crop.type, data: crop.data})),
          settings,
        };
        const mediaImage = (await updateImage(mediaId!, crops)) as any;
        if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          setSaving(false);
          errorEditingMedia = true;
        }
      }
      if (!errorEditingMedia) {
        if (!editing) {
          const newSession: CreateSessionRequest = {
            title: sessionName,
            description: sessionDescription,
            language: 'en-US',
            isRepeatable,
            repeatLimit: isRepeatable ? repeatLimit : undefined,
            resumeLimit,
            categories: sessionCategories,
            startFlowId: sessionFlow,
            mediaId: mediaId!,
          };
          try {
            let createdSession = (await addSession(newSession)) as any;
            if (createdSession.response.status >= 200 && createdSession.response.status < 300) {
              createdSession = createdSession.data.data;
              dispatch(selectSession(createdSession));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Session created successfully',
                  detail: createdSession.title,
                })
              );
              history.push('/tools/sessions');
            } else {
              if (sessionDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Session description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        } else {
          //EDITING
          const editedSession: UpdateSessionRequest = {
            title: sessionName,
            description: sessionDescription,
            language: 'en-US',
            repeatLimit: isRepeatable ? repeatLimit : undefined,
            isRepeatable,
            resumeLimit,
            categories: sessionCategories,
            startFlowId: sessionFlow,
            mediaId: mediaId,
          };

          try {
            let updatedSession = (await editSession(selectedSession?.id!, editedSession)) as any;
            if (updatedSession.response.status >= 200 && updatedSession.response.status < 300) {
              updatedSession = updatedSession.data.data;
              dispatch(selectSession(updatedSession));
              dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Session updated successfully'}));
              history.push('/tools/sessions');
            } else {
              if (sessionDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Session description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        }
      }
    } else {
      dispatch(
        sendMessage({
          severity: 'warn',
          summary: 'WARNING',
          detail: 'Name, Description, Flow, Category and Image can not be empty.',
        })
      );
    }
  };

  return (
    <CursorLoading disable={saving}>
      {sessionsLoading ? (
        <SpinnerLoading show />
      ) : (
        <PageContainer>
          <PageHeader>{editing ? <PageTitle>Edit Session</PageTitle> : <PageTitle>Add Session</PageTitle>}</PageHeader>
          <PageBody>
            <Form>
              <FormElement
                type='input'
                label='Name'
                value={sessionName}
                placeholder='Session name'
                onChange={(inputValue) => setSessionName(inputValue)}
              />
              <FormElement
                type='text-area'
                label='Description'
                value={sessionDescription}
                placeholder='Session description'
                onChange={(inputValue) => setSessionDescription(inputValue)}
              />
              <StyledFormElement>
                <label htmlFor={`inputCategory`}>{'Category'}</label>
                <CategorySelector
                  loading={loadingCategories}
                  selected={sessionCategories}
                  categories={categoryTree}
                  onChange={setSessionCategories}
                  error={!!errorLoadingCategories}
                />
              </StyledFormElement>
              <StyledFormElement>
                <label htmlFor={`input Flow`}>{'Flow'}</label>
                <Dropdown
                  value={sessionFlow}
                  options={flowsLabels}
                  onChange={(e) => setSessionFlow(e.value)}
                  placeholder='Select Flow'
                />
              </StyledFormElement>
              <StyledFormElement style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Label>Repeating</Label>
                <InputSwitch checked={isRepeatable} onChange={(e) => setIsRepeatable(e.value)} />
              </StyledFormElement>
              <StyledFormElement isVisible={isRepeatable} withHelpText>
                <label
                  title='After the client finished or canceled a session they have to wait for this time until they can use the tool again.'
                  htmlFor={`input Repeat Limit`}
                >
                  {'Repeat Limit'}
                </label>
                <Dropdown
                  value={repeatLimit}
                  options={repeatLimitDropdown}
                  onChange={(e) => setRepeatLimit(e.value)}
                  placeholder='Select Repeat Limit'
                />
              </StyledFormElement>
              <StyledFormElement withHelpText>
                <label
                  title='Time limit until when the tool can be resumed if the client paused in the middle. After this limit the tool will start again from the beginning.'
                  htmlFor={`input Resume Limit`}
                >
                  {'Resume Limit'}
                </label>
                <Dropdown
                  value={resumeLimit}
                  options={resumeLimitDropdown}
                  onChange={(e) => setResumeLimit(e.value)}
                  placeholder='Select Resume Limit'
                />
              </StyledFormElement>
            </Form>
            <StyledFormElement style={{width: '100%'}}>
              {!editing ? (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                  actualName={sessionName}
                  changeSettings={setSettings}
                />
              ) : (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  media={selectedSession!.media}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                  actualName={sessionName}
                  changeSettings={setSettings}
                />
              )}
            </StyledFormElement>
          </PageBody>
          <PageFooter>
            {editing &&
              (!selectedSession!.isArchived ? (
                <ArchiveButton onClick={onArchiveSession} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Archive ?' : 'Archive'}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={onArchiveSession} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Unarchive ?' : 'Unarchive'}
                </ArchiveButton>
              ))}

            <CancelButton onClick={onCancelSession}>
              <Link to='/tools/sessions/'>Cancel</Link>
            </CancelButton>
            <SaveButton onClick={onSaveSession} disable={saving} disabled={saving}>
              {saving ? 'Saving...' : 'Save'}
            </SaveButton>
          </PageFooter>
        </PageContainer>
      )}
    </CursorLoading>
  );
};
