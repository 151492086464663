import {CancelButton, PageFooter, SaveButton, ArchiveButton as DeleteButton} from 'components/page';
import {FormElement, StyledFormElement} from 'components/form-element';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ImageCropperManager} from '../../../../components/crop-manager';
import {createImage, updateImage, deleteMedia} from 'api/chatowl.api';
import {AddInput, FormContainer, InputButton, InputComplete, PageBody, Tag} from '../../styles';
import imageCompression from 'browser-image-compression';
import {useAppSelector, useAppDispatch} from '../../../../hooks';
import {sendMessage, unselectMedia, showLoading, hideLoading} from '../../../../store/action-creators';
import {RootState} from '../../../../index';
import {shallowEqual} from 'react-redux';
import {isAnyStringEmpty} from 'utils/helpers';

function validateSave(name: string, crops: number, imagesCrops: number): boolean {
  return !!name && (!!crops || !!imagesCrops);
}

export type FlowType = 'true' | 'false';

export const ImageFormContainer: React.FC<{
  formType: 'add' | 'edit';
  mediaName?: string;
  onSuccess: (mediaId: number, mediaName: string) => void;
  onError: () => void;
  name: string;
  setName: (name: string) => void;
  tags: {
    list: string[] | undefined;
    push: (newTag: string) => void;
    drop: (deleted: string) => void;
    initTag: (tagList: string[]) => void;
  };
  originalImage: Blob | undefined;
  setOriginalImage: (image: Blob | undefined) => void;
  crops: Crop[];
  setCrops: (Crops: Crop[]) => void;
  imagesCrop: CropDto[];
  setImagesCrop: (iCrop: CropDto[]) => void;
  originalImageURL: string | undefined;
  setOriginalImageURL: (imageURL: string | undefined) => void;
}> = ({
  formType,
  mediaName: nameFromMediaChooser,
  onSuccess,
  onError,
  name,
  setName,
  tags,
  originalImage,
  setOriginalImage,
  crops,
  setCrops,
  imagesCrop,
  setImagesCrop,
  originalImageURL,
  setOriginalImageURL,
}) => {
  const history = useHistory();
  const [currentTag, setCurrentTag] = useState<Tag>();
  const [settings, setSettings] = useState<ImageSettings | undefined>({brightness: 0, contrast: 0});
  const [imageforSettingsURL, setImageForSettingsURL] = useState<string>();
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);

  // const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);

  useEffect(() => {
    if (formType === 'edit' && selectedMedia) {
      setName(selectedMedia.name);
      tags.initTag(selectedMedia.tags!);
      setOriginalImageURL(selectedMedia.url);
      setImageForSettingsURL(selectedMedia.url);
      setImagesCrop(selectedMedia.crops);
      selectedMedia.settings && setSettings(JSON.parse(selectedMedia.settings));
    } else {
      nameFromMediaChooser && setName(nameFromMediaChooser);
    }
  }, [selectedMedia]);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
  };

  const clearForm = () => {
    setName('');
    setOriginalImage(undefined);
    setOriginalImageURL(undefined);
    setImageForSettingsURL(undefined);
    setCrops([]);
    setImagesCrop([]);
    setUserClickedDelete(false);
    tags.initTag([]);
    dispatch(unselectMedia());
  };

  const sendImage = async () => {
    if (validateSave(name, crops.length, imagesCrop.length) && !isAnyStringEmpty([name])) {
      dispatch(showLoading());

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 570,
      };
      const file: any = originalImage;
      const thumbnail = file && (await imageCompression(file, options));

      if (formType === 'add') {
        const newImage: CreateMediaImageRequest = {
          name,
          originalImage: originalImage!,
          fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
          fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          squared: crops!.find((crop) => crop.type === 'squared')!.blob,
          crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
          tags: tags.list,
          settings,
          thumbnail,
        };
        try {
          const mediaImage = (await createImage(newImage)) as any;
          if (mediaImage.response.status >= 200 && mediaImage.response.status < 300) {
            onSuccess(mediaImage.data.data.id, name);
            clearForm();
          } else {
            onError();
          }
        } catch (e) {
          onError();
        } finally {
          dispatch(hideLoading());
        }
      } else {
        //EDITING
        const fullWidthTall = crops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = crops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = crops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = crops!.find((crop) => crop.type === 'full_screen_portrait');
        const squared = crops!.find((crop) => crop.type === 'squared');

        let newImage: UpdateMediaImageRequest;
        if (crops.length) {
          newImage = {
            name,
            tags: tags.list,
            crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
            originalImage: originalImage!,
            fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
            squared: squared ? squared.blob : undefined,
            settings,
            thumbnail,
          };
        } else {
          newImage = {
            name,
            tags: tags.list,
            crops: [],
          };
        }
        try {
          const mediaImage = (await updateImage(selectedMedia!.id, newImage)) as any;
          if (mediaImage.response.status >= 200 && mediaImage.response.status < 300) {
            onSuccess(mediaImage.data.data.id, name);
          } else {
            onError();
          }
        } catch (e) {
          onError();
        } finally {
          dispatch(hideLoading());
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name and Image can not be empty.'}));
    }
  };

  const onCancelImageDetails = () => {
    history.push('/media-bank');
    dispatch(unselectMedia());
  };

  const onDeleteImage = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      try {
        dispatch(showLoading());
        const res: any = await deleteMedia(selectedMedia!.id);
        dispatch(unselectMedia());
        res.response.status === 400
          ? res.data.error.includes('MEDIA_IS_CURRENTLY_IN_USE')
            ? dispatch(
                sendMessage({
                  severity: 'error',
                  summary: 'ERROR',
                  detail: 'Image can not be deleted because it is currently in use',
                })
              )
            : dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}))
          : dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Image successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  return (
    <>
      <PageBody>
        <FormContainer>
          <FormElement type='input' label='Name' value={name ? name : ''} placeholder='Media name' onChange={setName} />
          <StyledFormElement>
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ',') {
                    e.preventDefault();
                    currentTag && !isAnyStringEmpty([currentTag]) && tags.push(currentTag);
                  }
                }}
              />
              <AddInput onClick={() => currentTag && !isAnyStringEmpty([currentTag]) && tags.push(currentTag)}>Add</AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag id={`${tag}_image`} key={`${tag}_image`} color={'#008C8C'} onClick={() => tags.drop(tag)}>
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </FormContainer>
        <StyledFormElement>
          {formType === 'edit' ? (
            <ImageCropperManager
              mediaType='image'
              setOriginalImage={setOriginalImage}
              addCrop={addCrop}
              imagesCrop={imagesCrop}
              image={originalImageURL}
              imageForSettings={imageforSettingsURL}
              settings={settings}
              setSettings={setSettings}
            />
          ) : (
            <ImageCropperManager
              settings={settings}
              setSettings={setSettings}
              mediaType='image'
              setOriginalImage={setOriginalImage}
              addCrop={addCrop}
              image={originalImageURL}
              originalImage={originalImage}
            />
          )}
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteImage} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelImageDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={sendImage}>Save</SaveButton>
      </PageFooter>
    </>
  );
};
