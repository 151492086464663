import React, {useEffect, useState} from 'react';
import {
  Image,
  CardThumbnail,
  CardThumbnailVideo,
  FilterContainer,
  SelectImageBox,
  CloseModal,
  modalStyle,
  RedirectModalButtonsContainer,
  RedirectModalSelectCancelButton,
  RedirectModalSelectYesButton,
} from './styles';
import Modal from 'react-modal';
import SearchBar from 'components/search-bar';
import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button';
import {listMedias} from 'api/chatowl.api';
import {ImageCropperManager} from 'components/crop-manager';
import {capitalize} from 'utils/helpers';
import VideoPlayer from 'components/media/video-player';
import AudioPlayer from 'components/media/audio-player';
import {MediaType} from 'components/media-card/style';
import {StyledFormElement} from 'components/form-element';
import {useAppDispatch} from 'hooks';
import {sendMessage} from 'store/action-creators';
import {ADD_MEDIA_FLOW_STATUS} from 'utils/constants';
import {AddButton} from 'pages/therapy-programs/manage-weeks/styles';

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '700px',
    height: '700px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
    zIndex: 3,
  },
};

type MediaChooserProps = {
  weeks?: boolean;
  onChooseMedia: (mediaId: number) => void;
  mediaType: MediaType;
  addCrop?: (newCrop: Crop) => void;
  media?: MediaDto;
  imagesTypes?: CropType[];
  actualName?: string;
  smallSize?: boolean;
  label?: string;
  changeSettings?: (setting: ImageSettings) => void;
};

export const MediaChooser: React.FC<MediaChooserProps> = ({
  weeks,
  onChooseMedia,
  media,
  mediaType,
  addCrop,
  imagesTypes,
  actualName,
  smallSize,
  label,
  changeSettings,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hideUsed, setHideUsed] = useState(true);
  const [medias, setMedias] = useState<MediaDto[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<MediaDto>();
  const [newMedia, setNewMedia] = useState<MediaDto>();
  const [windowAddMedia, setWindowAddMedia] = useState<Window | null>(null);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const [idMediaCreated, setIdMediaCreated] = useState<number>();
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedMedia(media);
    setNewMedia(media);
    media?.settings && setSettings(media?.settings);
  }, [media]);

  useEffect(() => {
    changeSettings && changeSettings(settings);
  }, [settings]);

  useEffect(() => {
    idMediaCreated && setSelectedMedia(medias?.find((media) => media.id === idMediaCreated));
    idMediaCreated && onChooseMedia(idMediaCreated);
    const element = document.getElementById(`${idMediaCreated}_media`);
    element?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }, [idMediaCreated, medias]); //Effect that makes posible auto select media recently created.

  const Card = (mediaAct: MediaDto) => {
    const isSelected = selectedMedia && mediaAct.id === selectedMedia.id;

    const selectMedia = () => {
      (!selectedMedia || !isSelected) && setSelectedMedia(mediaAct);
      onChooseMedia(mediaAct.id);
    };
    return (
      <div key={mediaAct.id + '_media'} title={mediaAct.name} id={mediaAct.id + '_media'}>
        {mediaAct.type === 'image' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia} isSelected={isSelected}>
              <CardThumbnail
                key={mediaAct.id}
                width='100%'
                height='100%'
                src={mediaAct?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
              />
            </Image>
            <div style={{marginLeft: '2%', fontWeight: 'bold'}}>
              {mediaAct.name} {mediaAct.tags?.length ? ` - ${mediaAct.tags}` : ''}
            </div>
          </div>
        )}
        {mediaAct.type === 'audio' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia} isSelected={isSelected}>
              <CardThumbnailVideo
                width='100%'
                height='100%'
                poster={mediaAct?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                onMouseOver={(event) => event.currentTarget.play()}
                onMouseOut={(event) => event.currentTarget.pause()}
                key={mediaAct.id}
              >
                <source src={mediaAct.url} type='audio/mp3'></source>
                Your browser does not support the video tag.
              </CardThumbnailVideo>
              <MediaType>
                <svg width='27' height='30' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M13.6547 22C14.8547 22 15.7768 21.101 15.7768 19.9149V2.1101C15.7768 0.92395 14.8547 0 13.6547 0C12.9095 0 12.3032 0.274688 11.52 0.998865L6.79579 5.34393C6.73263 5.39387 6.66947 5.43133 6.59368 5.43133H3.30947C1.17474 5.43133 0 6.64245 0 8.82747V13.185C0 15.37 1.17474 16.5812 3.30947 16.5812H6.58105C6.65684 16.5812 6.72 16.5936 6.79579 16.6561L11.52 21.0261C12.2653 21.7128 12.8716 22 13.6547 22ZM20.0084 16.8683C20.8042 17.3428 21.7768 17.1555 22.2695 16.4688C23.3558 14.9705 24 13.0352 24 10.9875C24 8.92735 23.3558 7.00454 22.2695 5.49376C21.7768 4.81952 20.8042 4.61975 20.0084 5.1067C19.1495 5.61862 18.9347 6.62997 19.6547 7.8286C20.2232 8.7151 20.5389 9.82633 20.5389 10.9875C20.5389 12.1487 20.2232 13.2474 19.6547 14.1464C18.9347 15.3451 19.1495 16.3439 20.0084 16.8683Z'
                    fill='white'
                  />
                </svg>
              </MediaType>
            </Image>
            <div style={{marginLeft: '2%', fontWeight: 'bold'}}>
              {mediaAct.name} {mediaAct.tags?.length ? ` - ${mediaAct.tags}` : ''}
            </div>
          </div>
          //MediaType and onMouseOver and onMouseOut were added to be compatible with mozilla.
          //In Chrome, old approach (with "controls") has better UX.
        )}
        {mediaAct.type === 'video' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia} isSelected={isSelected}>
              <CardThumbnailVideo
                width='100%'
                height='100%'
                poster={mediaAct?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                onMouseOver={(event) => event.currentTarget.play()}
                onMouseOut={(event) => event.currentTarget.pause()}
                key={mediaAct.id}
              >
                <source src={mediaAct.url} type='video/mp4'></source>
                Your browser does not support the video tag.
              </CardThumbnailVideo>
              <MediaType>
                <svg width='27' height='30' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1.56438 19.7544C2.02896 19.7544 2.42385 19.57 2.88842 19.3049L16.4308 11.5369C17.3948 10.9722 17.7316 10.6034 17.7316 9.99252C17.7316 9.38169 17.3948 9.01288 16.4308 8.45967L2.88842 0.680146C2.42385 0.415066 2.02896 0.242188 1.56438 0.242188C0.704917 0.242188 0.170654 0.8876 0.170654 1.89029V18.0948C0.170654 19.0974 0.704917 19.7544 1.56438 19.7544Z'
                    fill='white'
                  />
                </svg>
              </MediaType>
            </Image>
            <div style={{marginLeft: '2%', fontWeight: 'bold', width: '100%', overflow: 'hidden'}}>
              {mediaAct.name} {mediaAct.tags?.length ? ` - ${mediaAct.tags}` : ''}
            </div>
          </div>
          //MediaType and onMouseOver and onMouseOut were added to be functional in mozilla.
          //In Chrome, old approach (with "controls") has better UX.
        )}
      </div>
    );
  };

  const EmptyCard = () => {
    return (
      <div className='p-col-fixed'>
        <Image>{hasMore && <AddButton onClick={() => fetchMedias(currentPage + 1)}>Load more</AddButton>}</Image>
      </div>
    );
  };

  const onOpenModal = () => {
    fetchMedias(0);
    setIsOpen(true);
  };

  const onCancelModal = () => {
    setSelectedMedia(newMedia);
    setIsOpen(false);
  };

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchMedias = async (page: number) => {
    let mediaList: MediaDto[] = page === 0 ? [] : medias;
    const isInUse: false | undefined = hideUsed ? false : undefined;
    setCurrentPage(page);
    try {
      const response = (await listMedias(page, 6, searchValue, 'createdAt', 'desc', '', undefined, mediaType, isInUse)) as any;
      const mediasResponse = response.data ? response.data.data : [];
      mediaList = mediaList.concat(mediasResponse.result);
      setMedias(mediaList);
      setHasMore(mediasResponse.total > mediaList.length);
    } catch (error) {
      console.error(error);
      clearMediaList();
    }
  };

  const clearMediaList = () => {
    setMedias([]);
    setCurrentPage(0);
    setHasMore(false);
  };

  const onChangeHideUsedValue = (e: any) => {
    setHideUsed(e.checked);
  };

  useEffect(() => {
    fetchMedias(0);
  }, [hideUsed]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchMedias(0);
    }, 600);

    return () => clearTimeout(delay);
  }, [searchValue]);

  const onClickNewMedia = () => {
    try {
      localStorage.setItem(ADD_MEDIA_FLOW_STATUS, 'adding');
      actualName !== undefined && localStorage.setItem('mediaName', actualName);
      localStorage.setItem('mediaType', mediaType ? mediaType : '');
    } catch (e) {
      console.error(e);
    }
    setWindowAddMedia(
      window.open('/media-bank/medias/add?source=media-chooser&lock-others-media-types=true', 'popup', 'width=1900,height=1000')
    );
    windowAddMedia && windowAddMedia.focus();
  };

  window.onstorage = async (e: StorageEvent) => {
    if (e.key === ADD_MEDIA_FLOW_STATUS) {
      if (e.newValue === 'success') {
        const mediaName = localStorage.getItem('mediaName');
        mediaName && setSearchValue(mediaName);
        fetchMedias(0);
        const mediaId = localStorage.getItem('mediaId');
        mediaId && setIdMediaCreated(+mediaId);
        dispatch(sendMessage({summary: `${capitalize(mediaType ? mediaType : '')} created successfully`, severity: 'success'}));
        windowAddMedia?.close(); //close Add Media tab.
      } else if (e.newValue === 'error') {
        dispatch(sendMessage({summary: 'Something went wrong creating media', severity: 'error'}));
        windowAddMedia?.close(); //close Add Media tab.
      }
    }
  }; //Listen to storage change. When addMedia is finished, an event is triggered and handled here.

  const toggleRedirectModal = () => {
    setIsRedirectModalOpen(!isRedirectModalOpen);
  };

  const onSelectCancelRedirectModal = () => {
    toggleRedirectModal();
  };

  const onSelectYesRedirectModal = () => {
    onClickNewMedia();
    toggleRedirectModal();
  };

  const onClickNext = () => {
    setIsOpen(false);
    setSelectedMedia(selectedMedia);
    setNewMedia(selectedMedia);
  };

  return (
    <>
      {selectedMedia ? (
        <>
          <StyledFormElement>
            <ImageCropperManager
              mediaType={mediaType}
              addCrop={addCrop}
              image={newMedia?.originalImageUrl || newMedia?.thumbnailUrl}
              imagesCrop={newMedia?.crops}
              onUpdateImage={onOpenModal}
              settings={settings}
              setSettings={setSettings}
              imagesTypes={imagesTypes}
              imageForSettings={selectedMedia?.originalImageUrl || selectedMedia?.thumbnailUrl}
              weeks={weeks}
              label={label}
            >
              <SelectImageBox onClick={onOpenModal}>
                <p>Select an image</p>
                <Button className='p-button-outlined p-mr-2 p-mb-2'> Select an image </Button>
              </SelectImageBox>
            </ImageCropperManager>
          </StyledFormElement>

          {selectedMedia.type === 'audio' && (
            <>
              <StyledFormElement>
                <label>Media File</label>
                <div>
                  <AudioPlayer audioSrc={selectedMedia.url} key={selectedMedia.url} />
                </div>
              </StyledFormElement>
            </>
          )}

          {selectedMedia.type === 'video' && (
            <>
              <StyledFormElement>
                <label>Media File</label>
                <div>
                  <VideoPlayer
                    videoSrc={selectedMedia.url}
                    key={selectedMedia.url}
                    poster={selectedMedia?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                  />
                  {selectedMedia.portraitVideoUrl && (
                    <VideoPlayer
                      videoSrc={selectedMedia.portraitVideoUrl}
                      key={selectedMedia.url}
                      poster={selectedMedia?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                    />
                  )}
                </div>
              </StyledFormElement>
            </>
          )}
        </>
      ) : (
        //Agregar Media
        <>
          <StyledFormElement>
            {label ? (
              <label style={{fontSize: '12px', fontWeight: 'bold', color: '#6C757D'}}>{label}</label>
            ) : (
              <label>{mediaType !== 'image' ? 'Media File' : 'Image'}</label>
            )}
            <SelectImageBox onClick={onOpenModal} smallSize={smallSize}>
              <p>
                Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType ? mediaType : '')}
              </p>
              <Button className='p-button-outlined p-mr-2 p-mb-2'>
                Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType ? mediaType : '')}{' '}
              </Button>
            </SelectImageBox>
          </StyledFormElement>
        </>
      )}

      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        // onRequestClose={onCancelModal}
        contentLabel='Select image'
        style={style}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModal onClick={onCancelModal}>x</CloseModal>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <h5>
            Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType ? mediaType : '')}
          </h5>
        </div>
        <FilterContainer>
          <SearchBar value={searchValue} onChange={onChangeSearchValue} />
          <div>
            <Checkbox checked={hideUsed} onChange={onChangeHideUsedValue} />
            <label style={{marginLeft: '8px'}}>Hide in use</label>
          </div>
        </FilterContainer>
        <div className='p-grid p-justify-center' style={{height: '70%', overflow: 'auto'}}>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div className='p-col-fixed' onClick={toggleRedirectModal} style={{flex: '50%'}}>
              <Image style={{backgroundColor: '#e8e8e8'}}>
                <div style={{marginTop: '25%'}}>
                  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M16.4688 31.4062C25.0469 31.4062 32.1406 24.3125 32.1406 15.7344C32.1406 7.15625 25.0312 0.0625 16.4531 0.0625C7.875 0.0625 0.796875 7.15625 0.796875 15.7344C0.796875 24.3125 7.875 31.4062 16.4688 31.4062ZM16.4688 29.3906C8.90625 29.3906 2.82812 23.2969 2.82812 15.7344C2.82812 8.17188 8.89062 2.09375 16.4531 2.09375C24.0156 2.09375 30.1094 8.17188 30.1094 15.7344C30.125 23.2969 24.0156 29.3906 16.4688 29.3906ZM16.4375 23.1562C17.0781 23.1562 17.4688 22.7188 17.4688 22.0469V16.75H22.9219C23.5781 16.75 24.0469 16.375 24.0469 15.7656C24.0469 15.125 23.6094 14.7344 22.9219 14.7344H17.4688V9.26562C17.4688 8.57812 17.0781 8.14062 16.4375 8.14062C15.8125 8.14062 15.4531 8.59375 15.4531 9.26562V14.7344H9.98438C9.3125 14.7344 8.85938 15.125 8.85938 15.7656C8.85938 16.375 9.34375 16.75 9.98438 16.75H15.4531V22.0469C15.4531 22.7031 15.8125 23.1562 16.4375 23.1562Z'
                      fill='#2C98F0'
                    />
                  </svg>
                </div>
              </Image>
            </div>
            {medias && medias.length ? (
              medias.map((media) => <div style={{flex: '50%'}}>{Card(media)}</div>)
            ) : (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {hideUsed
                  ? `No ${capitalize(mediaType ? mediaType : '') + 's'}. Try show medias in use or add one.`
                  : `No ${capitalize(mediaType ? mediaType : '') + 's'}. Add one.`}
              </div>
            )}
          </div>
          <EmptyCard />
        </div>
        <RedirectModalButtonsContainer>
          <RedirectModalSelectCancelButton onClick={onCancelModal}>Cancel</RedirectModalSelectCancelButton>
          <RedirectModalSelectYesButton onClick={onClickNext}>Select</RedirectModalSelectYesButton>
        </RedirectModalButtonsContainer>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isRedirectModalOpen}
        onRequestClose={toggleRedirectModal}
        contentLabel='Redirect to Add Media.'
        style={modalStyle}
        shouldCloseOnOverlayClick={false}
      >
        <h5 style={{textAlign: 'center'}}>You will be redirected to Add Media</h5>
        <RedirectModalButtonsContainer>
          <RedirectModalSelectCancelButton onClick={onSelectCancelRedirectModal}>Cancel</RedirectModalSelectCancelButton>
          <RedirectModalSelectYesButton onClick={onSelectYesRedirectModal}>Go to Add Media</RedirectModalSelectYesButton>
        </RedirectModalButtonsContainer>
      </Modal>
    </>
  );
};
export default MediaChooser;
