import {CognitoUserSession} from 'amazon-cognito-identity-js';
import React, {createContext, FC, useEffect, useState} from 'react';
import {getSession} from 'utils/aws-cognito.utils';
import {UserRole} from 'utils/user.roles';

export interface AuthContextProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  role: UserRole[];
}

const initialContext: AuthContextProps = {
  isAuthenticated: true,
  setIsAuthenticated: () => {},
  role: [],
};

export const AuthContext = createContext<AuthContextProps>(initialContext);

export const AuthProvider: FC = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [role, setRole] = useState([] as UserRole[]);
  useEffect(() => {
    getSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          setIsAuthenticated(true);
          setRole((session.getAccessToken().payload['cognito:groups'] as UserRole[]) ?? [UserRole.Therapist]);
        } else setIsAuthenticated(false);
      })
      .catch((exception) => {
        setIsAuthenticated(false);
        console.error(exception);
      });
  }, [isAuthenticated]);

  const auth = {isAuthenticated, setIsAuthenticated, role};

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
