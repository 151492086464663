import {createAction} from '@reduxjs/toolkit';
export const setInfoToNewActivityForm = createAction<ActivityForm>('SET_INFO_TO_NEW_ACTIVITY_FORM');
export const selectActivity = createAction<ActivityDto>('SELECT_ACTIVITY');
export const unselectActivity = createAction('UNSELECT_ACTIVITY');
export const setActivitiesToSelectedProgram = createAction<ActivityDay[]>('SET_ACTIVITIES_TO_SELECTED_PROGRAM');
export const selectExercise = createAction<ExerciseDto>('SELECT_EXERCISE');
export const unselectExercise = createAction('UNSELECT_EXERCISE');
export const selectSession = createAction<SessionDto>('SELECT_SESSION');
export const unselectSession = createAction('UNSELECT_SESSION');
export const selectQuote = createAction<QuoteDto>('SELECT_QUOTE');
export const unselectQuote = createAction('UNSELECT_QUOTE');
export const selectImage = createAction<ImageDto>('SELECT_IMAGE');
export const unselectImage = createAction('UNSELECT_IMAGE');
export const selectMedia = createAction<MediaDto>('SELECT_MEDIA');
export const unselectMedia = createAction('UNSELECT_MEDIA');
export const sendMessage = createAction<ToastMessage>('SEND_MESSAGE');
export const sendUndoToast = createAction<ToastToolInfo>('SEND_UNDO_TOAST');
export const showLoading = createAction<void>('SHOW_LOADING');
export const hideLoading = createAction<void>('HIDE_LOADING');
export const selectPlansFilter = createAction<string>('SELECT_PLANS_FILTER');
export const unselectPlansFilter = createAction<string>('UNSELECT_PLANS_FILTER');
export const selectTagsFilter = createAction<string>('SELECT_TAGS_FILTER');
export const unselectTagsFilter = createAction<string>('UNSELECT_TAGS_FILTER');
export const selectSubscriptionStatusFilter = createAction<string>('SELECT_SELECTED_SUBSCRIPTION_FILTER');
export const unselectSubscriptionStatusFilter = createAction<string>('UNSELECT_SELECTED_SUBSCRIPTION_FILTER');
export const clearSelectedPlans = createAction<void>('CLEAR_SELECTED_PLANS');
export const clearSelectedSubscriptionStatus = createAction<void>('CLEAR_SELECTED_SUBSCRIPTION_STATUS');
export const clearSelectedTags = createAction<void>('CLEAR_SELECTED_TAGS');
export const setFiltersApplied = createAction<void>('SET_FILTERS_APPLIED');
export const unsetFiltersApplied = createAction<void>('UNSET_FILTERS_APPLIED');
export const selectMessagingTagsFilter = createAction<string>('SELECT_MESSAGING_TAGS_FILTER');
export const unselectMessagingTagsFilter = createAction<string>('UNSELECT_MESSAGING_TAGS_FILTER');
export const filterSessionsByTags = createAction<ToolTag[]>('FILTER_SESSIONS_BY_TAGS');
export const filterSessionsBySearchValue = createAction<string>('FILTER_SESSIONS_BY_SEARCH_VALUE');
export const filterExercisesByTags = createAction<ToolTag[]>('FILTER_EXERCISES_BY_TAGS');
export const filterExercisesBySearchValue = createAction<string>('FILTER_EXERCISES_BY_SEARCH_VALUE');
export const filterQuotesByTags = createAction<ToolTag[]>('FILTER_QUOTES_BY_TAGS');
export const filterQuotesBySearchValue = createAction<string>('FILTER_QUOTES_BY_SEARCH_VALUE');
export const filterImagesByTags = createAction<ToolTag[]>('FILTER_IMAGES_BY_TAGS');
export const filterImagesBySearchValue = createAction<string>('FILTER_IMAGES_BY_SEARCH_VALUE');
export const filterCategoriesByTags = createAction<ToolTag[]>('FILTER_CATEGORIES_BY_TAGS');
export const filterCategoriesBySearchValue = createAction<string>('FILTER_CATEGORIES_BY_SEARCH_VALUE');
export const filterMediaByTags = createAction<MediaBankTag[]>('FILTER_MEDIA_BY_TAGS');
export const filterMediaBySearchValue = createAction<string>('FILTER_MEDIA_BY_SEARCH_VALUE');
export const filterMediaByMediaType = createAction<MediaType>('FILTER_MEDIA_BY_MEDIA_TYPE');
export const filterMediaBySingleTag = createAction<string>('FILTER_MEDIA_BY_SINGLE_TAG');
export const clearFilters = createAction<ClearFiltersPayload>('CLEAR_FILTERS');
export const setCurrentMediaBankPage = createAction<number>('SET_CURRENT_MEDIA_BANK_PAGE');
