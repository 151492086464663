import React, {useState, useEffect} from 'react';

import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import styled from 'styled-components';
import GeneralSidebar from '../../../components/general-sidebar';
import {
  ListWrapper as SessionListWrapper,
  AddButton as AddSessionButton,
  ListControls,
  ListGeneral as SessionList,
} from '../../../components/tools/main-page-components';
import TagsFilter from '../../../components/tools/filters';
import SessionCard from '../../../components/tools/general-card-list';

import SearchBar from '../../../components/search-bar';
import {Link} from 'react-router-dom';
import {listSessions} from '../../../api/chatowl.api';

import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {shallowEqual} from 'react-redux';
import {selectSession, unselectSession} from '../../../store/action-creators';

import {SessionDetails} from './session-detail';
import {filterSessionsBySearchValue, filterSessionsByTags} from 'store/action-creators';

const SessionNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No Sessions';
  }
`;

export const Sessions: React.FC<{title?: string}> = ({title}) => {
  const [sessions, setSessions] = useState<SessionDto[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const selectedSession: SessionDto | null = useAppSelector(
    (state: RootState) => state.tools.sessions.selectedSession,
    shallowEqual
  );
  const sessionTags: ToolTag[] = ['Active', 'Archived'];
  const selectedTags: ToolTag[] = useAppSelector((state: RootState) => state.tools.sessions.filters.tags, shallowEqual);
  const searchValue: string = useAppSelector((state: RootState) => state.tools.sessions.filters.search, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    // dispatch(unselectSession());
    const fetchSessions = async () => {
      try {
        const response = (await listSessions({withDeleted: false, sortBy: 'id'})) as any;
        const sessionsResponse = response.data ? response.data.data : [];
        setSessions(sessionsResponse);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };
    fetchSessions();
  }, []);

  useEffect(() => {
    if (
      (selectedSession?.isArchived && !selectedTags.includes('Archived')) ||
      (!selectedSession?.isArchived && !selectedTags.includes('Active'))
    ) {
      dispatch(unselectSession());
    }
  }, [selectedTags]);

  const onSessionClick = (toolID?: number): void => {
    const sessionSelected = sessions.find((session) => session.id === toolID);
    if (sessionSelected) toolID !== selectedSession?.id ? dispatch(selectSession(sessionSelected)) : dispatch(unselectSession());
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(filterSessionsBySearchValue(e.target.value));
  };

  const onClick = (): void => {
    dispatch(unselectSession());
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as ToolTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(filterSessionsByTags(newTags));
  };

  const filterByStatus = (session: SessionDto) =>
    !selectedTags.length ||
    (selectedTags.includes('Active') && !session.isArchived) ||
    (selectedTags.includes('Archived') && session.isArchived);

  const filterBySearchValue = (session: SessionDto) =>
    !searchValue.toLowerCase() ||
    session.id.toString().includes(searchValue) ||
    session.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    session.description?.toLowerCase().includes(searchValue.toLowerCase());

  const sessionsFiltered = sessions.filter(filterByStatus).filter(filterBySearchValue);

  const skeleton = [1, 2, 3, 4, 5, 6];

  return (
    <PageContainer>
      <SessionListWrapper>
        <PageHeader>
          <PageTitle>Sessions</PageTitle>
          <AddSessionButton>
            <Link to='/tools/sessions/add'>+ Add Session</Link>
          </AddSessionButton>
        </PageHeader>
        <ListControls>
          <TagsFilter tags={sessionTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter}></TagsFilter>
          <SearchBar value={searchValue} onChange={onSearchValueChange} />
        </ListControls>
        <SessionList>
          {isLoading ? (
            skeleton.map((v) => <SessionCard key={v} selectedToolID={0} onClick={() => {}} />)
          ) : sessionsFiltered.length ? (
            sessionsFiltered.map((session) => (
              <SessionCard key={session.id} selectedToolID={selectedSession?.id} onClick={onSessionClick} tool={session} />
            )) //VER SI EN TOOL CATEGORY VA ESO, O EL NOMBRE DEL PROGRAMA.
          ) : (
            <SessionNotFound />
          )}
        </SessionList>
      </SessionListWrapper>

      <GeneralSidebar isActive={!!selectedSession} onClick={onClick}>
        <SessionDetails session={selectedSession} />
      </GeneralSidebar>
    </PageContainer>
  );
};
