import {getPromoteTypesTools} from 'api/chatowl.api';
import {useEffect, useMemo, useState} from 'react';

export const useToolToPromote = (toolType?: string, toolId?: number) => {
  const [typesToolsOptions, setTypesToolsOptions] = useState<ComboOptionDto[] | undefined>(undefined);
  const [toolTypeToPromote, setToolTypeToPromote] = useState<string | undefined>(toolType);
  const [toolIdToPromote, setToolIdToPromote] = useState<number | undefined>(toolId);

  const toolsOptions = useMemo(() => {
    return typesToolsOptions?.find((typeTools) => typeTools.value === toolTypeToPromote)?.labels;
  }, [typesToolsOptions, toolTypeToPromote]);

  useEffect(() => {
    const fetchPromoteTypesTools = async () => {
      try {
        const response = (await getPromoteTypesTools()) as any;
        const typesToolsOptions = response.data.data as ComboOptionDto[];
        setTypesToolsOptions(typesToolsOptions);
      } catch (error) {
        throw error; // TODO
      }
    };

    fetchPromoteTypesTools();
  }, []);

  return {
    typesToolsOptions,
    toolTypeToPromote,
    setToolTypeToPromote,
    toolsOptions,
    toolIdToPromote,
    setToolIdToPromote,
  };
};
