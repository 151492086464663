import styled from 'styled-components';
import {css} from '@emotion/react';

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const override = css`
  z-index: 200;
  display: block;
  margin: 0 auto;
  margin-top: 25%;
  justify-content: center;
`;

type SpinnerContainerProps = {
  show: boolean;
};

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  ${(props: SpinnerContainerProps) => !props.show && 'display: none'}
`;
