import {Button} from 'components/page';
import styled from 'styled-components';

interface MediaContainerType {
  isSelected?: boolean;
}

export const Image = styled.div`
  width: 270px;
  height: 160px;
  background-color: ${(props: MediaContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-radius: 9px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-color: ${(props: MediaContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-style: solid;
  border-width: 6px;
  :hover {
    opacity: 0.8;
  }
`; //'position: relative' To use icons of video, audio. Remove if icons are removed.

export const CardThumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`;

export const CardThumbnailVideo = styled.video`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const FilterContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

interface selectImageBoxProps {
  smallSize?: boolean;
}

export const SelectImageBox = styled.div`
  align-items: center;
  border: 2px dashed #bcc1c7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: ${(props: selectImageBoxProps) => (props.smallSize ? '150px' : '550px')};
  justify-content: center;
  width: 300px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    color: #777c7c;
  }

  :hover:not(:focus-within) {
    background-color: #fafafa;
    cursor: pointer;
    transition: 0.1s;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  color: #9ba2aa;
  font-size: 28px;
  cursor: pointer;
`;

export const modalStyle = {
  zIndex: 2,
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
    zIndex: 4,
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
    zIndex: 3,
  },
};

export const RedirectModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

export const RedirectModalSelectCancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
  margin: 6px;
`;

export const RedirectModalSelectYesButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
  margin: 6px;
`;
