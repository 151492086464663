import {
  DetailFieldValue as SDFieldValue,
  DetailButtonLink as SDButtonLink,
  DetailField as SDField,
  DetailFieldImage as SDFieldImage,
  DetailFieldLabel as SDFieldLabel,
  DetailHeader as SDHeader,
  DetailID as SDID,
  DetailTitle as SDTitle,
  Label,
  DetailFieldRow,
} from '../../../components/tools/main-page-components';
import {useHistory} from 'react-router-dom';
import React, {useState} from 'react';
import {CursorLoading} from 'components/page';
import {ProgressSpinner} from 'primereact/progressspinner';
import {secondsToLabel} from 'utils/helpers';
import {translateRepeatLimit, translateResumeLimit} from '../exercises/exercise-detail';
import {CategoriesUl} from 'components/info/categories-ul';

type SessionDetailsProps = {
  session: SessionDto | null;
};

export type DropdownType = {
  label: string;
  value: string | number;
};

export const SessionDetails: React.FC<SessionDetailsProps> = ({session}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const fullWidthRegularImageUrl = session?.media?.crops.find((c) => c.type === 'full_width_regular')?.url || '';

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <CursorLoading style={{height: '1000px'}} disable={loading}>
      <div>
        <SDHeader>
          <div style={{display: 'flex'}}>
            <SDTitle>Session Info</SDTitle>
            {session?.isFree && <Label color={'#20C5A0'}>Free</Label>}
          </div>
          <div>
            <SDButtonLink onClick={() => history.push(`/tools/sessions/edit/${session?.id}`)}>
              {/* <i className='pi pi-external-link' style={{marginRight: '10px'}}></i> */}
              Edit
            </SDButtonLink>
          </div>
        </SDHeader>
        <SDID>{session?.id}</SDID>
      </div>
      <div>
        <SDField>
          <SDFieldLabel>Name</SDFieldLabel>
          <SDFieldValue>{session?.title}</SDFieldValue>
        </SDField>
        <SDField>
          <SDFieldLabel>Description</SDFieldLabel>
          <SDFieldValue>{session?.description}</SDFieldValue>
        </SDField>
        <SDField>
          <SDFieldLabel>Category</SDFieldLabel>
          <SDFieldValue>
            <CategoriesUl categories={session?.toolCategories}></CategoriesUl>
          </SDFieldValue>
        </SDField>
        <SDField>
          <SDFieldLabel>Flow</SDFieldLabel>
          <SDFieldValue>{session?.flowName}</SDFieldValue>
        </SDField>
        <DetailFieldRow>
          <SDField>
            <SDFieldLabel>Repeat Limit</SDFieldLabel>
            <SDFieldValue>
              {session?.isRepeatable ? translateRepeatLimit(secondsToLabel(session?.repeatLimit)) : 'No Repeatable'}
            </SDFieldValue>
          </SDField>
          <SDField>
            <SDFieldLabel>Resume Limit</SDFieldLabel>
            <SDFieldValue>{translateResumeLimit(secondsToLabel(session?.resumeLimit))}</SDFieldValue>
          </SDField>
        </DetailFieldRow>
        <SDField>
          <SDFieldLabel>Image</SDFieldLabel>
          {loading && (
            <SDFieldValue>
              <ProgressSpinner style={{width: '45px', height: '45px'}} />
            </SDFieldValue>
          )}
          <SDFieldImage src={fullWidthRegularImageUrl} alt={session?.title} onLoad={handleLoaded} />
        </SDField>
      </div>
    </CursorLoading>
  );
};
export default SessionDetails;
