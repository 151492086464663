import React, {useEffect, useState} from 'react';
import {Action, ActionDate, Row, SeparatorLine, BoxMessageContainer} from '../styles';
import {getClientActions} from 'api/chatowl.api';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {ScrollPanel} from 'primereact/scrollpanel';

type ClientActionsProps = {client: ClientDto};

const actionsSkeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ClientActions: React.FC<ClientActionsProps> = ({client}) => {
  const [actions, setActions] = useState<ActionDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const response = (await getClientActions(client.username)) as any;
        const res = response.data.data as any;
        const actions = res as ActionDto[];
        setActions(actions);
      } catch (error) {
        throw error; // TODO
      } finally {
        setIsLoading(false);
      }
    };

    fetchActions();
  }, []);

  return (
    <>
      <ScrollPanel style={{width: '100%', height: '79vh'}}>
        <SeparatorLine />
        {isLoading ? (
          actionsSkeleton.map((_) => (
            <BoxMessageContainer style={{height: '12%'}}>
              <Skeleton style={{height: '100%', width: '100%'}} />
            </BoxMessageContainer>
          ))
        ) : actions?.length ? (
          actions.map((action) => (
            <>
              <Row>
                <ActionDate>{moment(action.date).calendar()}</ActionDate>
                <Action>{action.action}</Action>
              </Row>
              <SeparatorLine />
            </>
          ))
        ) : (
          <h5 style={{textAlign: 'center', margin: '5% 5% 5% 5%'}}>No actions found...</h5>
        )}
      </ScrollPanel>
    </>
  );
};
