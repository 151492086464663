// Here goes the chatowl api endpoints
import querystring from 'querystring';
import {getSession, signOut} from 'utils/aws-cognito.utils';
import {CHATOWL_API_HOST} from 'utils/constants';

// Here goes the chat api endpoints
interface ApiCallConfig extends RequestInit {
  params?: Record<string, string | number | undefined | boolean | number[] | string[]>;
  token?: string;
}

type ApiCallResponse<T> = {response: Response; data: T} | {response: Response; error: Error};

const callApi = async <T>(url: string, config?: ApiCallConfig): Promise<ApiCallResponse<T>> => {
  const queryString = config?.params ? '?' + querystring.stringify(config.params) : '';

  const token = (await getSession()).getAccessToken().getJwtToken();

  const response = await fetch(CHATOWL_API_HOST + url + queryString, {
    ...config,
    headers: {
      ...{Authorization: `Bearer ${token}`},
      ...(config?.headers || null),
    },
    body: config?.body,
  });

  try {
    if (response.status === 401) {
      signOut();
    }
    return {response, data: await response.json()};
  } catch (error) {
    return {response, error: error as Error};
  }
};

export interface ProgramFilters {
  search?: string;
  statusIs?: ProgramStatus;
  sortBy?: ProgramSort;
  sortDir?: 'asc' | 'desc';
  withDeleted?: boolean;
}

export const getTherapyPrograms = async (filters?: ProgramFilters, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto[]>('/programs', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export interface ListExerciseFilters {
  search?: string;
  categoryIs?: number;
  isArchived?: boolean;
  withDeleted?: boolean;
  sortBy?: 'id' | 'title';
  sortDir?: 'asc' | 'desc';
}

export const listExercises = async (filters?: ListExerciseFilters, requestConfig?: ApiCallConfig) =>
  callApi<ExerciseDto[]>('/tools/exercises', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export interface ActivityFilters {
  search?: string;
  week?: number;
  tools?: string;
  withDeleted?: boolean;
}

export const getProgramActivities = async (id: string, filters?: ActivityFilters, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto>(`/programs/${id}/activities`, {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const addTherapyProgram = async (program: CreateProgramRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(program);
  return callApi<ProgramDto>('/programs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const patchTherapyProgram = async (id: number, program: UpdateProgramRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(program);

  return callApi<ProgramDto>(`/programs/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export interface ActivityFilters {
  search?: string;
  week?: number;
  tools?: string;
  withDeleted?: boolean;
}

export const getProgramDetails = async (id: number, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto>(`/programs/${id}`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const getActivityTypesTools = async (requestConfig?: ApiCallConfig) =>
  callApi<ComboOptionDto[]>('/labels/activity-types-tools', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const getPromoteTypesTools = async (requestConfig?: ApiCallConfig) =>
  callApi<ComboOptionDto[]>('/labels/promote-types-tools', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const addActivityToProgram = async (id: number, activityData: CreateActivityRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(activityData);
  return callApi<ProgramDto>(`/programs/${id}/activities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const patchActivityProgram = async (
  idProgram: number,
  idActivity: number,
  activityData: UpdateActivityReqest,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(activityData);
  return callApi<ProgramDto>(`/programs/${idProgram}/activities/${idActivity}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

interface ListSessionFilters {
  search?: string;
  categoryIs?: number;
  isArchived?: boolean;
  withDeleted?: boolean;
  sortBy?: 'id' | 'title';
  sortDir?: 'asc' | 'desc';
}

export const listSessions = async (filters?: ListSessionFilters, requestConfig?: ApiCallConfig) =>
  callApi<SessionDto[]>('/tools/sessions', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const getTherapyProgramsLabels = async (filters?: ProgramFilters, requestConfig?: ApiCallConfig) =>
  callApi<Label[]>('/labels/programs', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const getFlowsLabels = async (filters?: ProgramFilters, requestConfig?: ApiCallConfig) =>
  callApi<Label[]>('/labels/flows', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const listQuotes = async (filters?: ListSessionFilters, requestConfig?: ApiCallConfig) =>
  callApi<SessionDto[]>('/tools/quotes', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const listImages = async (filters?: ListSessionFilters, requestConfig?: ApiCallConfig) =>
  callApi<ImageDto[]>('/tools/images', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const getCategories = async (requestConfig?: ApiCallConfig) =>
  callApi<CategoryDto[]>('/tools/categories', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  }); //Me traigo cosas innecesarias. Ver si dejamos este o modificamos el back.

export const getCategoryTree = async (requestConfig?: ApiCallConfig) =>
  callApi<{data: CategoryToolTreeNode[]; success: boolean}>('/categories', {
    params: {...(requestConfig?.params ?? {})},
  }).then((resp) => {
    if ('error' in resp) {
      throw resp.error;
    } else if (!resp.data.success) {
      throw new Error('Unknown error');
    } else {
      return resp.data.data;
    }
  });
export const updateCategoriesOrder = async (categoriesOrder: number[], requestConfig?: ApiCallConfig) =>
  callApi<{data: CategoryToolTreeNode[]; success: boolean}>('/categories', {
    params: {...(requestConfig?.params ?? {})},
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({categoriesOrder}),
    method: 'PATCH',
  }).then((resp) => {
    if ('error' in resp) {
      throw resp.error;
    } else if (!resp.data.success) {
      throw new Error('Unknown error');
    } else {
      return resp.data.data;
    }
  });

export const getCategory = async (id: number, requestConfig?: ApiCallConfig) =>
  callApi<{data: DetailedCategoryToolNode; success: boolean}>(`/categories/${id}`, {
    params: {...(requestConfig?.params ?? {})},
  }).then((resp) => {
    if ('error' in resp) {
      throw resp.error;
    } else if (!resp.data.success) {
      throw new Error('Unknown error');
    } else {
      return resp.data.data;
    }
  });
export const createCategory = async (request: CreateCategoryRequest, requestConfig?: ApiCallConfig) =>
  callApi<{data: DetailedCategoryToolNode; success: boolean}>(`/categories/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(request),
  }).then((resp) => {
    if ('error' in resp) {
      throw resp.error;
    } else if (!resp.data.success) {
      throw new Error('Unknown error');
    } else {
      return resp.data.data;
    }
  });
export const updateCategory = async (id: number, request: UpdateCategoryRequest, requestConfig?: ApiCallConfig) =>
  callApi<{data: DetailedCategoryToolNode; success: boolean}>(`/categories/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(request),
  }).then((resp) => {
    if ('error' in resp) {
      throw resp.error;
    } else if (!resp.data.success) {
      throw new Error('Unknown error');
    } else {
      return resp.data.data;
    }
  });
export const createVideo = async (video: CreateMediaVideoRequest) => {
  const postMediaBankVideo = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/videos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    duration,
    originalVideo,
    portraitVideo,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    tags,
    crops,
    settings,
    thumbnail,
  } = video;
  formData.append('name', name);
  formData.append('duration', duration.toString());
  formData.append('originalVideo', originalVideo);
  portraitVideo && formData.append('portraitVideo', portraitVideo);
  formData.append('originalImage', originalImage);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('settings', JSON.stringify(settings));
  formData.append('crops', JSON.stringify(crops));
  formData.append('thumbnail', thumbnail);
  return postMediaBankVideo(formData);
};

export const updateVideo = async (mediaId: number, video: UpdateMediaVideoRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankVideo = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/videos/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    duration,
    originalVideo,
    portraitVideo,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    tags,
    crops,
    settings,
    thumbnail,
  } = video;
  name && formData.append('name', name);
  duration && formData.append('duration', duration.toString());
  originalVideo && originalVideo.size && formData.append('originalVideo', originalVideo);
  portraitVideo && formData.append('portraitVideo', portraitVideo);
  originalImage && formData.append('originalImage', originalImage);
  thumbnail && formData.append('thumbnail', thumbnail);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  formData.append('settings', JSON.stringify(settings));
  return updateMediaBankVideo(formData);
};

export const createAudio = async (audio: CreateMediaAudioRequest) => {
  const postMediaBankAudio = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/audios', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    duration,
    originalAudio,
    fullScreenLandscape,
    fullScreenPortrait,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    tags,
    crops,
    settings,
    thumbnail,
  } = audio;
  formData.append('name', name);
  formData.append('duration', duration.toString());
  formData.append('originalAudio', originalAudio);
  formData.append('originalImage', originalImage);
  formData.append('fullScreenLandscape', fullScreenLandscape);
  formData.append('fullScreenPortrait', fullScreenPortrait);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  formData.append('settings', JSON.stringify(settings));
  formData.append('thumbnail', thumbnail);
  return postMediaBankAudio(formData);
};

export const updateAudio = async (mediaId: number, audio: UpdateMediaAudioRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankAudio = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/audios/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    duration,
    originalAudio,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    fullScreenLandscape,
    fullScreenPortrait,
    tags,
    crops,
    settings,
    thumbnail,
  } = audio;
  name && formData.append('name', name);
  duration && formData.append('duration', duration.toString());
  originalAudio && originalAudio.size && formData.append('originalAudio', originalAudio);
  originalImage && formData.append('originalImage', originalImage);
  thumbnail && formData.append('thumbnail', thumbnail);
  fullScreenLandscape && formData.append('fullScreenLandscape', fullScreenLandscape);
  fullScreenPortrait && formData.append('fullScreenPortrait', fullScreenPortrait);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  formData.append('settings', JSON.stringify(settings));
  return updateMediaBankAudio(formData);
};

export const createImage = async (image: CreateMediaImageRequest) => {
  const postMediaBankImage = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/images', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalImage,
    fullScreenLandscape,
    fullScreenPortrait,
    fullWidthRegular,
    fullWidthTall,
    squared,
    tags,
    crops,
    settings,
    thumbnail,
  } = image;
  formData.append('name', name);
  formData.append('originalImage', originalImage);
  formData.append('thumbnail', thumbnail);
  formData.append('fullScreenLandscape', fullScreenLandscape);
  formData.append('fullScreenPortrait', fullScreenPortrait);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  formData.append('squared', squared);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  formData.append('settings', JSON.stringify(settings));
  return postMediaBankImage(formData);
};

export const updateImage = async (mediaId: number, image: UpdateMediaImageRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankImage = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/images/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,

      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    fullScreenLandscape,
    fullScreenPortrait,
    tags,
    crops,
    squared,
    settings,
    thumbnail,
  } = image;
  name && formData.append('name', name);
  originalImage && formData.append('originalImage', originalImage);
  thumbnail && formData.append('thumbnail', thumbnail);
  fullScreenLandscape && formData.append('fullScreenLandscape', fullScreenLandscape);
  fullScreenPortrait && formData.append('fullScreenPortrait', fullScreenPortrait);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  squared && formData.append('squared', squared);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  formData.append('settings', JSON.stringify(settings));
  //moodId left
  return updateMediaBankImage(formData);
};

export const getMedia = async (mediaId: number) => callApi<MediaDto>(`/media-bank/medias/${mediaId}`);

export const listMedias = async (
  pageNumber: number,
  pageSize: number,
  search: string,
  sortBy: string,
  sortDir: string,
  tag?: string,
  requestConfig?: ApiCallConfig,
  typeIs?: MediaType,
  isInUse?: boolean
) => {
  let params;
  if (tag)
    params = {
      pageNumber,
      pageSize,
      search,
      sortBy,
      sortDir,
      tag,
    };
  else
    params = {
      pageNumber,
      pageSize,
      search,
      sortBy,
      sortDir,
    };
  if (typeIs)
    params = {
      ...params,
      typeIs,
    };
  if (isInUse !== undefined) {
    params = {
      ...params,
      isInUse,
    };
  }
  return callApi<MediaDto[]>('/media-bank', {
    params: {
      ...params,
    },

    ...(requestConfig || null),
  });
};

export const addExercise = async (exercise: CreateExerciseRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(exercise);
  return callApi<ExerciseDto>('/tools/exercises', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body,
    ...(requestConfig || null),
  });
};

export const editExercise = async (exerciseId: number, exercise: UpdateExerciseRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(exercise);
  return callApi<ExerciseDto>(`/tools/exercises/${exerciseId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },

    body,

    ...(requestConfig || null),
  });
};

export const getMediaTags = async (requestConfig?: ApiCallConfig) =>
  callApi<string[]>('/media-bank/tags', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const getImageMoods = async (requestConfig?: ApiCallConfig) =>
  callApi<LabelDto[]>('/media-bank/moods', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const deleteMedia = async (media_id: number, requestConfig?: ApiCallConfig) =>
  callApi(`/media-bank/medias/${media_id}`, {
    method: 'DELETE',
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const addSession = async (session: CreateSessionRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(session);
  return callApi<SessionDto>('/tools/sessions', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const editSession = async (sessionId: number, session: UpdateSessionRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(session);
  return callApi<SessionDto>(`/tools/sessions/${sessionId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const addQuote = async (quote: CreateQuoteRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(quote);
  return callApi<QuoteDto>('/tools/quotes', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const updateQuote = async (quote: UpdateQuoteRequest, id: number, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(quote);
  return callApi<QuoteDto>(`/tools/quotes/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const addImageTool = async (image: CreateImageToolRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(image);
  return callApi<ImageDto>('/tools/images', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const updateImageTool = async (image: UpdateImageToolRequest, id: number, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(image);
  return callApi<ImageDto>(`/tools/images/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const getProgramWeeks = async (programId: string, requestConfig?: ApiCallConfig) => {
  return callApi<Week>(`/programs/${programId}/weeks`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const postProgramWeek = async (week: Week, programId: number, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(week);
  return callApi<Week>(`/programs/${programId}/weeks`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const editProgramWeek = async (week: Week, programId: number, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(week);
  return callApi<Week>(`/programs/${programId}/weeks/${week.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const deleteProgramWeek = async (weekId: number, programId: number, requestConfig?: ApiCallConfig) => {
  return callApi<Week>(`/programs/${programId}/weeks/${weekId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const patchProgramWeek = async (weeks: Week[], programId: number, requestConfig?: ApiCallConfig) => {
  const content = {
    newPositions: weeks,
  };
  const body = JSON.stringify(content);
  return callApi<Week>(`/programs/${programId}/weeks`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const patchProgramActivies = async (days: ActivityDay[], programId: number, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(days);
  return callApi<ActivityDay>(`/programs/${programId}/activities`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const getSettings = async (filters?: ProgramFilters, requestConfig?: ApiCallConfig) =>
  callApi<AppSettingsDto>('/app-settings', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const updateSettings = async (settings: AppSettingRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(settings);
  return callApi('/app-settings', {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const deleteProgram = async (programId: number, requestConfig?: ApiCallConfig) => {
  return callApi<any>(`/programs/${programId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const deleteActivity = async (programId: number, activityId: number, requestConfig?: ApiCallConfig) => {
  return callApi<any>(`/programs/${programId}/activities/${activityId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const updateToolMedias = async (toolMedia: UpdateToolMediaRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(toolMedia);
  return callApi('/tools', {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export enum SubscriptionStatus {
  ON_TRIAL = 'on_trial',
  SUBSCRIBED_FOR_4_MONTHS = 'four_months',
  CANCELED = 'canceled',
}

export interface ClientFiltersToApply {
  pageNumber?: number; // start with 0
  pageSize?: number;
  programIs?: number[];
  search?: string; // search by alias or by tag
  subscriptionStatus?: ('on_trial' | 'four_months' | 'canceled')[];
  sortBy?: 'username' | 'alias' | 'createdAt';
  sortDir?: 'asc' | 'desc';
  tags?: string[];
}

const generateQueryArrayFromClientFilters = (filters: ClientFiltersToApply): string => {
  let result = '';
  let arr_res = {tags: '', subscriptionStatus: '', programIs: ''};
  if (filters.pageSize && (filters.pageNumber || filters.pageNumber === 0))
    result += `pageNumber=${filters.pageNumber}&pageSize=${filters.pageSize}&`;

  filters.tags?.forEach((tag) => {
    arr_res.tags += `tags[]=${tag}&`;
  });
  filters.subscriptionStatus?.forEach((stat) => {
    arr_res.subscriptionStatus += `subscriptionStatus[]=${stat}&`;
  });
  filters.programIs?.forEach((p) => {
    arr_res.programIs += `programsIs[]=${p}&`;
  });
  if (filters.sortBy) result += `sortBy=${filters.sortBy || 'alias'}&`;
  if (filters.sortDir) result += `sortDir=${filters.sortDir || 'desc'}&`;
  if (filters.search) result += `search=${filters.search}&`;
  result += (arr_res.tags + arr_res.subscriptionStatus + arr_res.programIs).slice(0, -1);
  return result;
};

export const getClients = async (filters?: ClientFiltersToApply, requestConfig?: ApiCallConfig) => {
  let queryArrays = '';
  if (filters) queryArrays = generateQueryArrayFromClientFilters(filters);
  return callApi<ClientDto[]>(`/clients${queryArrays ? `?${queryArrays}` : ''}`, {
    token: '',
    ...(requestConfig || null),
  });
};

export const getClientFiltersOptions = async (requestConfig?: ApiCallConfig) =>
  callApi<ClientFiltersOptionsDto[]>(`/labels/client-filters`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: '',
    ...(requestConfig || null),
  });

export const postClientNote = async (
  note: CreateSimpleNoteRequest | CreateChatNoteRequest,
  clientUsername: string,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(note);
  return callApi(`/clients/${clientUsername}/notes`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const patchClientNote = async (
  note: UpdateNoteRequest,
  noteId: number,
  clientUsername: string,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(note);
  return callApi(`/clients/${clientUsername}/notes/${noteId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const deleteClientNote = async (noteId: number, clientUsername: string, requestConfig?: ApiCallConfig) => {
  return callApi(`/clients/${clientUsername}/notes/${noteId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const addTagToClient = async (tag: TagRequest, clientUsername: string, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(tag);
  return callApi(`/clients/${clientUsername}/tags`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const removeTagFromClient = async (tagId: number, clientUsername: string, requestConfig?: ApiCallConfig) => {
  return callApi(`/clients/${clientUsername}/tags/${tagId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const getClientProperties = async (clientUsername: string, requestConfig?: ApiCallConfig) =>
  callApi<{}>(`/clients/${clientUsername}/properties/`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });

export const addValuesToProperties = async (
  clientUsername: string,
  addPropReq: PropertiesRequest,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(addPropReq);
  return callApi(`/clients/${clientUsername}/properties`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const getClientChats = async (clientUsername: string, endDate?: string, requestConfig?: ApiCallConfig) => {
  const API_PORTAL_HOST = 'http://localhost:3009/api/portal';
  let url;
  if (endDate) {
    url = `/clients/${clientUsername}/chat-history?top=20&startDate=2020-07-15T15:23:53.295-03:00&endDate=${endDate}/`;
  } else {
    url = `/clients/${clientUsername}/chat-history?top=20&startDate=2020-07-15T15:23:53.295-03:00&endDate=2021-07-15T15:23:53.295-03:00/`;
  }

  const token = (await getSession()).getAccessToken().getJwtToken();

  const response = await fetch(API_PORTAL_HOST + url, {
    ...requestConfig,
    headers: {
      ...{Authorization: `Bearer ${token}`},
      ...(requestConfig?.headers || null),
    },
    body: requestConfig?.body,
  });

  try {
    if (response.status === 401) {
      signOut();
    }
    return {response, data: await response.json()};
  } catch (error) {
    return {response, error: error as Error};
  }
};

export const getClientStats = async (
  clientUsername: string,
  startISODate: string,
  endISODate: string,
  limit?: number,
  requestConfig?: ApiCallConfig
) =>
  callApi<StatsDto>(`/clients/${clientUsername}/stats`, {
    params: {
      startDate: startISODate,
      endDate: endISODate,
      limit,
    },
    ...(requestConfig || null),
  });

export interface UnrespondedMessagesFilters {
  search?: string;
  tags?: InteractionTagType[];
}

const generateQueryArrayFromInteractionsFilters = (filters: UnrespondedMessagesFilters): string => {
  let result = '';
  let arr_res = {tags: ''};
  filters.tags?.forEach((tag) => {
    if (tag === 'Overdue') arr_res.tags += 'tags[]=overdue&';
    if (tag === 'Unassigned') arr_res.tags += 'tags[]=unassigned&';
    if (tag === 'Assigned to me') arr_res.tags += 'tags[]=assigned_to_me&';
  });
  if (filters.search && filters.search !== '') result += `search=${filters.search}&`;
  result += arr_res.tags.slice(0, -1);
  return result;
};

export const getUnrespondedMessages = async (filters?: UnrespondedMessagesFilters, requestConfig?: ApiCallConfig) => {
  let queryArrays = '';
  if (filters) queryArrays = generateQueryArrayFromInteractionsFilters(filters);
  return callApi<InteractionDTO[]>(`/messaging${queryArrays ? `?${queryArrays}` : ''}`, {
    token: '',
    ...(requestConfig || null),
  });
};

export const getClientDetails = async (clientUsername: string, requestConfig?: ApiCallConfig) =>
  callApi<ClientDto>(`/clients/${clientUsername}`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const getInteraction = async (id: string, requestConfig?: ApiCallConfig) =>
  callApi<InteractionDTO>(`/messaging/${id}`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const getClientInteractions = async (clientUsername: string, requestConfig?: ApiCallConfig) =>
  callApi<InteractionDTO[]>(`/clients/${clientUsername}/messages`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const assignInteractionToCounselor = async (interactionId: string, requestConfig?: ApiCallConfig) => {
  return callApi(`/messaging/${interactionId}/counselor`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},

    ...(requestConfig || null),
  });
};

export const answerMessageToClient = async (
  interactionId: string,
  answerMessageReq: InteractionAnswerRequest,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(answerMessageReq);
  return callApi(`/messaging/${interactionId}/messages`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,

    ...(requestConfig || null),
  });
};

export const getClientActions = async (clientUsername: string, requestConfig?: ApiCallConfig) =>
  callApi<ActionDto[]>(`/clients/${clientUsername}/actions`, {
    ...(requestConfig || null),
  });
