import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {TabView, TabPanel} from 'primereact/tabview';
import {deleteProgram, getProgramActivities, patchProgramActivies} from 'api/chatowl.api';
import {useHistory} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {PageContainer, PageTitle, PDButtonBack, PageListWrapper as ActivityListWrapper} from '../../components/page';
import {RootState} from 'index';
import {useAppDispatch, useAppSelector} from 'hooks';
import {shallowEqual} from 'react-redux';
import {
  hideLoading,
  selectActivity,
  sendMessage,
  setActivitiesToSelectedProgram,
  setInfoToNewActivityForm,
  showLoading,
  unselectActivity,
} from 'store/action-creators';
import {capitalize, momentToNumber} from 'utils/helpers';
import {
  ActivitiesNotFound,
  ActivityCardContainer,
  ActivityCardHeader,
  ActivityCardInfo,
  ActivityDescription,
  ActivityImage,
  ActivityList,
  ActivityTitle,
  DayActivityContainer,
  Filters,
  FullActivityCardInfo,
  Label,
  ListControls,
  MenuContainer,
  MomentDay,
  PageDescription,
  PageDescriptionText,
  PageHeader,
  PageId,
  PageImg,
  PDButtonLink,
  PDField,
  PDFieldLabel,
  PDFieldValue,
  PDFieldValueContainer,
  PDHeader,
  PDTitle,
  StyledFilter,
  Tag,
  WeekSelectorContainer,
} from './styles';
import SearchBar from 'components/search-bar';
import GeneralSidebar from 'components/general-sidebar';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  DefaultProgramChoiceButtonsContainer,
  DefaultProgramSelectCancelButton,
  DefaultProgramSelectYesButton,
  modalStyle,
} from 'components/therapy-programs/default-program-assignment/styles';
import Modal from 'react-modal';
import {VersionLabel} from './therapy-programs';
import Skeleton from 'react-loading-skeleton';

const getColorByStatus = (status: ProgramStatus | undefined): string => {
  switch (status) {
    case 'archived':
      return '#D563FF';
    case 'draft':
      return '#038C8D';
    case 'published':
      return '#02ACEC';
    default:
      return '#02ACEC';
  }
};

type ActivityDetailsProps = {
  activity: ActivityDto | null;
  activities: Array<ActivityDay | undefined>;
  weekValue: any;
};

const ActivityDetails: React.FC<ActivityDetailsProps> = ({activity, activities, weekValue}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const params = useParams<RouteParam>();

  const goToFirst = () => {
    let first = false;

    if (activities) {
      activities.map((d) => {
        if (!first) {
          d?.moments.map((m) => {
            let firstActivity = m.activities.find((a) => a?.id == activity?.id);
            if (!!firstActivity) {
              first = true;
              weekValue(firstActivity?.timeDue?.week);
              dispatch(selectActivity(firstActivity));
              setTimeout(() => {
                const element = document.getElementById(`${firstActivity?.index}`);
                element?.scrollIntoView({behavior: 'smooth', block: 'center'});
              }, 100);
            }
          });
        }
      });
    }
  };

  return (
    <div>
      <div>
        <PDHeader>
          <PDTitle>Activity Info</PDTitle>
          <PDButtonLink onClick={() => history.push(`/therapy-programs/${params.id}/activities/edit/${activity?.id}`)}>
            {/* <i className='pi pi-external-link' style={{marginRight: '10px'}}></i> */}
            Edit
          </PDButtonLink>
        </PDHeader>
      </div>
      <div>
        <PDField>
          <PDFieldLabel>Type</PDFieldLabel>
          <PDFieldValueContainer>
            <PDFieldValue>{activity?.typeLabel.name}</PDFieldValue>
            {activity?.isMandatory && <Label color={'#F8A830'}>Mandatory</Label>}
            {activity?.isLocked && <Label color={'#777C7C'}>Locked</Label>}
          </PDFieldValueContainer>
        </PDField>
        <PDField>
          <PDFieldLabel>{capitalize(activity?.activityTool?.toolType || '')}</PDFieldLabel>
          <PDFieldValue>{activity?.activityTool?.title}</PDFieldValue>
        </PDField>
        <PDField>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {activity?.type !== 'add' && (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <PDFieldLabel>Repeat</PDFieldLabel>
                <PDFieldValue>
                  {activity?.repeatInterval && activity?.repeatInterval != 0
                    ? `Every ${activity?.repeatInterval} day` + (activity?.repeatInterval > 1 ? 's' : '')
                    : 'No repeat'}
                </PDFieldValue>
              </div>
            )}
            <div style={activity?.type !== 'add' ? {marginLeft: '83px'} : {}}>
              <PDFieldLabel>Priority</PDFieldLabel>
              <PDFieldValue>
                {activity?.priority
                  ? activity?.priority === 1
                    ? 'Low'
                    : activity?.priority === 2
                    ? 'Medium'
                    : 'High'
                  : 'No priority'}
              </PDFieldValue>
            </div>
          </div>
        </PDField>
        {activity?.repeatInterval && activity?.repeatInterval > 0 && activity?.isRepeated ? (
          <PDField>
            <PDButtonLink style={{justifyContent: 'flex-start'}} onClick={goToFirst}>
              <i className='pi pi-arrow-left' style={{marginRight: '10px'}}></i>
              Go to the first activity of this repetition
            </PDButtonLink>
          </PDField>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

interface RouteParam {
  id: string;
}

export const TherapyProgramDetail: React.FC<{title?: string}> = ({title}) => {
  const [therapyProgram, setTherapyProgram] = useState<ProgramDto>();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [weekValue, setWeekValue] = useState(1);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [programActivitiesResults, setProgramActivitiesResults] = useState<ActivityDay[]>([]);
  const [programActivities, setProgramActivities] = useState<ActivityDay[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const selectedActivity: ActivityDto | null = useAppSelector(
    (state: RootState) => state.activities.selectedActivity,
    shallowEqual
  );

  const params = useParams<RouteParam>();

  const filterByStatus = (activity: ActivityDto) =>
    !selectedFilters.length || selectedFilters.some((f) => f === activity.activityTool?.toolType);

  const filterBySearchValue = (activity: ActivityDto) =>
    !searchValue.toLowerCase() ||
    activity.activityTool?.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    activity.activityTool?.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
    activity.type?.toLowerCase().includes(searchValue.toLowerCase());

  const fetchProgramActivities = async () => {
    try {
      const response = (await getProgramActivities(params.id)) as any;
      const therapyProgram = response.data.data;
      setTherapyProgram(therapyProgram);
      therapyProgram?.programActivities && setProgramActivities(therapyProgram?.programActivities);
      therapyProgram?.programActivities && setProgramActivitiesResults(therapyProgram?.programActivities);
    } catch (error) {
      throw error; // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    fetchProgramActivities();
  }, []);

  useEffect(() => {
    dispatch(setActivitiesToSelectedProgram(programActivities));
  }, [programActivities]);

  const filters: string[] = ['session', 'exercise', 'quote', 'image', 'interactive'];

  const Filter = (filter: string) => {
    const toggleFilters = () => {
      selectedFilters.some((f) => f === filter)
        ? setSelectedFilters(selectedFilters.filter((f) => f !== filter)) // turn off
        : setSelectedFilters([...selectedFilters, filter]); // turn on
    };

    return (
      <StyledFilter key={filter} onClick={toggleFilters} isSelected={selectedFilters.some((f) => f === filter)}>
        {filter}
      </StyledFilter>
    );
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  type Week = {
    name: string;
    value: number;
  };

  const dropdownValues = (): Week[] => {
    const number = programActivities.length / 7;
    let weeks: Week[] = [];
    for (let n = 1; n <= number; n++) {
      weeks.push({name: `Week ${n}`, value: n});
    }
    return weeks;
  };

  const WeekSelector = () => {
    const next = () => {
      if (weekValue < dropdownValues().length) {
        setWeekValue(weekValue + 1);
      }
    };

    const back = () => {
      if (weekValue > 1) {
        setWeekValue(weekValue - 1);
      }
    };
    if (dropdownValues().length > 0)
      return (
        <WeekSelectorContainer>
          <i className='pi pi-angle-left' style={{fontSize: '1.8rem', color: '#2196F3', marginRight: 16}} onClick={back}></i>
          <Dropdown value={weekValue} onChange={(e) => setWeekValue(e.value)} options={dropdownValues()} optionLabel='name' />
          <i className='pi pi-angle-right' style={{fontSize: '1.8rem', color: '#2196F3', marginLeft: 16}} onClick={next}></i>
        </WeekSelectorContainer>
      );
    else return null;
  };

  const ActivityCard: React.FC<ActivityCardProps> = ({activity}) => {
    const isSelected = selectedActivity?.index === activity?.index;

    const onActivityClick = (): void => {
      if (isSelected) {
        dispatch(unselectActivity());
      } else {
        activity && dispatch(selectActivity(activity));
      }
    };

    const activityThumbnailUrl = activity?.activityTool?.media?.crops.find((c) => c.type === 'full_width_tall')?.url;

    if (activity)
      return (
        <ActivityCardContainer key={activity.index} id={`${activity.index}`} onClick={onActivityClick} isSelected={isSelected}>
          <ActivityCardInfo>
            <ActivityCardHeader>
              <ActivityTitle title={activity?.activityTool?.title}>{activity?.activityTool?.title}</ActivityTitle>

              {activity?.isMandatory && activity?.isLocked ? null : (
                <>
                  {activity?.isMandatory && <Label color={'#F8A830'}>Mandatory</Label>}
                  {activity?.isLocked && <Label color={'#777C7C'}>Locked</Label>}
                </>
              )}
            </ActivityCardHeader>
            <ActivityDescription title={activity.activityTool?.description}>
              {activity.activityTool?.description}
            </ActivityDescription>
          </ActivityCardInfo>
          <div style={{position: 'relative'}}>
            <ActivityImage isSidebarOpen={!!selectedActivity} src={activityThumbnailUrl} />
            {activity.repeatInterval !== null && activity?.repeatInterval != 0 && (
              <div style={{position: 'absolute', top: '-9px', right: '-9px'}}>
                <svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11.3672 23.3555C17.5781 23.3555 22.7227 18.2109 22.7227 12C22.7227 5.78906 17.5664 0.644531 11.3555 0.644531C5.14453 0.644531 0 5.78906 0 12C0 18.2109 5.15625 23.3555 11.3672 23.3555ZM4.20703 11.5664V11.0273C4.20703 9.10547 5.56641 7.875 7.69922 7.875H12.1992V6.71484C12.1992 6.28125 12.4922 5.98828 12.9492 5.98828C13.125 5.98828 13.3359 6.07031 13.4883 6.17578L15.9023 8.19141C16.2773 8.49609 16.2773 9 15.9023 9.30469L13.4766 11.3203C13.3242 11.4492 13.125 11.5312 12.9492 11.5312C12.4922 11.5312 12.1992 11.2266 12.1992 10.793V9.59766H7.57031C6.59766 9.59766 5.96484 10.207 5.96484 11.1328V11.5664C5.96484 12.0469 5.57812 12.4219 5.08594 12.4219C4.59375 12.4219 4.20703 12.0469 4.20703 11.5664ZM6.82031 15.8672C6.43359 15.5508 6.43359 15.0469 6.82031 14.7539L9.24609 12.7383C9.39844 12.6094 9.59766 12.5273 9.77344 12.5273C10.2305 12.5273 10.5234 12.832 10.5234 13.2656V14.4492H15.1406C16.125 14.4492 16.7461 13.8398 16.7461 12.9141V12.4805C16.7461 12 17.1445 11.6133 17.6367 11.6133C18.1289 11.6133 18.5273 12 18.5273 12.4805V13.0195C18.5273 14.9414 17.1562 16.1836 15.0234 16.1836H10.5234V17.3438C10.5234 17.7773 10.2305 18.0703 9.77344 18.0703C9.59766 18.0703 9.375 17.9883 9.23438 17.8828L6.82031 15.8672Z'
                    fill={activity?.isRepeated ? '#B6C1C1' : '#2C98F0'}
                  />
                </svg>
              </div>
            )}
          </div>
        </ActivityCardContainer>
      );
    else return null;
  };

  type ActivityCardProps = {
    activity?: ActivityDto;
  };

  const FullActivityCard: React.FC<ActivityCardProps> = ({activity}) => {
    const isSelected = selectedActivity?.index === activity?.index;

    const onActivityClick = (): void => {
      if (isSelected) {
        dispatch(unselectActivity());
      } else {
        activity && dispatch(selectActivity(activity));
      }
    };

    if (activity)
      return (
        <ActivityCardContainer id={`${activity.index}`} key={activity.index} onClick={onActivityClick} isSelected={isSelected}>
          <Tag>{activity?.type}</Tag>
          <FullActivityCardInfo>
            <div style={{display: 'flex'}}>
              <ActivityCardHeader>
                <ActivityTitle title={activity?.activityTool?.title}>{activity?.activityTool?.title}</ActivityTitle>
              </ActivityCardHeader>
            </div>
            {activity.repeatInterval !== null && activity?.repeatInterval != 0 && (
              <div>
                <svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11.3672 23.3555C17.5781 23.3555 22.7227 18.2109 22.7227 12C22.7227 5.78906 17.5664 0.644531 11.3555 0.644531C5.14453 0.644531 0 5.78906 0 12C0 18.2109 5.15625 23.3555 11.3672 23.3555ZM4.20703 11.5664V11.0273C4.20703 9.10547 5.56641 7.875 7.69922 7.875H12.1992V6.71484C12.1992 6.28125 12.4922 5.98828 12.9492 5.98828C13.125 5.98828 13.3359 6.07031 13.4883 6.17578L15.9023 8.19141C16.2773 8.49609 16.2773 9 15.9023 9.30469L13.4766 11.3203C13.3242 11.4492 13.125 11.5312 12.9492 11.5312C12.4922 11.5312 12.1992 11.2266 12.1992 10.793V9.59766H7.57031C6.59766 9.59766 5.96484 10.207 5.96484 11.1328V11.5664C5.96484 12.0469 5.57812 12.4219 5.08594 12.4219C4.59375 12.4219 4.20703 12.0469 4.20703 11.5664ZM6.82031 15.8672C6.43359 15.5508 6.43359 15.0469 6.82031 14.7539L9.24609 12.7383C9.39844 12.6094 9.59766 12.5273 9.77344 12.5273C10.2305 12.5273 10.5234 12.832 10.5234 13.2656V14.4492H15.1406C16.125 14.4492 16.7461 13.8398 16.7461 12.9141V12.4805C16.7461 12 17.1445 11.6133 17.6367 11.6133C18.1289 11.6133 18.5273 12 18.5273 12.4805V13.0195C18.5273 14.9414 17.1562 16.1836 15.0234 16.1836H10.5234V17.3438C10.5234 17.7773 10.2305 18.0703 9.77344 18.0703C9.59766 18.0703 9.375 17.9883 9.23438 17.8828L6.82031 15.8672Z'
                    fill={activity?.isRepeated ? '#B6C1C1' : '#2C98F0'}
                  />
                </svg>
              </div>
            )}
          </FullActivityCardInfo>
        </ActivityCardContainer>
      );
    else
      return (
        <ActivityCardContainer isSelected={false}>
          <Skeleton />
          <FullActivityCardInfo>
            <Skeleton />
            <div style={{display: 'flex'}}>
              <ActivityCardHeader>
                <ActivityTitle>
                  <Skeleton />
                </ActivityTitle>
              </ActivityCardHeader>
              <ActivityDescription>
                <Skeleton />
              </ActivityDescription>
            </div>
          </FullActivityCardInfo>
        </ActivityCardContainer>
      );
  };

  const activities: Array<ActivityDto[] | undefined> = new Array();

  const history = useHistory();

  const back = () => {
    history.push('/therapy-programs');
  };

  const programActivitiesWeek = programActivitiesResults.slice((weekValue - 1) * 7, (weekValue - 1) * 7 + 7);

  const redirectToEdit = () => {
    history.push(`/therapy-programs/edit/${therapyProgram?.id}`);
  };

  const redirectToManage = () => {
    history.push(`/therapy-programs/${params.id}/manage-weeks`);
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const onDeleteProgram = async () => {
    if (therapyProgram?.id) {
      dispatch(showLoading());
      const response = await deleteProgram(therapyProgram.id);
      if (response.response.status >= 200 && response.response.status < 300) {
        dispatch(
          sendMessage({
            severity: 'success',
            summary: 'Program deleted successfully',
            detail: therapyProgram.name,
          })
        );
        dispatch(hideLoading());
        history.push('/therapy-programs');
      } else {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        dispatch(hideLoading());
      }
    }
  };

  const overlayMenuItems = [
    {
      icon: 'pi pi-external-link',
      label: 'Edit Details',
      command: redirectToEdit,
    },
    {
      icon: 'pi pi-calendar',
      label: 'Manage Weeks',
      command: redirectToManage,
    },
    {
      icon: 'pi pi-trash',
      label: 'Delete Program',
      command: openDeleteModal,
      disabled: therapyProgram?.status !== 'draft',
      className: 'delete-program',
    },
  ];

  const menu: React.MutableRefObject<any> = useRef(null);

  const toggleMenu = (event: any) => {
    menu.current.toggle(event);
  };

  const addActivity = (day: number) => {
    const activityInfo: ActivityForm = {
      day,
    };
    dispatch(setInfoToNewActivityForm(activityInfo));
    history.push(`/therapy-programs/${therapyProgram?.id}/activities/add`);
  };

  const unselectActivityAction = () => {
    dispatch(unselectActivity());
  };

  const therapyProgramTitle = `${therapyProgram?.name}${therapyProgram?.tagline && `: ${therapyProgram.tagline}`}`;

  const reorder = (
    list: ActivityDay[],
    startDay: number,
    startMoment: ActivityMoment,
    startIndex: number,
    endDay: number,
    endMoment: ActivityMoment,
    endIndex: number
  ): ActivityDay[] => {
    let newList: ActivityDay[] = list;
    let activitiesOrigin = list[startDay].moments[momentToNumber(startMoment)].activities;
    let activitiesTarget = list[endDay].moments[momentToNumber(endMoment)].activities;
    const [removed] = activitiesOrigin.splice(startIndex, 1);
    activitiesTarget.splice(endIndex, 0, removed);
    newList[startDay].moments[momentToNumber(startMoment)].activities = activitiesOrigin;
    newList[endDay].moments[momentToNumber(endMoment)].activities = activitiesTarget;
    return newList;
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const start = result.source.droppableId.split('-');
    const end = result.destination.droppableId.split('-');
    const items = reorder(
      programActivities,
      start[1] - 1,
      start[0],
      result.source.index,
      end[1] - 1,
      end[0],
      result.destination.index
    );

    const startMoment = items[start[1] - 1].moments[momentToNumber(start[0])].activities;
    const endMoment = items[end[1] - 1].moments[momentToNumber(end[0])].activities;

    let daysToSend: ActivityDay[] = [];

    if (start[1] != end[1]) {
      daysToSend.push({
        day: start[1],
        moments: [
          {
            activities: startMoment,
            moment: start[0],
          },
        ],
      });
      daysToSend.push({
        day: end[1],
        moments: [
          {
            activities: endMoment,
            moment: end[0],
          },
        ],
      });
    } else {
      if (start[0] != end[0]) {
        daysToSend.push({
          day: start[1],
          moments: [
            {
              activities: startMoment,
              moment: start[0],
            },
            {
              activities: endMoment,
              moment: end[0],
            },
          ],
        });
      } else {
        daysToSend.push({
          day: start[1],
          moments: [
            {
              activities: startMoment,
              moment: start[0],
            },
          ],
        });
      }
    }

    setProgramActivitiesResults(items);
    if (therapyProgram?.id) {
      const response = (await patchProgramActivies(daysToSend, therapyProgram.id)) as any;
      if (response.response.status <= 200 && response.response.status > 300) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
    fetchProgramActivities();
  };

  const droppableStyle = (isDraggings: boolean, items: number, isFull: boolean) => ({
    // change background colour if dragging
    background: isDraggings ? 'rgba(220, 220, 220, 0.3)' : 'white',
    borderRadius: '10px',
    minHeight: isFull ? 160 : 300,
    height: isFull ? (items ? items * 84 + 84 : 160) : items ? items * 134 + 134 : 300,
    padding: 10,
    width: '80%',
  });

  useEffect(() => {
    if (programActivities) {
      const result = programActivities.map((x) => {
        const day: ActivityDay = {
          day: x.day,
          moments: x.moments.map((y: Moment) => {
            const moment: Moment = {
              moment: y.moment,
              activities: y.activities.filter(filterByStatus).filter(filterBySearchValue),
            };
            return moment;
          }),
        };
        return day;
      });
      setProgramActivitiesResults(result);
    }
  }, [searchValue, selectedFilters]);

  const EmptyWeeks = () => {
    return (
      <ActivitiesNotFound>
        <p>You need at least a week before creating activities</p>
        <Button onClick={redirectToManage} style={{fontSize: '24px'}}>
          Go to Manage Weeks
        </Button>
      </ActivitiesNotFound>
    );
  };

  const activitiesSkeleton = [1, 2, 3];

  return (
    <PageContainer>
      <ActivityListWrapper>
        <PageHeader style={{marginBottom: '0px'}}>
          <PageTitle>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {isLoading ? <Skeleton width={600} height={40} /> : therapyProgramTitle}
              {isLoading ? null : (
                <>
                  <MenuContainer>
                    <Menu ref={menu} model={overlayMenuItems} popup />
                  </MenuContainer>
                  <Button onClick={toggleMenu} className='p-button-text p-m-2'>
                    <svg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M12 2C12 0.9 12.9 1.35505e-07 14 8.74228e-08C15.1 3.93402e-08 16 0.9 16 2C16 3.1 15.1 4 14 4C12.9 4 12 3.1 12 2ZM10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2C6 0.900001 6.9 3.97774e-07 8 3.49691e-07C9.1 3.01609e-07 10 0.9 10 2ZM4 2C4 3.1 3.1 4 2 4C0.9 4 -3.93403e-08 3.1 -8.74228e-08 2C-1.35505e-07 0.900001 0.899999 6.60042e-07 2 6.11959e-07C3.1 5.63877e-07 4 0.900001 4 2Z'
                        fill='#C2C9D1'
                      />
                      <path
                        d='M12 2C12 0.9 12.9 1.35505e-07 14 8.74228e-08C15.1 3.93402e-08 16 0.9 16 2C16 3.1 15.1 4 14 4C12.9 4 12 3.1 12 2ZM10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2C6 0.900001 6.9 3.97774e-07 8 3.49691e-07C9.1 3.01609e-07 10 0.9 10 2ZM4 2C4 3.1 3.1 4 2 4C0.9 4 -3.93403e-08 3.1 -8.74228e-08 2C-1.35505e-07 0.900001 0.899999 6.60042e-07 2 6.11959e-07C3.1 5.63877e-07 4 0.900001 4 2Z'
                        fill='black'
                        fillOpacity='0.25'
                      />
                    </svg>
                  </Button>
                </>
              )}
            </div>
            <PDButtonBack onClick={back}>
              <i className='pi pi-angle-left' style={{fontSize: '1.5rem'}}></i>
              Back to list
            </PDButtonBack>
          </PageTitle>
          <PageId>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                ID: {params.id} <Label color={getColorByStatus(therapyProgram?.status)}>{therapyProgram?.status}</Label>
                <VersionLabel version={therapyProgram?.version} />
              </>
            )}
          </PageId>
          <PageDescription>
            <PageDescriptionText>
              {isLoading ? <Skeleton height={50} width={300} /> : therapyProgram?.description}
            </PageDescriptionText>
            {isLoading ? (
              <Skeleton width={300} height={180} />
            ) : (
              <PageImg src={therapyProgram?.media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}></PageImg>
            )}
          </PageDescription>
        </PageHeader>
        <TabView>
          <TabPanel
            header={
              <div>
                <i style={{marginRight: '5px'}} className='pi pi-bars'></i>Full Program
              </div>
            }
          >
            <ListControls>
              <Filters>{filters.map(Filter)}</Filters>
              <SearchBar value={searchValue} onChange={onSearchValueChange} />
            </ListControls>

            {isLoading ? (
              <>
                <DayActivityContainer>
                  <Skeleton />
                  <div style={{marginRight: '88px', width: '185px'}}>
                    <Skeleton />
                    <div>
                      <h1>
                        <Skeleton />
                      </h1>
                      <Skeleton height={'30px'} />
                    </div>
                  </div>
                  <ActivityList>
                    <MomentDay>
                      <Skeleton width={'20%'} />
                    </MomentDay>
                    {activitiesSkeleton.map((i) => (
                      <FullActivityCard key={i} />
                    ))}
                    <MomentDay>
                      <Skeleton width={'20%'} />
                    </MomentDay>
                    {activitiesSkeleton.map((i) => (
                      <FullActivityCard key={i} />
                    ))}
                  </ActivityList>
                </DayActivityContainer>
              </>
            ) : (
              <>
                {programActivitiesResults?.length ? null : (
                  <DayActivityContainer>
                    <ActivityList>
                      <EmptyWeeks />
                    </ActivityList>
                  </DayActivityContainer>
                )}

                <DragDropContext onDragEnd={onDragEnd}>
                  {programActivitiesResults?.map((day, i) => (
                    <DayActivityContainer key={day.day}>
                      <div style={{marginRight: '88px', width: '185px'}}>
                        <div>
                          <h1>Day {day.day}</h1>
                          <Button
                            style={{width: '100%'}}
                            label='+ Add activity'
                            className='p-button-outlined p-mr-2 p-mb-2'
                            onClick={() => addActivity(day.day)}
                          />
                        </div>
                      </div>
                      <ActivityList>
                        <>
                          <MomentDay>Morning</MomentDay>
                          <Droppable droppableId={`morning-${day.day}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={droppableStyle(snapshot.isDraggingOver, day.moments[0].activities.length, true)}
                              >
                                {day.moments[0].activities.map((act, index) => (
                                  <Draggable
                                    key={act.index}
                                    draggableId={`${act.index}`}
                                    index={index}
                                    isDragDisabled={selectedFilters.length > 0 || searchValue !== '' || act.isRepeated}
                                  >
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <FullActivityCard activity={act} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>
                          <MomentDay>Afternoon</MomentDay>
                          <Droppable droppableId={`afternoon-${day.day}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={droppableStyle(snapshot.isDraggingOver, day.moments[1].activities.length, true)}
                              >
                                {day.moments[1].activities.map((act, index) => (
                                  <Draggable
                                    key={act.index}
                                    draggableId={`${act.index}`}
                                    index={index}
                                    isDragDisabled={selectedFilters.length > 0 || searchValue !== '' || act.isRepeated}
                                  >
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <FullActivityCard activity={act} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>

                          <MomentDay>Evening</MomentDay>
                          <Droppable droppableId={`evening-${day.day}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={droppableStyle(snapshot.isDraggingOver, day.moments[2].activities.length, true)}
                              >
                                {day.moments[2].activities.map((act, index) => (
                                  <Draggable
                                    key={act.index}
                                    draggableId={`${act.index}`}
                                    index={index}
                                    isDragDisabled={selectedFilters.length > 0 || searchValue !== '' || act.isRepeated}
                                  >
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <FullActivityCard activity={act} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>

                          <MomentDay>Night</MomentDay>
                          <Droppable droppableId={`night-${day.day}`}>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={droppableStyle(snapshot.isDraggingOver, day.moments[3].activities.length, true)}
                              >
                                {day.moments[3].activities.map((act, index) => (
                                  <Draggable
                                    key={act.index}
                                    draggableId={`${act.index}`}
                                    index={index}
                                    isDragDisabled={selectedFilters.length > 0 || searchValue !== '' || act.isRepeated}
                                  >
                                    {(provided, snapshot) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <FullActivityCard activity={act} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              </div>
                            )}
                          </Droppable>
                        </>
                      </ActivityList>
                    </DayActivityContainer>
                  ))}
                </DragDropContext>
              </>
            )}
          </TabPanel>
          <TabPanel
            header={
              <div>
                <i style={{marginRight: '5px'}} className='pi pi-image'></i>Week View
              </div>
            }
          >
            <WeekSelector />
            {programActivities?.length ? null : (
              <DayActivityContainer>
                <ActivityList>
                  <ActivitiesNotFound />
                </ActivityList>
              </DayActivityContainer>
            )}

            <DragDropContext onDragEnd={onDragEnd}>
              {programActivitiesWeek.map((day, i) => (
                <DayActivityContainer>
                  <div style={{marginRight: '88px', width: '185px'}}>
                    <div>
                      <h1>Day {day.day}</h1>
                      <Button
                        style={{width: '100%'}}
                        label='+ Add activity'
                        className='p-button-outlined p-mr-2 p-mb-2'
                        onClick={() => addActivity(day.day)}
                      />
                    </div>
                  </div>
                  <ActivityList>
                    <>
                      <MomentDay>Morning</MomentDay>
                      <Droppable droppableId={`morning-${day.day}`}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={droppableStyle(snapshot.isDraggingOver, day.moments[0].activities.length, false)}
                          >
                            {day.moments[0].activities.map((act, index) => (
                              <Draggable key={act.index} draggableId={`${act.index}`} index={index}>
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <ActivityCard activity={act} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                      <MomentDay>Afternoon</MomentDay>
                      <Droppable droppableId={`afternoon-${day.day}`}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={droppableStyle(snapshot.isDraggingOver, day.moments[1].activities.length, false)}
                          >
                            {day.moments[1].activities.map((act, index) => (
                              <Draggable key={act.index} draggableId={`${act.index}`} index={index}>
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <ActivityCard activity={act} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>

                      <MomentDay>Evening</MomentDay>
                      <Droppable droppableId={`evening-${day.day}`}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={droppableStyle(snapshot.isDraggingOver, day.moments[2].activities.length, false)}
                          >
                            {day.moments[2].activities.map((act, index) => (
                              <Draggable key={act.index} draggableId={`${act.index}`} index={index}>
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <ActivityCard activity={act} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>

                      <MomentDay>Night</MomentDay>
                      <Droppable droppableId={`night-${day.day}`}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={droppableStyle(snapshot.isDraggingOver, day.moments[3].activities.length, false)}
                          >
                            {day.moments[3].activities.map((act, index) => (
                              <Draggable key={act.index} draggableId={`${act.index}`} index={index}>
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <ActivityCard activity={act} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </>
                  </ActivityList>
                </DayActivityContainer>
              ))}
            </DragDropContext>
          </TabPanel>
        </TabView>
      </ActivityListWrapper>
      <GeneralSidebar isActive={!!selectedActivity} onClick={unselectActivityAction}>
        <ActivityDetails activities={programActivitiesResults} activity={selectedActivity} weekValue={setWeekValue} />
      </GeneralSidebar>
      <Modal
        ariaHideApp={false}
        isOpen={isOpenDeleteModal}
        onRequestClose={() => {
          setIsOpenDeleteModal(false);
        }}
        contentLabel='Are you sure you want to set Default Program?'
        style={modalStyle}
        shouldCloseOnOverlayClick={false}
      >
        {therapyProgram?.isDefault ? (
          <div style={{textAlign: 'center'}}>
            <h5>The Default Program can't be deleted </h5>
            <p>To delete this program you need to set another one as default</p>
            <DefaultProgramChoiceButtonsContainer>
              <DefaultProgramSelectYesButton
                onClick={() => {
                  setIsOpenDeleteModal(false);
                }}
              >
                OK
              </DefaultProgramSelectYesButton>
            </DefaultProgramChoiceButtonsContainer>
          </div>
        ) : (
          <div style={{textAlign: 'center'}}>
            <h5>Are you sure you want to delete this program?</h5>
            <p>
              Deleting a program can't be undone. Users on this program will be notified and suggested to retake the assignment
              session or stay with the default program
            </p>
            <DefaultProgramChoiceButtonsContainer style={{justifyContent: 'center'}}>
              <DefaultProgramSelectCancelButton
                onClick={() => {
                  setIsOpenDeleteModal(false);
                }}
                style={{marginRight: '3%'}}
              >
                Cancel
              </DefaultProgramSelectCancelButton>
              <DefaultProgramSelectYesButton onClick={onDeleteProgram}>Delete program</DefaultProgramSelectYesButton>
            </DefaultProgramChoiceButtonsContainer>
          </div>
        )}
      </Modal>
    </PageContainer>
  );
};
