/* eslint-disable react-hooks/exhaustive-deps */
import GeneralSidebar from 'components/general-sidebar';
import {AddButton, CursorLoading, PageContainer, PageHeader, PageTitle} from 'components/page';
import {SearchBar} from 'components/search-bar';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {useAppSelector, useAppDispatch} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';
import {selectMedia, setCurrentMediaBankPage, unselectMedia} from 'store/action-creators';
import {listMedias, getMediaTags} from 'api/chatowl.api';
import {capitalize} from 'utils/helpers';
import ReactPaginate from 'react-paginate';
import './styles.css';
import {
  CardContent,
  CardImage,
  CardThumbnail,
  CardsWrapper,
  CardTitle,
  MediaType,
  FilterWrapper,
  ToggleContainer,
  PDButtonLink,
  PDTitle,
  PDID,
  PDHeader,
  PDField,
  PDFieldValue,
  Label,
  PDFieldLabel,
  MediasNotFound,
} from './styles';
import MediaCard from 'components/media-card';
import {ProgressSpinner} from 'primereact/progressspinner';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Link} from 'react-router-dom';
import {filterMediaByMediaType, filterMediaBySearchValue, filterMediaBySingleTag, filterMediaByTags} from 'store/action-creators';
import GeneralFilters from 'components/tools/filters';

const typest = [
  {name: 'All Types', value: 'all-types'},
  {name: 'Audio', value: 'audio'},
  {name: 'Video', value: 'video'},
  {name: 'Image', value: 'image'},
];

const PER_PAGE = 12;
const mediaBankTags: MediaBankTag[] = ['Available', 'Used'];

export const MediaBank: React.FC<{title?: string}> = ({title}) => {
  const [medias, setMedias] = useState<MediaDto[]>([]); //Load with API call in useEffect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [singleTagOptions, setSingleTagOptions] = useState<LabelDto[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const {
    selectedMedia,
    currentPage,
    filters: {tags: selectedTags, search: searchValue, mediaType: selectedMediaType, singleTag: selectedSingleTag},
  } = useAppSelector((state: RootState) => state.mediaBank, shallowEqual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
    fetchTags();
  }, []);

  useEffect(() => {
    fetchMedia();
  }, [selectedTags, selectedMediaType, selectedSingleTag]);

  useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        fetchMedia();
      }, 600);

      return () => clearTimeout(delay);
    }
  }, [searchValue]);

  const fetchTags = async () => {
    try {
      const response = (await getMediaTags()) as any;
      const mediasResponse = response.data ? response.data.data : [];
      const tagsString: string[] = mediasResponse;
      const tagOptionsNew: LabelDto[] = tagsString.map((tag) => {
        return {name: capitalize(tag), value: tag};
      });
      setSingleTagOptions([{name: 'All Tags', value: 'All Tags'}, ...tagOptionsNew]);
    } catch (error) {
      throw error; // TODO
    }
  };

  const fetchMedia = async (page: number = currentPage) => {
    setIsLoading(true);
    try {
      if (page !== currentPage) {
        dispatch(setCurrentMediaBankPage(page));
      }
      const type = selectedMediaType === 'all-types' ? undefined : selectedMediaType;
      const tag = selectedSingleTag === 'All Tags' ? undefined : selectedSingleTag;
      const isInUse = selectedTags.length === 1 ? selectedTags.includes('Used') : undefined;
      const response = (await listMedias(page, PER_PAGE, searchValue, 'name', 'asc', tag, undefined, type, isInUse)) as any;
      const mediasResponse = response.data.data ? response.data.data : [];
      setMedias(mediasResponse.result);
      setPageCount(Math.ceil(mediasResponse.total / PER_PAGE));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error; // TODO
    }
  };

  useEffect(() => {
    if (selectedMedia) {
      if (!medias.find((media) => media.id === selectedMedia.id)) {
        dispatch(unselectMedia());
      }
    }
  }, [selectedMediaType, searchValue, selectedTags, selectedSingleTag]);

  const history = useHistory();

  const EmptyCard = () => {
    return (
      <div className={'p-col-fixed'} style={{visibility: 'hidden'}}>
        <CardContent className='box p-shadow-6' onClick={onClick} isSelected={false}>
          <CardImage>
            <CardThumbnail src='' />
            <MediaType></MediaType>
          </CardImage>
          <CardTitle>Hola</CardTitle>
        </CardContent>
      </div>
    );
  };

  const onChangeSearchBar = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrentMediaBankPage(0));
    dispatch(filterMediaBySearchValue(e.target.value));
  };

  const toggleTagsFilter = (_filter: string): void => {
    const filter = _filter as MediaBankTag;
    const newTags = selectedTags.some((f) => f === filter)
      ? selectedTags.filter((f) => f !== filter) // turn off
      : [...selectedTags, filter]; // turn on
    dispatch(setCurrentMediaBankPage(0));
    dispatch(filterMediaByTags(newTags));
  };

  const onClick = (): void => {
    dispatch(unselectMedia());
  };

  const onClickCard = (mediaID: number) => {
    const mediaSelected = medias.find((media) => media.id === mediaID);
    if (mediaSelected) mediaID !== selectedMedia?.id ? dispatch(selectMedia(mediaSelected)) : dispatch(unselectMedia());
  };

  const arraySkeleton = [1, 2, 3];

  return (
    <ScrollPanel style={{width: '100%', height: '100vh'}}>
      <PageContainer>
        <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
          <PageHeader>
            <PageTitle>Media Bank</PageTitle>
            <AddButton onClick={() => history.push('/media-bank/medias/add')}>+ Add Media</AddButton>
          </PageHeader>
          <ToggleContainer>
            <GeneralFilters tags={mediaBankTags} selectedTags={selectedTags} onClickToggle={toggleTagsFilter} />
          </ToggleContainer>
          <FilterWrapper>
            <SearchBar onChange={onChangeSearchBar} value={searchValue} />

            <div>
              <Dropdown
                style={{width: '200px', marginRight: ' 10px'}}
                value={selectedMediaType}
                onChange={(e) => {
                  dispatch(setCurrentMediaBankPage(0));
                  dispatch(filterMediaByMediaType(e.value));
                }}
                options={typest}
                optionLabel='name'
              />
              <Dropdown
                style={{width: '200px', marginRight: ' 10px'}}
                value={selectedSingleTag}
                onChange={(e) => {
                  dispatch(setCurrentMediaBankPage(0));
                  dispatch(filterMediaBySingleTag(e.value));
                }}
                options={singleTagOptions}
                optionLabel='name'
              />
            </div>
          </FilterWrapper>
          {isLoading ? (
            <CardsWrapper className='p-grid'>
              {arraySkeleton.map((m, i) => (
                <MediaCard
                  loading
                  selectedMediaID={selectedMedia?.id}
                  media={medias[0]}
                  onClick={onClickCard}
                  key={i}
                ></MediaCard>
              ))}
            </CardsWrapper>
          ) : (
            <>
              {medias.length ? (
                <CardsWrapper className='p-grid'>
                  {medias.map((media) => (
                    <MediaCard selectedMediaID={selectedMedia?.id} media={media} onClick={onClickCard} key={media.id}></MediaCard>
                  ))}
                  {pageCount - 1 === currentPage && medias.length < PER_PAGE && <EmptyCard />}
                </CardsWrapper>
              ) : (
                <MediasNotFound />
              )}
            </>
          )}
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px'}}>
            {pageCount > 1 && (
              <ReactPaginate
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                previousLabel={'← Previous'}
                nextLabel={'Next →'}
                pageCount={pageCount}
                onPageChange={({selected: selectedPage}: any) => {
                  fetchMedia(selectedPage);
                }}
                containerClassName={'pagination'}
                previousLinkClassName={'pagination__link'}
                nextLinkClassName={'pagination__link'}
                disabledClassName={'pagination__link--disabled'}
                activeClassName={'pagination__link--active'}
                forcePage={currentPage}
              />
            )}
          </div>
        </div>
        <GeneralSidebar isActive={!!selectedMedia} onClick={onClick}>
          <MediaDetail media={selectedMedia} />
        </GeneralSidebar>
      </PageContainer>
    </ScrollPanel>
  );
};

interface MediaDetailProps {
  media: MediaDto | null;
}

const MediaDetail: React.FC<MediaDetailProps> = ({media}) => {
  const [loading, setLoading] = useState(true);

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <CursorLoading style={{height: '1000px'}} disable={loading}>
      <div>
        <PDHeader>
          {media?.type && <PDTitle>{capitalize(media?.type) + ' Info'}</PDTitle>}
          <PDButtonLink>
            <Link to={`/media-bank/medias/${media?.id}/edit`}>Edit</Link>
          </PDButtonLink>
        </PDHeader>
        <PDID>{media?.id}</PDID>
      </div>
      <div>
        <PDField>
          <PDFieldLabel>Name</PDFieldLabel>
          <PDFieldValue>{media?.name}</PDFieldValue>
        </PDField>
        <PDField>
          <PDFieldLabel>Tags</PDFieldLabel>
          {media?.tags?.length ? (
            <PDFieldValue>
              {media?.tags?.map((tag) => (
                <Label id={tag} color={'#008C8C'} key={tag}>
                  {tag}
                </Label>
              ))}
            </PDFieldValue>
          ) : (
            <PDFieldValue>None</PDFieldValue>
          )}
        </PDField>
        <PDField>
          <PDFieldLabel>Used for</PDFieldLabel>

          {media?.usedFor?.length ? (
            media?.usedFor?.map((tag) => <PDFieldValue key={tag}>{tag}</PDFieldValue>)
          ) : (
            <PDFieldValue>None</PDFieldValue>
          )}
        </PDField>
        {media?.type === 'audio' && (
          <PDField>
            <PDFieldLabel>Audio File</PDFieldLabel>
            {loading && (
              <PDFieldValue>
                <ProgressSpinner style={{width: '45px', height: '45px'}} />
              </PDFieldValue>
            )}
            <audio controls key={media.url} onLoadedData={handleLoaded}>
              <source src={media.url} type='audio/mpeg' />
              Your browser does not support the audio tag.
            </audio>
          </PDField>
        )}
        {media?.type === 'video' && (
          <>
            <PDField>
              <PDFieldLabel>Video formats</PDFieldLabel>
              {loading && (
                <PDFieldValue>
                  <ProgressSpinner style={{width: '45px', height: '45px'}} />
                </PDFieldValue>
              )}
              <video
                width='300'
                controls
                key={media.url}
                onLoadedData={handleLoaded}
                poster={media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
              >
                <source src={media.url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </PDField>
            {media.portraitVideoUrl && (
              <PDField>
                <video
                  width='300'
                  controls
                  key={media.url}
                  onLoadedData={handleLoaded}
                  poster={media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                >
                  <source src={media.portraitVideoUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </PDField>
            )}
          </>
        )}
        <PDField>
          <PDFieldLabel>Image formats</PDFieldLabel>
          {loading && (
            <PDFieldValue>
              <ProgressSpinner style={{width: '45px', height: '45px'}} />
            </PDFieldValue>
          )}
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {media?.crops.find((crop) => crop.type === 'squared') && (
              <img
                style={{maxWidth: '40%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'squared')?.url}
                alt={media?.name}
              ></img>
            )}

            {media?.crops.find((crop) => crop.type === 'full_width_regular') && (
              <img
                style={{width: '90%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                alt={media?.name}
              ></img>
            )}
            {media?.crops.find((crop) => crop.type === 'full_screen_landscape') && (
              <img
                style={{maxWidth: '100%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'full_screen_landscape')?.url}
                alt={media?.name}
              ></img>
            )}

            {media?.crops.find((crop) => crop.type === 'full_screen_portrait') && (
              <img
                style={{maxWidth: '50%', marginTop: '20px', marginBottom: '10px'}}
                src={media?.crops.find((crop) => crop.type === 'full_screen_portrait')?.url}
                alt={media?.name}
              ></img>
            )}
            {media?.crops.find((crop) => crop.type === 'full_width_tall') && (
              <img
                style={{maxWidth: '60%', marginTop: '20px', marginBottom: '10px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                alt={media?.name}
                onLoad={handleLoaded}
              ></img>
            )}
          </div>
        </PDField>
      </div>
    </CursorLoading>
  );
};
