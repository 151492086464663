import {PageHeader as PageHeaderGlobal, PageContainer as PageContainerGlobal} from 'components/page';
import styled from 'styled-components';

export const PageContainer = styled(PageContainerGlobal)`
  padding: 0px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const PageHeader = styled(PageHeaderGlobal)`
  padding: 32px 52px 0px 52px;
`;

export const SearchFilterContainer = styled.div`
  display: flex;
`;

type FilterOptionProps = {
  color: string;
  selected: boolean;
};

export const FilterOption = styled.div`
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-radius: 16.5px;
  border-color: ${(props: FilterOptionProps) => props.color};
  color: ${(props: FilterOptionProps) => (props.selected ? 'white' : props.color)};
  background-color: ${(props: FilterOptionProps) => (props.selected ? props.color : 'white')};
  font-size: 1em;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 5px 5px;
  padding: 5px 20px;
  cursor: pointer;
  user-select: none;
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  min-height: 116px;
  border-top: 1px solid #b4c1c1;
  font-size: 20px;
  cursor: pointer;
  height: fit-content;
`;
