import React, {useEffect, useState} from 'react';
import {ActionButtonsContainer, CloseModal, ModalBody} from './styles';
import Modal from 'react-modal';
import {ButtonLink, CancelButton, SaveButton} from 'components/page';
import {Slider} from 'primereact/slider';
import imageCompression from 'browser-image-compression';
import FadeLoader from 'react-spinners/FadeLoader';

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '650px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
    zIndex: 255,
  },
};

const applyBrightness = (data: any, brightness: number) => {
  for (var i = 0; i < data.length; i += 4) {
    data[i] += 255 * (brightness / 100);
    data[i + 1] += 255 * (brightness / 100);
    data[i + 2] += 255 * (brightness / 100);
  }
};

const applyContrast = (data: any, contrast: number) => {
  var factor = (259.0 * (contrast + 255.0)) / (255.0 * (259.0 - contrast));

  for (var i = 0; i < data.length; i += 4) {
    data[i] = truncateColor(factor * (data[i] - 128.0) + 128.0);
    data[i + 1] = truncateColor(factor * (data[i + 1] - 128.0) + 128.0);
    data[i + 2] = truncateColor(factor * (data[i + 2] - 128.0) + 128.0);
  }
};

const truncateColor = (value: number) => {
  if (value < 0) {
    value = 0;
  } else if (value > 255) {
    value = 255;
  }

  return value;
};

type MediaSettingsProps = {
  mediaUrl: string;
  mediaBlob: any;
  onSaveSettings: (brightnessValue: number, contrastValue: number, url: string) => void;
  settings: ImageSettings | undefined;
};

export const MediaSettings: React.FC<MediaSettingsProps> = ({mediaUrl, mediaBlob, onSaveSettings, settings}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [brightnessValue, setBrightnessValue] = useState(0);
  const [contrastValue, setContrastValue] = useState(0);
  const [imgOriginalCmp, setImgOriginalCmp] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [imgShow, setImgShow] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [mediaUrl]);

  const compressImage = async () => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 570,
    };

    const compimg = await imageCompression(mediaBlob, options);

    const image = await imageCompression.getDataUrlFromFile(compimg);

    setImgOriginalCmp(image);
    setImgShow(image);

    if (settings) {
      let settingsValue = settings;
      if (Object.keys(settings).length === 0) settingsValue = {brightness: 0, contrast: 0};
      else if (settings.brightness === undefined) settingsValue = JSON.parse(settings as any);
      setBrightnessValue(settingsValue.brightness);
      setContrastValue(settingsValue.contrast);
    }

    setBrightness();
  };

  useEffect(() => {
    if (settings) {
      let settingsValue = settings;
      if (Object.keys(settings).length === 0) settingsValue = {brightness: 0, contrast: 0};
      else if (settings?.brightness === undefined) settingsValue = JSON.parse(settings as any);
      setBrightnessValue(settingsValue.brightness);
      setContrastValue(settingsValue.contrast);
    }
  }, []);

  useEffect(() => {
    setBrightness();
  }, [isOpen]);

  useEffect(() => {
    if (mediaBlob && mediaUrl) {
      compressImage();
    }
  }, [mediaBlob, mediaUrl]);

  const onOpenModal = () => {
    setBrightness();
    setIsOpen(true);
  };

  const onCancelModal = () => {
    if (settings) {
      let settingsValue = settings;
      if (Object.keys(settings).length === 0) settingsValue = {brightness: 0, contrast: 0};
      else if (settings.brightness === undefined) settingsValue = JSON.parse(settings as any);
      setBrightnessValue(settingsValue.brightness);
      setContrastValue(settingsValue.contrast);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isLoading) setBrightness();
  }, [isLoading]);

  const setBrightness = (save: boolean = false) => {
    var img = new Image();
    var canvas: any = document.getElementById('canvas');
    var ctx = canvas && canvas.getContext('2d');

    img.crossOrigin = 'anonymous';

    if (save) img.src = mediaUrl;
    else img.src = imgOriginalCmp;

    img.onload = async function () {
      canvas.width = img.width;
      canvas.height = img.height;

      img.crossOrigin = 'anonymous';
      img.setAttribute('crossOrigin', 'anonymous');

      ctx.drawImage(img, 0, 0);

      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      brightnessValue && applyBrightness(imageData.data, brightnessValue);

      contrastValue && applyContrast(imageData.data, contrastValue);

      ctx.putImageData(imageData, 0, 0);

      if (save) setImgUrl(canvas?.toDataURL('image/jpeg'));
      else setImgShow(canvas?.toDataURL('image/jpeg'));
    };
  };

  useEffect(() => {
    if (imgUrl != '') {
      onSaveSettings(brightnessValue, contrastValue, imgUrl);
      setIsOpen(false);
    }
  }, [imgUrl]);

  const changeBrightness = (e: any) => {
    const value = e.value;
    setBrightnessValue(value);
    setBrightness();
  };

  const changeContrast = (e: any) => {
    const value = e.value;
    setContrastValue(value);
    setBrightness();
  };

  const onSave = () => {
    setBrightness(true);
  };

  return (
    <>
      <canvas id='canvas' style={{display: 'none'}}></canvas>
      <canvas id='finCanvas' style={{display: 'none'}}></canvas>
      <img></img>
      <ButtonLink onClick={onOpenModal}>
        <i className='pi pi-cog' style={{marginRight: '3px'}} /> Brightness settings
      </ButtonLink>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onCancelModal}
        contentLabel='Select image'
        style={style}
        shouldCloseOnOverlayClick={false}
      >
        <CloseModal onClick={onCancelModal}>x</CloseModal>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <ModalBody>
            <div>
              <img crossOrigin='anonymous' src={imgShow} width='100%' onLoad={() => setIsLoading(false)} />
              {isLoading && (
                <div style={{display: 'flex'}}>
                  <FadeLoader color={'#12E7B7'} />
                </div>
              )}
            </div>
            {!isLoading && (
              <>
                <div style={{width: '50%'}}>
                  <label>Brightness</label>
                  <Slider min={-50} max={50} value={brightnessValue} onChange={changeBrightness} />
                </div>
                <div style={{width: '50%'}}>
                  <label>Contrast</label>
                  <Slider min={-50} max={50} value={contrastValue} onChange={changeContrast} />
                </div>
              </>
            )}
          </ModalBody>
        </div>
        {!isLoading && (
          <ActionButtonsContainer>
            <CancelButton onClick={onCancelModal}>Cancel</CancelButton>
            <SaveButton onClick={onSave}>Apply</SaveButton>
          </ActionButtonsContainer>
        )}
      </Modal>
    </>
  );
};
export default MediaSettingsProps;
