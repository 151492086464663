import {Checkbox} from 'primereact/checkbox';
import {SyntheticEvent} from 'react';
import {SpaceCheckboxes} from './style';

type CheckboxEvent = {
  originalEvent: SyntheticEvent<Element, Event>;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

interface GeneralCheckboxTypes {
  formValues: string[];
  value: string;
  label: string;
  onChange: (event: CheckboxEvent) => void;
}

export const GeneralCheckbox: React.FC<GeneralCheckboxTypes> = ({formValues, value, label, onChange}) => {
  return (
    <SpaceCheckboxes>
      <Checkbox
        inputId={value}
        value={value}
        onChange={(e: CheckboxEvent) => onChange(e)}
        checked={formValues?.includes(value)}
      ></Checkbox>
      <label htmlFor={value} className='p-checkbox-label' style={{marginLeft: '8px'}}>
        {label}
      </label>
    </SpaceCheckboxes>
  );
};
export default GeneralCheckbox;
