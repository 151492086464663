function reduceCategory<T>(
  node: CategoryToolTreeNode | null,
  reduce: (prevVal: T, n: CategoryToolTreeNode, level: number, parent: CategoryToolTreeNode | null) => T,
  level: number,
  parent: CategoryToolTreeNode | null,
  identity: T
): T {
  if (node) {
    return (node.subcategories || []).reduce((prev, child, idx) => {
      return reduceCategory(child, reduce, level + 1, node, prev);
    }, reduce(identity, node, level, parent));
  } else {
    return identity;
  }
}
function reduceCategories<T>(
  rootNodes: CategoryToolTreeNode[] | null | undefined,
  reduce: (prevVal: T, n: CategoryToolTreeNode, level: number, parent: CategoryToolTreeNode | null) => T,
  identity: T
) {
  return (rootNodes || []).reduce((result, category) => {
    return reduceCategory(category, reduce, 0, null, result);
  }, identity);
}

export function flatMapCategory<T>(
  node: CategoryToolTreeNode,
  map: (
    node: CategoryToolTreeNode,
    level: number,
    parent: CategoryToolTreeNode | null,
    distanceToParent: number,
    index: number
  ) => T,
  filter:
    | ((node: CategoryToolTreeNode, level: number, parent: CategoryToolTreeNode | null, dist: number, index: number) => boolean)
    | undefined,
  nodes: T[],
  parent: CategoryToolTreeNode | null,
  level: number,
  distanceToParent: number,
  index: number
) {
  if (node) {
    if (!filter || filter(node, level, parent, distanceToParent, index))
      nodes.push(map(node, level, parent, distanceToParent, index));
    const addChildren: number = (node.subcategories || []).reduce((childrenUpToNow, subCat, idx) => {
      return childrenUpToNow + flatMapCategory(subCat, map, filter, nodes, node, level + 1, childrenUpToNow, idx);
    }, 1);

    return addChildren;
  }
  return 0;
}
export function flatMapCategories<T>(
  rootNodes: CategoryToolTreeNode[] | undefined | null,
  map: (node: CategoryToolTreeNode, level: number, parent: CategoryToolTreeNode | null, dist: number, index: number) => T,
  filter?: (
    node: CategoryToolTreeNode,
    level: number,
    parent: CategoryToolTreeNode | null,
    dist: number,
    index: number
  ) => boolean
): T[] {
  const result = [] as T[];
  rootNodes?.forEach((n, idx) => flatMapCategory(n, map, filter, result, null, 0, 0, idx));
  return result;
}
function filterCategoryNode(
  node: CategoryToolTreeNode,
  filter: (node: CategoryToolTreeNode, level?: number, parent?: CategoryToolTreeNode | null) => boolean,
  level: number,
  parent: CategoryToolTreeNode | null
) {
  if (node) {
    let filteredChildren = (node?.subcategories || []).reduce((children, child) => {
      const filteredNode = filterCategoryNode(child, filter, level + 1, node);

      if (filteredNode) {
        children.push(filteredNode);
      }
      return children;
    }, [] as CategoryToolTreeNode[]);
    if (filteredChildren.length || filter(node, level, parent)) {
      return {...node, children: filteredChildren};
    }
  }
  return null;
}
export function filterCategoryTree(
  rootNodes: CategoryToolTreeNode[],
  filter: (node: CategoryToolTreeNode, level?: number, parent?: CategoryToolTreeNode | null) => boolean
): CategoryToolTreeNode[] {
  return rootNodes.map((n) => filterCategoryNode(n, filter, 0, null)).filter((v) => !!v) as CategoryToolTreeNode[];
}
