import {addQuote, updateQuote as updateQuote, getCategoryTree, getPromoteTypesTools, updateImage} from 'api/chatowl.api';
import {FormElement, StyledFormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {
  ArchiveButton,
  CancelButton,
  CursorLoading,
  Form,
  PageBody,
  PageContainer as GlobalPageContaner,
  PageFooter,
  PageHeader,
  PageTitle,
  SaveButton,
} from 'components/page';
import SpinnerLoading from 'components/spinner-loading';
import CategorySelector from 'components/tools/category-selector';
import {Dropdown} from 'primereact/dropdown';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {shallowEqual} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {selectQuote, sendMessage, sendUndoToast, unselectQuote} from 'store/action-creators';
import styled from 'styled-components';
import {isAnyStringEmpty} from 'utils/helpers';
import {listQuotes} from '../../../api/chatowl.api';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {useToolToPromote} from '../hooks/useToolToPromote';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const imagesTypes: CropType[] = ['full_width_regular', 'full_screen_portrait']; //En diseño aparecen 3 en un caso, pero 2 arriba a la derecha

type MediaDetailsFormType = 'add' | 'edit';

export const AddQuote: React.FC<{title?: string}> = ({title}) => {
  const params = useParams<{formType: MediaDetailsFormType; quoteId: string}>();
  const formType = params.formType;
  const quoteId = +params.quoteId || null;
  let selectedQuote: QuoteDto | null = useAppSelector((state: RootState) => state.tools.quotes.selectedQuote, shallowEqual);

  const [quoteContent, setQuoteContent] = useState<string>('');
  const [quoteAuthor, setQuoteAuthor] = useState<string>('');
  const [quoteCategories, setQuoteCategories] = useState<number[]>([]);
  const [userClickedArchive, setUserClickedArchive] = useState<boolean>(false);
  const [mediaId, setMediaId] = useState<number | undefined>(0);
  const [newCrops, setNewCrops] = useState<Crop[]>([]);
  const dispatch = useAppDispatch();
  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const editing: boolean = formType == 'edit' && !!selectedQuote;
  const [saving, setSaving] = useState(false);
  const [quotes, setQuotes] = useState<QuoteDto[]>([]);
  const [quotesLoading, setQuotesLoading] = useState(false);

  const history = useHistory();

  const {
    typesToolsOptions,
    toolTypeToPromote,
    setToolTypeToPromote,
    toolsOptions,
    toolIdToPromote,
    setToolIdToPromote,
  } = useToolToPromote();

  const validateSave = (content: string, author: string, mediaId: number | undefined): boolean =>
    !(toolTypeToPromote && !toolIdToPromote) && !!content && mediaId !== 0 && !!author;

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    if (selectedQuote && formType === 'edit') {
      setQuoteContent(selectedQuote.quoteContent!);
      setQuoteAuthor(selectedQuote.quoteAuthor);
      setQuoteCategories((selectedQuote.toolCategories || []).map((v) => v.id));
      setMediaId(selectedQuote.mediaId);
    }
  }, []);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = (await listQuotes({withDeleted: false, sortBy: 'id'})) as any;
        const quotesResponse = response.data ? response.data.data : [];
        setQuotes(quotesResponse);
        const quoteSelected = quotesResponse.find((quote: any) => quote.id === quoteId) as QuoteDto;
        if (quoteSelected) dispatch(selectQuote(quoteSelected));
        setQuoteContent(quoteSelected?.quoteContent!);
        setQuoteAuthor(quoteSelected.quoteAuthor);
        setQuoteCategories((quoteSelected.toolCategories || []).map((v) => v.id));
        setMediaId(quoteSelected.mediaId);
        setToolTypeToPromote(quoteSelected.promotedTool?.toolType);
        setToolIdToPromote(quoteSelected.promotedTool?.id);
      } catch (error) {
        throw error; // TODO
      } finally {
        setQuotesLoading(false);
      }
    };
    if (quoteId) {
      setQuotesLoading(true);
      fetchQuotes();
    }
  }, []);

  const onChangeToolTypeToPromote = (e: any) => {
    setToolIdToPromote(undefined);
    if (e.value === 'none') {
      setToolTypeToPromote(undefined);
    } else {
      setToolTypeToPromote(e.value);
    }
  };

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onCancelQuote = () => {
    history.push('/tools/quotes');
  };

  const onArchiveQuote = async () => {
    if (!userClickedArchive) setUserClickedArchive(true);
    else {
      setSaving(true);
      const editedQuote: UpdateQuoteRequest = {
        isArchived: !selectedQuote?.isArchived,
      };
      try {
        let updatedQuote = (await updateQuote(editedQuote, selectedQuote?.id!)) as any;
        if (updatedQuote.response.status >= 200 && updatedQuote.response.status < 300) {
          updatedQuote = updatedQuote.data.data;
          dispatch(unselectQuote());
          !selectedQuote?.isArchived && dispatch(sendUndoToast({idTool: selectedQuote?.id!, toolType: 'quote'}));
          history.push('/tools/quotes');
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          setSaving(false);
        }
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const onSaveQuote = async () => {
    if (validateSave(quoteContent, quoteAuthor, mediaId) && !isAnyStringEmpty([quoteContent, quoteAuthor])) {
      setSaving(true);

      let errorEditingMedia = false;
      if (newCrops.length > 0) {
        const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
        const fullScreenLandscape = newCrops!.find((crop) => crop.type === 'full_screen_landscape');
        const fullScreenPortrait = newCrops!.find((crop) => crop.type === 'full_screen_portrait');
        const squared = newCrops!.find((crop) => crop.type === 'squared');
        const crops: UpdateMediaImageRequest = {
          fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
          fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
          fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
          fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
          squared: squared ? squared.blob : undefined,
        };
        const mediaImage = (await updateImage(mediaId!, crops)) as any;
        if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
          setSaving(false);
          errorEditingMedia = true;
        }
      }
      const newQuote: UpdateQuoteRequest = {
        categories: quoteCategories,
        mediaId: mediaId!,
        quoteAuthor,
        quoteContent,
        startToolId: toolIdToPromote ?? null,
      };
      if (!errorEditingMedia) {
        if (!editing) {
          const newQuote: CreateQuoteRequest = {
            categories: quoteCategories,
            mediaId: mediaId!,
            quoteAuthor,
            quoteContent,
            startToolId: toolIdToPromote ?? null,
          };
          try {
            const createdQuote = (await addQuote(newQuote)) as any;
            if (createdQuote.response.status >= 200 && createdQuote.response.status < 300) {
              dispatch(selectQuote(createdQuote.data.data));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Quote created successfully',
                })
              );
              history.push('/tools/quotes');
            } else {
              if (quoteContent.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Quote content must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        } else {
          //Editing
          try {
            const editedQuote = selectedQuote?.id && ((await updateQuote(newQuote, selectedQuote?.id)) as any);
            if (editedQuote.response.status >= 200 && editedQuote.response.status < 300) {
              dispatch(selectQuote(editedQuote.data.data));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Quote updated successfully',
                })
              );
              history.push('/tools/quotes');
            } else {
              if (quoteContent.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Quote content must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        }
      }
    } else {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Please fill all the fields'}));
    }
  };
  return (
    <CursorLoading disable={saving}>
      {quotesLoading ? (
        <SpinnerLoading show />
      ) : (
        <PageContainer>
          <PageHeader>{editing ? <PageTitle>Edit Quote</PageTitle> : <PageTitle>Add Quote</PageTitle>}</PageHeader>
          <PageBody>
            <Form>
              <FormElement
                type='text-area'
                label='Quote'
                value={quoteContent}
                placeholder='Quote Content'
                onChange={(inputValue) => setQuoteContent(inputValue)}
              />

              <FormElement
                type='input'
                label='Author'
                value={quoteAuthor}
                placeholder='Quote Author'
                onChange={(inputValue) => setQuoteAuthor(inputValue)}
              />

              <StyledFormElement>
                <label htmlFor={`input Category`}>{'Category'}</label>
                <CategorySelector
                  loading={loadingCategories}
                  selected={quoteCategories}
                  categories={categoryTree}
                  onChange={setQuoteCategories}
                  error={!!errorLoadingCategories}
                />
              </StyledFormElement>
              <StyledFormElement>
                <label htmlFor='toolTypeToPromote'>{'Promote'}</label>
                <Dropdown
                  id='toolTypeToPromote'
                  value={toolTypeToPromote}
                  options={typesToolsOptions}
                  onChange={onChangeToolTypeToPromote}
                  placeholder='Select tool type to promote'
                  optionLabel='name'
                />
                {/* promoteLabels cargado dinamicamente */}
              </StyledFormElement>
              {toolTypeToPromote && (
                <StyledFormElement>
                  <label htmlFor='toolIdToPromote'>{`Select which ${toolTypeToPromote} to promote`}</label>
                  <Dropdown
                    id='toolIdToPromote'
                    value={toolIdToPromote}
                    options={toolsOptions}
                    onChange={(e) => setToolIdToPromote(e.value)}
                    placeholder={`Select ${toolTypeToPromote} to promote`}
                    optionLabel='name'
                  />
                  {/* promoteLabels cargado dinamicamente */}
                </StyledFormElement>
              )}
            </Form>
            <StyledFormElement style={{width: '100%'}}>
              {!editing ? (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                />
              ) : (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  media={selectedQuote!.media}
                  mediaType={'image'}
                  key={'Image'}
                  imagesTypes={imagesTypes}
                  addCrop={addCrop}
                />
              )}
            </StyledFormElement>
          </PageBody>
          <PageFooter>
            {editing &&
              (!selectedQuote!.isArchived ? (
                <ArchiveButton onClick={onArchiveQuote} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Archive ?' : 'Archive'}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={onArchiveQuote} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Unarchive ?' : 'Unarchive'}
                </ArchiveButton>
              ))}

            <CancelButton onClick={onCancelQuote}>
              <Link to='/tools/quotes/'>Cancel</Link>
            </CancelButton>
            <SaveButton disabled={saving} onClick={onSaveQuote} disable={saving}>
              {saving ? 'Saving...' : 'Save'}
            </SaveButton>
          </PageFooter>
        </PageContainer>
      )}
    </CursorLoading>
  );
};
