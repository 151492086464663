import {ActionReducerMapBuilder, createReducer} from '@reduxjs/toolkit';
import {
  clearFilters,
  filterCategoriesBySearchValue,
  filterCategoriesByTags,
  filterExercisesBySearchValue,
  filterExercisesByTags,
  filterImagesBySearchValue,
  filterImagesByTags,
  filterMediaByMediaType,
  filterMediaBySearchValue,
  filterMediaBySingleTag,
  filterMediaByTags,
  filterQuotesBySearchValue,
  filterQuotesByTags,
  filterSessionsBySearchValue,
  filterSessionsByTags,
  setCurrentMediaBankPage,
} from './action-creators';
import {
  hideLoading,
  selectActivity,
  setActivitiesToSelectedProgram,
  setInfoToNewActivityForm,
  showLoading,
  unselectActivity,
} from './action-creators'; //Action creators for activityReducer
import {
  selectExercise,
  unselectExercise,
  selectSession,
  unselectSession,
  selectQuote,
  unselectQuote,
  selectImage,
  unselectImage,
} from './action-creators'; //Action creators for toolsReducer
import {selectMedia, unselectMedia} from './action-creators'; //Action creators for exerciseReducer
import {sendMessage} from './action-creators'; //Action creators for generalToast
import {sendUndoToast} from './action-creators'; //Action creators for undoArchiveReducer
import {
  selectPlansFilter,
  unselectPlansFilter,
  selectSubscriptionStatusFilter,
  unselectSubscriptionStatusFilter,
  selectTagsFilter,
  unselectTagsFilter,
  clearSelectedPlans,
  clearSelectedSubscriptionStatus,
  clearSelectedTags,
  setFiltersApplied,
  unsetFiltersApplied,
} from './action-creators'; //Actions creators for filters

import {selectMessagingTagsFilter, unselectMessagingTagsFilter} from './action-creators'; //Actions creators for messaging

const initialActivityState: ActivityState = {
  selectedActivity: null,
  newActivity: {
    day: 1,
  },
};

export const activityReducer = createReducer(initialActivityState, (builder: ActionReducerMapBuilder<ActivityState>) => {
  builder
    .addCase(setInfoToNewActivityForm, (state: ActivityState, action: ActivityAction) => {
      state.newActivity = action.payload! as ActivityForm;
    })
    .addCase(selectActivity, (state: ActivityState, action: ActivityAction) => {
      state.selectedActivity = action.payload! as ActivityDto;
    })
    .addCase(unselectActivity, (state: ActivityState) => {
      state.selectedActivity = null;
    });
});

const initialProgramState: ProgramState = {
  selectedProgram: null,
};

export const programReducer = createReducer(initialProgramState, (builder: ActionReducerMapBuilder<ProgramState>) => {
  builder.addCase(setActivitiesToSelectedProgram, (state: ProgramState, action: ProgramAction) => {
    if (state.selectedProgram) {
      state.selectedProgram.programActivities = action.payload! as ActivityDay[];
    }
  });
});

const initialToolsFilterState: ToolFilters = {
  tags: ['Active'],
  search: '',
};

const initialToolsState: ToolsState = {
  exercises: {
    selectedExercise: null,
    filters: initialToolsFilterState,
  },
  sessions: {
    selectedSession: null,
    filters: initialToolsFilterState,
  },
  quotes: {
    selectedQuote: null,
    filters: initialToolsFilterState,
  },
  images: {
    selectedImage: null,
    filters: initialToolsFilterState,
  },
  categories: {
    filters: initialToolsFilterState,
  },
};

export const toolsReducer = createReducer(initialToolsState, (builder: ActionReducerMapBuilder<ToolsState>) => {
  builder
    .addCase(selectExercise, (state: ToolsState, action: ExerciseAction) => {
      state.exercises.selectedExercise = action.payload!;
    })
    .addCase(unselectExercise, (state: ToolsState) => {
      state.exercises.selectedExercise = null;
    })
    .addCase(filterExercisesBySearchValue, (state: ToolsState, action: FilterSearchAction) => {
      state.exercises.filters.search = action.payload!;
    })
    .addCase(filterExercisesByTags, (state: ToolsState, action: FilterToolTagsAction) => {
      state.exercises.filters.tags = action.payload!;
    })
    .addCase(selectSession, (state: ToolsState, action: SessionAction) => {
      state.sessions.selectedSession = action.payload!;
    })
    .addCase(unselectSession, (state: ToolsState) => {
      state.sessions.selectedSession = null;
    })
    .addCase(filterSessionsBySearchValue, (state: ToolsState, action: FilterSearchAction) => {
      state.sessions.filters.search = action.payload!;
    })
    .addCase(filterSessionsByTags, (state: ToolsState, action: FilterToolTagsAction) => {
      state.sessions.filters.tags = action.payload!;
    })
    .addCase(selectQuote, (state: ToolsState, action: QuoteAction) => {
      state.quotes.selectedQuote = action.payload!;
    })
    .addCase(unselectQuote, (state: ToolsState) => {
      state.quotes.selectedQuote = null;
    })
    .addCase(filterQuotesBySearchValue, (state: ToolsState, action: FilterSearchAction) => {
      state.quotes.filters.search = action.payload!;
    })
    .addCase(filterQuotesByTags, (state: ToolsState, action: FilterToolTagsAction) => {
      state.quotes.filters.tags = action.payload!;
    })
    .addCase(selectImage, (state: ToolsState, action: ImageAction) => {
      state.images.selectedImage = action.payload!;
    })
    .addCase(unselectImage, (state: ToolsState) => {
      state.images.selectedImage = null;
    })
    .addCase(filterImagesBySearchValue, (state: ToolsState, action: FilterSearchAction) => {
      state.images.filters.search = action.payload!;
    })
    .addCase(filterImagesByTags, (state: ToolsState, action: FilterToolTagsAction) => {
      state.images.filters.tags = action.payload!;
    })
    .addCase(filterCategoriesBySearchValue, (state: ToolsState, action: FilterSearchAction) => {
      state.categories.filters.search = action.payload!;
    })
    .addCase(filterCategoriesByTags, (state: ToolsState, action: FilterToolTagsAction) => {
      state.categories.filters.tags = action.payload!;
    })
    .addCase(clearFilters, (state: ToolsState, action: ClearFiltersAction) => {
      const exclude = action.payload?.exclude;

      if (exclude !== 'exercises') {
        state.exercises.filters = initialToolsFilterState;
      }
      if (exclude !== 'sessions') {
        state.sessions.filters = initialToolsFilterState;
      }
      if (exclude !== 'quotes') {
        state.quotes.filters = initialToolsFilterState;
      }
      if (exclude !== 'images') {
        state.images.filters = initialToolsFilterState;
      }
      if (exclude !== 'categories') {
        state.categories.filters = initialToolsFilterState;
      }
    });
});

const initialMediaBankFiltersState: MediaBankFilters = {
  tags: [],
  search: '',
  mediaType: 'all-types',
  singleTag: 'All Tags',
};

const initialMediaBankState: MediaBankState = {
  selectedMedia: null,
  filters: initialMediaBankFiltersState,
  currentPage: 0,
};

export const mediaBankReducer = createReducer(initialMediaBankState, (builder: ActionReducerMapBuilder<MediaBankState>) => {
  builder
    .addCase(selectMedia, (state: MediaBankState, action: MediaBankAction) => {
      state.selectedMedia = action.payload!;
    })
    .addCase(unselectMedia, (state: MediaBankState) => {
      state.selectedMedia = null;
    })
    .addCase(setCurrentMediaBankPage, (state: MediaBankState, action: CurrentMediaBankPageAction) => {
      state.currentPage = action.payload!;
    })
    .addCase(filterMediaByTags, (state: MediaBankState, action: FilterMediaTagsAction) => {
      state.filters.tags = action.payload!;
    })
    .addCase(filterMediaBySearchValue, (state: MediaBankState, action: FilterSearchAction) => {
      state.filters.search = action.payload!;
    })
    .addCase(filterMediaByMediaType, (state: MediaBankState, action: FilterMediaTypeAction) => {
      state.filters.mediaType = action.payload!;
    })
    .addCase(filterMediaBySingleTag, (state: MediaBankState, action: FilterMediaSingleTagAction) => {
      state.filters.singleTag = action.payload!;
    })
    .addCase(clearFilters, (state: MediaBankState, action: ClearFiltersAction) => {
      const exclude = action.payload?.exclude;
      if (exclude !== 'media-bank') {
        state.currentPage = 0;
        state.filters = initialMediaBankFiltersState;
      }
    });
});

const initialGeneralToast: GeneralToastState = {
  toastInfo: null,
  toggleToast: false,
};

export const generalToastReducer = createReducer(initialGeneralToast, (builder: ActionReducerMapBuilder<GeneralToastState>) => {
  builder.addCase(sendMessage, (state: GeneralToastState, action: GeneralToastAction) => {
    state.toastInfo = action.payload!;
    state.toggleToast = !state.toggleToast; //Always updating state.
  });
});

const initialUndoArchive: UndoArchiveState = {
  toastInfo: null,
  toggleToast: false,
};

export const undoArchiveReducer = createReducer(initialUndoArchive, (builder: ActionReducerMapBuilder<UndoArchiveState>) => {
  builder.addCase(sendUndoToast, (state: UndoArchiveState, action: UndoArchiveAction) => {
    state.toastInfo = action.payload!;
    state.toggleToast = !state.toggleToast; //Always updating state.
  });
});

const initialLoading: LoadingState = {
  show: false,
};

export const loadingReducer = createReducer(initialLoading, (builder: ActionReducerMapBuilder<LoadingState>) => {
  builder
    .addCase(showLoading, (state: LoadingState) => {
      state.show = true;
    })
    .addCase(hideLoading, (state: LoadingState) => {
      state.show = false;
    });
});

const initialClientFilters: ClientFilters = {
  filtersApplied: false,
  selectedPlans: [],
  selectedSubscriptionStatus: [],
  selectedTags: [],
};

export const clientFiltersReducer = createReducer(initialClientFilters, (builder: ActionReducerMapBuilder<ClientFilters>) => {
  builder
    .addCase(clearSelectedPlans, (state: ClientFilters) => {
      state.selectedPlans = [];
    })
    .addCase(clearSelectedSubscriptionStatus, (state: ClientFilters) => {
      state.selectedSubscriptionStatus = [];
    })
    .addCase(clearSelectedTags, (state: ClientFilters) => {
      state.selectedTags = [];
    })
    .addCase(selectPlansFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedPlans = [...state.selectedPlans, action.payload!];
    })
    .addCase(unselectPlansFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedPlans = state.selectedPlans.filter((plan) => plan !== action.payload!);
    })
    .addCase(selectSubscriptionStatusFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedSubscriptionStatus = [...state.selectedSubscriptionStatus, action.payload!];
    })
    .addCase(unselectSubscriptionStatusFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedSubscriptionStatus = state.selectedSubscriptionStatus.filter((status) => status !== action.payload!);
    })
    .addCase(selectTagsFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedTags = [...state.selectedTags, action.payload!];
    })
    .addCase(unselectTagsFilter, (state: ClientFilters, action: ClientFilterAction) => {
      state.selectedTags = state.selectedTags.filter((tag) => tag !== action.payload!);
    })
    .addCase(setFiltersApplied, (state: ClientFilters) => {
      state.filtersApplied = true;
    })
    .addCase(unsetFiltersApplied, (state: ClientFilters) => {
      state.filtersApplied = false;
    });
});

const initialMessagingFilters: MessagingFilters = {
  selectedTags: [],
};

export const messagingFiltersReducer = createReducer(
  initialMessagingFilters,
  (builder: ActionReducerMapBuilder<MessagingFilters>) => {
    builder
      .addCase(selectMessagingTagsFilter, (state: MessagingFilters, action: MessagingFilterAction) => {
        state.selectedTags = [...state.selectedTags, action.payload!];
      })
      .addCase(unselectMessagingTagsFilter, (state: MessagingFilters, action: MessagingFilterAction) => {
        state.selectedTags = state.selectedTags.filter((tag) => tag !== action.payload!);
      });
  }
);
