import React, {useState, useEffect} from 'react';
import {StyledFormElement} from 'components/form-element';
import {Label} from 'components/page';
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import {repeatValues} from 'utils/values';

type ActivityRepeatIntervalProps = {
  repeatInterval: number;
  setRepeatInterval: (repeatInterval: number) => void;
};

export const ActivityRepeatInterval: React.FC<ActivityRepeatIntervalProps> = ({repeatInterval, setRepeatInterval}) => {
  const [repeating, setRepeating] = useState(repeatInterval !== 0);

  useEffect(() => {
    if (!repeating) {
      setRepeatInterval(0);
    }
  }, [repeating]);

  return (
    <>
      <StyledFormElement style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Label>Repeating</Label>
        <InputSwitch checked={repeating} onChange={(e) => setRepeating(e.value)} />
      </StyledFormElement>
      <StyledFormElement isVisible={repeating}>
        <label htmlFor='repeatIntervalActivity'>Repeat interval</label>
        <Dropdown
          id='repeatIntervalActivity'
          value={repeatInterval}
          options={repeatValues}
          optionLabel='name'
          onChange={(e) => setRepeatInterval(e.value)}
          placeholder='Select repetition frequency'
        />
      </StyledFormElement>
    </>
  );
};
