import React, {useState} from 'react';
import {BubbleNote, BubbleInputNote, RedButtonNote, DefaultButtonNote, ChatNoteFooter} from './styles';

type ChatNoteProps = {
  isAdding?: boolean;
  note?: NoteDto;
  createNote?: (text: string) => void;
  updateNote?: (noteId: number, text: string) => void;
  discardNote?: () => void;
  deleteNote?: (noteId: number) => void;
};

export const ChatNote: React.FC<ChatNoteProps> = ({note, isAdding, createNote, updateNote, discardNote, deleteNote}) => {
  const [write, setWrite] = useState<boolean>(!!isAdding);
  const [text, setText] = useState(note?.note);

  const onSave = async () => {
    setWrite(false);
    if (text) {
      isAdding ? createNote && createNote(text!) : updateNote && updateNote(note!.id, text!);
    } else {
      discardNote && discardNote();
    }
  };

  const onEdit = () => {
    setWrite(true);
    setText(note!.note);
  };

  const onDiscard = () => {
    setText(note?.note);
    setWrite(false);
    if (isAdding) {
      discardNote && discardNote();
    }
  };

  const onDelete = () => {
    deleteNote && deleteNote(note!.id);
  };

  return (
    <div>
      {write ? (
        <>
          <BubbleInputNote value={text} onChange={(e) => setText(e.target.value)} placeholder='Type something...' autoFocus />
          <ChatNoteFooter>
            <DefaultButtonNote onClick={onSave}>Save note</DefaultButtonNote>
            <DefaultButtonNote onClick={onDiscard}>Discard</DefaultButtonNote>
          </ChatNoteFooter>
        </>
      ) : (
        <>
          <BubbleNote>{text}</BubbleNote>
          <ChatNoteFooter>
            <DefaultButtonNote onClick={onEdit}>Edit note</DefaultButtonNote>
            <RedButtonNote onClick={onDelete}>Delete note</RedButtonNote>
          </ChatNoteFooter>
        </>
      )}
    </div>
  );
};
