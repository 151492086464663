import {configureStore} from '@reduxjs/toolkit';
import {AuthProvider} from 'contexts/auth.context';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import 'styles/index.scss';
import AppWrapper from './components/app/app.wrapper';
import {
  activityReducer,
  clientFiltersReducer,
  generalToastReducer,
  loadingReducer,
  mediaBankReducer,
  messagingFiltersReducer,
  programReducer,
  toolsReducer,
  undoArchiveReducer,
} from './store/reducers';

const store = configureStore({
  reducer: {
    activities: activityReducer,
    programs: programReducer,
    tools: toolsReducer,
    mediaBank: mediaBankReducer,
    toast: generalToastReducer,
    undoToast: undoArchiveReducer,
    loading: loadingReducer,
    clientFilters: clientFiltersReducer,
    messagingFilters: messagingFiltersReducer,
  },
});

const queryClient = new QueryClient();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <AppWrapper />
          </Router>
        </Provider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
