import React, {useEffect, useState} from 'react';
import {PropName, Row, SeparatorLine} from '../styles';
import {getClientProperties, addValuesToProperties} from 'api/chatowl.api';
import {useAppDispatch} from 'hooks';
import {sendMessage} from 'store/action-creators';
import SearchBar from 'components/search-bar';
import {InputText} from 'primereact/inputtext';
import {Button} from 'components/page';
import moment from 'moment';
import {isValidValue, cast} from 'utils/helpers';

type ClientPropertiesProps = {client: ClientDto};

export const ClientProperties: React.FC<ClientPropertiesProps> = ({client}) => {
  const [properties, setProperties] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);
  const [showOld, setShowOld] = useState<boolean>(false);
  const [propOldHidden, setPropOldHidden] = useState<boolean[]>([]);
  const [currentValues, setCurrentValues] = useState<{id: string; value: any; type: string}[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //set properties to the get result
    const fetchProperties = async () => {
      try {
        const response = (await getClientProperties(client.username)) as any;
        const res = response.data.data as [];
        setProperties(res);
        setPropOldHidden(new Array(res.length).fill(true));
        let current: {id: string; value: any; type: string}[] = [];
        res.map((p: any) => {
          current.push({id: p.id, value: p.values[0].value, type: p.type});
        });
        setCurrentValues(current);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchProperties();
  }, [client.username]);

  const onChangeSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleOpenOld = (index: number) => {
    setShowOld(!showOld);
    const newValue = propOldHidden;
    newValue[index] = !newValue[index];
    setPropOldHidden(newValue);
  };

  const modifyPropValue = (index: number, value: any) => {
    let newCurrentVals = currentValues;
    newCurrentVals[index].value = value;
    setCurrentValues(newCurrentVals);
  };

  const handleChangesButtonClick = async () => {
    if (editing) {
      let changedProperties: {properties: {id: string; value: any}[]} = {properties: []};

      currentValues.map((current: any, index: number) => {
        if ((current.value || current.value === 0) && current.value !== properties[index].values[0].value) {
          let castedVal = cast(current.value, current.type);
          if (isValidValue(current.type, castedVal)) {
            changedProperties.properties.push({id: current.id, value: castedVal});
          } else {
            dispatch(
              sendMessage({
                severity: 'error',
                summary: 'ERROR',
                detail: `INVALID PROP TYPE ${current.type} ENTERED: ${current.value} OF TYPE ${typeof current.type}`,
              })
            );
          }
        }
      });
      await addValuesToProperties(client.username, changedProperties);
      const response = (await getClientProperties(client.username)) as any;
      const res = response.data.data as [];
      setProperties(res);
      setPropOldHidden(new Array(res.length).fill(true));
      let current: {id: string; value: any; type: string}[] = [];
      res.map((p: any) => {
        current.push({id: p.id, value: p.values[0].value, type: p.type});
      });
      setCurrentValues(current);
    }
    setEditing(!editing);
  };

  return (
    <>
      <SearchBar onChange={onChangeSearchValue} value={searchValue} />
      <br />
      <SeparatorLine />
      <Button
        style={{textDecoration: 'underline', fontWeight: 'bold', justifyContent: 'flex-end'}}
        onClick={handleChangesButtonClick}
      >
        {editing ? 'Save Changes' : 'Edit Values'}
      </Button>
      <SeparatorLine />
      {properties &&
        properties.map((prop: any, index: number) => {
          return (
            <>
              {' '}
              <SeparatorLine />
              <Row>
                <PropName>
                  {prop.name} <i className='pi pi-angle-down' onClick={() => handleOpenOld(index)}></i>
                </PropName>
                {editing ? (
                  <InputText
                    onChange={(e: any) => modifyPropValue(index, e.target.value)}
                    style={{width: '10%', border: 'none', textDecoration: 'underline'}}
                    defaultValue={currentValues[index].value}
                  ></InputText>
                ) : (
                  prop.values[0].value
                )}
              </Row>
              <div hidden={propOldHidden[index]}>
                {prop.values.map((value: any, index: number) => {
                  if (index === 0) return;
                  return (
                    <>
                      <Row style={{fontSize: '0.9em'}}>
                        <div style={{color: '#B4C1C1', marginTop: '5px', marginBottom: '5px'}}>
                          Modified on{' '}
                          <span title={moment(value.timestamp).calendar()}>{moment(value.timestamp).format('l')}</span>
                        </div>
                        {value.value}
                      </Row>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      <SeparatorLine />
    </>
  );
};
