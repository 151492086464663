import React, {useState, useEffect} from 'react';
import {AvatarGenerator} from 'random-avatar-generator';
import {CloseModal, RedirectModalButtonsContainer, RedirectModalSelectYesButton, Tag} from './styles';
import {
  selectPlansFilter,
  unselectPlansFilter,
  selectSubscriptionStatusFilter,
  unselectSubscriptionStatusFilter,
  selectTagsFilter,
  unselectTagsFilter,
  clearSelectedPlans,
  clearSelectedSubscriptionStatus,
  clearSelectedTags,
  setFiltersApplied,
  unsetFiltersApplied,
  sendMessage,
} from 'store/action-creators';
import {getClients, getClientFiltersOptions, ClientFiltersToApply} from 'api/chatowl.api';
import {signDate} from 'utils/helpers';
import Modal from 'react-modal';
import {StyledFormElement} from 'components/form-element';
import {Checkbox} from 'primereact/checkbox';
import {useAppDispatch, useAppSelector} from 'hooks';
import {PageContainer, PageHeader, PageTitle} from 'components/page';
import GeneralSidebar from '../../components/general-sidebar';
import FilterToggle from 'components/filter-toggle';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import SearchBar from 'components/search-bar';
import {SelectButton as MediaTypeSelector} from 'primereact/selectbutton';
import {DetailContainer, SliderAlias, SliderHeader, SliderLocation, SliderTitle, SliderTitleImage, SeparatorLine} from './styles';
import {ClientInfo} from './details/client-info';
import {ClientActions} from './details/client-actions';
import {ClientProperties} from './details/client-properties';
import {ClientMessages} from './details/client-messages';
import {ClientChat} from './details/client-chat';
import {ClientStats} from './details/client-stats';
import {Button} from 'components/page';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '550px',
    height: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

const PAGE_SIZE = 10;

const generator = new AvatarGenerator();

export const Clients: React.FC<{title?: string}> = ({title}) => {
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [clientDetail, setClientDetail] = useState<string>('info');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedPlans: number[] = useAppSelector((state: any) => state.clientFilters.selectedPlans);
  const selectedSubscriptionStatus: string[] = useAppSelector((state: any) => state.clientFilters.selectedSubscriptionStatus);
  const selectedTags: string[] = useAppSelector((state: any) => state.clientFilters.selectedTags);
  const filtersApplied: boolean = useAppSelector((state: any) => state.clientFilters.filtersApplied);
  const [clients, setClients] = useState<ClientDto[]>();
  const [lazyParams, setLazyParams] = useState({first: 0, rows: 10, page: 1});
  const [availablePlans, setAvailablePlans] = useState<{name: string; value: number}[]>();
  const [availableSubscriptionStatus, setAvailableSubscriptionStatus] = useState<{name: string; value: string}[]>();
  const [availableTags, setAvailableTags] = useState<{name: string; value: string}[]>();
  const [showingTags, setShowingTags] = useState<number>(10);
  const [filters_obj, setFilters_obj] = useState<any>({});
  const [totalClients, setTotalClients] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setIsLoading(true);
        const response = (await getClients({pageSize: PAGE_SIZE, pageNumber: 0})) as any;
        const res = response.data.data as any;
        const cls = res.result as ClientDto[];
        const tot = res.total;
        const clientFiltersOpt = (await getClientFiltersOptions()) as any;
        const filtOp = clientFiltersOpt.data.data as ClientFiltersOptionsDto;
        setAvailablePlans(filtOp.programs);
        setAvailableSubscriptionStatus(filtOp.subscriptionStatus);
        setAvailableTags(filtOp.tags);
        setClients(cls);
        setTotalClients(tot);
        setIsLoading(false);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const delay = setTimeout(() => {
        filteredClients();
      }, 600);

      return () => clearTimeout(delay);
    }
  }, [searchValue]);

  const handleKeysSearchBar = async (e: any) => {
    if (e.code === 'Enter') await filteredClients();
  };

  const onChangeClient = (e: any) => {
    setSelectedClient(e.value);
  };

  const onOpenModal = () => {
    setIsOpen(true);
    setSelectedClient(null);
  };

  const onCancelModal = () => {
    setIsOpen(false);
  };

  const onChangeBoxPlans = (e: any) => {
    selectedPlans.includes(e.value.value)
      ? dispatch(unselectPlansFilter(e.value.value))
      : dispatch(selectPlansFilter(e.value.value));
    dispatch(unsetFiltersApplied());
  };

  const onChangeBoxsubscriptionStatus = (e: any) => {
    selectedSubscriptionStatus.includes(e.value.value)
      ? dispatch(unselectSubscriptionStatusFilter(e.value.value))
      : dispatch(selectSubscriptionStatusFilter(e.value.value));
    dispatch(unsetFiltersApplied());
  };

  const onChangeTag = (e: any) => {
    const val = e.target.innerText.substring(2).toLowerCase();
    selectedTags.includes(val) ? dispatch(unselectTagsFilter(val)) : dispatch(selectTagsFilter(val));
    dispatch(unsetFiltersApplied());
  };

  const filteredClients = async (filters?: ClientFiltersToApply) => {
    let ft: any = {};
    ft.pageSize = filters?.pageSize || PAGE_SIZE;
    ft.pageNumber = filters?.pageNumber || 0;
    if (selectedSubscriptionStatus && selectedSubscriptionStatus.length > 0) ft.subscriptionStatus = selectedSubscriptionStatus;
    if (selectedTags && selectedTags.length > 0) ft.tags = selectedTags;
    if (selectedPlans && selectedPlans.length > 0) ft.programIs = selectedPlans;
    if (searchValue) ft.search = searchValue;
    const response = (await getClients({...ft})) as any;
    if (response.data.error) {
      dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: response.data.error.details[0].message}));
      return;
    }
    setFilters_obj(ft);
    const res = response.data.data as any;
    const cls = res.result as ClientDto[];
    setFilteredActiveClients(cls);
  };
  const [filteredActiveClients, setFilteredActiveClients] = useState<ClientDto[]>();

  const onChangeSearchValue = async (e: any) => {
    setSearchValue(e.target.value);
  };

  const onPage = async (event: any) => {
    setIsLoading(true);
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
    await filteredClients({pageNumber: _lazyParams.page});
    setIsLoading(false);
  };

  const tagsBodyTemplate = (rowData: ClientDto) => {
    if (!rowData) {
      return (
        <div style={{padding: '10px 0px', height: '50px'}}>
          <Skeleton height={30} />
        </div>
      );
    }
    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {rowData.tags?.map((t: TagDTO, index: number) => (
          <>
            {' '}
            {index < 5 && (
              <Tag
                style={rowData.username === selectedClient?.username ? {color: '#FFFFFF'} : {}}
                color={rowData.username === selectedClient?.username ? '#9CCFFD' : '#F6F6F6'}
              >
                {t.tag}
              </Tag>
            )}
          </>
        ))}
        {rowData.tags?.length > 5 && (
          <Tag
            style={rowData.username === selectedClient?.username ? {color: '#FFFFFF'} : {}}
            color={rowData.username === selectedClient?.username ? '#9CCFFD' : '#F6F6F6'}
          >
            ...
          </Tag>
        )}
      </div>
    );
  };

  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader>
          <PageTitle>Clients</PageTitle>
          <div style={{width: '50%'}}></div>
          <div onClick={onOpenModal}>
            <FilterToggle filter={'Filter'} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
          </div>
          <SearchBar onChange={onChangeSearchValue} value={searchValue} onKeyDown={handleKeysSearchBar} />
        </PageHeader>

        <div style={{marginLeft: '-15px', marginRight: '-15px'}}>
          <DataTable
            // loading={isLoading}
            value={isLoading ? new Array<null>(10) : filteredActiveClients ? filteredActiveClients : clients}
            selectionMode='single'
            selection={selectedClient}
            onSelectionChange={onChangeClient}
            dataKey='username'
            onPage={onPage}
            first={lazyParams.first}
            rows={PAGE_SIZE}
            totalRecords={totalClients ? totalClients : 0}
            paginator
            lazy
          >
            <Column field='alias' body={aliasBodyTemplate} header='Alias'></Column>
            <Column
              field='location'
              header='Location'
              body={(rowData: ClientDto) =>
                !rowData ? (
                  <div style={{padding: '10px 0px', height: '50px'}}>
                    <Skeleton height={30} />
                  </div>
                ) : (
                  <div title={rowData.location} style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {rowData.location}
                  </div>
                )
              }
            ></Column>
            <Column
              field='subscription'
              body={(rowData: ClientDto) =>
                !rowData ? (
                  <div style={{padding: '10px 0px', height: '50px'}}>
                    <Skeleton height={30} />
                  </div>
                ) : (
                  rowData.clientProgram?.name
                )
              }
              header='Plan'
            ></Column>
            {/*
            *
            * No implemented yet
            * 
            * 
             <Column
              field='subscription'
              body={(rowData: ClientDto) =>
                !rowData ? (
                  <div style={{padding: '10px 0px', height: '50px'}}>
                    <Skeleton height={30} />
                  </div>
                ) : (
                  rowData.subscription?.status
                )
              }
              header='Subscription'
            ></Column> */}
            <Column field='lastSeen' header='Last Seen' body={aliasLastSeenTemplate}></Column>
            <Column field='tags' body={tagsBodyTemplate} header='Tags' style={{width: '20%'}}></Column>
          </DataTable>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={onCancelModal}
        contentLabel='Select image'
        style={style}
        shouldCloseOnOverlayClick={false}
      >
        <div style={{textAlign: 'left'}}>
          <h2 style={{fontSize: '3em', fontWeight: 'bold'}}>Filter</h2>
          <CloseModal onClick={onCancelModal} style={{fontWeight: 'bold', position: 'absolute', top: '7%'}}>
            Close
          </CloseModal>
        </div>

        <SeparatorLine />
        <StyledFormElement>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h5 style={{fontWeight: 'bold', margin: '0.4em 0 0.4em 0'}}>Plans</h5>
            <Button
              style={{textDecoration: 'underline', fontWeight: 'bold'}}
              onClick={() => {
                dispatch(clearSelectedPlans());
                dispatch(unsetFiltersApplied());
              }}
            >
              Clear All
            </Button>
          </div>

          <SeparatorLine />

          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {availablePlans?.map((plan, i) => {
              return (
                <div key={i} style={{margin: '15px 15px 15px 15px'}}>
                  <Checkbox
                    inputId={`plan-checkbox-${i}`}
                    value={plan}
                    checked={selectedPlans.includes(plan.value)}
                    onChange={onChangeBoxPlans}
                    style={{marginRight: '4px'}}
                  />
                  <label htmlFor={`plan-checkbox-${i}`}>{plan.name}</label>
                </div>
              );
            })}
          </div>
        </StyledFormElement>

        <SeparatorLine />

        <StyledFormElement>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h5 style={{fontWeight: 'bold', margin: '0.4em 0 0.4em 0'}}>Subscription Status</h5>
            <Button
              style={{textDecoration: 'underline', fontWeight: 'bold'}}
              onClick={() => {
                dispatch(clearSelectedSubscriptionStatus());
                dispatch(unsetFiltersApplied());
              }}
            >
              Clear All
            </Button>
          </div>

          <SeparatorLine />

          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {availableSubscriptionStatus?.map((status, i) => {
              return (
                <div key={i} style={{margin: '15px 15px 15px 15px'}}>
                  <Checkbox
                    inputId={`stat-checkbox-${i}`}
                    value={status}
                    checked={selectedSubscriptionStatus.includes(status.value)}
                    onChange={onChangeBoxsubscriptionStatus}
                    style={{marginRight: '4px'}}
                  />
                  <label htmlFor={`stat-checkbox-${i}`}>{status.name}</label>
                </div>
              );
            })}
          </div>
        </StyledFormElement>

        <SeparatorLine />

        <StyledFormElement>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h5 style={{fontWeight: 'bold', margin: '0.4em 0 0.4em 0'}}>Tags</h5>
            <Button
              style={{textDecoration: 'underline', fontWeight: 'bold'}}
              onClick={() => {
                dispatch(clearSelectedTags());
                dispatch(unsetFiltersApplied());
              }}
            >
              Clear All
            </Button>
          </div>

          <SeparatorLine />
          <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
            {availableTags?.map((tag, i: number) => {
              if (i < showingTags)
                return (
                  <Tag
                    key={i}
                    color={selectedTags.includes(tag.name.toLowerCase()) ? '#9CCFFD' : '#F6F6F6'}
                    onClick={onChangeTag}
                  >
                    + {tag.name}
                  </Tag>
                );
            })}
            <Button
              style={{textDecoration: 'underline', fontWeight: 'bold', fontSize: '0.8rem'}}
              onClick={() => {
                if (availableTags)
                  showingTags >= availableTags.length ? setShowingTags(showingTags - 10) : setShowingTags(showingTags + 10);
              }}
            >
              {availableTags && showingTags >= availableTags.length ? 'Show Less' : 'Show More'}
            </Button>
          </div>
        </StyledFormElement>

        <div style={{float: 'right'}}>
          <RedirectModalButtonsContainer>
            <RedirectModalSelectYesButton
              onClick={async () => {
                onCancelModal();
                await filteredClients();
                dispatch(setFiltersApplied());
              }}
            >
              {filtersApplied ? 'See Results' : 'Apply'}
            </RedirectModalSelectYesButton>
          </RedirectModalButtonsContainer>
        </div>
      </Modal>

      <GeneralSidebar isActive={!!selectedClient} onClick={() => setSelectedClient(null)}>
        <SliderHeader>
          <SliderTitleImage>
            <img width='65px' height='65px' alt='' src={generator.generateRandomAvatar(selectedClient?.username)} />
            <SliderTitle>
              <SliderAlias>{selectedClient?.alias}</SliderAlias>
              <SliderLocation>{selectedClient?.location}</SliderLocation>
            </SliderTitle>
          </SliderTitleImage>
        </SliderHeader>
        <MediaTypeSelector
          value={clientDetail}
          onChange={(e) => setClientDetail(e.value ? e.value : clientDetail)}
          options={clientDetails}
          optionLabel='name'
        />
        <DetailContainer>
          {clientDetail === 'info' && <ClientInfo key={selectedClient?.username} client={selectedClient} />}
          {clientDetail === 'stats' && <ClientStats key={selectedClient?.username} client={selectedClient} />}
          {clientDetail === 'chat' && <ClientChat key={selectedClient?.username} client={selectedClient} withNotes={true} />}
          {clientDetail === 'messages' && <ClientMessages key={selectedClient?.username} client={selectedClient} />}
          {clientDetail === 'properties' && <ClientProperties key={selectedClient?.username} client={selectedClient} />}
          {clientDetail === 'actions' && <ClientActions key={selectedClient?.username} client={selectedClient} />}
        </DetailContainer>
      </GeneralSidebar>
    </PageContainer>
  );
};

const aliasBodyTemplate = (rowData: any) => {
  if (!rowData) {
    return (
      <div style={{padding: '10px 0px', height: '50px'}}>
        <Skeleton height={30} />
      </div>
    );
  }
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <img alt='alias' src={generator.generateRandomAvatar(rowData.username)} width={50} height={50} />
      <span style={{marginLeft: '10px'}}>{rowData.alias}</span>
    </div>
  );
};

const aliasLastSeenTemplate = (rowData: any) =>
  !rowData ? (
    <div style={{padding: '10px 0px', height: '50px'}}>
      <Skeleton height={30} />
    </div>
  ) : (
    <div title={moment(rowData.lastSeen).calendar()} style={{display: 'flex', alignItems: 'center'}}>
      {signDate(rowData.lastSeen)}
    </div>
  );

const clientDetails = [
  {value: 'info', name: 'Info'},
  {value: 'stats', name: 'Stats'},
  {value: 'chat', name: 'Chat'},
  {value: 'messages', name: 'Messages'},
  {value: 'properties', name: 'Properties'},
  {value: 'actions', name: 'Actions'},
];
