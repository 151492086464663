import * as React from 'react';
import {Redirect} from 'react-router';

export class RedirectErrorBoundary extends React.Component<{}, {errorHappened: boolean}> {
  constructor(props: {}) {
    super(props);
    this.state = {
      errorHappened: false,
    };
  }
  componentDidCatch() {
    this.setState({errorHappened: true});
  }

  render() {
    if (this.state.errorHappened) {
      return <Redirect to='/error'></Redirect>;
    } else {
      return this.props.children;
    }
  }
}
