import React from 'react';
import Modal from 'react-modal';
import {
  modalStyle,
  DeleteMediaModalButtonsContainer,
  DeleteMediaModalCancelButton,
  DeleteMediaModalYesButton,
  ModalContainer,
} from './styles';

type DeleteWeekModalProps = {
  isDeleteModalOpen: boolean;
  toggleDeletePrompt: () => void;
  onDeleteWeek: () => void;
};

const DeleteWeekModal: React.FC<DeleteWeekModalProps> = ({isDeleteModalOpen, toggleDeletePrompt, onDeleteWeek}) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isDeleteModalOpen}
      onRequestClose={toggleDeletePrompt}
      contentLabel='Are you sure you want to delete Image?'
      style={modalStyle}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        <h5>You sure you want to delete this week?</h5>
        <p>When you delete a week, the following weeks move a place forward to rearrange the program. </p>
        <DeleteMediaModalButtonsContainer>
          <DeleteMediaModalCancelButton onClick={toggleDeletePrompt}>Cancel</DeleteMediaModalCancelButton>
          <DeleteMediaModalYesButton onClick={onDeleteWeek}>Yes</DeleteMediaModalYesButton>
        </DeleteMediaModalButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteWeekModal;
