import styled from 'styled-components';

export const CategoryMultiSelectItem = styled.div`
  display: inline-flex;
  margin-right: 5px;
  background: rgb(45, 152, 240);
  padding: 5px;
  border-radius: 5px;
  color: white;
`;
export const CategoryMultiSelectRemoveButton = styled.button`
  background: transparent;
  outline: 0;
  border: 0;
  color: white;

  cursor: pointer;
  margin-left: 10px;
`;
