import React, {useEffect, useState} from 'react';
import {Chart} from 'primereact/chart';
import {ChartContainer, ChartLegend} from '../styles';
import moment from 'moment';
import {getClientStats} from 'api/chatowl.api';

interface StatsData {
  labels?: string[];
  datasets?: {
    label?: string;
    data?: Array<number | null>;
    fill?: boolean;
    borderColor?: string;
  }[];
}

const basicOptions = {
  legend: {
    display: false,
    position: 'bottom',
  },
  elements: {
    point: {
      radius: 4,
    },
    line: {
      tension: 0.2,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};

type ClientStatsProps = {client: ClientDto};

export const ClientStats: React.FC<ClientStatsProps> = ({client}) => {
  const [exercisesData, setExercisesData] = useState<StatsData>();
  const [sessionsData, setSessionsData] = useState<StatsData>();
  const [moodData, setMoodData] = useState<StatsData>();

  useEffect(() => {
    const fetchStats = async () => {
      const now = moment();
      const weekAgo = moment().subtract(1000, 'week');

      try {
        const response = (await getClientStats(client.username, weekAgo.format(), now.format(), 7)) as any;
        const res = response.data.data as any;
        const stats = res as StatsDto[];
        const exercisesStats = stats?.find((s) => s.name === 'exercises');

        const labelStatsDates = exercisesStats?.stats.labels.map((d) => new Date(d).toISOString().substring(0, 10));

        const exercisesData: StatsData = {
          labels: labelStatsDates,
          datasets: [
            {
              label: 'Exercises',
              data: exercisesStats?.stats.datasets[0].data,
              fill: false,
              borderColor: exercisesStats?.stats.datasets[0].color || '#2C98F0',
            },
          ],
        };

        setExercisesData(exercisesData);

        const sessionsStats = stats?.find((s) => s.name === 'sessions');

        const labelSessionsDates = sessionsStats?.stats.labels.map((d) => new Date(d).toISOString().substring(0, 10));

        const sessionsData: StatsData = {
          labels: labelSessionsDates,
          datasets: [
            {
              label: 'Sessions',
              data: sessionsStats?.stats.datasets[0].data,
              fill: false,
              borderColor: sessionsStats?.stats.datasets[0].color || '#2C98F0',
            },
          ],
        };
        setSessionsData(sessionsData);

        const moodStats = stats?.find((s) => s.name === 'mood_meter');

        const labelMoodDates = moodStats?.stats.labels.map((d) => new Date(d).toISOString().substring(0, 10));

        const moodData: StatsData = {
          labels: labelMoodDates,
          datasets: moodStats?.stats.datasets.map((d) => ({
            label: d.label,
            data: d.data,
            fill: false,
            borderColor: d.color || '#2C98F0',
          })),
        };

        setMoodData(moodData);
      } catch (error) {
        throw error; // TODO
      }
    };

    fetchStats();
  }, [client.username]);

  return (
    <ChartContainer>
      <div>
        <h3>Sessions</h3>
        <Chart width={'100%'} type='line' data={exercisesData} options={basicOptions} />
      </div>
      <div>
        <h3>Exercises</h3>
        <Chart width={'100%'} type='line' data={sessionsData} options={basicOptions} />
      </div>
      <div>
        <h3>Mood Meter</h3>
        <Chart width={'100%'} type='line' data={moodData} options={basicOptions} />
        <ChartLegend>
          {moodData?.datasets?.map((d) => (
            <div style={{color: d.borderColor || '#2C98F0'}}>{d.label}</div>
          ))}
        </ChartLegend>
      </div>
    </ChartContainer>
  );
};
