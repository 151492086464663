import {addExercise, editExercise, getCategoryTree, updateAudio, updateVideo} from 'api/chatowl.api';
import {FormElement, StyledFormElement} from 'components/form-element';
import MediaChooser from 'components/media-chooser';
import {
  ArchiveButton,
  CancelButton,
  CursorLoading,
  Form,
  Label,
  PageBody,
  PageContainer as GlobalPageContaner,
  PageFooter,
  PageHeader,
  PageTitle,
  SaveButton,
} from 'components/page';
import SpinnerLoading from 'components/spinner-loading';
import CategorySelector from 'components/tools/category-selector';
import GeneralCheckbox from 'components/tools/general-checkbox';
import {StyleCheckboxElements} from 'components/tools/general-checkbox/style';
import {useAppDispatch, useAppSelector} from 'hooks';
import {RootState} from 'index';
import {StyleAddAudioFiles as StyledFormElementAux} from 'pages/media-bank/containers/audio/styles';
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {shallowEqual} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {selectExercise, sendMessage, sendUndoToast, unselectExercise} from 'store/action-creators';
import styled from 'styled-components';
import {isAnyStringEmpty} from 'utils/helpers';
import {listExercises} from '../../../api/chatowl.api';
import {mediaTypesDropdown, repeatLimitDropdown, resumeLimitDropdown} from './exercise-detail';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

type CheckboxEvent = {
  originalEvent: SyntheticEvent<Element, Event>;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

function validateSave(name: string, description: string, mediaId: number | undefined, categories: number[]): boolean {
  return !!name && !!description && mediaId !== 0 && !!categories?.length;
}

type MediaDetailsFormType = 'add' | 'edit';

export const AddExercise: React.FC<{title?: string}> = ({title}) => {
  const params = useParams<{formType: MediaDetailsFormType; exerciseId: string}>();
  const formType = params.formType;
  const exerciseId = +params.exerciseId || null;
  let selectedExercise: ExerciseDto | null = useAppSelector(
    (state: RootState) => state.tools.exercises.selectedExercise,
    shallowEqual
  );
  const [exerciseName, setExerciseName] = useState<string>('');
  const [exerciseDescription, setExerciseDescription] = useState<string>('');
  const [exerciseCategories, setExerciseCategories] = useState<number[]>([]);
  const [repeatLimit, setRepeatLimit] = useState<number>(0);
  const [resumeLimit, setResumeLimit] = useState<number>(0);
  const [exerciseMediaType, setExerciseMediaType] = useState<MediaType>('audio');
  const [instructions, setInstructions] = useState<string[]>([]);
  const [isRepeatable, setIsRepeatable] = useState(true);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});
  const [exercises, setExercises] = useState<ExerciseDto[]>([]);
  const [exercisesLoading, setExerciesLoading] = useState(false);
  const {data: categoryTree, isLoading: loadingCategories, error: errorLoadingCategories} = useQuery(
    'category/api',
    () => getCategoryTree(),
    {
      onError: (error: Error) =>
        dispatch(
          sendMessage({
            severity: 'error',
            summary: 'There was an error while loading the categories',
            detail: error?.message || '',
          })
        ),
    }
  );

  const [mediaId, setMediaId] = useState<number | undefined>(0);
  const [exerciseMedia, setExerciseMedia] = useState<MediaDto>();

  const [newCrops, setNewCrops] = useState<Crop[]>([]);

  const [saving, setSaving] = useState(false);

  const [userClickedArchive, setUserClickedArchive] = useState<boolean>(false);

  const editing: boolean = formType === 'edit' && !!selectedExercise;

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = `Chatowl | ${title}` || 'Chatowl';
  }, []);

  useEffect(() => {
    if (selectedExercise && formType === 'edit') {
      setExerciseName(selectedExercise.title);
      setExerciseDescription(selectedExercise.description);
      setExerciseCategories(selectedExercise.toolCategories.map((v) => v.id));
      setIsRepeatable(selectedExercise.isRepeatable);
      setRepeatLimit(selectedExercise.repeatLimit);
      setResumeLimit(selectedExercise.resumeLimit);
      setExerciseMediaType(selectedExercise.media?.type || 'audio');
      const instructions = selectedExercise.instructions?.map((i) => i);
      setInstructions(instructions ? instructions : []);
      setMediaId(selectedExercise.mediaId);
      setExerciseMedia(selectedExercise!.media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const response = (await listExercises({withDeleted: false, sortBy: 'id'})) as any;
        const exercisesResponse = response.data ? response.data.data : [];
        setExercises(exercisesResponse);
        const exerciseSelected = exercisesResponse.find(
          (exercise: ExerciseDto) => exercise.id === exerciseId
        ) as ExerciseDto | null;
        if (exerciseSelected) dispatch(selectExercise(exerciseSelected));
        setExerciseName(exerciseSelected?.title!);
        setExerciseDescription(exerciseSelected?.description!);
        setExerciseCategories((exerciseSelected?.toolCategories || []).map((v) => v.id));
        setIsRepeatable(exerciseSelected?.isRepeatable!);
        setRepeatLimit(exerciseSelected?.repeatLimit!);
        setResumeLimit(exerciseSelected?.resumeLimit!);
        setExerciseMediaType(exerciseSelected?.media?.type || 'audio');
        const instructions = exerciseSelected?.instructions?.map((i: any) => i);
        setInstructions(instructions ? instructions : []);
        setMediaId(exerciseSelected?.mediaId);
        setExerciseMedia(exerciseSelected?.media);
      } catch (error) {
        throw error; // TODO
      } finally {
        setExerciesLoading(false);
      }
    };
    if (exerciseId) {
      setExerciesLoading(true);
      fetchExercises();
    }
  }, []);

  const onCancelExercise = () => {
    // dispatch(unselectExercise());
    setSaving(false);
    history.push('/tools/exercises');
  };

  const onInstructionChange = (event: CheckboxEvent) => {
    let selectedInstructions = [...instructions];
    if (event.checked) selectedInstructions.push(event.value);
    else selectedInstructions.splice(selectedInstructions.indexOf(event.value), 1);
    setInstructions(selectedInstructions);
  };

  const onArchiveExercise = async () => {
    if (!userClickedArchive) setUserClickedArchive(true);
    else {
      setSaving(true);
      const editedExercise: UpdateExerciseRequest = {
        isArchived: !selectedExercise?.isArchived,
      };
      try {
        let updatedExercise = (await editExercise(selectedExercise?.id!, editedExercise)) as any;
        if (updatedExercise.response.status >= 200 && updatedExercise.response.status < 300) {
          updatedExercise = updatedExercise.data.data;
          dispatch(unselectExercise());
          !selectedExercise?.isArchived && dispatch(sendUndoToast({idTool: selectedExercise?.id!, toolType: 'exercise'}));
          history.push('/tools/exercises');
        } else {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          setSaving(false);
        }
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const addCrop = (newCrop: Crop) => {
    const addedCrops = newCrops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? newCrops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...newCrops, newCrop]; // else push crop
    setNewCrops(addedCrops);
  };

  const onSaveExercise = async () => {
    if (
      validateSave(exerciseName, exerciseDescription, mediaId, exerciseCategories) &&
      !isAnyStringEmpty([exerciseName, exerciseDescription])
    ) {
      let errorEditingMedia = false;
      setSaving(true);
      if (newCrops.length > 0) {
        if (exerciseMediaType === 'audio') {
          const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
          const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
          const fullScreenLandscape = newCrops!.find((crop) => crop.type === 'full_screen_landscape');
          const fullScreenPortrait = newCrops!.find((crop) => crop.type === 'full_screen_portrait');
          const crops: UpdateMediaAudioRequest = {
            fullScreenLandscape: fullScreenLandscape ? fullScreenLandscape.blob : undefined,
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            fullScreenPortrait: fullScreenPortrait ? fullScreenPortrait.blob : undefined,
            crops: newCrops.map((crop) => ({type: crop.type, data: crop.data})),
            settings,
          };
          const mediaAudio = (await updateAudio(mediaId!, crops)) as any;
          if (!(mediaAudio.response.status >= 200 && mediaAudio.response.status < 300)) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
            setSaving(false);
            errorEditingMedia = true;
          }
        } else {
          const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
          const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
          const crops: UpdateMediaVideoRequest = {
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            crops: newCrops.map((crop) => ({type: crop.type, data: crop.data})),
            settings,
          };
          const mediaVideo = (await updateVideo(mediaId!, crops)) as any;
          if (!(mediaVideo.response.status >= 200 && mediaVideo.response.status < 300)) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
            setSaving(false);
            errorEditingMedia = true;
          }
        }
      }
      //ADDING
      if (!errorEditingMedia) {
        if (!editing) {
          const newExercise: CreateExerciseRequest = {
            title: exerciseName,
            description: exerciseDescription,
            language: 'en-US',
            repeatLimit: isRepeatable ? repeatLimit : undefined,
            resumeLimit,
            categories: exerciseCategories,
            instructions,
            mediaType: exerciseMediaType,
            mediaId: mediaId!,
            isRepeatable,
          };
          try {
            let createdExercise = (await addExercise(newExercise)) as any;
            if (createdExercise.response.status >= 200 && createdExercise.response.status < 300) {
              createdExercise = createdExercise.data.data as ExerciseDto;
              dispatch(selectExercise(createdExercise));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Exercise created successfully',
                  detail: createdExercise.title,
                })
              );
              history.push('/tools/exercises');
            } else {
              if (exerciseDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Exercice description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        } else {
          //EDITING
          const editedExercise: UpdateExerciseRequest = {
            title: exerciseName,
            description: exerciseDescription,
            language: 'en-US',
            repeatLimit: isRepeatable ? repeatLimit : undefined,
            resumeLimit,
            categories: exerciseCategories,
            instructions,
            mediaId: mediaId,
            isRepeatable,
          };
          try {
            let updatedExercise = (await editExercise(selectedExercise?.id!, editedExercise)) as any;
            if (updatedExercise.response.status >= 200 && updatedExercise.response.status < 300) {
              updatedExercise = updatedExercise.data.data;
              dispatch(selectExercise(updatedExercise));
              dispatch(
                sendMessage({
                  severity: 'success',
                  summary: 'Exercise updated successfully',
                  detail: updatedExercise.title,
                })
              );
              history.push('/tools/exercises');
            } else {
              if (exerciseDescription.length > 255)
                dispatch(
                  sendMessage({
                    severity: 'error',
                    summary: 'ERROR',
                    detail: 'Exercice description must have at most 255 characters',
                  })
                );
              else dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name, Media and Category can not be empty.'}));
    }
  };

  return (
    <CursorLoading disable={saving}>
      {exercisesLoading ? (
        <SpinnerLoading show />
      ) : (
        <PageContainer>
          <PageHeader>{editing ? <PageTitle>Edit Exercise</PageTitle> : <PageTitle>Add Exercise</PageTitle>}</PageHeader>
          <PageBody>
            <Form>
              <FormElement
                type='input'
                label='Name'
                value={exerciseName}
                placeholder='Exercise name'
                onChange={(inputValue) => setExerciseName(inputValue)}
              />
              <FormElement
                type='text-area'
                label='Description'
                value={exerciseDescription}
                placeholder='Exercise description'
                onChange={(inputValue) => setExerciseDescription(inputValue)}
              />
              <StyledFormElement>
                <label htmlFor={`inputCategory`}>{'Category'}</label>
                <CategorySelector
                  loading={loadingCategories}
                  selected={exerciseCategories}
                  categories={categoryTree}
                  onChange={setExerciseCategories}
                  error={!!errorLoadingCategories}
                />
              </StyledFormElement>
              <StyledFormElement style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Label>Repeating</Label>
                <InputSwitch checked={isRepeatable} onChange={(e) => setIsRepeatable(e.value)} />
              </StyledFormElement>
              <StyledFormElement isVisible={isRepeatable} withHelpText>
                <label
                  title='After the client finished or canceled an exercise they have to wait for this time until they can use the tool again.'
                  htmlFor={`input Repeat Limit`}
                >
                  {'Repeat Limit'}
                </label>
                <Dropdown
                  value={repeatLimit}
                  options={repeatLimitDropdown}
                  onChange={(e) => setRepeatLimit(e.value)}
                  placeholder='Select Repeat Limit'
                />
              </StyledFormElement>
              <StyledFormElement withHelpText>
                <label
                  title='Time limit until when the tool can be resumed if the client paused in the middle. After this limit the tool will start again from the beginning.'
                  htmlFor={`input Resume Limit`}
                >
                  {'Resume Limit'}
                </label>
                <Dropdown
                  value={resumeLimit}
                  options={resumeLimitDropdown}
                  onChange={(e) => setResumeLimit(e.value)}
                  placeholder='Select Resume Limit'
                />
              </StyledFormElement>
              <StyledFormElement>
                <label htmlFor={`inputType`}>{'Type'}</label>
                <Dropdown
                  value={exerciseMediaType}
                  options={mediaTypesDropdown}
                  onChange={(e) => {
                    setExerciseMediaType(e.value);
                    setMediaId(0);
                    setNewCrops([]);
                    setExerciseMedia(undefined);
                  }}
                  placeholder='Select Type'
                />
              </StyledFormElement>
              <StyledFormElement>
                <label htmlFor={`inputType`}>{'Instructions'}</label>
              </StyledFormElement>
              <StyleCheckboxElements>
                <div>
                  <GeneralCheckbox
                    label={'Make yourself comfortable'}
                    formValues={instructions}
                    value={'comfortable'}
                    onChange={onInstructionChange}
                  ></GeneralCheckbox>
                </div>
                <div>
                  <GeneralCheckbox
                    label={'Use headphones'}
                    formValues={instructions}
                    value={'headphones'}
                    onChange={onInstructionChange}
                  ></GeneralCheckbox>
                </div>
                <div>
                  <GeneralCheckbox
                    label={'Dim the lights'}
                    formValues={instructions}
                    value={'dim_lights'}
                    onChange={onInstructionChange}
                  ></GeneralCheckbox>
                </div>
              </StyleCheckboxElements>
            </Form>
            <StyledFormElementAux>
              {exerciseMediaType === 'audio' ? (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  media={exerciseMedia ? exerciseMedia : undefined}
                  mediaType={exerciseMediaType}
                  addCrop={addCrop}
                  actualName={exerciseName}
                  key={'Audio'}
                  changeSettings={setSettings}
                />
              ) : (
                <MediaChooser
                  onChooseMedia={setMediaId}
                  media={exerciseMedia ? exerciseMedia : undefined}
                  mediaType={exerciseMediaType}
                  addCrop={addCrop}
                  actualName={exerciseName}
                  key={'Video'}
                  changeSettings={setSettings}
                />
              )}
            </StyledFormElementAux>
          </PageBody>
          <PageFooter>
            {editing &&
              (!selectedExercise!.isArchived ? (
                <ArchiveButton onClick={onArchiveExercise} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Archive ?' : 'Archive'}
                </ArchiveButton>
              ) : (
                <ArchiveButton onClick={onArchiveExercise} userClicked={userClickedArchive}>
                  {userClickedArchive ? 'Unarchive ?' : 'Unarchive'}
                </ArchiveButton>
              ))}

            <CancelButton onClick={onCancelExercise}>
              <Link to='/tools/exercises/'>Cancel</Link>
            </CancelButton>
            <SaveButton disabled={saving} onClick={onSaveExercise} disable={saving}>
              {saving ? 'Saving...' : 'Save'}
            </SaveButton>
          </PageFooter>
        </PageContainer>
      )}
    </CursorLoading>
  );
};
