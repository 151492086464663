import {createVideo, deleteMedia, updateVideo} from 'api/chatowl.api';
import imageCompression from 'browser-image-compression';
import {FormElement, StyledFormElement} from 'components/form-element';
import VideoPlayer from 'components/media/video-player';
import VideoUploader from 'components/media/video-uploader';
import {ArchiveButton as DeleteButton, ButtonLink, CancelButton, PageFooter, SaveButton} from 'components/page';
import getBlobDuration from 'get-blob-duration';
import {Button} from 'primereact/button';
import React, {useEffect, useState} from 'react';
import {shallowEqual} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {createFile, isAnyStringEmpty} from 'utils/helpers';
import {ImageCropperManager} from '../../../../components/crop-manager';
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {RootState} from '../../../../index';
import {hideLoading, sendMessage, showLoading, unselectMedia} from '../../../../store/action-creators';
import {AddInput, FormContainer, InputButton, InputComplete, PageBody, Tag} from '../../styles';
import {Header, StyleAddVideoFiles, StyleAddVideoFilesCol} from './styles';

function validateSave(
  name: string,
  videoBlob: Blob | undefined,
  videoUrl: string | undefined,
  crops: number,
  imagesCrops: number
): boolean {
  return !!name && (!!videoBlob || !!videoUrl) && (!!crops || !!imagesCrops);
}

export const VideoFormContainer: React.FC<{
  formType: 'add' | 'edit';
  mediaName?: string;
  onSuccess: (mediaId: number, mediaName: string) => void;
  onError: () => void;
  name: string;
  setName: (name: string) => void;
  tags: {
    list: string[] | undefined;
    push: (newTag: string) => void;
    drop: (deleted: string) => void;
    initTag: (tagList: string[]) => void;
  };
  originalImage: Blob | undefined;
  setOriginalImage: (image: Blob | undefined) => void;
  crops: Crop[];
  setCrops: (Crops: Crop[]) => void;
  imagesCrop: CropDto[];
  setImagesCrop: (iCrop: CropDto[]) => void;
  originalVideoURL: string | undefined;
  setOriginalVideoURL: (videoURL: string | undefined) => void;
  originalVideo: Blob | undefined;
  setOriginalVideo: (image: Blob | undefined) => void;
  portraitVideoURL: string | undefined;
  setPortraitVideoURL: (videoURL: string | undefined) => void;
  portraitVideo: Blob | undefined;
  setPortraitVideo: (image: Blob | undefined) => void;
}> = ({
  formType,
  mediaName,
  onSuccess,
  onError,
  name,
  setName,
  tags,
  originalImage,
  setOriginalImage,
  crops,
  setCrops,
  imagesCrop,
  setImagesCrop,
  originalVideoURL,
  setOriginalVideoURL,
  originalVideo,
  setOriginalVideo,
  portraitVideo,
  setPortraitVideo,
  portraitVideoURL,
  setPortraitVideoURL,
}) => {
  const history = useHistory();
  const [currentTag, setCurrentTag] = useState<Tag>();
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);
  const [settings, setSettings] = useState<ImageSettings>({brightness: 0, contrast: 0});
  const dispatch = useAppDispatch();
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);

  useEffect(() => {
    if (formType === 'edit' && selectedMedia) {
      const fetchVideoBlob = async () => {
        if (selectedMedia) {
          const videoBlob = await fetch(selectedMedia?.url).then((r) => r.blob());
          setOriginalVideo(videoBlob);
        }
      };
      setName(selectedMedia.name);
      tags.initTag(selectedMedia.tags!);
      setOriginalVideoURL(selectedMedia.url);
      setPortraitVideoURL(selectedMedia.portraitVideoUrl);
      setImagesCrop(selectedMedia.crops);
      selectedMedia.settings && setSettings(JSON.parse(selectedMedia.settings));
      fetchVideoBlob();
    } else {
      mediaName && setName(mediaName);
    }
  }, [selectedMedia]);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
  };

  const clearForm = () => {
    setName('');
    tags.initTag([]);
    setOriginalVideo(undefined);
    setOriginalVideoURL('');
    setPortraitVideo(undefined);
    setPortraitVideoURL('');
    setOriginalImage(undefined);
    setCrops([]);
    setImagesCrop([]);
    setUserClickedDelete(false);
    dispatch(unselectMedia());
  };

  const sendVideo = async () => {
    if (validateSave(name, originalVideo, originalVideoURL, crops.length, imagesCrop.length) && !isAnyStringEmpty([name])) {
      dispatch(showLoading());
      let duration = originalVideo ? await getBlobDuration(originalVideo) : undefined;
      if (!duration) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        dispatch(hideLoading());
        return;
      } else {
        duration = Math.trunc(duration);
      }

      let thumbnail = null;

      if (originalImage) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 570,
        };
        let file: any = originalImage;
        thumbnail = file && (await imageCompression(file, options));
        thumbnail = file && createFile(thumbnail, 'thumbnail.jpg');
      }

      if (formType === 'add') {
        const newVideo: CreateMediaVideoRequest = {
          name,
          tags: tags.list,
          originalVideo: originalVideo!,
          portraitVideo: portraitVideo,
          originalImage: originalImage!,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
          duration,
          thumbnail,
        };
        try {
          const mediaVideo = (await createVideo(newVideo)) as any;
          if (mediaVideo.response.status >= 200 && mediaVideo.response.status < 300) {
            onSuccess(mediaVideo.data.data.id, name);
            clearForm();
          } else {
            dispatch(hideLoading());
            onError();
          }
        } catch (e) {
          onError();
        } finally {
          dispatch(hideLoading());
        }
      }

      if (formType === 'edit') {
        const fullWidthTall = crops!.find((crop) => crop.type === 'full_width_tall');
        const fullWidthRegular = crops!.find((crop) => crop.type === 'full_width_regular');
        let newVideo: UpdateMediaVideoRequest;
        if (crops.length) {
          newVideo = {
            name,
            tags: tags.list,
            crops: crops.map((crop) => ({type: crop.type, data: crop.data})),
            originalVideo: originalVideo,
            portraitVideo: portraitVideo,
            originalImage: originalImage,
            fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
            fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
            duration,
            thumbnail,
          };
        } else {
          newVideo = {
            name,
            tags: tags.list,
            crops: [],
            originalVideo: originalVideo,
            portraitVideo: portraitVideo,
            originalImage: originalImage,
            duration,
          };
        }
        try {
          const mediaVideo = (await updateVideo(selectedMedia!.id, newVideo)) as any;
          if (mediaVideo.response.status >= 200 && mediaVideo.response.status < 300) {
            onSuccess(mediaVideo.data.data.id, name);
          } else {
            onError();
            dispatch(hideLoading());
          }
        } catch (e) {
          onError();
        } finally {
          dispatch(hideLoading());
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name, Video and Image can not be empty.'}));
    }
  };
  const onCancelVideoDetails = () => {
    history.push('/media-bank');
    dispatch(unselectMedia());
  };

  const onDeleteVideo = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      try {
        dispatch(showLoading());
        const res: any = await deleteMedia(selectedMedia!.id);
        dispatch(unselectMedia());
        res.response.status === 400
          ? res.data.error.includes('MEDIA_IS_CURRENTLY_IN_USE')
            ? dispatch(
                sendMessage({
                  severity: 'error',
                  summary: 'ERROR',
                  detail: 'Video can not be deleted because it is currently in use',
                })
              )
            : dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}))
          : dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Video successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handleOnClickNewVideo = () => {
    setOriginalVideo(undefined);
    setPortraitVideo(undefined);
    setOriginalVideoURL('');
    setPortraitVideoURL('');
  };

  return (
    <>
      <PageBody>
        <FormContainer>
          <FormElement type='input' label='Name' value={name ? name : ''} placeholder='Media name' onChange={setName} />
          <StyledFormElement>
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value.toLowerCase())}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ',') {
                    e.preventDefault(); // to avoid insert comma as currentTag
                    currentTag && !isAnyStringEmpty([currentTag]) && tags.push(currentTag);
                  }
                }}
              />
              <AddInput
                onClick={() => {
                  currentTag && !isAnyStringEmpty([currentTag]) && tags.push(currentTag);
                }}
              >
                Add
              </AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag
                  id={`${tag}_video`}
                  key={`${tag}_video`}
                  color={'#008C8C'}
                  onClick={() => {
                    tags.drop(tag);
                  }}
                >
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </FormContainer>
        <StyleAddVideoFilesCol>
          <Header>
            <label>Video</label>
            <ButtonLink onClick={handleOnClickNewVideo}>Upload New Video</ButtonLink>
          </Header>
          <StyleAddVideoFiles>
            {!originalVideoURL ? (
              <VideoUploader
                onUploadVideo={(video: Blob) => {
                  setOriginalVideo(video);
                  setOriginalVideoURL(URL.createObjectURL(video));
                }}
              >
                <p style={{color: 'red'}}>*Required</p>
                <p>Upload a video file (.mp4)</p>
                <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload File </Button>
              </VideoUploader>
            ) : (
              <VideoPlayer videoSrc={originalVideoURL} />
            )}
            {!portraitVideoURL ? (
              <VideoUploader
                onUploadVideo={(video: Blob) => {
                  setPortraitVideo(video);
                  setPortraitVideoURL(URL.createObjectURL(video));
                }}
                isPortrait
              >
                <p>Upload vertical view</p>
                <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload </Button>
              </VideoUploader>
            ) : (
              <VideoPlayer videoSrc={portraitVideoURL} />
            )}
          </StyleAddVideoFiles>

          <StyledFormElement>
            {formType === 'edit' ? (
              <ImageCropperManager
                mediaType='video'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                imagesCrop={imagesCrop}
                image={selectedMedia?.originalImageUrl || selectedMedia?.thumbnailUrl}
                imageForSettings={selectedMedia?.originalImageUrl || selectedMedia?.thumbnailUrl}
                settings={settings}
                setSettings={setSettings}
              />
            ) : (
              <ImageCropperManager
                settings={settings}
                setSettings={setSettings}
                mediaType='video'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                originalImage={originalImage}
              />
            )}
          </StyledFormElement>
        </StyleAddVideoFilesCol>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteVideo} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelVideoDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={sendVideo}>Save</SaveButton>
      </PageFooter>
    </>
  );
};
