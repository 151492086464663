import {useEffect} from 'react';
import {CardContent, CardImage, CardThumbnail, ImageTag, MediaType, CardTitle} from './style';
import Skeleton from 'react-loading-skeleton';

interface MediaCardProps {
  selectedMediaID: number | undefined; //selected Tool ID, state variable in the component.
  media: MediaDto;
  onClick: (mediaID: number) => void;
  loading?: boolean; //If already selected unselect, else, select.
}

export const MediaCard: React.FC<MediaCardProps> = ({loading, media, selectedMediaID, onClick}) => {
  const isSelected = selectedMediaID === media?.id;

  useEffect(() => {
    if (isSelected) {
      const element = document.getElementById(`${media?.id}_media`);
      element?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [isSelected]);

  return (
    <div className={'p-col-fixed'} id={media?.id + '_media'}>
      <CardContent
        className='box p-shadow-6'
        onClick={() => onClick(media?.id)}
        isSelected={loading === undefined ? isSelected : false}
      >
        <CardImage>
          {loading === undefined ? (
            <>
              <CardThumbnail src={media?.crops?.find((x) => x.type === 'full_width_tall')?.url} />
              {media.isInUse && (
                <ImageTag>
                  <i className='pi pi-check-circle' style={{alignSelf: 'center', marginRight: '5px'}}></i>In use
                </ImageTag>
              )}
              <MediaType>
                {media?.type === 'video' && (
                  <svg width='28' height='30' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.56438 19.7544C2.02896 19.7544 2.42385 19.57 2.88842 19.3049L16.4308 11.5369C17.3948 10.9722 17.7316 10.6034 17.7316 9.99252C17.7316 9.38169 17.3948 9.01288 16.4308 8.45967L2.88842 0.680146C2.42385 0.415066 2.02896 0.242188 1.56438 0.242188C0.704917 0.242188 0.170654 0.8876 0.170654 1.89029V18.0948C0.170654 19.0974 0.704917 19.7544 1.56438 19.7544Z'
                      fill='white'
                    />
                  </svg>
                )}
                {media?.type === 'image' && (
                  <svg width='30' height='25' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M3.53191 19H20.4787C22.766 19 24 17.7684 24 15.5263V3.46316C24 1.23158 22.766 0 20.4787 0H3.53191C1.24468 0 0 1.22105 0 3.46316V15.5263C0 17.7684 1.24468 19 3.53191 19ZM8.30851 9.61053C6.91489 9.61053 5.76596 8.47368 5.76596 7.08421C5.76596 5.71579 6.91489 4.56842 8.30851 4.56842C9.70213 4.56842 10.8404 5.71579 10.8404 7.08421C10.8404 8.47368 9.70213 9.61053 8.30851 9.61053ZM3.87234 16.4842C3.04255 16.4842 2.54255 15.9895 2.54255 15.1579V14.0947L5.02128 11.9474C5.44681 11.5684 5.8617 11.3789 6.35106 11.3789C6.82979 11.3789 7.31915 11.5789 7.75532 11.9579L9.64894 13.6421L14.3298 9.51579C14.8085 9.09474 15.3298 8.90526 15.8936 8.90526C16.4255 8.90526 16.9894 9.10526 17.4362 9.52632L21.4681 13.2105V15.1684C21.4681 15.9895 20.9149 16.4842 20.1383 16.4842H3.87234Z'
                      fill='white'
                    />
                  </svg>
                )}
                {media?.type === 'audio' && (
                  <svg width='30' height='28' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.6547 22C14.8547 22 15.7768 21.101 15.7768 19.9149V2.1101C15.7768 0.92395 14.8547 0 13.6547 0C12.9095 0 12.3032 0.274688 11.52 0.998865L6.79579 5.34393C6.73263 5.39387 6.66947 5.43133 6.59368 5.43133H3.30947C1.17474 5.43133 0 6.64245 0 8.82747V13.185C0 15.37 1.17474 16.5812 3.30947 16.5812H6.58105C6.65684 16.5812 6.72 16.5936 6.79579 16.6561L11.52 21.0261C12.2653 21.7128 12.8716 22 13.6547 22ZM20.0084 16.8683C20.8042 17.3428 21.7768 17.1555 22.2695 16.4688C23.3558 14.9705 24 13.0352 24 10.9875C24 8.92735 23.3558 7.00454 22.2695 5.49376C21.7768 4.81952 20.8042 4.61975 20.0084 5.1067C19.1495 5.61862 18.9347 6.62997 19.6547 7.8286C20.2232 8.7151 20.5389 9.82633 20.5389 10.9875C20.5389 12.1487 20.2232 13.2474 19.6547 14.1464C18.9347 15.3451 19.1495 16.3439 20.0084 16.8683Z'
                      fill='white'
                    />
                  </svg>
                )}
              </MediaType>
            </>
          ) : (
            <MediaType>
              <Skeleton />
            </MediaType>
          )}
        </CardImage>
        <CardTitle>{loading === undefined ? media?.name : <Skeleton width='90%' />}</CardTitle>
      </CardContent>
    </div>
  );
};
export default MediaCard;
