import {answerMessageToClient, assignInteractionToCounselor, getInteraction} from 'api/chatowl.api';
import moment from 'moment';
import {AnswerContainer} from 'pages/messaging/answer-message/styles';
import {InputTextarea} from 'primereact/inputtextarea';
import React, {useState} from 'react';
import reactStringReplace from 'react-string-replace';
import {
  AnswerButton,
  BoxAssignedTo,
  BoxMessage,
  BoxMessageContainer,
  BoxReceive,
  BoxSend,
  BoxTitle,
  Icon,
  InteractionInfoAnsweredAt,
  InteractionInfoAssignedTo,
  InteractionInfoRepliedAt,
  TextareaContainer,
} from './styles';

type MessageProps = {
  message: MessagesDto;
};

export const Message: React.FC<MessageProps> = ({message}) => {
  return (
    <BoxMessage>
      <BoxTitle>
        <div>
          <Icon className='pi pi-clock'></Icon>
          {message.time}
        </div>
        <div>
          <Icon className='pi pi-user'></Icon>
          {message.from}
        </div>
        <div>
          <Icon className='pi pi-refresh'></Icon>
          {message.since}
        </div>
      </BoxTitle>
      <BoxSend>{message.sent}</BoxSend>
      <BoxReceive>{message.received}</BoxReceive>
    </BoxMessage>
  );
};

type InteractionProps = {
  interaction: InteractionDTO;
  setCurrentSelectedInteractionId: any;
  selectedInteractionId?: string;
  searchValue?: string;
};
export const Interaction: React.FC<InteractionProps> = ({
  interaction,
  searchValue,
  setCurrentSelectedInteractionId,
  selectedInteractionId,
}) => {
  const [currentInteraction, setcurrentInteraction] = useState(interaction);
  const [messageValue, setMessageValue] = useState<string>('');
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const [loadingMedia, setLoadingMedia] = useState(true);

  const isSelected = selectedInteractionId === currentInteraction.id;

  React.useEffect(() => {
    if (isSelected) {
      const element = document.getElementById(`${currentInteraction.id}_interaction`);
      element?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [isSelected]);

  const handleLoaded = () => {
    setLoadingMedia(false);
  };

  const answerClientQuestion = async () => {
    const answ = {content: messageValue};
    await answerMessageToClient(currentInteraction.id, answ);
    await fetchCurrentInteraction();
  };

  const fetchCurrentInteraction = async () => {
    try {
      const response = (await getInteraction(currentInteraction.id)) as any;
      const resInteraction = response.data.data as InteractionDTO;
      setcurrentInteraction(resInteraction);
    } catch (error) {
      throw error; // TODO
    }
  };

  const getReg = () => {
    let expre = '(' + searchValue + ')';
    let re = new RegExp(expre, 'i');
    return re;
  };

  const unknownAssigned: boolean = currentInteraction.assignedCounselorEmail === '';
  const assignedToMe: boolean = currentInteraction.tags.includes('assigned_to_me');
  const assignedToTitle = unknownAssigned
    ? 'Assigned'
    : currentInteraction.assignedCounselorEmail && `Assigned to ${currentInteraction.assignedCounselorEmail}`;

  return (
    <BoxMessageContainer id={`${currentInteraction.id}_interaction`} isSelected={isSelected}>
      <BoxMessage>
        {currentInteraction.clientQuestion.map((question, index) => (
          <>
            {index === 0 && (
              <BoxTitle>
                <InteractionInfoAnsweredAt title={'Asked on ' + moment(question.date).format('YYYY/MM/DD h:mm:ss a')}>
                  <Icon className='pi pi-clock'></Icon>
                  {moment(question.date).calendar()}
                </InteractionInfoAnsweredAt>
                {((currentInteraction.assignedCounselorEmail && currentInteraction.counselorAnswer) ||
                  (assignedToMe && !currentInteraction.counselorAnswer)) && (
                  <InteractionInfoAssignedTo title={assignedToTitle}>
                    <Icon className='pi pi-user'></Icon>
                    {currentInteraction.assignedCounselorEmail}
                  </InteractionInfoAssignedTo>
                )}
                <InteractionInfoRepliedAt>
                  {currentInteraction.counselorAnswer && (
                    <span title={'Replied on ' + moment(currentInteraction.counselorAnswer.date).format('YYYY/MM/DD h:mm:ss a')}>
                      <Icon className='pi pi-refresh'></Icon>
                      {moment(currentInteraction.counselorAnswer.date).calendar()}
                    </span>
                  )}
                </InteractionInfoRepliedAt>
              </BoxTitle>
            )}

            <BoxSend>
              {question.type === 'text' && (
                <div>
                  {reactStringReplace(question.text, getReg(), (match, i) => (
                    <span key={i} style={{backgroundColor: '#f9fc3d', fontWeight: 'bold'}}>
                      {match}
                    </span>
                  ))}
                </div>
              )}
              {question.type === 'image' && (
                <img
                  style={{width: '100%', marginTop: '20px', maxHeight: '350px', objectFit: 'contain'}}
                  src={question.mediaUrl}
                  alt=''
                ></img>
              )}
              {question.type === 'audio' && (
                <audio controls key={question.mediaUrl} onLoadedData={handleLoaded}>
                  <source src={question.mediaUrl} type='audio/mpeg' />
                  Your browser does not support the audio tag.
                </audio>
              )}
              {question.type === 'video' && (
                <video width='100%' controls key={question.mediaUrl} onLoadedData={handleLoaded}>
                  <source src={question.mediaUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              )}
            </BoxSend>
          </>
        ))}

        {currentInteraction.counselorAnswer ? (
          <>
            <BoxReceive>
              <div>
                {reactStringReplace(currentInteraction.counselorAnswer.text, getReg(), (match, i) => (
                  <span key={i} style={{backgroundColor: '#f9fc3d', fontWeight: 'bold'}}>
                    {match}
                  </span>
                ))}
              </div>
            </BoxReceive>
          </>
        ) : !assignedToMe ? (
          <>
            {currentInteraction.tags.includes('unassigned') ? (
              <AnswerContainer>
                {showTextArea ? (
                  <>
                    <TextareaContainer>
                      <InputTextarea
                        autoFocus={isSelected}
                        onFocus={() => {
                          window.history.replaceState(null, '', `/messaging/${currentInteraction.id}`);
                          setCurrentSelectedInteractionId(currentInteraction.id);
                        }}
                        placeholder='Write you answer here...'
                        rows={5}
                        style={{minHeight: '50px', width: '94%', margin: '3% 3% 1% 3%'}}
                        value={messageValue}
                        onChange={(e: any) => setMessageValue(e.target.value)}
                      />
                    </TextareaContainer>
                    <AnswerButton onClick={async () => await answerClientQuestion()}>Send</AnswerButton>
                  </>
                ) : (
                  <AnswerButton
                    onClick={async () => {
                      await assignInteractionToCounselor(currentInteraction.id);
                      await fetchCurrentInteraction();
                      setShowTextArea(true);
                    }}
                  >
                    Assign to me and reply
                  </AnswerButton>
                )}
              </AnswerContainer>
            ) : (
              <BoxAssignedTo>
                <Icon className='pi pi-lock'></Icon> {assignedToTitle}
              </BoxAssignedTo>
            )}
          </>
        ) : (
          <AnswerContainer>
            <TextareaContainer>
              <InputTextarea
                autoFocus={isSelected}
                onFocus={() => {
                  window.history.replaceState(null, '', `/messaging/${currentInteraction.id}`);
                  setCurrentSelectedInteractionId(currentInteraction.id);
                }}
                placeholder='Write you answer here...'
                rows={5}
                style={{minHeight: '50px', width: '94%', margin: '3% 3% 1% 3%'}}
                value={messageValue}
                onChange={(e: any) => setMessageValue(e.target.value)}
              />
            </TextareaContainer>
            <AnswerButton onClick={async () => await answerClientQuestion()}>Send</AnswerButton>
          </AnswerContainer>
        )}
      </BoxMessage>
    </BoxMessageContainer>
  );
};
